<template>
  <div class="outting">
    <div class="select">
      <span class="title">新增账号</span>
      <el-button type="text" size="small" icon="el-icon-back" @click="goBackHandler">返回账号列表</el-button>
    </div>
    <div class="middle">
      <el-card shadow="always" style="margin-top:10px;">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" size="small">
          <el-divider content-position="left">
            账户信息
            <el-tooltip class="item" effect="light" content="用户用于登录的基本账号信息" placement="right-end">
              <i class="el-icon-info" style="margin-left: 5px"></i>
            </el-tooltip>
          </el-divider>

          <div style="display:flex;width:100%;">
            <div style="flex:1">
              <div class="grid-content bg-purple-dark">
                <el-form-item label="用户名 : " prop="name" class="item">
                  <el-input v-model="ruleForm.name" style="width:90%;"></el-input>
                </el-form-item>
              </div>
            </div>
            <div style="flex:1">
              <div class="grid-content bg-purple-dark">
                <el-form-item label="昵称 : " prop="nickName">
                  <el-input v-model="ruleForm.nickName" style="width:90%;"></el-input>
                </el-form-item>
              </div>
            </div>
            <div style="flex:1">
              <div class="grid-content bg-purple-dark">
                <el-form-item label="密码 : " prop="password">
                  <el-input v-model="ruleForm.password" style="width:90%;" show-password></el-input>
                </el-form-item>
              </div>
            </div>
          </div>

          <div style="display:flex;width:100%;margin-top:1rem;">
            <div style="flex:1;">
              <div class="grid-content bg-purple-dark">
                <el-form-item label="手机号 : " prop="mobile">
                  <el-input v-model="ruleForm.mobile" style="width:90%;"></el-input>
                </el-form-item>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple-dark" v-if="ifManagement">
                <el-form-item label="账号深度 : " prop="account">
                  <el-select v-model="ruleForm.account" placeholder="请选择账号深度" @change="changeAccount"
                    style="width:90%;">
                    <el-option label="3" value="3"></el-option>
                    <el-option label="5" value="5"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple-dark" v-if="ifManagement">
                <el-form-item label="所属角色 : " prop="role">
                  <el-select v-model="ruleForm.role" placeholder="请选择角色" @change="changeRole" style="width:90%;">
                    <el-option v-for="item in options" :key="item.id" :label="item.roleName" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
          <el-divider content-position="left">项目详情</el-divider>
          <div style="width:100%;display:flex;">
            <div style="flex:1">
              <div class="grid-content bg-purple-dark">
                <el-form-item label="项目名称 : " prop="projectName">
                  <el-input v-model="ruleForm.projectName" style="width:90%;"></el-input>
                </el-form-item>
              </div>
            </div>
            <div style="flex:1">
              <div class="grid-content bg-purple-dark">
                <el-form-item label="项目联系人 : " prop="projectContact">
                  <el-input v-model="ruleForm.projectContact" style="width:90%;"></el-input>
                </el-form-item>
              </div>
            </div>
            <div style="flex:1">
              <div class="grid-content bg-purple-dark">
                <el-form-item label="联系人手机 : " prop="projectMobile">
                  <el-input v-model="ruleForm.projectMobile" style="width:90%;"></el-input>
                </el-form-item>
              </div>
            </div>
          </div>

          <div style="width:100%;display:flex;margin-top:1rem;">
            <div style="flex:1;">
              <div class="grid-content bg-purple-dark">
                <el-form-item label="所在地区 : " prop="sanji">
                  <el-cascader :props="props" v-model="ruleForm.sanji" @change="getChange" placeholder="请选择省/市/区"
                    ref="cascader" style="width:90%;"></el-cascader>
                </el-form-item>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple-dark">
                <el-form-item label="详细地址 : " prop="address">
                  <el-input v-model="ruleForm.address" style="width:90%;"></el-input>
                </el-form-item>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple-dark">
                <el-form-item label="联系人电话 : " prop="projectTelephone">
                  <el-input v-model="ruleForm.projectTelephone" style="width:90%;"></el-input>
                </el-form-item>
              </div>
            </div>
          </div>
          <!-- <el-row :gutter="15" style="margin-bottom:0px;">
            <el-col :span="8">
              <div class="grid-content bg-purple-dark" v-if="ifManagement">
                <el-form-item label="报警方式 : " prop="type">
                  <el-checkbox-group v-model="ruleForm.type" @change="changeWayHandler">
                    <el-checkbox label="短信" name="type" :check="this.thisSendMsg"></el-checkbox>
                    <el-checkbox label="语音" name="type" :check="this.thisCallMobile"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content bg-purple-dark">
                <div class="grid-content bg-purple-dark">
                  <el-form-item label="账号状态 : " prop="status">
                    <el-switch v-model="status" @change="changeOpen"></el-switch>
                  </el-form-item>
                </div>
              </div>
            </el-col>
          </el-row>-->
          <div style="width:100%;display:flex;margin-top:1rem;">
            <div style="flex:1;">
              <div class="grid-content bg-purple-dark" v-if="ifManagement">
                <el-form-item label="报警方式 : " prop="type">
                  <el-checkbox-group v-model="ruleForm.type" @change="changeWayHandler">
                    <el-checkbox label="短信" name="type" :check="this.thisSendMsg"></el-checkbox>
                    <el-checkbox label="语音" name="type" :check="this.thisCallMobile"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple-dark" v-if="ifManagement">
                <el-form-item label="报警温度 : " prop="type">
                  <el-radio-group v-model="ruleForm.isNotify" @change="isNotifyFn()">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                  </el-radio-group>
                  <span v-if="dialogVisible" >
                        <el-input placeholder="" size="mini" style="width:50px;margin-left:10px" v-model="ruleForm.notifyData"
           >
          </el-input>℃
                  </span>
                
                </el-form-item>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple-dark">
                <div class="grid-content bg-purple-dark">
                  <el-form-item label="账号状态 : " prop="status">
                    <el-switch v-model="status" @change="changeOpen"></el-switch>
                  </el-form-item>
                </div>
              </div>
            </div>
            <!-- <div style="flex:1;"></div> -->
          </div>
          <el-row :gutter="15"></el-row>
          <el-row :gutter="15">
            <el-col :span="8">
              <div class="grid-content bg-purple-dark" v-if="!ifManagement">
                <el-divider content-position="left">您当前登录的账号等级为为{{this.current}} 级，您还可以可以向下创建 {{this.other}}级账号
                </el-divider>
              </div>
            </el-col>
          </el-row>
          <el-form-item style="float:right">
            <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
        <!-- <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
          <el-input placeholder="请输入内容" size="mini" style="width:80px" v-model="ruleForm.notifyData"
            :disabled="disabledNo">
          </el-input> <i class="el-icon-edit" v-if="disabledNo"></i> <i v-else class="el-icon-check"></i>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
          </span>
        </el-dialog> -->

      </el-card>
    </div>
  </div>
</template>
<script>
  export default {
    name: "SysUserAdd",
    data() {
      let that = this;
      //密码不能输入汉字验证
      var checkData = (rule, value, callback) => {
        if (value) {
          if (/[\u4E00-\u9FA5]/g.test(value)) {
            callback(new Error("密码不能输入汉字!"));
          } else {
            callback();
          }
        }
        callback();
      };
      //手机号验证
      var checkPhone = (rule, value, callback) => {
        const phoneReg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
        if (!value) {
          return callback(new Error("电话号码不能为空"));
        }
        setTimeout(() => {
          if (!Number.isInteger(+value)) {
            callback(new Error("请输入数字值"));
          } else {
            if (phoneReg.test(value)) {
              callback();
            } else {
              callback(new Error("电话号码格式不正确"));
            }
          }
        }, 100);
      };

      //固定电话验证验证
      var checkTelephone = (rule, value, callback) => {
        const phoneReg = /^(\d{3,4}-)?\d{7,8}$/;
        if (!value) {
          callback();
          return;
        }
        setTimeout(() => {

          if (phoneReg.test(value)) {
            callback();
          } else {
            callback(new Error("电话号码格式不正确,正确格式参考(区号-区段号码)"));
          }

        }, 100);
      };
      //昵称验证
      var checkNickName = (rule, value, callback) => {
        if (value) {
          if (this.ruleForm.nickName.length > 20) {
            callback(new Error("不能超过20个字符!"));
          } else {
            callback();
          }
        }
        callback();
      };
      return {
        ruleForm: {
          name: "",
          nickName: "",
          password: "",
          mobile: "",
          projectName: "",
          projectContact: "",
          projectMobile: "",
          projectTelephone: "",
          sanji: "",
          address: "",
          role: "",
          account: "",
          delivery: false,
          type: [],
          isNotify: "0",
          notifyData: '60',
        },
        value: [],
        code: 0,
        dialogVisible: false,
 
        //三级联动获取数据
        props: {
          lazy: true,
          value: "code",
          label: "name",
          lazyLoad(node, resolve) {
            var token = that.$store.state.userinfo.token;
            console.log(node);
            const {
              level
            } = node;
            if (level > 0) {
              that.code = node.data.code;
            }
            if (level == 1) {
              that.province = node.data.name;
            }
            if (level == 2) {
              that.city = node.data.name;
            }
            that.$api
              .areaSanjiHandler(that.code, token)
              .then(res => {
                const nodes = res.data.data;
                if (level >= 1) {
                  nodes.forEach(item => {
                    item.leaf = level == 2;
                  });
                }
                resolve(nodes);
              })
              .catch(err => {
                console.log(err);
              });
          }
        },
        accountId: "",
        roleId: [],
        status: true,
        isCallMobile: 0,
        isSendMsg: 0,
        thisCallMobile: "",
        thisSendMsg: "",
        current: "",
        other: "",
        ifManagement: "",
        isEnable: 0,
        province: "",
        city: "",
        area: "",

        options: [],

        rules: {
          name: [{
              required: true,
              message: "请输入用户名称",
              trigger: "blur"
            },
            {
              min: 1,
              max: 20,
              message: "长度在 1 到 20 个字符",
              trigger: "blur"
            },
            {
              pattern: "^[\u4e00-\u9fa5A-Za-z0-9-_]{1,20}$",
              message: "用户名是必填项,只能中英文，数字，下划线，减号组成，且不能超过20位",
              trigger: "blur"
            }
          ],
          nickName: [{
              required: false,
              min: 1,
              max: 20,
              message: "长度在 1 到 20 个字符",
              trigger: "blur"
            }
            //  { validator: checkNickName, trigger: "blur" }
          ],
          password: [{
              required: true,
              message: "请输入用户密码",
              trigger: "blur"
            },
            {
              min: 6,
              max: 18,
              message: "长度在 6 到 18 个字符",
              trigger: "blur"
            },
            {
              validator: checkData,
              trigger: "blur"
            },
            {
              pattern: "^[\u4e00-\u9fa5A-Za-z0-9-_+]{1,18}$",
              message: "密码只能中英文，数字，下划线，减号,加号组成，且不能小于6位,大于18位",
              trigger: "blur"
            }
          ],
          mobile: [{
            required: true,
            validator: checkPhone,
            trigger: "blur"
          }],
          projectName: [{
            required: true,
            message: "请输入项目名称",
            trigger: "blur"
          }],
          projectContact: [{
            required: true,
            message: "请输入项目联系人",
            trigger: "blur"
          }],
          projectTelephone: [{
            required: false,
            validator: checkTelephone,
            trigger: "blur"
          }],
          projectMobile: [{
            required: true,
            validator: checkPhone,
            trigger: "blur"
          }],
          // type: [
          //   { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
          // ],
          sanji: [{
            required: true,
            message: "请选择省/市/区",
            trigger: "change"
          }],
          address: [{
              required: true,
              message: "请输入详细地址",
              trigger: "blur",
            },
            {
              min: 1,
              max: 30,
              message: "长度在 1 到 30 个字符",
            },
          ],
          role: [{
            required: true,
            message: "请选择角色",
            trigger: "change"
          }],
          account: [{
            required: true,
            message: "请选择账号深度",
            trigger: "change"
          }]
        }
      };
    },
    created() {
      var token = this.$store.state.userinfo.token;
      //角色列表请求
      this.$api
        .roleListSelect(token)
        .then(res => {
          this.options = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
      //
      this.$api
        .userDetail(token)
        .then(res => {
          // console.log(res.data.data)
          this.current = res.data.data.accountLevel;
          this.other = 3 - this.current;
          if (res.data.data.accountLevel == 0) {
            this.ifManagement = true;
          } else {
            this.ifManagement = false;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    methods: {
      //页面返回函数
      goBackHandler() {
        this.$router.replace("/sysUser");
      },
      //选择账号深度函数
      changeAccount(status) {
        console.log(status);
        this.accountId = Number(status);
      },
      //选择角色层级函数
      changeRole(status) {
        console.log(status);
        this.roleId.push(Number(status));
      },
      //账号开启函数
      changeOpen() {
        console.log(this.status);
        if (this.status == true) {
          this.isEnable = 0;
        } else {
          this.isEnable = 1;
        }
      },
      //报警方式函数
      changeWayHandler(eve) {
        console.log(eve);
        console.log(this.isCallMobile);

        if (eve.indexOf("语音") == -1) {
          this.isCallMobile = 0;
        } else {
          this.isCallMobile = 1;
        }
        if (eve.indexOf("短信") == -1) {
          this.isSendMsg = 0;
        } else {
          this.isSendMsg = 1;
        }
        console.log(this.isCallMobile, this.isSendMsg);
      },
      //提交函数
      submitForm(formName) {
        console.log(this.accountId);
        this.$refs["ruleForm"].validate(valid => {
          if (valid) {
            var token = this.$store.state.userinfo.token;
            if (this.ifManagement) {
              this.$api
                .addUserHandler({
                    accountDegree: this.accountId,
                    address: this.ruleForm.address,
                    area: this.area,
                    city: this.city,
                    isCallMobile: this.isCallMobile,
                    isEnable: Number(this.isEnable),
                    isSendMsg: this.isSendMsg,
                    mobile: this.ruleForm.mobile,
                    nickName: this.ruleForm.nickName,
                    password: this.ruleForm.password,
                    projectContact: this.ruleForm.projectContact,
                    projectMobile: this.ruleForm.projectMobile,
                    fixedPhone: this.ruleForm.projectTelephone,
                    projectName: this.ruleForm.projectName,
                    province: this.province,
                    roleIds: this.roleId,
                    username: this.ruleForm.name,
                    isNotify: this.ruleForm.isNotify,
                    notifyData: this.ruleForm.notifyData,
                  },
                  token
                )
                .then(res => {
                  console.log(res);
                  if (res.data.code == 200) {
                    this.$message.success("创建成功!");
                    this.$router.replace("/sysUser");
                  }
                })
                .catch(err => {
                  console.log(err);
                });
            } else {
              console.log("提交");
              this.$api
                .addUserHandler({
                    address: this.ruleForm.address,
                    area: this.area,
                    city: this.city,
                    isCallMobile: this.isCallMobile,
                    isEnable: Number(this.isEnable),
                    isSendMsg: this.isSendMsg,
                    mobile: this.ruleForm.mobile,
                    nickName: this.ruleForm.nickName,
                    password: this.ruleForm.password,
                    projectContact: this.ruleForm.projectContact,
                    projectMobile: this.ruleForm.projectMobile,
                    projectName: this.ruleForm.projectName,
                    province: this.province,
                    username: this.ruleForm.name
                  },
                  token
                )
                .then(res => {
                  console.log(res);
                  if (res.data.code == 200) {
                    this.$message.success("创建成功!");
                    this.$router.replace("/sysUser");
                  }
                })
                .catch(err => {
                  console.log(err);
                });
            }
          } else {
            return false;
          }
        });
      },
      //清空重置函数
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      // 是否选择报警温度
      isNotifyFn() {
        if (this.ruleForm.isNotify == '1') {
        this.dialogVisible = true
        }else{
            this.dialogVisible = false
        }

      },
       handleClose(done) {
      
            done();
        
      
    },
      getChange(val) {
        console.log(this.ruleForm.type);
        var result = this.$refs["cascader"].getCheckedNodes(true);
        // console.log(result[0].data.name);
        this.area = result[0].data.name;
      }
    }
  };
</script>
<style lang="less" scoped>
  @border-bottom-color: #e7eaed;
  @toolBarFontColor: #111d;

  .outting {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #fafafa;

    .select {
      height: 46px;
      overflow: hidden;
      border-bottom: 1px solid #cccccc;
      padding: 0rem 3rem;

      .title {
        display: inline-block;
        line-height: 46px;
        font-weight: bold;
        font-size: 0.8rem;
      }

      button {
        color: @toolBarFontColor;
        font-size: 0.8rem;
        float: right;
        margin-top: 7px;
      }
    }

    .middle {
      padding: 0 3rem;

      .demo-ruleForm {
        width: 90%;
        margin: 0 auto;
      }

      .el-row {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .el-col {
        border-radius: 4px;
      }

      // .bg-purple-dark {
      //   background: #99a9bf;
      // }

      .grid-content {
        border-radius: 4px;
        min-height: 36px;
      }

      .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
      }
    }
  }

  .el-form-item__label {
    font-size: 12px !important;
  }
</style>