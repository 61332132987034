<template>
  <div class="outting">
    <div class="select">
      <span class="title">产品详情</span>
      <el-button type="text" size="small" icon="el-icon-back" @click="goBackHandler">返回产品列表</el-button>
    </div>
    <div class="information">
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix">
          <span>产品基本信息</span>
          <el-button style="float: right; padding: 3px 0;margin-left:10px;" type="text" size="small"
            icon="el-icon-upload2" @click="updateProductHandler" v-show="ifEditclick">保存修改</el-button>
          <el-button style="float: right; padding: 3px 0" type="text" size="small" icon="el-icon-edit"
            @click="editOpen">编辑</el-button>
        </div>
        <!-- <div> -->
        <!-- <el-row :gutter="20">
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <span class="product-span">产品名称</span>
                <div class="product-vv">
                  <input
                    size="mini"
                    :placeholder="name"
                    v-model="input"
                    :class="ifEdit?'inputEnable':'inputDisable'"
                    :disabled="ifEdit"
                  />
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <span class="product-span">所属品类</span>
                <div class="product-vv">{{ categoryName }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <span class="product-span">协议名称</span>
                <div class="product-vv">{{ protocolName }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <span class="product-span">创建账号</span>
                <div class="product-vv">{{ manufacturerName }}</div>
              </div>
            </el-col>
        </el-row>-->
        <!-- <el-row :gutter="20">
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <span class="product-span">创建时间</span>
                <div class="product-vv">{{ createTime }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <span class="product-span">更新时间</span>
                <div class="product-vv">{{ updateTime }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <span class="product-span">设备数量</span>
                <div class="product-vv">{{ deviceNum }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple"></div>
            </el-col>
        </el-row>-->

        <!-- </div> -->
        <div style="display:flex;">
          <div style="flex:1;">
            <div class="grid-content bg-purple">
              <span class="product-span">产品名称</span>
              <div class="product-vv">
                <el-input size="mini" :placeholder="name" v-model="input" :disabled="ifEdit" style="width:80%;">
                </el-input>
              </div>
            </div>
          </div>
          <div style="flex:1;">
            <div class="grid-content bg-purple">
              <span class="product-span">所属品类</span>
              <div class="product-vv">{{ categoryName }}</div>
            </div>
          </div>
          <div style="flex:1;">
            <div class="grid-content bg-purple">
              <span class="product-span">品类类型</span>
              <div class="product-vv">{{ categoryType == '0'?'自有':categoryType == '1'?'第三方':"" }}</div>
            </div>
          </div>

          <div style="flex:1;">
            <div class="grid-content bg-purple">
              <span class="product-span">创建账号</span>
              <div class="product-vv">{{ manufacturerName }}</div>
            </div>
          </div>
          <div style="flex:1;">
            <div class="grid-content bg-purple" v-if="protocolName">
              <span class="product-span">协议名称</span>
              <div class="product-vv">{{ protocolName }}</div>
            </div>
          </div>
        </div>
        <div style="display:flex;margin-top:1rem;">
          <div style="flex:1;">
            <div class="grid-content bg-purple">
              <span class="product-span">创建时间</span>
              <div class="product-vv">{{ createTime }}</div>
            </div>
          </div>
          <div style="flex:1;">
            <div class="grid-content bg-purple">
              <span class="product-span">更新时间</span>
              <div class="product-vv">{{ updateTime }}</div>
            </div>
          </div>
          <div style="flex:1;">
            <div class="grid-content bg-purple">
              <span class="product-span">设备数量</span>
              <div class="product-vv">{{ deviceNum }}</div>
            </div>
          </div>
          <div style="flex:1;">
            <div class="grid-content bg-purple">
              <span class="product-span">接入类型</span>
              <div class="product-vv">{{ joinType  == '0'?'NB':joinType == '1'?'WIFI':"" }}</div>
            </div>
          </div>
          <div style="flex:1;" v-if="netOperatorName">
            <div class="grid-content bg-purple">
              <span class="product-span">运营商类型</span>
              <div class="product-vv">
                {{ netOperatorName == '0'?'移动':netOperatorName == '1'?'电信':netOperatorName == '2'?'联通':''}}</div>
            </div>
          </div>
          <div style="flex:1;" v-if="protocolServerType">
            <div class="grid-content bg-purple">
              <span class="product-span">服务器类型</span>
              <div class="product-vv">{{ protocolServerType  == '0'?'阿里云':protocolServerType == '1'?'吻胜云':"" }}</div>
            </div>
          </div>
          <!-- <div style="flex:1;"></div> -->
        </div>
        <div style="display:flex;margin-top:1rem;">
          <div style="flex:1;">
            <div class="grid-content bg-purple">
              <span class="product-span">图片信息</span>
              <div class="product-vv">
                <el-image v-if="productImgUrl"
                  style="width: 100px; height: 100px;display: inline-block; vertical-align: middle;"
                  :src="productImgUrl" referrerpolicy="no-referrer"></el-image>
                <span class="detail-span" v-else>
                  暂无数据
                </span>
                <el-upload action="#" :auto-upload='false'
                  style="display: inline-block; vertical-align: middle;margin-left:15px;" :on-change="handleChange"
                  list-type="picture-card" v-show="!isValue" :show-file-list="false">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <!-- <el-dialog :visible.sync="dialogVisibleImg">
                  <img width="100%" :src="dialogImageUrl" alt />
                </el-dialog>
                <div v-show="isValue" class="el-upload-list el-upload-list--picture-card">
                  <div class="el-upload-list__item is-success">
                    <img class="avatar" v-show="isValue" :src="isValue" />
                    <label class="el-upload-list__item-status-label">
                      <i class="el-icon-upload-success el-icon-check"></i>
                    </label>
                    <span class="el-upload-list__item-actions">
                      <span class="el-upload-list__item-preview">
                        <i class="el-icon-zoom-in" @click.stop="handlePictureCardPreview"></i>
                      </span>
                      <span class="el-upload-list__item-delete">
                        <i class="el-icon-delete" @click.stop="handleRemove"></i>
                      </span>
                    </span>
                  </div>
                </div> -->
              </div>
            </div>
          </div>

          <div style="flex:1;"></div>
        </div>
      </el-card>
      <el-card class="box-card" shadow="never" style="padding-bottom: 1rem;margin-top: 1rem">
        <div slot="header" class="clearfix">
          <span>产品数据点</span>
          <el-button style="float: right; padding: 3px 0" type="text" size="small" icon="el-icon-plus" @click="addOpen">
            新增数据点</el-button>
          <el-button style="float: right; padding: 3px 0;margin-right:7px;" type="text" size="small"
            icon="el-icon-refresh-right" @click="refrash">刷新数据点缓存</el-button>
        </div>

        <div style="display:flex;">
          <div style="flex:1;">
            <div class="grid-content bg-purple">
              <el-input v-model="inputDataPointKey" placeholder="请输入数据点" size="small" style="width:80%;"></el-input>
            </div>
          </div>
          <div style="flex:1;">
            <div class="grid-content bg-purple">
              <el-input v-model="inputDataPointShowName" placeholder="请输入数据点名称" size="small" style="width:80%;">
              </el-input>
            </div>
          </div>
          <div style="flex:1;">
            <div class="grid-content bg-purple">
              <el-select v-model="disDataPointType " placeholder="请选择数据点类型" size="small"
                @change="changeSelectDataPointType" style="width:80%;">
                <el-option label="火警" value="0"></el-option>
                <el-option label="故障" value="1"></el-option>
                <el-option label="一般数据点" value="2"></el-option>
              </el-select>
            </div>
          </div>
          <div style="flex:1;">
            <div class="grid-content bg-purple">
              <el-select v-model="disDataType" placeholder="请选择数据类型" size="small" @change="changeSelectDataType"
                style="width:80%;">
                <el-option label="整型" value="0"></el-option>
                <el-option label="浮点型" value="1"></el-option>
                <el-option label="string" value="2"></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div style="display:flex;margin-top:1rem;">
          <div style="flex:1;">
            <div class="grid-content bg-purple">
              <el-select v-model="disNeedHandle" placeholder="数据点是否需要处理" size="small" @change="changeSelectNeedHandle"
                style="width:80%;">
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
              </el-select>
            </div>
          </div>
          <div style="flex:1;">
            <div class="grid-content bg-purple">
              <el-select v-model="disNeedShowOnDevice" placeholder="数据点是否需要展示" @change="changeSelectNeedShowOnDevice"
                size="small" style="width:80%;">
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
              </el-select>
            </div>
          </div>
          <div style="flex:1;"></div>
          <div style="flex:1;"></div>
        </div>
        <div style="display:flex;margin-top:1rem;margin-bottom:1rem;">
          <div style="flex:1;"></div>
          <div style="flex:1;"></div>
          <div style="flex:1;"></div>
          <div style="flex:1;">
            <div style="width:80%;overflow:hidden;">
              <el-button size="small" @click="clearHandler" style="float:right;margin-left:1rem;">清空</el-button>
              <el-button size="small" type="primary" @click="checkHandler" style="float:right;">搜索</el-button>
            </div>
          </div>
        </div>
        <el-table style="width: 100%" stripe size="small" :header-cell-style="{'background-color': '#eeeeee'}"
          :data="tableData">
          <el-table-column prop="dataPointKey" label="数据点" min-width="1" fixed align="center">
            <template slot-scope="{row}">{{ row.dataPointKey || '暂无数据' }}</template>
          </el-table-column>
          <el-table-column prop="dataPointShowName" label="数据点名称" min-width="1" fixed align="center">
            <template slot-scope="{row}">{{ row.dataPointShowName || '暂无数据' }}</template>
          </el-table-column>
          <el-table-column prop="dataPointType" label="数据点类型" min-width="1" fixed align="center"
            :formatter="stateFormatDataPointType">
            <!-- <template></template> -->
          </el-table-column>
          <el-table-column prop="dataType" label="数据类型" min-width="1" fixed align="center"
            :formatter="stateFormatDataType">
            <!-- <template slot-scope="{row}">{{ row.dataType || '暂无数据' }}</template> -->
          </el-table-column>
          <el-table-column prop="needHandle" label="数据点是否需要处理" min-width="1" fixed align="center">
            <template slot-scope="{row}">{{ row.needHandle==1 ? '需要':'不需要' }}</template>
          </el-table-column>
          <el-table-column prop="needShowOnDevice" label="是否展示" min-width="1" fixed align="center">
            <template slot-scope="{row}">{{ row.needShowOnDevice==1 ? '需要':'不需要' }}</template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" min-width="2" fixed align="center">
            <template slot-scope="{row}">{{ row.createTime || '暂无数据' }}</template>
          </el-table-column>
          <el-table-column prop="updateTime" label="修改时间" min-width="2" fixed align="center">
            <template slot-scope="{row}">{{ row.updateTime || '暂无数据' }}</template>
          </el-table-column>
          <el-table-column min-width="1" label="操作" width="180" align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row)"
                style="margin-right:10px;">编辑</el-button>
              <el-popconfirm title="确定删除这个数据点吗？" @onConfirm="deleteDataPoint()">
                <el-button type="text" size="small" icon="el-icon-delete" @click="handleDelete(scope.$index, scope.row)"
                  slot="reference">删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background layout="prev, pager, next" :total="total" style="float:right;margin-top:10px;"
          @current-change="changeCurrent" :page-size="eveTotal" v-if="ifpag" :current-page.sync="currentPage">
        </el-pagination>
      </el-card>
    </div>
    <!-- 新增数据点对话框开始 -->
    <el-dialog title="新增数据点" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form :model="form" size="small" :inline="true" style="width:700px" :rules="rules" ref="form">
        <el-form-item label="数据点" :label-width="formLabelWidth" prop="dataPointKey">
          <el-input v-model.trim="form.dataPointKey" autocomplete="off" style="width:215px"></el-input>
        </el-form-item>
        <el-form-item label="数据点名称" :label-width="formLabelWidth" prop="dataPointShowName">
          <el-input v-model.trim="form.dataPointShowName " autocomplete="off" style="width:215px"></el-input>
        </el-form-item>
        <el-form-item label="数据点类型" :label-width="formLabelWidth" prop="dataPointType">
          <el-select v-model="form.dataPointType " placeholder="请选择数据点类型" @change="changeDataPointType">
            <el-option label="火警" value="0"></el-option>
            <el-option label="故障" value="1"></el-option>
            <el-option label="普通" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数据类型" :label-width="formLabelWidth" prop="dataType">
          <el-select v-model="form.dataType" placeholder="请选择数据类型" @change="changeDataType">
            <el-option label="整型" value="0"></el-option>
            <el-option label="浮点型" value="1"></el-option>
            <el-option label="string" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否处理" :label-width="formLabelWidth" prop="needHandle">
          <el-select v-model="form.needHandle " placeholder="数据点是否需要处理" @change="changeNeedHandle">
            <el-option label="不需要" value="0"></el-option>
            <el-option label="需要" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否展示" :label-width="formLabelWidth" prop="needShowOnDevice">
          <el-select v-model="form.needShowOnDevice " placeholder="是否需要在设备实时状态处展示" @change="changeNeedShowOnDevice">
            <el-option label="不需要" value="0"></el-option>
            <el-option label="需要" value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="reset" size="small">重 置</el-button>
        <el-button type="primary" @click="submitForm('form')" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 新增数据点对话框结束 -->
    <!-- 编辑数据点对话框开始 -->
    <el-dialog title="编辑数据点" :visible.sync="editDialogFormVisible" :close-on-click-modal="false">
      <el-form :model="editForm" size="small" :inline="true" style="width:700px" :rules="editRules" ref="editForm">
        <el-form-item label="数据点" :label-width="formLabelWidth" prop="dataPointKey">
          <el-input v-model.trim="editForm.dataPointKey" autocomplete="off" style="width:215px"></el-input>
        </el-form-item>
        <el-form-item label="数据点名称" :label-width="formLabelWidth" prop="dataPointShowName">
          <el-input v-model.trim="editForm.dataPointShowName " autocomplete="off" style="width:215px"></el-input>
        </el-form-item>
        <el-form-item label="数据点类型" :label-width="formLabelWidth" prop="dataPointType">
          <el-select v-model="editForm.dataPointType " placeholder="请选择数据点类型" @change="editChangeDataPointType">
            <el-option label="火警" value="0"></el-option>
            <el-option label="故障" value="1"></el-option>
            <el-option label="普通" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数据类型" :label-width="formLabelWidth" prop="dataType">
          <el-select v-model="editForm.dataType" placeholder="请选择数据类型" @change="editChangeDataType">
            <el-option label="整型" value="0"></el-option>
            <el-option label="浮点型" value="1"></el-option>
            <el-option label="string" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否处理" :label-width="formLabelWidth" prop="needHandle">
          <el-select v-model="editForm.needHandle " placeholder="数据点是否需要处理" @change="editChangeNeedHandle">
            <el-option label="不需要" value="0"></el-option>
            <el-option label="需要" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否展示" :label-width="formLabelWidth" prop="needShowOnDevice">
          <el-select v-model="editForm.needShowOnDevice " placeholder="是否需要在设备实时状态处展示"
            @change="editChangeNeedShowOnDevice">
            <el-option label="不需要" value="0"></el-option>
            <el-option label="需要" value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="editSubmitForm('editForm')" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 编辑数据点对话框结束 -->
  </div>
</template>
<script>
  import Bus from "../../../components/bus";
  export default {
    name: "ProductDetail",
    data() {
      return {
        currentPage: 0,
        tableData: [],
        name: "",
        imgDisabled: true,
        categoryName: "",
        protocolName: "",
        categoryType: "", //品类类型
        netOperatorName: "", //运营商类型
        productImgUrl: "", //
        joinType: "", //
        protocolServerType: "", //服务器
        isValue: "", //展示图片
        dialogImageUrl: "",
        manufacturerName: "",
        dialogVisibleImg: false,
        file: {},
        createTime: "",
        updateTime: "",
        deviceNum: "",
        page: 1,
        id: "",
        input: "",
        ifpag: false,
        ifEdit: true,
        ifEditclick: false,
        dialogFormVisible: false,
        editDialogFormVisible: false,
        total: 0,
        eveTotal: 5,
        inputDataPointKey: "",
        success: "",
        //新增数据点请求字段
        sendDataPointType: "",
        sendDataType: "",
        sendNeedHandle: "",
        sendNeedShowOnDevice: "",
        //删除数据点
        deleteDataPointId: "",
        form: {
          dataPointKey: "",
          dataPointShowName: "",
          dataPointType: "",
          dataType: "",
          needHandle: "",
          needShowOnDevice: ""
        },
        editForm: {
          dataPointKey: "",
          dataPointShowName: "",
          dataPointType: "",
          dataType: "",
          needHandle: "",
          needShowOnDevice: ""
        },
        //编辑数据发送
        editSendDataPointType: "",
        editSendDataType: "",
        editSendNeedHandle: "",
        editSendNeedShowOnDevice: "",
        dataPointId: "",
        formLabelWidth: "100px",
        rules: {
          dataPointKey: [{
            required: true,
            message: "请输入数据点",
            trigger: "blur"
          }],
          dataPointShowName: [{
            required: true,
            message: "请输入数据点",
            trigger: "blur"
          }],
          dataPointType: [{
            required: true,
            message: "请选择数据点类型",
            trigger: "change"
          }],
          dataType: [{
            required: true,
            message: "请选择数据类型",
            trigger: "change"
          }],
          needHandle: [{
            required: true,
            message: "请选择是否需要处理",
            trigger: "change"
          }],
          needShowOnDevice: [{
            required: true,
            message: "请选择是否需要在设备实时状态处展示",
            trigger: "change"
          }]
        },
        editRules: {
          dataPointKey: [{
            required: true,
            message: "请输入数据点",
            trigger: "blur"
          }],
          dataPointShowName: [{
            required: true,
            message: "请输入数据点",
            trigger: "blur"
          }],
          dataPointType: [{
            required: true,
            message: "请选择数据点类型",
            trigger: "change"
          }],
          dataType: [{
            required: true,
            message: "请选择数据类型",
            trigger: "change"
          }],
          needHandle: [{
            required: true,
            message: "请选择是否需要处理",
            trigger: "change"
          }],
          needShowOnDevice: [{
            required: true,
            message: "请选择是否需要在设备实时状态处展示",
            trigger: "change"
          }]
        },
        inputDataPointShowName: "",
        value: "",
        //
        selectDataPointType: "",
        selectDataType: "",
        selectNeedShowOnDevice: "",
        selectNeedHandle: "",
        disNeedShowOnDevice: "",
        disNeedHandle: "",
        disDataType: "",
        disDataPointType: ""
      };
    },
    created() {
      this.id = sessionStorage.getItem("id");
      this.$api.getProductDetial(this.id).then(res => {
        this.name = res.data.data.name;
        this.categoryName = res.data.data.categoryName;
        this.protocolName = res.data.data.protocolName;
        this.categoryType = res.data.data.categoryType;
        this.netOperatorName = res.data.data.netOperatorName;
        this.productImgUrl = res.data.data.productImgUrl;
        this.joinType = res.data.data.joinType;
        this.protocolServerType = res.data.data.protocolServerType;
        this.manufacturerName = res.data.data.manufacturerName;
        this.createTime = res.data.data.createTime;
        this.updateTime = res.data.data.updateTime;
        this.deviceNum = res.data.data.deviceNum;
      });
      this.$api
        .getDataPointList({
          // page: {
          page: {
            current: this.page,
            size: 5
          },
          // query: {}
          // },
          productId: Number(this.id)
        })
        .then(res => {
          if (res.data.data.records.length !== 0) {
            this.ifpag = true;
          } else {
            this.ifpag = false;
          }
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        });
    },
    methods: {
      refrash() {
        this.$api.refrashHandler()
      },
      goBackHandler() {
        this.$router.push("/productList");
      },
      saveEdit() {},
      editOpen() {
        this.ifEdit = false;
        this.ifEditclick = true;
        this.imgDisabled = false
      },
      addOpen() {
        this.dialogFormVisible = true;
        if (this.$refs["form"] !== undefined) {
          this.resetForm("form");
        }
      },
      // 上传图片
      handleChange(file, fileList) {
        this.isValue = file.url;
        this.file = file.raw;
        let formData = new FormData();
        formData.append("multipartFile", this
          .file);
        this.$api
          .addPic(formData)
          .then(res => {
            console.log(res.data.data)
            this.productImgUrl = res.data.data

            this.rules.productImgUrl = [{
              required: false,
              message: ""
            }]
          
            this.$api
              .updateProduct({
                productId: this.id,
                productName: this.name,
                productImgUrl: this.productImgUrl
              })
              .then(res => {
                console.log(res);
                if (res.data.code == 200) {
                  this.$message.success("编辑成功!");
                  this.$api.getProductDetial(this.id).then(res => {
                    this.name = res.data.data.name;
                    this.ifEdit = true;
                    this.ifEditclick = false;
                    // this.imgDisabled = true;
                  });
                }
              });
        // this.$refs['formImg'].clearValidate()
          })
          .catch(error => {
            console.log(error);
          });
      },
      handleRemove(file, fileList) {
        console.log(file, fileList);
        this.isValue = "";
        this.file = {};
      },
      handlePictureCardPreview(file) {
        console.log(file.url)
        this.dialogImageUrl = file.url;
        this.dialogVisibleImg = true;
      },
      //数据点类型选择函数
      changeDataPointType(val) {
        console.log(val);
        this.sendDataPointType = Number(val);
      },
      //数据点类型选择函数
      changeDataType(val) {
        console.log(val);
        this.sendDataType = Number(val);
      },
      //是否需要处理选择函数
      changeNeedHandle(val) {
        console.log(val);
        this.sendNeedHandle = Number(val);
      },
      //是否需要展示函数
      changeNeedShowOnDevice(val) {
        console.log(val);
        this.sendNeedShowOnDevice = Number(val);
      },
      //重置函数
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      reset() {
        this.resetForm("form");
      },
      //编辑产品请求
      updateProductHandler() {
        if (this.input && this.input.length <= 20) {
          this.$api
            .updateProduct({
              productId: this.id,
              productName: this.input,
              productImgUrl: this.productImgUrl
            })
            .then(res => {
              console.log(res);
              if (res.data.code == 200) {
                this.$message.success("编辑成功!");
                this.$api.getProductDetial(this.id).then(res => {
                  this.name = res.data.data.name;
                  this.ifEdit = true;
                  this.ifEditclick = false;
                  this.imgDisabled = true;
                });
              }
            });
        } else if (this.input.length > 20) {
          this.$message.error("产品名称应在20字以内!");
        } else {
          this.$message.error("您没有修改产品名称!");
        }
      },
      //数据点类型展示函数
      stateFormatDataPointType(row) {
        if (row.dataPointType == 0) {
          return "火警";
        } else if (row.dataPointType == 1) {
          return "故障";
        } else if (row.dataPointType == 2) {
          return "普通";
        } else {
          return "暂无数据";
        }
      },
      //数据类型展示函数
      stateFormatDataType(row) {
        if (row.dataType == 0) {
          return "整型";
        } else if (row.dataType == 1) {
          return "浮点型";
        } else if (row.dataType == 2) {
          return "String";
        } else {
          return "暂无数据";
        }
      },
      //编辑弹框函数
      handleEdit(index, row) {
        console.log("编辑");
        console.log(index, row);
        this.dataPointId = Number(row.id);
        this.editDialogFormVisible = true;
        if (this.$refs["editForm"] !== undefined) {
          this.resetForm("editForm");
        }

        console.log(this.editForm);
        this.editForm.dataPointKey = row.dataPointKey;
        this.editForm.dataPointShowName = row.dataPointShowName;
        if (row.dataPointType == 0) {
          this.editForm.dataPointType = "火警";
        } else if (row.dataPointType == 1) {
          this.editForm.dataPointType = "故障";
        } else {
          this.editForm.dataPointType = "普通";
        }
        if (row.dataType == 0) {
          this.editForm.dataType = "整型";
        } else if (row.dataType == 1) {
          this.editForm.dataType = "浮点型";
        } else {
          this.editForm.dataType = "string";
        }
        if (row.needHandle == 0) {
          this.editForm.needHandle = "不需要";
        } else {
          this.editForm.needHandle = "需要";
        }
        if (row.needShowOnDevice == 0) {
          this.editForm.needShowOnDevice = "不需要";
        } else {
          this.editForm.needShowOnDevice = "需要";
        }
        this.editSendDataPointType = Number(row.dataPointType);
        this.editSendDataType = Number(row.dataType);
        this.editSendNeedHandle = Number(row.needHandle);
        this.editSendNeedShowOnDevice = Number(row.needShowOnDevice);
      },

      //删除弹框函数
      handleDelete(index, row) {
        console.log(row.id);
        this.deleteDataPointId = row.id;
      },
      //分页函数
      changeCurrent(id) {
        console.log(id);
        this.page = Number(id);
        this.$api
          .getDataPointList({
            page: {
              current: this.page,
              size: 5
            },
            query: {
              dataPointKey: this.inputDataPointKey,
              dataPointShowName: this.inputDataPointShowName,
              dataPointType: this.selectDataPointType,
              dataType: this.selectDataType,
              needHandle: this.selectNeedHandle,
              needShowOnDevice: this.selectNeedShowOnDevice
            },
            productId: Number(this.id)
          })
          .then(res => {
            console.log(res.data.data.records);
            this.tableData = res.data.data.records;
            this.total = Number(res.data.data.total);
            if (res.data.data.records.length !== 0) {
              this.ifpag = true;
            } else {
              this.ifpag = false;
            }
          });
      },
      //新增数据点提交函数
      submitForm(formName) {
        // this.dialogFormVisible = false;
        this.$refs["form"].validate(valid => {
          if (valid) {
            this.dialogFormVisible = false;
            this.$api
              .addDatePoint({
                dataPointKey: this.form.dataPointKey,
                dataPointShowName: this.form.dataPointShowName,
                dataPointType: this.sendDataPointType,
                dataType: this.sendDataType,
                needHandle: this.sendNeedHandle,
                needShowOnDevice: this.sendNeedShowOnDevice,
                productId: Number(this.id)
              })
              .then(res => {
                console.log(res);
                if (res.data.code == 200) {
                  this.$message.success("新增成功!");
                  this.$api
                    .getDataPointList({
                      page: {
                        current: this.page,
                        size: 5
                      },
                      productId: Number(this.id)
                    })
                    .then(res => {
                      console.log(res.data.data.records);
                      this.tableData = res.data.data.records;
                      this.total = Number(res.data.data.total);
                      if (res.data.data.records.length !== 0) {
                        this.ifpag = true;
                      } else {
                        this.ifpag = false;
                      }
                    });
                }
              });
          } else {
            return false;
          }
        });
      },
      //删除数据点函数
      deleteDataPoint() {
        this.$api.deleteDataPoint(this.deleteDataPointId).then(res => {
          console.log(res);
          if (res.data.code == 200) {
            this.$message.success("删除成功!");
            this.$api
              .getDataPointList({
                page: {
                  current: this.page,
                  size: 5
                },
                productId: Number(this.id)
              })
              .then(res => {
                console.log(res.data.data.records);
                this.tableData = res.data.data.records;
                this.total = Number(res.data.data.total);
                if (res.data.data.records.length !== 0) {
                  this.ifpag = true;
                } else {
                  this.ifpag = false;
                }
              });
          }
        });
      },
      //编辑数据点改变数据点类型函数
      editChangeDataPointType(val) {
        console.log(val);
        this.editSendDataPointType = Number(val);
      },
      //编辑数据点改变数据类型函数
      editChangeDataType(val) {
        this.editSendDataType = Number(val);
      },
      //编辑数据点改变是否需要处理函数
      editChangeNeedHandle(val) {
        this.editSendNeedHandle = Number(val);
      },
      //编辑数据点改变是否需要展示函数
      editChangeNeedShowOnDevice(val) {
        console.log(val);
        this.editSendNeedShowOnDevice = Number(val);
      },
      editSubmitForm(formName) {
        this.$refs["editForm"].validate(valid => {
          if (valid) {
            this.editDialogFormVisible = false;
            this.$api
              .editDataPoint({
                id: this.dataPointId,
                dataPointKey: this.editForm.dataPointKey,
                dataPointShowName: this.editForm.dataPointShowName,
                dataPointType: this.editSendDataPointType,
                dataType: this.editSendDataType,
                needHandle: this.editSendNeedHandle,
                needShowOnDevice: this.editSendNeedShowOnDevice
              })
              .then(res => {
                if (res.data.code == 200) {
                  this.$message.success("编辑数据点成功!");
                  this.$api
                    .getDataPointList({
                      page: {
                        current: this.page,
                        size: 5
                      },
                      productId: Number(this.id)
                    })
                    .then(res => {
                      console.log(res.data.data.records);
                      this.tableData = res.data.data.records;
                      this.total = Number(res.data.data.total);
                      if (res.data.data.records.length !== 0) {
                        this.ifpag = true;
                      } else {
                        this.ifpag = false;
                      }
                    });
                }
              });
          } else {
            return false;
          }
        });
      },
      //查询数据点数据点类型函数
      changeSelectDataPointType(val) {
        console.log(val);
        this.selectDataPointType = Number(val);
      },
      //查询数据点数据类型函数
      changeSelectDataType(val) {
        console.log(val);
        this.selectDataType = Number(val);
      },
      //查询数据点是否需要展示函数
      changeSelectNeedShowOnDevice(val) {
        console.log(val);
        this.selectNeedShowOnDevice = Number(val);
      },
      //查询数据点是否需要处理
      changeSelectNeedHandle(val) {
        console.log(val);
        this.selectNeedHandle = Number(val);
      },
      //数据点查询函数
      checkHandler() {
        this.$api
          .getDataPointList({
            // page: {
            page: {
              current: 1,
              size: 5
            },
            query: {
              dataPointKey: this.inputDataPointKey,
              dataPointShowName: this.inputDataPointShowName,
              dataPointType: this.selectDataPointType,
              dataType: this.selectDataType,
              needHandle: this.selectNeedHandle,
              needShowOnDevice: this.selectNeedShowOnDevice
            },
            // },
            productId: Number(this.id)
          })
          .then(res => {
            this.currentPage = 1;
            if (res.data.data.records.length !== 0) {
              this.ifpag = true;
            } else {
              this.ifpag = false;
            }
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
          });
      },
      //数据点清空函数
      clearHandler() {
        (this.inputDataPointKey = ""),
        (this.inputDataPointShowName = ""),
        (this.disDataPointType = ""),
        (this.disDataType = ""),
        (this.disNeedHandle = ""),
        (this.disNeedShowOnDevice = "");
        (this.selectDataPointType = ""),
        (this.selectDataType = ""),
        (this.selectNeedHandle = ""),
        (this.selectNeedShowOnDevice = "");
      }
    }
  };
</script>
<style lang="less" scoped>
  @border-bottom-color: #e7eaed;
  @toolBarFontColor: #111d;

  .outting {
    height: 100%;
    overflow: hidden;
    background-color: #fafafa;

    .select {
      height: 46px;
      overflow: hidden;
      border-bottom: 1px solid #cccccc;
      padding: 0rem 3rem;

      .title {
        display: inline-block;
        line-height: 46px;
        font-weight: bold;
        font-size: 0.8rem;
      }

      button {
        color: @toolBarFontColor;
        font-size: 0.8rem;
        float: right;
        margin-top: 7px;
      }
    }

    .information {
      margin-top: 1rem;
      padding: 0rem 3rem;

      .clearfix:before,
      .clearfix:after {
        display: table;
        content: "";
      }

      .clearfix:after {
        clear: both;
      }

      // .bg-purple-dark {
      //   background: #99a9bf;
      // }
      .grid-content {
        border-radius: 4px;
        min-height: 36px;
      }

      .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
      }

      .inputDisable {
        height: 30px;
      }

      .inputEnable {
        height: 30px;
        border: 0;

        background: white;
      }
    }
  }

  .product-span {
    color: #909399;
    font-size: 12px;
  }

  .product-vv {
    margin-top: 5px;
    font-size: 12px;
  }
</style>