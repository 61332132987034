<template>
  <div class="outting">
    <div class="select">
      <span class="title">协议列表</span>
      <el-button
        type="text"
        size="small"
        icon="el-icon-plus"
        @click="addUserHandler"
        class="addRoleButton"
      >新增协议</el-button>
      <el-button
        type="text"
        size="small"
        icon="el-icon-bottom"
        @click="playShow"
        class="serachRoleButton"
      >高级查询</el-button>
      <div class="select-input-right-input">
        <el-input
          v-model="inputName"
          prefix-icon="el-icon-search"
          placeholder="请输入协议名称"
          size="small"
          @keyup.enter.native="search"
        ></el-input>
      </div>
    </div>
    <div class="gaojiSearch" v-show="ifSearchMany">
      <div style="width:100%;overflow: hidden;">
        <div style="float:left">
          <div class="grid-content bg-purple-dark">
            <span>创建时间</span>
            <div style="margin-top:5px;">
              <el-date-picker
                size="small"
                class="update-input-timer-select"
                v-model="value2"
                type="datetimerange"
                :picker-options="pickerOptionsTwo"
                range-separator="~"
                start-placeholder="开始"
                end-placeholder="结束"
                align="right"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00','23:59:59']"
                @change="getTimeBlock"
              ></el-date-picker>
            </div>
          </div>
        </div>
        <div style="width:12rem;float:left;margin-left:1.5rem;">
          <div class="grid-content bg-purple-dark">
            <span>协议头编码</span>
            <div style="margin-top:5px;">
              <el-input
                v-model="inputlHeaderCode"
                prefix-icon="el-icon-search"
                placeholder="请输入协议头编码"
                size="small"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div style="width:100%;overflow: hidden;margin-top:.5rem;">
        <div style="float:right;">
          <div class="grid-content bg-purple-dark">
            <div class="searchBut">
              <el-button size="small" type="primary" @click="checkHandler">搜索</el-button>
            </div>
            <div class="emptyBut">
              <el-button size="small" @click="emptyHandler">清空</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="xieyiTable">
      <el-table
        :data="tableData"
        style="width: 100%"
        stripe
        size="small"
        :header-cell-style="{'background-color': '#eeeeee'}"
      >
        <el-table-column prop="protocolName" label="协议名称" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.protocolName || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="protocolHeaderCode" label="协议头编码" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.protocolHeaderCode || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="remark" label="备注信息" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.remark || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.createTime || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.updateTime || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column min-width="1" label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              icon="el-icon-info"
              @click="handleDetail(scope.$index, scope.row)"
            >编辑</el-button>
            <el-popconfirm title="确定删除这条协议吗？" @onConfirm="deleteDeviceFault()">
              <el-button
                type="text"
                size="small"
                icon="el-icon-delete"
                @click="handleDelete(scope.$index, scope.row)"
                style="margin-left:10px;"
                slot="reference"
              >删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        style="float:right;margin-top:10px;"
        @current-change="changeCurrent"
      ></el-pagination>
    </div>
    <el-dialog
      title="新增协议"
      :visible.sync="dialogFormVisible"
      :lock-scroll="false"
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="协议名称" :label-width="formLabelWidth" prop="protocolName">
          <el-input v-model="form.protocolName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="协议头编码" :label-width="formLabelWidth" prop="protocolHeaderCode">
          <el-input v-model="form.protocolHeaderCode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注信息" :label-width="formLabelWidth" prop="remark">
          <el-input v-model="form.remark" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="编辑协议"
      :visible.sync="dialogEditFormVisible"
      :lock-scroll="false"
      :close-on-click-modal="false"
    >
      <el-form :model="editForm" :rules="editRules" ref="editForm">
        <el-form-item label="协议名称" :label-width="formLabelWidth" prop="protocolName">
          <el-input v-model="editForm.protocolName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="协议头编码" :label-width="formLabelWidth" prop="protocolHeaderCode">
          <el-input v-model="editForm.protocolHeaderCode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注信息" :label-width="formLabelWidth" prop="remark">
          <el-input v-model="editForm.remark" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogEditFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitEditForm('editForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "AgreeMent",
  data() {
    return {
      inputName: "",
      ifSearchMany: false,
      value2: "",
      inputlHeaderCode: "",
      current: 1,
      total: 0,
      tableData: [],
      dialogFormVisible: false,
      dialogEditFormVisible: false,
      deleteId: "",
      editId: "",
      form: {
        protocolName: "",
        protocolHeaderCode: "",
        remark: ""
      },
      editForm: {
        protocolName: "",
        protocolHeaderCode: "",
        remark: ""
      },
      rules: {
        protocolName: [
          { required: true, message: "请输入协议名称", trigger: "blur" },
          { min: 1, max: 30, message: "长度在 1 到 30 个字符", trigger: "blur" }
        ],
        protocolHeaderCode: [
          { required: true, message: "请输入协议头编码", trigger: "blur" },
          { min: 1, max: 30, message: "长度在 1 到 30 个字符", trigger: "blur" }
        ],
        remark: [
          { required: true, message: "请输入备注信息", trigger: "blur" },
          { min: 1, max: 30, message: "长度在 1 到 30 个字符", trigger: "blur" }
        ]
      },
      editRules: {
        protocolName: [
          { required: true, message: "请输入协议名称", trigger: "blur" },
          { min: 1, max: 30, message: "长度在 1 到 30 个字符", trigger: "blur" }
        ],
        protocolHeaderCode: [
          { required: true, message: "请输入协议头编码", trigger: "blur" },
          { min: 1, max: 30, message: "长度在 1 到 30 个字符", trigger: "blur" }
        ],
        remark: [
          { required: true, message: "请输入备注信息", trigger: "blur" },
          { min: 1, max: 30, message: "长度在 1 到 30 个字符", trigger: "blur" }
        ]
      },
      formLabelWidth: "120px",
      pickerOptionsTwo: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },
  methods: {
    //新增故障类型
    addUserHandler() {
      this.dialogFormVisible = true;
      if (this.$refs["form"] !== undefined) {
        this.resetForm("form");
      }
    },
    //重置函数
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //高级搜索函数
    playShow() {
      if (this.ifSearchMany == false) {
        this.ifSearchMany = true;
      } else {
        this.ifSearchMany = false;
      }
    },
    //搜索协议名称回车
    search() {
      this.getAgreementlist();
    },
    //获取时间函数
    getTimeBlock() {
      if (this.value2) {
        this.startTime = this.value2[0];
        this.endTime = this.value2[1];
      } else {
        this.startTime = "";
        this.endTime = "";
      }
    },
    //搜索函数
    checkHandler() {
      this.getAgreementlist();
    },
    //清空函数
    emptyHandler() {
      (this.startTime = ""),
        (this.endTime = ""),
        (this.inputName = ""),
        (this.inputlHeaderCode = ""),
        (this.value2 = "");
    },
    //请求列表函数
    getAgreementlist() {
      this.$api
        .agreementList({
          page: {
            current: this.current,
            size: 10
          },
          query: {
            endTime: this.endTime,
            protocolHeaderCode: this.inputlHeaderCode,
            protocolName: this.inputName,
            startTime: this.startTime
          }
        })
        .then(res => {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        });
    },
    //删除获取数据函数
    handleDelete(index, row) {
      this.deleteId = row.id;
    },
    //确定删除协议函数
    deleteDeviceFault() {
      this.$api.deleteAgreement(this.deleteId).then(res => {
        if (res.data.code == 200) {
          this.$message.success("删除协议成功!");
          this.getAgreementlist();
        }
      });
    },
    //分页函数
    changeCurrent(val) {
      this.current = val;
      this.getAgreementlist();
    },
    //确认添加故障类型
    submitForm(formName) {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.dialogFormVisible = false;
          this.$api
            .addAgreement({
              protocolHeaderCode: this.form.protocolHeaderCode,
              protocolName: this.form.protocolName,
              remark: this.form.remark
            })
            .then(res => {
              if (res.data.code == 200) {
                this.$message.success("添加协议成功！");
                this.getAgreementlist();
              }
            });
        } else {
          return false;
        }
      });
    },
    //查看详情函数
    handleDetail(index, row) {
      //      this.$router.push({
      //       　path: `/agreementDetail`,
      // 　　　　 query: { protocolId: row.id }
      //       })
      this.dialogEditFormVisible = true;
      if (this.$refs["editForm"] !== undefined) {
        this.resetForm("editForm");
      }
      this.editId = row.id;
      this.$api.agreementDetail(this.editId).then(res => {
        console.log(res.data.data);
        this.editForm.protocolName = res.data.data.protocolName;
        this.editForm.protocolHeaderCode = res.data.data.protocolHeaderCode;
        this.editForm.remark = res.data.data.remark;
      });
    },
    //编辑确认函数
    submitEditForm(formName) {
      this.$refs["editForm"].validate(valid => {
        if (valid) {
          this.dialogEditFormVisible = false;
          this.$api
            .editAgreement({
              id: this.editId,
              protocolHeaderCode: this.editForm.protocolHeaderCode,
              protocolName: this.editForm.protocolName,
              remark: this.editForm.remark
            })
            .then(res => {
              if (res.data.code == 200) {
                this.$message.success("编辑协议成功！");
                this.getAgreementlist();
              }
            });
        } else {
          return false;
        }
      });
    }
  },
  created() {
    this.getAgreementlist();
  }
};
</script>
<style lang="less" scoped>
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;
.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .select-status {
      width: 9rem;
      margin-left: 1.7rem;
      margin-top: 7px;
      // margin-top:7px;
    }

    .select-input-timer {
      float: right;
    }

    .select-status {
      float: right;
    }

    .select-input-right-input {
      float: right;
      // width: 8rem;
      line-height: 46px;
    }

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .addRoleButton {
      margin-left: 15px;
    }

    .serachRoleButton {
      margin-left: 15px;
    }

    button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
    }

    .update-input-timer {
      float: right;
      line-height: 46px;
      margin-left: 25px;
      height: 46px;

      .demonstrationTwo {
        float: left;
        font-size: 0.8rem;
      }

      .update-input-timer-select {
        width: 11rem;
        margin-left: 5px;
      }
    }
  }
  .gaojiSearch {
    margin-top: 10px;
    padding: 1rem 3rem;
    margin-bottom: 10px;
    background-color: #f4f6fa;
    .el-row {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    .el-col {
      border-radius: 4px;
    }
    .grid-content {
      border-radius: 4px;
      min-height: 36px;
    }

    .row-bg {
      padding: 10px 0;
      background-color: #f9fafc;
    }
    .searchBut {
      float: right;
      margin-left: 20px;
    }

    .emptyBut {
      float: right;
    }
  }
  .pagin {
    margin-left: 30%;
    margin-top: 10px;
  }
  .xieyiTable {
    padding: 0rem 3rem;
    margin-top: 1rem;
  }
}
</style>
