<template>
    <div class="iphoneSolve">


        <div class="titleDisplay">
            <div class="major_service">
                <!-- <p> -->
      
                <img src="../../assets/merchantJoin/10_01.jpg" class="text_list" />
                <img src="../../assets/merchantJoin/10_02.jpg" class="text_list" />
               <div class="text_list2">
          <span class="tiele_span">免费获取项目资料</span>
          <div class="form_box">
            <p style="color:black;margin-bottom:10px">区域<span style="color:red">*</span></p>
            <el-cascader    :options="options" :props="{ expandTrigger: 'hover' }" v-model="sanji" style="width:100%" placeholder="请选择省/市"   
                  ></el-cascader>
          </div>
  <div class="form_box">
            <p style="color:black;margin-bottom:10px">称呼<span style="color:red">*</span></p>
            <el-input placeholder="请输入您的称呼" v-model="input" clearable style="width:100%">
            </el-input>
          </div>
            <div class="form_box">
            <p style="color:black;margin-bottom:10px">电话<span style="color:red">*</span></p>
                 <el-form  :model="form" :rules="rules" ref="ruleForm">
  <el-form-item  prop="phone">
    <el-input  clearable style="width:100%" v-model="form.phone" type="text" placeholder="请输入手机号"  >
            </el-input>
  </el-form-item>
   <el-form-item >
      <div class="button_box"  @click="buttonFn('form')">
            提交
          </div>
  </el-form-item>
</el-form>
            
          </div>
        
        </div>
                <img src="../../assets/merchantJoin/10_04.jpg" class="text_list" />
                <img src="../../assets/merchantJoin/10_05.jpg" class="text_list" />
                <img src="../../assets/merchantJoin/10_06.jpg" class="text_list" />
                <img src="../../assets/merchantJoin/10_07.jpg" class="text_list" />
                <img src="../../assets/merchantJoin/10_08.jpg" class="text_list" />
               <div class="text_list2">
          <span class="tiele_span">免费获取项目资料</span>
          <div class="form_box">
            <p style="color:black;margin-bottom:10px">区域<span style="color:red">*</span></p>
            <el-cascader    :options="options" :props="{ expandTrigger: 'hover' }" v-model="sanji" style="width:100%" placeholder="请选择省/市"   
                  ></el-cascader>
          </div>
  <div class="form_box">
            <p style="color:black;margin-bottom:10px">称呼<span style="color:red">*</span></p>
            <el-input placeholder="请输入您的称呼" v-model="input" clearable style="width:100%">
            </el-input>
          </div>
            <div class="form_box">
            <p style="color:black;margin-bottom:10px">电话<span style="color:red">*</span></p>
          <el-form  :model="form" :rules="rules" ref="ruleForm">
  <el-form-item  prop="phone">
    <el-input  clearable style="width:100%" v-model="form.phone" type="text" placeholder="请输入手机号"  >
            </el-input>
  </el-form-item>
   <el-form-item >
      <div class="button_box"  @click="buttonFn('form')">
            提交
          </div>
  </el-form-item>
</el-form>
            
          </div>
        
        </div>
                <img src="../../assets/merchantJoin/10_10.jpg" class="text_list" />
            
                <!-- </p> -->

            </div>


            <div style="clear: both;"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "iphoneSolve",

        data() {
           // 验证手机号的规则
    var cheackMobile = (rule, value, cb) => {
     // 手机号一般最小以“13”开头
      const regMobile = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (regMobile.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的手机号!'))
    }

            return {
              form:{
                 phone:'',
              },
               rules: {
                  phone: [
	          { required: true, message: '请输入手机号', trigger: 'blur' },
	          { validator: cheackMobile, trigger: 'blur' }]
               },
               
               iphone:"",
          hintShow : false,  // 提示语显示
                hint : '', // 提示语

                /*
                *  val 为值,err为错误显示, test检验信息
                * */
                phone : {
                    val : '',
                    err : false,
                    pass: false,
                    test : function () {
                        // 验证手机号
                        let reg = /^1[0-9]{10}$/
                        if (this.val == '' || this.val.length <= 10 || !reg.test(this.val)) {
                            // vm.$data.hintShow = true
                            // vm.$data.hint = '请输入正确的手机号'
                            console.log('请输入正确的手机')
                            this.err = true
                            return false
                        }

                        // vm.$data.hintShow = false
                        // vm.$data.hint = ''
                        this.err = false
                        this.pass = true
                        return true
                    }
                },
                        options:[
    {
      "label":"北京",
     value:"北京",
      "code":"1100",
      "children":[
        {
          "label":"北京",
         value:"北京",
          "code":"1100"
        }
      ]
    },
    {
      "label":"天津",
     value:"天津",
      "code":"1200",
      "children":[
        {
          "label":"天津",
         value:"天津",
          "code":"1200"
        }
      ]
    },
    {
      "label":"河北",
     value:"河北",
      "code":"1300",
      "children":[
        {
          "label":"石家庄",
         value:"石家庄",
          "code":"1301"
        },
        {
          "label":"唐山",
         value:"唐山",
          "code":"1302"
        },
        {
          "label":"秦皇岛",
         value:"秦皇岛",
          "code":"1303"
        },
        {
          "label":"邯郸",
         value:"邯郸",
          "code":"1304"
        },
        {
          "label":"邢台",
         value:"邢台",
          "code":"1305"
        },
        {
          "label":"保定",
         value:"保定",
          "code":"1306"
        },
        {
          "label":"张家口",
         value:"张家口",
          "code":"1307"
        },
        {
          "label":"承德",
         value:"承德",
          "code":"1308"
        },
        {
          "label":"沧州",
         value:"沧州",
          "code":"1309"
        },
        {
          "label":"廊坊",
         value:"廊坊",
          "code":"1310"
        },
        {
          "label":"衡水",
         value:"衡水",
          "code":"1311"
        }
      ]
    },
    {
      "label":"山西",
     value:"山西",
      "code":"1400",
      "children":[
        {
          "label":"太原",
         value:"太原",
          "code":"1401"
        },
        {
          "label":"大同",
         value:"大同",
          "code":"1402"
        },
        {
          "label":"阳泉",
         value:"阳泉",
          "code":"1403"
        },
        {
          "label":"长治",
         value:"长治",
          "code":"1404"
        },
        {
          "label":"晋城",
         value:"晋城",
          "code":"1405"
        },
        {
          "label":"朔州",
         value:"朔州",
          "code":"1406"
        },
        {
          "label":"晋中",
         value:"晋中",
          "code":"1407"
        },
        {
          "label":"运城",
         value:"运城",
          "code":"1408"
        },
        {
          "label":"忻州",
         value:"忻州",
          "code":"1409"
        },
        {
          "label":"临汾",
         value:"临汾",
          "code":"1410"
        },
        {
          "label":"吕梁",
         value:"吕梁",
          "code":"1411"
        }
      ]
    },
    {
      "label":"内蒙古自治区",
     value:"内蒙古自治区",
      "code":"1500",
      "children":[
        {
          "label":"呼和浩特",
         value:"呼和浩特",
          "code":"1501"
        },
        {
          "label":"包头",
         value:"包头",
          "code":"1502"
        },
        {
          "label":"乌海",
         value:"乌海",
          "code":"1503"
        },
        {
          "label":"赤峰",
         value:"赤峰",
          "code":"1504"
        },
        {
          "label":"通辽",
         value:"通辽",
          "code":"1505"
        },
        {
          "label":"鄂尔多斯",
         value:"鄂尔多斯",
          "code":"1506"
        },
        {
          "label":"呼伦贝尔",
         value:"呼伦贝尔",
          "code":"1507"
        },
        {
          "label":"巴彦淖尔",
         value:"巴彦淖尔",
          "code":"1508"
        },
        {
          "label":"乌兰察布",
         value:"乌兰察布",
          "code":"1509"
        },
        {
          "label":"兴安盟",
         value:"兴安盟",
          "code":"1522"
        },
        {
          "label":"锡林郭勒盟",
         value:"锡林郭勒盟",
          "code":"1525"
        },
        {
          "label":"阿拉善盟",
         value:"阿拉善盟",
          "code":"1529"
        }
      ]
    },
    {
      "label":"辽宁",
     value:"辽宁",
      "code":"2100",
      "children":[
        {
          "label":"沈阳",
         value:"沈阳",
          "code":"2101"
        },
        {
          "label":"大连",
         value:"大连",
          "code":"2102"
        },
        {
          "label":"鞍山",
         value:"鞍山",
          "code":"2103"
        },
        {
          "label":"抚顺",
         value:"抚顺",
          "code":"2104"
        },
        {
          "label":"本溪",
         value:"本溪",
          "code":"2105"
        },
        {
          "label":"丹东",
         value:"丹东",
          "code":"2106"
        },
        {
          "label":"锦州",
         value:"锦州",
          "code":"2107"
        },
        {
          "label":"营口",
         value:"营口",
          "code":"2108"
        },
        {
          "label":"阜新",
         value:"阜新",
          "code":"2109"
        },
        {
          "label":"辽阳",
         value:"辽阳",
          "code":"2110"
        },
        {
          "label":"盘锦",
         value:"盘锦",
          "code":"2111"
        },
        {
          "label":"铁岭",
         value:"铁岭",
          "code":"2112"
        },
        {
          "label":"朝阳",
         value:"朝阳",
          "code":"2113"
        },
        {
          "label":"葫芦岛",
         value:"葫芦岛",
          "code":"2114"
        }
      ]
    },
    {
      "label":"吉林",
     value:"吉林",
      "code":"2200",
      "children":[
        {
          "label":"长春",
         value:"长春",
          "code":"2201"
        },
        {
          "label":"吉林",
         value:"吉林",
          "code":"2202"
        },
        {
          "label":"四平",
         value:"四平",
          "code":"2203"
        },
        {
          "label":"辽源",
         value:"辽源",
          "code":"2204"
        },
        {
          "label":"通化",
         value:"通化",
          "code":"2205"
        },
        {
          "label":"白山",
         value:"白山",
          "code":"2206"
        },
        {
          "label":"松原",
         value:"松原",
          "code":"2207"
        },
        {
          "label":"白城",
         value:"白城",
          "code":"2208"
        },
        {
          "label":"延边朝鲜族自治州",
         value:"延边朝鲜族自治州",
          "code":"2224"
        }
      ]
    },
    {
      "label":"黑龙江",
     value:"黑龙江",
      "code":"2300",
      "children":[
        {
          "label":"哈尔滨",
         value:"哈尔滨",
          "code":"2301"
        },
        {
          "label":"齐齐哈尔",
         value:"齐齐哈尔",
          "code":"2302"
        },
        {
          "label":"鸡西",
         value:"鸡西",
          "code":"2303"
        },
        {
          "label":"鹤岗",
         value:"鹤岗",
          "code":"2304"
        },
        {
          "label":"双鸭山",
         value:"双鸭山",
          "code":"2305"
        },
        {
          "label":"大庆",
         value:"大庆",
          "code":"2306"
        },
        {
          "label":"伊春",
         value:"伊春",
          "code":"2307"
        },
        {
          "label":"佳木斯",
         value:"佳木斯",
          "code":"2308"
        },
        {
          "label":"七台河",
         value:"七台河",
          "code":"2309"
        },
        {
          "label":"牡丹江",
         value:"牡丹江",
          "code":"2310"
        },
        {
          "label":"黑河",
         value:"黑河",
          "code":"2311"
        },
        {
          "label":"绥化",
         value:"绥化",
          "code":"2312"
        },
        {
          "label":"大兴安岭地区",
         value:"大兴安岭地区",
          "code":"2327"
        }
      ]
    },
    {
      "label":"上海",
     value:"上海",
      "code":"3100",
      "children":[
        {
          "label":"上海",
         value:"上海",
          "code":"3100"
        }
      ]
    },
    {
      "label":"江苏",
     value:"江苏",
      "code":"3200",
      "children":[
        {
          "label":"南京",
         value:"南京",
          "code":"3201"
        },
        {
          "label":"无锡",
         value:"无锡",
          "code":"3202"
        },
        {
          "label":"徐州",
         value:"徐州",
          "code":"3203"
        },
        {
          "label":"常州",
         value:"常州",
          "code":"3204"
        },
        {
          "label":"苏州",
         value:"苏州",
          "code":"3205"
        },
        {
          "label":"南通",
         value:"南通",
          "code":"3206"
        },
        {
          "label":"连云港",
         value:"连云港",
          "code":"3207"
        },
        {
          "label":"淮安",
         value:"淮安",
          "code":"3208"
        },
        {
          "label":"盐城",
         value:"盐城",
          "code":"3209"
        },
        {
          "label":"扬州",
         value:"扬州",
          "code":"3210"
        },
        {
          "label":"镇江",
         value:"镇江",
          "code":"3211"
        },
        {
          "label":"泰州",
         value:"泰州",
          "code":"3212"
        },
        {
          "label":"宿迁",
         value:"宿迁",
          "code":"3213"
        }
      ]
    },
    {
      "label":"浙江",
     value:"浙江",
      "code":"3300",
      "children":[
        {
          "label":"杭州",
         value:"杭州",
          "code":"3301"
        },
        {
          "label":"宁波",
         value:"宁波",
          "code":"3302"
        },
        {
          "label":"温州",
         value:"温州",
          "code":"3303"
        },
        {
          "label":"嘉兴",
         value:"嘉兴",
          "code":"3304"
        },
        {
          "label":"湖州",
         value:"湖州",
          "code":"3305"
        },
        {
          "label":"绍兴",
         value:"绍兴",
          "code":"3306"
        },
        {
          "label":"金华",
         value:"金华",
          "code":"3307"
        },
        {
          "label":"衢州",
         value:"衢州",
          "code":"3308"
        },
        {
          "label":"舟山",
         value:"舟山",
          "code":"3309"
        },
        {
          "label":"台州",
         value:"台州",
          "code":"3310"
        },
        {
          "label":"丽水",
         value:"丽水",
          "code":"3311"
        }
      ]
    },
    {
      "label":"安徽",
     value:"安徽",
      "code":"3400",
      "children":[
        {
          "label":"合肥",
         value:"合肥",
          "code":"3401"
        },
        {
          "label":"芜湖",
         value:"芜湖",
          "code":"3402"
        },
        {
          "label":"蚌埠",
         value:"蚌埠",
          "code":"3403"
        },
        {
          "label":"淮南",
         value:"淮南",
          "code":"3404"
        },
        {
          "label":"马鞍山",
         value:"马鞍山",
          "code":"3405"
        },
        {
          "label":"淮北",
         value:"淮北",
          "code":"3406"
        },
        {
          "label":"铜陵",
         value:"铜陵",
          "code":"3407"
        },
        {
          "label":"安庆",
         value:"安庆",
          "code":"3408"
        },
        {
          "label":"黄山",
         value:"黄山",
          "code":"3410"
        },
        {
          "label":"滁州",
         value:"滁州",
          "code":"3411"
        },
        {
          "label":"阜阳",
         value:"阜阳",
          "code":"3412"
        },
        {
          "label":"宿州",
         value:"宿州",
          "code":"3413"
        },
        {
          "label":"巢湖",
         value:"巢湖",
          "code":"3414"
        },
        {
          "label":"六安",
         value:"六安",
          "code":"3415"
        },
        {
          "label":"亳州",
         value:"亳州",
          "code":"3416"
        },
        {
          "label":"池州",
         value:"池州",
          "code":"3417"
        },
        {
          "label":"宣城",
         value:"宣城",
          "code":"3418"
        }
      ]
    },
    {
      "label":"福建",
     value:"福建",
      "code":"3500",
      "children":[
        {
          "label":"福州",
         value:"福州",
          "code":"3501"
        },
        {
          "label":"厦门",
         value:"厦门",
          "code":"3502"
        },
        {
          "label":"莆田",
         value:"莆田",
          "code":"3503"
        },
        {
          "label":"三明",
         value:"三明",
          "code":"3504"
        },
        {
          "label":"泉州",
         value:"泉州",
          "code":"3505"
        },
        {
          "label":"漳州",
         value:"漳州",
          "code":"3506"
        },
        {
          "label":"南平",
         value:"南平",
          "code":"3507"
        },
        {
          "label":"龙岩",
         value:"龙岩",
          "code":"3508"
        },
        {
          "label":"宁德",
         value:"宁德",
          "code":"3509"
        }
      ]
    },
    {
      "label":"江西",
     value:"江西",
      "code":"3600",
      "children":[
        {
          "label":"南昌",
         value:"南昌",
          "code":"3601"
        },
        {
          "label":"景德镇",
         value:"景德镇",
          "code":"3602"
        },
        {
          "label":"萍乡",
         value:"萍乡",
          "code":"3603"
        },
        {
          "label":"九江",
         value:"九江",
          "code":"3604"
        },
        {
          "label":"新余",
         value:"新余",
          "code":"3605"
        },
        {
          "label":"鹰潭",
         value:"鹰潭",
          "code":"3606"
        },
        {
          "label":"赣州",
         value:"赣州",
          "code":"3607"
        },
        {
          "label":"吉安",
         value:"吉安",
          "code":"3608"
        },
        {
          "label":"宜春",
         value:"宜春",
          "code":"3609"
        },
        {
          "label":"抚州",
         value:"抚州",
          "code":"3610"
        },
        {
          "label":"上饶",
         value:"上饶",
          "code":"3611"
        }
      ]
    },
    {
      "label":"山东",
     value:"山东",
      "code":"3700",
      "children":[
        {
          "label":"济南",
         value:"济南",
          "code":"3701"
        },
        {
          "label":"青岛",
         value:"青岛",
          "code":"3702"
        },
        {
          "label":"淄博",
         value:"淄博",
          "code":"3703"
        },
        {
          "label":"枣庄",
         value:"枣庄",
          "code":"3704"
        },
        {
          "label":"东营",
         value:"东营",
          "code":"3705"
        },
        {
          "label":"烟台",
         value:"烟台",
          "code":"3706"
        },
        {
          "label":"潍坊",
         value:"潍坊",
          "code":"3707"
        },
        {
          "label":"济宁",
         value:"济宁",
          "code":"3708"
        },
        {
          "label":"泰安",
         value:"泰安",
          "code":"3709"
        },
        {
          "label":"威海",
         value:"威海",
          "code":"3710"
        },
        {
          "label":"日照",
         value:"日照",
          "code":"3711"
        },
        {
          "label":"莱芜",
         value:"莱芜",
          "code":"3712"
        },
        {
          "label":"临沂",
         value:"临沂",
          "code":"3713"
        },
        {
          "label":"德州",
         value:"德州",
          "code":"3714"
        },
        {
          "label":"聊城",
         value:"聊城",
          "code":"3715"
        },
        {
          "label":"滨州",
         value:"滨州",
          "code":"3716"
        },
        {
          "label":"菏泽",
         value:"菏泽",
          "code":"3717"
        }
      ]
    },
    {
      "label":"河南",
     value:"河南",
      "code":"4100",
      "children":[
        {
          "label":"郑州",
         value:"郑州",
          "code":"4101"
        },
        {
          "label":"开封",
         value:"开封",
          "code":"4102"
        },
        {
          "label":"洛阳",
         value:"洛阳",
          "code":"4103"
        },
        {
          "label":"平顶山",
         value:"平顶山",
          "code":"4104"
        },
        {
          "label":"安阳",
         value:"安阳",
          "code":"4105"
        },
        {
          "label":"鹤壁",
         value:"鹤壁",
          "code":"4106"
        },
        {
          "label":"新乡",
         value:"新乡",
          "code":"4107"
        },
        {
          "label":"焦作",
         value:"焦作",
          "code":"4108"
        },
        {
          "label":"濮阳",
         value:"濮阳",
          "code":"4109"
        },
        {
          "label":"许昌",
         value:"许昌",
          "code":"4110"
        },
        {
          "label":"漯河",
         value:"漯河",
          "code":"4111"
        },
        {
          "label":"三门峡",
         value:"三门峡",
          "code":"4112"
        },
        {
          "label":"南阳",
         value:"南阳",
          "code":"4113"
        },
        {
          "label":"商丘",
         value:"商丘",
          "code":"4114"
        },
        {
          "label":"信阳",
         value:"信阳",
          "code":"4115"
        },
        {
          "label":"周口",
         value:"周口",
          "code":"4116"
        },
        {
          "label":"驻马店",
         value:"驻马店",
          "code":"4117"
        }
      ]
    },
    {
      "label":"湖北",
     value:"湖北",
      "code":"4200",
      "children":[
        {
          "label":"武汉",
         value:"武汉",
          "code":"4201"
        },
        {
          "label":"黄石",
         value:"黄石",
          "code":"4202"
        },
        {
          "label":"十堰",
         value:"十堰",
          "code":"4203"
        },
        {
          "label":"宜昌",
         value:"宜昌",
          "code":"4205"
        },
        {
          "label":"襄樊",
         value:"襄樊",
          "code":"4206"
        },
        {
          "label":"鄂州",
         value:"鄂州",
          "code":"4207"
        },
        {
          "label":"荆门",
         value:"荆门",
          "code":"4208"
        },
        {
          "label":"孝感",
         value:"孝感",
          "code":"4209"
        },
        {
          "label":"荆州",
         value:"荆州",
          "code":"4210"
        },
        {
          "label":"黄冈",
         value:"黄冈",
          "code":"4211"
        },
        {
          "label":"咸宁",
         value:"咸宁",
          "code":"4212"
        },
        {
          "label":"随州",
         value:"随州",
          "code":"4213"
        },
        {
          "label":"恩施土家族苗族自治州",
         value:"恩施土家族苗族自治州",
          "code":"4228"
        }
      ]
    },
    {
      "label":"湖南",
     value:"湖南",
      "code":"4300",
      "children":[
        {
          "label":"长沙",
         value:"长沙",
          "code":"4301"
        },
        {
          "label":"株洲",
         value:"株洲",
          "code":"4302"
        },
        {
          "label":"湘潭",
         value:"湘潭",
          "code":"4303"
        },
        {
          "label":"衡阳",
         value:"衡阳",
          "code":"4304"
        },
        {
          "label":"邵阳",
         value:"邵阳",
          "code":"4305"
        },
        {
          "label":"岳阳",
         value:"岳阳",
          "code":"4306"
        },
        {
          "label":"常德",
         value:"常德",
          "code":"4307"
        },
        {
          "label":"张家界",
         value:"张家界",
          "code":"4308"
        },
        {
          "label":"益阳",
         value:"益阳",
          "code":"4309"
        },
        {
          "label":"郴州",
         value:"郴州",
          "code":"4310"
        },
        {
          "label":"永州",
         value:"永州",
          "code":"4311"
        },
        {
          "label":"怀化",
         value:"怀化",
          "code":"4312"
        },
        {
          "label":"娄底",
         value:"娄底",
          "code":"4313"
        },
        {
          "label":"湘西土家族苗族自治州",
         value:"湘西土家族苗族自治州",
          "code":"4331"
        }
      ]
    },
    {
      "label":"广东",
     value:"广东",
      "code":"4400",
      "children":[
        {
          "label":"广州",
         value:"广州",
          "code":"4401"
        },
        {
          "label":"韶关",
         value:"韶关",
          "code":"4402"
        },
        {
          "label":"深圳",
         value:"深圳",
          "code":"4403"
        },
        {
          "label":"珠海",
         value:"珠海",
          "code":"4404"
        },
        {
          "label":"汕头",
         value:"汕头",
          "code":"4405"
        },
        {
          "label":"佛山",
         value:"佛山",
          "code":"4406"
        },
        {
          "label":"江门",
         value:"江门",
          "code":"4407"
        },
        {
          "label":"湛江",
         value:"湛江",
          "code":"4408"
        },
        {
          "label":"茂名",
         value:"茂名",
          "code":"4409"
        },
        {
          "label":"肇庆",
         value:"肇庆",
          "code":"4412"
        },
        {
          "label":"惠州",
         value:"惠州",
          "code":"4413"
        },
        {
          "label":"梅州",
         value:"梅州",
          "code":"4414"
        },
        {
          "label":"汕尾",
         value:"汕尾",
          "code":"4415"
        },
        {
          "label":"河源",
         value:"河源",
          "code":"4416"
        },
        {
          "label":"阳江",
         value:"阳江",
          "code":"4417"
        },
        {
          "label":"清远",
         value:"清远",
          "code":"4418"
        },
        {
          "label":"东莞",
         value:"东莞",
          "code":"4419"
        },
        {
          "label":"中山",
         value:"中山",
          "code":"4420"
        },
        {
          "label":"潮州",
         value:"潮州",
          "code":"4451"
        },
        {
          "label":"揭阳",
         value:"揭阳",
          "code":"4452"
        },
        {
          "label":"云浮",
         value:"云浮",
          "code":"4453"
        }
      ]
    },
    {
      "label":"广西壮族自治区",
     value:"广西壮族自治区",
      "code":"4500",
      "children":[
        {
          "label":"南宁",
         value:"南宁",
          "code":"4501"
        },
        {
          "label":"柳州",
         value:"柳州",
          "code":"4502"
        },
        {
          "label":"桂林",
         value:"桂林",
          "code":"4503"
        },
        {
          "label":"梧州",
         value:"梧州",
          "code":"4504"
        },
        {
          "label":"北海",
         value:"北海",
          "code":"4505"
        },
        {
          "label":"防城港",
         value:"防城港",
          "code":"4506"
        },
        {
          "label":"钦州",
         value:"钦州",
          "code":"4507"
        },
        {
          "label":"贵港",
         value:"贵港",
          "code":"4508"
        },
        {
          "label":"玉林",
         value:"玉林",
          "code":"4509"
        },
        {
          "label":"百色",
         value:"百色",
          "code":"4510"
        },
        {
          "label":"贺州",
         value:"贺州",
          "code":"4511"
        },
        {
          "label":"河池",
         value:"河池",
          "code":"4512"
        },
        {
          "label":"来宾",
         value:"来宾",
          "code":"4513"
        },
        {
          "label":"崇左",
         value:"崇左",
          "code":"4514"
        }
      ]
    },
    {
      "label":"海南",
     value:"海南",
      "code":"4600",
      "children":[
        {
          "label":"海口",
         value:"海口",
          "code":"4601"
        },
        {
          "label":"三亚",
         value:"三亚",
          "code":"4602"
        }
      ]
    },
    {
      "label":"重庆",
     value:"重庆",
      "code":"5000",
      "children":[
        {
          "label":"重庆",
         value:"重庆",
          "code":"5000"
        }
      ]
    },
    {
      "label":"四川",
     value:"四川",
      "code":"5100",
      "children":[
        {
          "label":"成都",
         value:"成都",
          "code":"5101"
        },
        {
          "label":"自贡",
         value:"自贡",
          "code":"5103"
        },
        {
          "label":"攀枝花",
         value:"攀枝花",
          "code":"5104"
        },
        {
          "label":"泸州",
         value:"泸州",
          "code":"5105"
        },
        {
          "label":"德阳",
         value:"德阳",
          "code":"5106"
        },
        {
          "label":"绵阳",
         value:"绵阳",
          "code":"5107"
        },
        {
          "label":"广元",
         value:"广元",
          "code":"5108"
        },
        {
          "label":"遂宁",
         value:"遂宁",
          "code":"5109"
        },
        {
          "label":"内江",
         value:"内江",
          "code":"5110"
        },
        {
          "label":"乐山",
         value:"乐山",
          "code":"5111"
        },
        {
          "label":"南充",
         value:"南充",
          "code":"5113"
        },
        {
          "label":"眉山",
         value:"眉山",
          "code":"5114"
        },
        {
          "label":"宜宾",
         value:"宜宾",
          "code":"5115"
        },
        {
          "label":"广安",
         value:"广安",
          "code":"5116"
        },
        {
          "label":"达州",
         value:"达州",
          "code":"5117"
        },
        {
          "label":"雅安",
         value:"雅安",
          "code":"5118"
        },
        {
          "label":"巴中",
         value:"巴中",
          "code":"5119"
        },
        {
          "label":"资阳",
         value:"资阳",
          "code":"5120"
        },
        {
          "label":"阿坝藏族羌族自治州",
         value:"阿坝藏族羌族自治州",
          "code":"5132"
        },
        {
          "label":"甘孜藏族自治州",
         value:"甘孜藏族自治州",
          "code":"5133"
        },
        {
          "label":"凉山彝族自治州",
         value:"凉山彝族自治州",
          "code":"5134"
        }
      ]
    },
    {
      "label":"贵州",
     value:"贵州",
      "code":"5200",
      "children":[
        {
          "label":"贵阳",
         value:"贵阳",
          "code":"5201"
        },
        {
          "label":"六盘水",
         value:"六盘水",
          "code":"5202"
        },
        {
          "label":"遵义",
         value:"遵义",
          "code":"5203"
        },
        {
          "label":"安顺",
         value:"安顺",
          "code":"5204"
        },
        {
          "label":"铜仁地区",
         value:"铜仁地区",
          "code":"5222"
        },
        {
          "label":"黔西南布依族苗族自治州",
         value:"黔西南布依族苗族自治州",
          "code":"5223"
        },
        {
          "label":"毕节地区",
         value:"毕节地区",
          "code":"5224"
        },
        {
          "label":"黔东南苗族侗族自治州",
         value:"黔东南苗族侗族自治州",
          "code":"5226"
        },
        {
          "label":"黔南布依族苗族自治州",
         value:"黔南布依族苗族自治州",
          "code":"5227"
        }
      ]
    },
    {
      "label":"云南",
     value:"云南",
      "code":"5300",
      "children":[
        {
          "label":"昆明",
         value:"昆明",
          "code":"5301"
        },
        {
          "label":"曲靖",
         value:"曲靖",
          "code":"5303"
        },
        {
          "label":"玉溪",
         value:"玉溪",
          "code":"5304"
        },
        {
          "label":"保山",
         value:"保山",
          "code":"5305"
        },
        {
          "label":"昭通",
         value:"昭通",
          "code":"5306"
        },
        {
          "label":"丽江",
         value:"丽江",
          "code":"5307"
        },
        {
          "label":"普洱",
         value:"普洱",
          "code":"5308"
        },
        {
          "label":"临沧",
         value:"临沧",
          "code":"5309"
        },
        {
          "label":"楚雄彝族自治州",
         value:"楚雄彝族自治州",
          "code":"5323"
        },
        {
          "label":"红河哈尼族彝族自治州",
         value:"红河哈尼族彝族自治州",
          "code":"5325"
        },
        {
          "label":"文山壮族苗族自治州",
         value:"文山壮族苗族自治州",
          "code":"5326"
        },
        {
          "label":"西双版纳傣族自治州",
         value:"西双版纳傣族自治州",
          "code":"5328"
        },
        {
          "label":"大理",
         value:"大理",
          "code":"5329"
        },
        {
          "label":"德宏傣族景颇族自治州",
         value:"德宏傣族景颇族自治州",
          "code":"5331"
        },
        {
          "label":"怒江傈僳族自治州",
         value:"怒江傈僳族自治州",
          "code":"5333"
        },
        {
          "label":"迪庆藏族自治州",
         value:"迪庆藏族自治州",
          "code":"5334"
        }
      ]
    },
    {
      "label":"西藏自治区",
     value:"西藏自治区",
      "code":"5400",
      "children":[
        {
          "label":"拉萨",
         value:"拉萨",
          "code":"5401"
        },
        {
          "label":"昌都地区",
         value:"昌都地区",
          "code":"5421"
        },
        {
          "label":"山南地区",
         value:"山南地区",
          "code":"5422"
        },
        {
          "label":"日喀则地区",
         value:"日喀则地区",
          "code":"5423"
        },
        {
          "label":"那曲地区",
         value:"那曲地区",
          "code":"5424"
        },
        {
          "label":"阿里地区",
         value:"阿里地区",
          "code":"5425"
        },
        {
          "label":"林芝地区",
         value:"林芝地区",
          "code":"5426"
        }
      ]
    },
    {
      "label":"陕西",
     value:"陕西",
      "code":"6100",
      "children":[
        {
          "label":"西安",
         value:"西安",
          "code":"6101"
        },
        {
          "label":"铜川",
         value:"铜川",
          "code":"6102"
        },
        {
          "label":"宝鸡",
         value:"宝鸡",
          "code":"6103"
        },
        {
          "label":"咸阳",
         value:"咸阳",
          "code":"6104"
        },
        {
          "label":"渭南",
         value:"渭南",
          "code":"6105"
        },
        {
          "label":"延安",
         value:"延安",
          "code":"6106"
        },
        {
          "label":"汉中",
         value:"汉中",
          "code":"6107"
        },
        {
          "label":"榆林",
         value:"榆林",
          "code":"6108"
        },
        {
          "label":"安康",
         value:"安康",
          "code":"6109"
        },
        {
          "label":"商洛",
         value:"商洛",
          "code":"6110"
        }
      ]
    },
    {
      "label":"甘肃",
     value:"甘肃",
      "code":"6200",
      "children":[
        {
          "label":"兰州",
         value:"兰州",
          "code":"6201"
        },
        {
          "label":"嘉峪关",
         value:"嘉峪关",
          "code":"6202"
        },
        {
          "label":"金昌",
         value:"金昌",
          "code":"6203"
        },
        {
          "label":"白银",
         value:"白银",
          "code":"6204"
        },
        {
          "label":"天水",
         value:"天水",
          "code":"6205"
        },
        {
          "label":"武威",
         value:"武威",
          "code":"6206"
        },
        {
          "label":"张掖",
         value:"张掖",
          "code":"6207"
        },
        {
          "label":"平凉",
         value:"平凉",
          "code":"6208"
        },
        {
          "label":"酒泉",
         value:"酒泉",
          "code":"6209"
        },
        {
          "label":"庆阳",
         value:"庆阳",
          "code":"6210"
        },
        {
          "label":"定西",
         value:"定西",
          "code":"6211"
        },
        {
          "label":"陇南",
         value:"陇南",
          "code":"6212"
        },
        {
          "label":"临夏回族自治州",
         value:"临夏回族自治州",
          "code":"6229"
        },
        {
          "label":"甘南藏族自治州",
         value:"甘南藏族自治州",
          "code":"6230"
        }
      ]
    },
    {
      "label":"青海",
     value:"青海",
      "code":"6300",
      "children":[
        {
          "label":"西宁",
         value:"西宁",
          "code":"6301"
        },
        {
          "label":"海东地区",
         value:"海东地区",
          "code":"6321"
        },
        {
          "label":"海北藏族自治州",
         value:"海北藏族自治州",
          "code":"6322"
        },
        {
          "label":"黄南藏族自治州",
         value:"黄南藏族自治州",
          "code":"6323"
        },
        {
          "label":"海南藏族自治州",
         value:"海南藏族自治州",
          "code":"6325"
        },
        {
          "label":"果洛藏族自治州",
         value:"果洛藏族自治州",
          "code":"6326"
        },
        {
          "label":"玉树藏族自治州",
         value:"玉树藏族自治州",
          "code":"6327"
        },
        {
          "label":"海西蒙古族藏族自治州",
         value:"海西蒙古族藏族自治州",
          "code":"6328"
        }
      ]
    },
    {
      "label":"宁夏回族自治区",
     value:"宁夏回族自治区",
      "code":"6400",
      "children":[
        {
          "label":"银川",
         value:"银川",
          "code":"6401"
        },
        {
          "label":"石嘴山",
         value:"石嘴山",
          "code":"6402"
        },
        {
          "label":"吴忠",
         value:"吴忠",
          "code":"6403"
        },
        {
          "label":"固原",
         value:"固原",
          "code":"6404"
        },
        {
          "label":"中卫",
         value:"中卫",
          "code":"6405"
        }
      ]
    },
    {
      "label":"新疆维吾尔自治区",
     value:"新疆维吾尔自治区",
      "code":"6500",
      "children":[
        {
          "label":"乌鲁木齐",
         value:"乌鲁木齐",
          "code":"6501"
        },
        {
          "label":"克拉玛依",
         value:"克拉玛依",
          "code":"6502"
        },
        {
          "label":"吐鲁番地区",
         value:"吐鲁番地区",
          "code":"6521"
        },
        {
          "label":"哈密地区",
         value:"哈密地区",
          "code":"6522"
        },
        {
          "label":"昌吉回族自治州",
         value:"昌吉回族自治州",
          "code":"6523"
        },
        {
          "label":"博尔塔拉蒙古自治州",
         value:"博尔塔拉蒙古自治州",
          "code":"6527"
        },
        {
          "label":"巴音郭楞蒙古自治州",
         value:"巴音郭楞蒙古自治州",
          "code":"6528"
        },
        {
          "label":"阿克苏地区",
         value:"阿克苏地区",
          "code":"6529"
        },
        {
          "label":"克孜勒苏柯尔克孜自治州",
         value:"克孜勒苏柯尔克孜自治州",
          "code":"6530"
        },
        {
          "label":"喀什地区",
         value:"喀什地区",
          "code":"6531"
        },
        {
          "label":"和田地区",
         value:"和田地区",
          "code":"6532"
        },
        {
          "label":"伊犁哈萨克自治州",
         value:"伊犁哈萨克自治州",
          "code":"6540"
        },
        {
          "label":"塔城地区",
         value:"塔城地区",
          "code":"6542"
        },
        {
          "label":"阿勒泰地区",
         value:"阿勒泰地区",
          "code":"6543"
        }
      ],
      
    },
   
   
  ],
        sanji:"",
          code: 0,
            input: "",
            }
        },
        methods: {
              buttonFn(form){
                    this.$refs.ruleForm.validate((valid) => {
          if (valid) {
   this.sanji=   this.sanji.toString();
        this.$api
          .attractInvestmentAdd({
        mobile :this.form.phone,
        region  :this.sanji ,
        userName  :this.input,
          })
          .then(res => {
          console.log(res.data)
          if(res.data.code == 200){
              this.$message.success("提交成功!");
          }
              
          })
          .catch(error => {
            console.log(error);
          });
            // alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    
      },
        }

    }
</script>
<style lang="less" scoped>


        .iphoneSolve {
              .text_list2 {
      width: 100%;
      // height: 200px;
      padding:4rem 0 3rem 0;
     text-align: center;
      background: #d9d9f5;
      .tiele_span {
      border-bottom: 0.2rem solid black;
      font-size: 7.8vw;
      font-weight: 500;
      color: black;
    }
       .button_box{
          margin: 3rem auto;
          width:28%;
            font-size: 5.2vw;
        font-weight: 500;
        color: white;
        height:10vw;
        text-align: center;
         border-radius: 5px;
        line-height:10vw;
          letter-spacing:0.8vw;
     background:linear-gradient( #3d89ef, #3769e2 95%);
          //  border: #9b9494 1px solid;
       }


      .form_box {
        margin: 3rem auto;
        width: 75%;
        font-size: 5.2vw;
        // font-weight: 500;
        text-align: left;
        /deep/.el-input {
          // 输入框高度
          height: 42px;
          // 边框圆角
          border-radius: 8px;
        }
         /deep/.el-cascader{
              height: 42px;
          border-radius: 8px;
         }
        /deep/.el-input__inner {
          height: 42px;
          background: #d9d9f5;
          border: #9b9494 1px solid;
          // 设置字号
          font-size: 4.2vw;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          // 设置输入字体的颜色
          color: #2c2c2c;
        }

      }
    }

              background:#fff;
            .lunbotu {
                 margin-top: 14vw;  
                img {
                    width: 100%;
                    display:block;
                }
            }

            .major_service {
                img {
                    width: 100%;
                        display:block;
                }
            }
        



    }
</style>