<template>
    <div class="iphoneNews">

        <img src="../../assets/xwzxbanner.jpg" class="img" />
          <div class="news_one" v-for="(item,index) in this.tableList" :key="index" @click="newDetails(item)">
           <img :src="item.newsImgUrl"  />
              <div class="news_title">
                <p>{{ item.newsTitle |ellipsis  }}</p>
                <div class="new_tit_bottom">
                  <span>
                   {{ item.newsSubtitle  |ellipsistit }}
                  </span>
                  <span>
                  日期： {{item.releaseTime}}
                  </span>
                </div>
              </div>
            </div>
             <el-pagination :current-page.sync="currentPage" class="paging"   background :page-size="pageSize" :total="total"
                        layout="prev, pager, next" @size-change="handleSizeChange" :pager-count="pagercount"
                        @current-change="handlePageChange"></el-pagination>
    </div>
</template>

<script>
    export default {
        name: "iphoneNews",
        data() {
            return {
                  tableList: [],
                pageSize: 3,
                total: 0,
                pagercount:5,
                  currentPage: 1,
            };
        },
         filters: {
      ellipsis(value) {
        if (!value) return "";
        if (value.length > 24) {
          return value.slice(0, 24) + "...";
        }
        return value;
      },
        ellipsistit(value) {
        if (!value) return "";
        if (value.length > 9) {
          return value.slice(0, 9) + "...";
        }
        return value;
      },
    },
           mounted() {
            this.newsList()
        },
        methods:{
                //新闻资讯列表
            newsList() {
                this.$api
                    .newsListApp({
                        page: {
                            current: this.current,
                            size: this.pageSize
                        }
                    })
                    .then(res => {
                        this.tableList = res.data.data.records;
                        this.total = res.data.data.total;
                           this.currentPage = parseInt(this.current)
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },



                newDetails(data) {

                  if(data.clickUrl == null || data.clickUrl == ""){
                    this.$router.push({
                      path: "/iphoneNewsDetails",
                      query: {

                        id: data.id,
                        current: this.current,
                        size: this.pageSize
                      }
                    });
                  }else {
                    window.open(
                        data.clickUrl,
                        "_blank"
                    );

                  }

            },
                 handleSizeChange(val) {
                this.pageSize = val;
                this.pagercount =5
                this.newsList();
            },
            handlePageChange(val) {
 this.pagercount =5
                this.current = val;
                this.newsList();
            },
        },
             beforeRouteEnter(to, from, next) {
      console.log(from.path, '组件独享守卫beforeRouteEnter第二个参数');
      next(vm => {
        if (from.path == '/iphoneNewsDetails') {
          vm.current = sessionStorage.getItem("iphoneNewsDet")
          vm.newsList();
        } else {

          vm.current = 1
          vm.newsList();
        }

      });
    },

    beforeRouteLeave(to, from, next) {

      console.log(to.path, '组件独享守卫beforeRouteLeave第一个参数');
      if (to.path == '/iphoneNewsDetails') {
        sessionStorage.setItem("iphoneNewsDet", this.current);
      } else {
        sessionStorage.removeItem("phoneNewsDet");
      }
      next();
    },

    }
</script>
<style lang="less" scoped>


        .iphoneNews {
            margin-top: 14vw;
            width: 100%;
            background: #f7f7f7;

        }
          .img {
                width: 100%;
                // margin-bottom: 7.4vw;
            }

     .news_one {
      width: 94.6vw;
      border-radius: 5px;
      height: 70.6vw;
      margin: 7vw  auto  2vw auto;
      background: #ffffff;

       box-shadow: 2px 2px 2px 0 rgb(197, 196, 196);

      img {
        width: 100%;
        height: 40.8vw;
      }

      .news_title {
        width: 85.3vw;
        margin: auto;

        p {
          padding: 4.4vw 0;
          font-size: 4.2vw;
          color: #333;
        }

        .new_tit_bottom {
          display: flex;
          width: 100%;
          justify-content: space-between;
    align-items: center;
margin: 0 auto;

          span:nth-child(1) {
            font-size: 3.7vw;
            color: #666;
          }

          span:nth-child(2) {
            font-size: 2.9vw;
            color: #999;
          }
        }
      }
    }
       .paging{
                // display: inline-block;
                font-size: 7vw;
                text-align: center;
                padding: 6vw 0 10vw 0;
            }


</style>
