<template>
  <div class="mainContainer">
    <div class="select">
      <span class="title">问题列表</span>
      <el-button type="text" size="small" icon="el-icon-refresh" class="select-button" @click="refresh">刷新</el-button>
      <el-button type="text" size="small" icon="el-icon-upload2" class="select-button" @click="problemExport">导出
      </el-button>
      <el-button type="text" size="small" icon="el-icon-plus" class="select-button" @click="questionAdd">新增</el-button>
      <el-button type="text" size="small" icon="el-icon-bottom" class="select-button" @click="playShow()">高级查询
      </el-button>
      <el-button type="text" size="small" class="select-button" :class="{active: isActive === 1}"
        @click="changeClass(1)">由我处理</el-button>
      <el-button type="text" size="small" class="select-button" :class="{active: isActive === 2}"
        @click="changeClass(2)">所有</el-button>
    </div>
    <div class="gaojiSearch" v-show="ifSearchMany">
      <div style="width:100%;overflow: hidden;">
        <div style="float:left">
          <div class="toolbar-right">
            <span style="font-size:14px" class="toolbarTit">当前处理人员 ：</span>
            <el-select v-model="handlerUserId" clearable size="mini" :disabled="isHandlerUser"
              style="width:8.25rem;margin-right:2rem;margin-bottom:10px">
              <el-option v-for="item in realNameList" :key="item.value" :label="item.realName" :value="item.sysUserId">
              </el-option>
            </el-select>

            <span style="font-size:14px" class="toolbarTit">设备IMEI号 ：</span>
            <el-input
              oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
              maxLength='15' placeholder="请输入内容" v-model="mac" size="mini"
              style="width: 8.25rem;;margin-right:2rem;margin-bottom:10px" clearable></el-input>
            <span style="font-size:14px">问题状态 ：</span>
            <el-input placeholder="请输入内容" v-model="problemStatus" size="mini" style="width: 8.25rem;;margin-right:2rem"
              clearable></el-input>
            <span style="font-size:14px">问题来源 ：</span>
            <el-select v-model="problemSource" clearable size="mini" style="width: 8.25rem;margin-right:2rem;">
              <el-option v-for="item in problemSourceList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <span style="font-size:14px" class="toolbarTit">问题创建人员 ：</span>
            <el-select v-model="creatorUserId" clearable size="mini"
              style="width:8.25rem;margin-right:2rem;margin-bottom:10px">
              <el-option v-for="item in realNameList" :key="item.value" :label="item.realName" :value="item.sysUserId">
              </el-option>
            </el-select>
            <br />
            <span style="font-size:14px" class="toolbarTit">问题标题 ：</span>
            <el-input placeholder="请输入标题" v-model="problemTitle" size="mini" style="width: 8.25rem;;margin-right:2rem"
              clearable></el-input>
            <!-- <span style="font-size:14px" class="toolbarTit">问题类型 ：</span>
            <el-select
              v-model="problemTypeId"
              clearable
              size="mini"
              style="width: 11.25rem;margin-right:2rem;"
            >
              <el-option
                v-for="item in problemTypeIdlist"
                :key="item.value"
                :label="item.typeName"
                :value="item.id"
              ></el-option>
            </el-select> -->
            <span style="font-size:14px" class="toolbarTit">产品名称 ：</span>
            <el-select v-model="productId" clearable size="mini" style="width: 8.25rem;margin-right:2rem;">
              <el-option v-for="item in product" :key="item.value" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <span style="font-size:14px">工单编号 ：</span>
            <el-input placeholder="请输入" v-model.trim="workOrderNo" size="mini" style="width: 8.25rem;;margin-right:2rem"
              clearable></el-input>
            <span style="font-size:14px">问题等级 ：</span>
            <el-select v-model="severityCode" clearable size="mini" style="width: 8.25rem;margin-right:2rem;">
              <el-option v-for="item in severity" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div style="width:100%;overflow: hidden;">
        <div style="float:right;">
          <div class="grid-content bg-purple-dark">
            <div class="searchBut">
              <el-button size="small" type="primary" @click="imeiSearch">搜索</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-vv">
      <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
        <div class="result-info">
          共有
          <span>{{ total }}</span>个问题
        </div>
      </div>
      <el-table :data="tableData" stripe size="small" class="product-table"
        :default-sort="{prop: 'date', order: 'descending'}" :header-cell-style="{'background-color': '#eeeeee'}"
        @select="getIds" @header-click="clickFun" @select-all="getAllIds" @sort-change="sortChange">
        <el-table-column type="selection" align="center" width="55"></el-table-column>
        <el-table-column label="序号" type="index" align="center"></el-table-column>
        <!-- <el-table-column
          v-for="(item,index) in labels"
          :key="index"
          :prop="props[index]"
          :label="item"
         :render-header="renderHeader"
          v-if="relLabelsStatus[index]"
          align="center"
        ></el-table-column> -->

        <el-table-column prop="workOrderNo" label="工单编号" align="center"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" sortable="custom" width="140" align="center">
          <template slot-scope="scope">
            <span @click="problemList">
              {{scope.row.createTime}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" sortable="custom" label="更新时间" width="140" align="center"></el-table-column>
        <el-table-column prop="problemTitle" label="问题标题" align="center"></el-table-column>
        <el-table-column prop="creatorUser" label="问题创建者" align="center"></el-table-column>
        <el-table-column prop="handlerUser" label="当前处理人员" align="center"></el-table-column>
        <el-table-column prop="problemStatus" label="问题状态" align="center"></el-table-column>
        <el-table-column prop="severity" label="问题等级" align="center"></el-table-column>
        <el-table-column prop="problemSource" label="问题来源" align="center"></el-table-column>
        <el-table-column prop="problemType" label="问题类型" align="center"></el-table-column>
        <el-table-column prop="mac" label="设备IMEI" align="center"></el-table-column>
        <el-table-column prop="productName" label="产品名称" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" align="center" width="240">
          <template slot-scope="scope">
            <el-button @click="questionDetails(scope.row.id)" type="text" size="small" icon="el-icon-document">详情
            </el-button>
            <el-button @click="handleFn(scope.row)" type="text" size="small" icon="el-icon-printer"
              v-if="scope.row.isHandler">处理</el-button>
            <el-button @click="assignFn(scope.row)" type="text" size="small" icon="el-icon-link"
              v-if="scope.row.isHandler">转派</el-button>
            <el-button @click="repatriate(scope.row)" type="text" size="small" icon="el-icon-link"
              v-if="scope.row.isRepatriate">回派</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background :current-page.sync="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
        :total="total" layout="sizes,prev, pager, next" @size-change="handleSizeChange"
        @current-change="handlePageChange">
      </el-pagination>
    </div>
    <el-dialog title="转派" :visible.sync="dialogVisible" :lock-scroll="false" :close-on-click-modal="false">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="指派人员" :label-width="formLabelWidth" prop="handlerUserId">
          <el-select v-model="form.handlerUserId" clearable size="mini"
            style="width: 11.25rem;margin-right:2rem;margin-bottom:10px">
            <el-option v-for="item in realNameList" :key="item.value" :label="item.realName" :value="item.sysUserId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否发送邮件" :label-width="formLabelWidth" prop="isSendEmail">
          <el-select v-model="form.isSendEmail" clearable size="mini"
            style="width: 11.25rem;margin-right:2rem;margin-bottom:10px">
            <el-option v-for="item in isSendEmailList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="转派说明" :label-width="formLabelWidth" prop="handleOpinion">
          <el-input v-model="form.handleOpinion" type="textarea" size="mini" rows="10"
            style="width:60%;;margin-right:2rem;margin-bottom:10px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="回派" :visible.sync="handleDialogVisible" :lock-scroll="false" :close-on-click-modal="false">
      <el-form :model="formHandle" :rules="rulesHandle" ref="formHandle">
        <el-form-item label="是否短信通知" :label-width="formLabelWidth" prop="isSMS">
          <el-select v-model="formHandle.isSMS" clearable size="mini" style="width:60%;margin-bottom:10px">
            <el-option v-for="item in handleResurt" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="回派说明" :label-width="formLabelWidth" prop="handleOpinion">
          <el-input v-model="formHandle.handleOpinion" type="textarea" size="mini" rows="5" maxlength="30"
            style="width:60%;;margin-right:2rem;margin-bottom:10px"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFormFandle('formHandle')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
  import Bus from "../../../components/bus";
  import qs from "qs";
  import PubSub from "pubsub-js";
  import Storage from "@/utils/Storage";
  export default {
    name: "problemStatus",
    data() {
      return {
        isActive: 1,
        productDel: false,
        productAdd: false,
        ifSearchMany: false,
        problemSource: "",
        form: {
          handlerUserId: [],
          isSendEmail: "",
          handleOpinion: ""
        },
        formHandle: {
          handleOpinion: "",
          isSMS: ""
        },
        isSendEmailList: [{
            value: "1",
            label: "是"
          },
          {
            value: "0",
            label: "否"
          },
        ],
        visible: false,
        total: 0,
        tableData: [],
        ids: [],
        problemSourceList: [{
            value: 1,
            label: "内部问题"
          },
          {
            value: 2,
            label: "app"
          },
          {
            value: 3,
            label: "客户反馈"
          }
        ],
        chooseOnlineStatus: "", //搜索选择
        sysUserlist: [], //账号所属类型
        handRealNameList: [],
        severity: [1, 2, 3, 4, 5],
        severityCode: '',
        newSsUserlist: {
          value: "id",
          label: "typeName",
          children: "childs",
          expandTrigger: "hover"
          // emitPath: false
        },
        checked: false,
        //转派对话框标记
        dialogVisible: false,
        //修改账号弹出对话框标记
        handleDialogVisible: false,
        //协议类型列表
        protocols: [{
          id: 1,
          protocolName: "test"
        }],

        //产品品类列表
        categories: [],
        handleResurt: [{
            value: false,
            label: "否"
          },
          {
            value: true,
            label: "是"
          }
        ],
        value: "",
        input: "",
        current: 1,
        currentPage: 0,
        pageSize: 10,
        delDialogVisible: false,
        isHandlerUser: true,
        // productId: "",
        indexId: "", //反显id
        nameinput: "", //搜索输入
        indexIdlist: "", //反显列表
        formLabelWidth: "120px",
        realNameList: [], //当前处理人员列表
        handlerUserId: "", //当前处理人员
        creatorUserId: "", //问题创建人员ID
        mac: "", //设备号
        problemStatus: "", //问题状态
        problemTitle: "", //问题标题
        workOrderNo: "", //工单类型
        problemTypeId: "", //问题类型
        problemTypeIdlist: [], //问题类型列表
        product: [], //获取产品下拉列表接口
        productId: "", //获取产品下拉列表接口
        problemId: "", //指派id
        createTime: "",
        acs: "",
        ascs: "",
        userDisabled: false,
        rules: {
          handlerUserId: [{
            required: true,
            message: "请选择指派人员",
            trigger: "blur"
          }],
          handleOpinion: [{
            required: true,
            message: "请输入转派说明",
            trigger: "blur"
          }],
          isSendEmail: [{
            required: true,
            message: "请选择是否发送邮件",
            trigger: "blur"
          }],
        },
        rulesHandle: {

          handleOpinion: [{
            required: true,
            message: "请输入处理意见",
            trigger: "blur"
          }],
          isSMS: [{
            required: true,
            message: "请选择是否短信通知",
            trigger: "change"
          }]
        },
        props: [
          "workOrderNo",
          "problemTitle",
          "problemSource",
          "problemType",
          "severity",
          "creatorUser",
          "mac",
          "productName",
          "problemStatus",
          "handlerUser",
          "createTime",
          "updateTime"
        ],
        labels: [
          "工单编号",
          "问题标题",
          "问题来源",
          "问题类型",
          "问题等级",
          "问题创建者",
          "设备IMEI",
          "产品名称",
          "问题状态",
          "当前处理人员",
          "创建时间",
          "更新时间"
        ],
        relLabelsStatus: [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
        ],
        accountLevel: "", //是否是超级账号,
        defaulShow: false, //处理人隐藏,
        realName: "", //指派人禁止选择是id
      };
    },
    methods: {
      refresh() {
        this.problemList();
      },
      // 处理结果change事件
      defaultHandler(item) {
        console.log(item)
        this.defaulShow = true
        this.$api.problemDefaultHandler({
          problemId: this.problemId,
          handleResurt: item
        }).then(res => {
          if (res.data.code == 200) {
            // console.log(res.data.data)
            this.userDisabled = false
            this.$refs["formHandle"].clearValidate();
            this.formHandle.assignUserId = ""
            if (res.data.data.length == 1) {
              this.formHandle.assignUserId = res.data.data[0].realName
              this.realName = res.data.data[0].sysUserId
              this.userDisabled = true
            } else {
              this.handRealNameList = res.data.data
            }
          }
        });
      },
      clickFun(column, event) {
        console.log(column, event)
      },
      sortMethod(obj1, obj2, type) {
        console.log(obj1, obj2, type)
      },
      sortChange({
        column,
        prop,
        order
      }) {
        if (prop == "createTime") {
          switch (order) {
            case "ascending":
              console.log("升序");
              this.acs = true
              this.createTime = "p.create_time"

              this.problemList(this.acs, this.createTime)
              break;
            case "descending":
              console.log("降序");
              this.acs = false
              this.createTime = "p.create_time"

              this.problemList(this.acs, this.createTime)
              break;

            default:
              // this.getData();
              break;
          }
        } else if (prop == "updateTime") {
          switch (order) {
            case "ascending":
              console.log("升序");
              this.acs = true
              this.createTime = "p.update_time"

              this.problemList(this.acs, this.createTime)
              break;
            case "descending":
              console.log("降序");
              this.acs = false
              this.createTime = "p.update_time"

              this.problemList(this.acs, this.createTime)
              break;

            default:
              // this.getData();
              break;
          }
        }
      },
      renderHeader(h, {
        column
      }) {
        return h('span', [
          h('span', column.label, {}),
          h('span', {
            style: 'margin-left: 5px;',
            on: {
              click: this.changeTitle
            }
          }),
        ]);
      },
      changeTitle(val) {
        console.log(val)
      },
      // 切换样式
      changeClass(i) {
        this.isActive = i;

        if (i == 2) {
          this.isHandlerUser = false
        } else {
          this.isHandlerUser = true
        }
        this.current = 1;
        this.problemList();
      },
      //高级搜索点击函数
      playShow() {
        if (this.ifSearchMany == false) {
          this.ifSearchMany = true;
        } else {
          this.ifSearchMany = false;
        }
        // 获取产品下拉列表接口
        this.$api.problemListForPull().then(res => {
          this.product = res.data.data;
        });
        // 默认处理者下拉框接口
        this.$api.moduleselectorList().then(res => {

          this.realNameList = res.data.data;
        });
        // 问题类型下拉框
        // this.$api.selectorForWorkOrder().then(res => {

        //   this.problemTypeIdlist = res.data.data;
        // });
      },
      // 高级搜索模糊查询
      imeiSearch() {
        this.current = 1;
        this.problemList();
      },
      // 点击转派
      assignFn(item) {
        this.problemId = item.id;
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs["form"].clearValidate();
        });
        // 默认处理者下拉框接口
        this.$api.moduleselectorList().then(res => {

          this.realNameList = res.data.data;

        });
      },
      // 点击回派
      repatriate(item) {
        this.formHandle.handleOpinion = ""
        this.formHandle.isSMS = ""

        this.problemId = item.id;
        this.handleDialogVisible = true;
      },
      // 点击处理
      handleFn(item) {
        //  sessionStorage.setItem("detailsIsActive", this.isActive);
        this.$router.push({
          path: `/questionHandle`,
          query: {
            id: item.id,
            isActive: this.isActive,
            current: this.current,
            ifSearchMany: this.ifSearchMany,
            mac: this.mac,
            handlerUserId: this.handlerUserId,
            isHandlerUser: this.isHandlerUser,
            problemStatus: this.problemStatus,
            problemSource: this.problemSource,
            creatorUserId: this.creatorUserId,
            problemTitle: this.problemTitle,
            productId: this.productId,
            workOrderNo: this.workOrderNo,
            severityCode: this.severityCode,

          }
        });
      },
      // 处理确定接口
      submitFormFandle() {
        this.$refs["formHandle"].validate(valid => {
          if (valid) {

            this.handleDialogVisible = false;
            this.formHandle.problemId = this.problemId;

            this.$api.problemRepatriate(this.formHandle).then(res => {
              if (res.data.code == 200) {
                this.$message.success("处理成功!");
                this.$nextTick(() => {
                  this.$refs["formHandle"].clearValidate();
                });
                this.problemList();
              }
            });
          } else {
            return false;
          }
        });
      },
      //批量选中
      getIds(selection, row) {
        var arr = [];
        for (var i = 0; i < selection.length; i++) {
          arr.push(selection[i].mac);
        }
        this.dispatchList = arr;
        var idArr = [];
        for (var i = 0; i < selection.length; i++) {
          idArr.push(selection[i].id);
        }
        this.ids = idArr;

      },
      //全部选中
      getAllIds(selection, row) {
        var arr = [];
        for (var i = 0; i < selection.length; i++) {
          arr.push(selection[i].mac);
        }
        this.dispatchList = arr;
        var idArr = [];
        for (var i = 0; i < selection.length; i++) {
          idArr.push(selection[i].id);
        }
        this.ids = idArr;

      },
      // 跳转详情
      questionDetails(id) {
        // console.log(this.isActive)
        sessionStorage.setItem("detailsIsActive", this.isActive);
        this.$router.push({
          path: '/questionDetails',
          query: {
            id: id,
            isActive: this.isActive,
            current: this.current,
            ifSearchMany: this.ifSearchMany,
            mac: this.mac,
            isHandlerUser: this.isHandlerUser,
            handlerUserId: this.handlerUserId,
            problemStatus: this.problemStatus,
            problemSource: this.problemSource,
            creatorUserId: this.creatorUserId,
            problemTitle: this.problemTitle,
            productId: this.productId,
            workOrderNo: this.workOrderNo,
            severityCode: this.severityCode,

          }
        });
      },
      // 新增跳转
      questionAdd() {
        this.$router.push({
          path: `/questionAdd`
        });
      },
      ifMohuHandler(e) {
        // console.log(e);
        this.ifMohu = false;
        this.searchData = [];
      },
      // changex清空
      upOptionType() {
        this.nameinput = "";
      },
      handlePageChange(val) {

        this.current = val;
        this.problemList();
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.problemList();
      },

      cancelDelete() {
        this.delDialogVisible = false;
        this.productId = "";
      },
      handleDelClose() {
        this.delDialogVisible = false;
        this.deleteProduct(this.productId);
      },

      problemList(sort, time) {
        //查询列表
        // console.log(sort, time)
        if (time == undefined) {
          time = "p.update_time"
          sort = false
        }
        // console.log(this.current)
        this.currentPage = this.current
        //  console.log(this.currentPage)
        this.$api
          .problemList({
            page: {
              current: this.current,
              size: this.pageSize,
              orders: [{
                column: time,
                asc: sort
              }]
            },
            query: {
              isHandled: this.isActive,
              // ids: [],
              handlerUserId: this.handlerUserId,
              creatorUserId: this.creatorUserId,
              mac: this.mac,
              problemSource: this.problemSource,
              problemStatus: this.problemStatus,
              problemTitle: this.problemTitle,
              workOrderNo: this.workOrderNo,
              severity: this.severityCode,
              problemTypeId: this.problemTypeId,
              productId: this.productId
            }
          })
          .then(res => {
            this.tableData = res.data.data.records;

            this.total = res.data.data.total;
            let cloudList = JSON.parse(sessionStorage.getItem("cloudList"));

            for (let i = 0; i < cloudList.length; i++) {
              if (
                this.$store.state.localList.localList.productDel == cloudList[i]
              ) {
                this.productDel = true;
              }
              if (
                this.$store.state.localList.localList.productAdd == cloudList[i]
              ) {
                this.productAdd = true;
              }
            }
          })
          .catch(error => {
            console.log(error);
          });
      },
      // 导出
      problemExport() {
        let properties = [];
        let titles = [];
        for (let i = 0; i < this.relLabelsStatus.length; i++) {
          if (this.relLabelsStatus[i]) {
            properties.push(this.props[i]);
            titles.push(this.labels[i]);
          }
        }
        titles.push("问题描述")
        properties.push("problemDesc")
        let query = {};
        query.handlerUserId = this.handlerUserId;
        query.ids = this.ids;
        query.isHandled = this.isActive;
        query.mac = this.mac;
        query.problemStatus = this.problemStatus;
        query.problemTitle = this.problemTitle;
        query.workOrderNo = this.workOrderNo;
        query.problemTypeId = this.problemTypeId;
        this.$api
          .problemExport({
            properties: properties,
            titles: titles,
            query: query
          })
          .then(res => {
            this.download(res.data);
          })
          .catch(error => {});
      },
      download(data) {
        if (!data) {
          return;
        }
        let url = window.URL.createObjectURL(
          new Blob([data], {
            type: "application/octet-binary"
            // type: "application/vnd.ms-excel"
          })
        );
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", "excel.xls");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      },
      //重置函数
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      showAddProductDialog() {
        this.dialogVisible = true;
        this.form.problemStatus = "";
        this.$nextTick(() => {
          this.$refs["form"].clearValidate();
        });
      },
      // 指派接口
      submitForm(formName) {
        this.$refs["form"].validate(valid => {
          if (valid) {
            this.dialogVisible = false;
            this.$api
              .problemReassignment({
                problemId: this.problemId,
                reassignmentUserId: this.form.handlerUserId,
                handleOpinion: this.form.handleOpinion,
                isSendEmail: this.form.isSendEmail
              })
              .then(res => {
                if (res.data.code == 200) {
                  this.$message.success("转派成功!");
                  this.form.handlerUserId = "";
                  this.problemList();
                }
              });
          } else {
            return false;
          }
        });
      }
    },
    created() {
      //查询产品列表
  
      let newData = JSON.parse(window.sessionStorage.getItem('keepAliveList'))
      //  console.log(sessionStorage.getItem('isKeepAlive'))
      
      if (sessionStorage.getItem('isKeepAlive') == '1') {
        console.log('需要缓存',sessionStorage.getItem("keepAliveList"))


        this.accountLevel = sessionStorage.getItem('accountLevel');
        if (newData) {
          this.isActive = newData.isActive == 2 ? 2 : 1
          // console.log( newData.isActive)
          this.currentPage = newData.current
             // 反显高级查询
        if (newData.ifSearchMany == undefined) {
          this.ifSearchMany = this.ifSearchMany
        } else {
          this.ifSearchMany = newData.ifSearchMany
        }
        // 反显设备imei
        if (newData.mac == undefined) {
          this.mac = this.mac
        } else {
          this.mac = newData.mac
        }

        // 当前处理人
        if (newData.handlerUserId == undefined) {
          this.handlerUserId = this.handlerUserId
        } else {
          this.handlerUserId = newData.handlerUserId
        }

        // 当前处理人是否disabled
        if (newData.isHandlerUser == false) {
          this.isHandlerUser = newData.isHandlerUser
        } else {
          this.isHandlerUser = this.isHandlerUser
        }
        // 问题状态
        if (newData.problemStatus == undefined) {
          this.problemStatus = this.problemStatus
        } else {
          this.problemStatus = newData.problemStatus
        }
        // 问题来源
        if (newData.problemSource == undefined) {
          this.problemSource = this.problemSource
        } else {
          this.problemSource = newData.problemSource
        }
        // 问题创建人员
        if (newData.creatorUserId == undefined) {
          this.creatorUserId = this.creatorUserId
        } else {
          this.creatorUserId = newData.creatorUserId
        }
        // 问题标题
        if (newData.problemTitle == undefined) {
          this.problemTitle = this.problemTitle
        } else {
          this.problemTitle = newData.problemTitle
        }
        // 产品名称
        if (newData.productId == undefined) {
          this.productId = this.productId
        } else {
          this.productId = newData.productId
        }
        // 工单编号
        if (newData.workOrderNo == undefined) {
          this.workOrderNo = this.workOrderNo
        } else {
          this.workOrderNo = newData.workOrderNo
        }

        // 工单编号
        if (newData.workOrderNo == undefined) {
          this.workOrderNo = this.workOrderNo
        } else {
          this.workOrderNo = newData.workOrderNo
        }
        // 问题等级
        if (newData.severityCode == undefined) {
          this.severityCode = this.severityCode
        } else {
          this.severityCode = newData.severityCode
        }

          
        } else {
          this.isActive = 1
        this.problemList();
        }
     

        sessionStorage.removeItem("isKeepAlive");
      } else {
        console.log('不需要缓存')
        sessionStorage.removeItem("keepAliveList");
        this.problemList();
      }






      this.$api.problemListForPull().then(res => {
        this.product = res.data.data;
      });
      // 默认处理者下拉框接口
      this.$api.moduleselectorList().then(res => {

        this.realNameList = res.data.data;
      });

    },
    mounted() {
      let newData = JSON.parse(window.sessionStorage.getItem('keepAliveList'))
      if (newData) {
        if (this.isActive == 2) {
          this.isActive = 2
          if (newData.current) {
            this.current = newData.current
            this.currentPage = newData.current
          } else {
            this.current = 1
          }
          this.problemList();

        } else {
          if (newData.current) {
            this.current = newData.current
            this.currentPage = newData.current
          } else {
            this.current = 1
          }
          this.problemList();

        }
      }
      this.currentPage = this.current

    },
    beforeRouteEnter(to, from, next) {
      if (from.path == "/questionAdd") {
        next(vm => {
          vm.problemList()
        });
      }
      // else if(from.path != "/questionHandle" ||from.path != "/questionDetails"){
      //  sessionStorage.removeItem("isKeepAlive");
      // }
        // 
      console.log(to.path,from.path)
      next();
    },
    //   beforeRouteLeave(to, from, next) {
    //      // 设置下一个路由的 meta
    //      if(to.path == "/questionDetails"){
    //         from.meta.keepAlive = true;  
    //           console.log(to.path,from.path,from.meta.keepAlive)
    //      }else {
    //         from.meta.keepAlive = false;  
    //         //  vm.problemList()
    //         next(vm => {
    //       vm.problemList()
    //     });
    //           console.log(to.path,from.path,from.meta.keepAlive)
    //      }
    //     next();
    // }

  };
</script>
<style lang="less" scoped>
  @toolBarFontColor: rgba(17, 17, 17, 0.87);

  .el-row {
    margin: 1rem 3rem;
    line-height: 0;
    min-width: 900px;

    .platform-toolbar {
      position: relative;
      margin: 0;
      border: 1px solid #d9d9d9;

      .title-info {
        height: 46px;
        line-height: 46px;
        font-weight: bold;
        font-size: 0.8rem;
      }

      .el-col {
        padding: 0 3rem;

        .toolbar-right {
          float: right;
          margin-top: 7px;

          &.export {
            margin-left: 1rem;

            .toolbar-btn {
              color: @toolBarFontColor;
              font-size: 0.9rem;

              &.left {
                margin-left: 1.5rem;
              }

              &.right {
                margin-right: 0;
              }
            }
          }
        }
      }

      .title-info {
        float: left;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .result-info-wrapper {
      height: 1rem;
      margin: 1rem 0 0.5rem 0;
      position: relative;

      .setting-panel {
        position: absolute;
        float: right;
        top: 2rem;
        width: 200px;
        height: 200px;
        background: red;
        z-index: 1000;
        border-radius: 3px;
      }

      .result-info {
        height: 1rem;
        line-height: 1rem;
        font-size: 0.875rem;
        float: left;
        color: #898989;

        span {
          color: #292929;
          padding: 0 0.1rem;
        }
      }

      .data-setting {
        font-size: 0.875rem;
        color: #2673bb;
        float: right;

        i {
          padding-right: 0.2rem;
        }

        cursor: pointer;
      }
    }

    .el-table td,
    .el-table th {
      padding: 0 !important;
    }

    .el-pagination {
      margin: 1rem 0;
      float: right;
    }
  }

  .product-content {
    height: 6rem;

    .product-info-item {
      float: left;
      margin-right: 4rem;

      .label-info {
        margin-bottom: 0.5rem;

        label {
          font-size: 0.75rem;

          span {
            color: red;
            margin-right: 0.5rem;
          }
        }
      }

      .el-input {
        width: 10rem;
      }

      .el-select {
        width: 13rem;
      }
    }
  }

  .container-el-row {
    margin-top: 0px;
  }

  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .select-button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
      margin-left: 15px;
    }

    .active {
      color: #2673bb;
    }

    .select-input-right-input {
      float: right;
      margin-top: 7px;
    }
  }

  .gaojiSearch {
    margin-top: 10px;
    padding: 1rem 3rem;
    margin-bottom: 10px;
    background-color: #f4f6fa;

    .searchBut {
      float: right;
      margin-left: 20px;
    }
  }

  .toolbarTit {
    min-width: 101px;
    display: inline-block;
  }

  .table-vv {
    padding: 0rem 3rem;
    margin-top: 1rem;

    .result-info {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      float: left;
      color: #898989;

      span {
        color: #292929;
        padding: 0 0.1rem;
      }
    }

    .el-pagination {
      margin: 1rem 0;
      float: right;
    }
  }
</style>