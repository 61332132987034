<template>
    <div class="iphoneSolve">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item @click.native="backTo">
                {{this.$route.query.crumbsTitle=="首页"? this.$route.query.crumbsTitle :"产品中心"}}</el-breadcrumb-item>
            <el-breadcrumb-item> <span style="color:#333">
                    产品详情
                </span></el-breadcrumb-item>
        </el-breadcrumb>
        <div class="item-title_new">
            <div v-html="outlink" class="texztaaa"></div>
            <!-- <img src="../../assets/iphonelogo/6101/6101_01.jpg">
          <img src="../../assets/iphonelogo/6101/6101_02.jpg">
          <img src="../../assets/iphonelogo/6101/6101_03.jpg">
          <img src="../../assets/iphonelogo/6101/6101_04.jpg">
          <img src="../../assets/iphonelogo/6101/6101_05.jpg">
          <img src="../../assets/iphonelogo/6101/6101_06.jpg">
          <img src="../../assets/iphonelogo/6101/6101_07.jpg">
          <img src="../../assets/iphonelogo/6101/6101_08.jpg">
            <div class='botom_tit'>   *如需获取产品3c证书、检验报告、说明书等，
               可扫描底部公众号二维码进入“吻胜智能生活”公众号获取</div> -->
        </div>


    </div>
</template>

<script>
    export default {
        name: "dynamicPage",

        data() {
            return {
                outlink: "", // 外部链接,
                title: "",
                productCategoryId:null
            };
        },
        created() {
             console.log(this.$route.query.newsContent,'ceshicehsi ')
        },
        mounted() {
           this.outlink = this.$route.query.newsContent

             this.newsList()
        },
         beforeRouteEnter(to, from, next) {
      console.log(to.path,from.path, '组件独享守卫beforeRouteEnter第二个参数');
      next(vm => {
        if (from.path == '/iphoneProduct') {


          vm.newsList();
        } else {
          //因为当钩子执行前，组件实例还没被创建
          // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
          // console.log(vm);//当前组件的实例

        //   vm.nodeList();
        }

      });
    },
        methods: {
            newsList() {
     console.log('进入页面事件')
                this.$api
                    .productListForApp({
            queryDto: {
              productCategoryId: this.productCategoryId,
            },
             page: {
                            current: 1,
                            size: 1000
                        }
          })
                    .then(res => {
                        if (res.data.code == "200") {
                            console.log()
                            for (let index = 0; index < res.data.data.records.length; index++) {
                                if (this.$route.query.id == res.data.data.records[index].id) {
                                    this.outlink = res.data.data.records[index].newsContent;
                                    console.log(this.outlink)
                                }

                            }


                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            backTo() {

                if (this.$route.query.crumbsTitle == "首页") {
                    let vm = this;
                    vm.$router.push({
                        path: `/`
                    });

                } else {
                    this.$router.push({
                        path: "/iphoneProduct"
                    });
                }
            }
        }

    }
</script>
<style lang="less" scoped>
    .botom_tit {
        font-size: 3vw;
        width: 96%;
        margin: -6vw auto;
        text-align: left;
        padding-bottom: 18vw;

    }

    .iphoneSolve {
        margin-top: 14vw;
        width: 100%;

        // text-align: center;

        .el-breadcrumb {
            background: #F6F6F6;
            line-height: 8vw;
            height: 8vw;
            font-size: 3.7vw;
            color: #333333;
            padding-left: 5.6vw;
        }

        background: #ffffff;



        .item-title_new {
            width: 100%;

            img {
                display: block;
                width: 100%;
            }


        }



    }
</style>
