<template>
 <div class="navBar">
<el-menu
  :default-active="activeIndex"
  class="el-menu-demo"
  mode="horizontal"
  @select="handleSelect"
  background-color="#26262600"
  text-color="#fff"
  active-text-color="#ffd04b">
  <el-menu-item index="1"  class="navSelect"  v-if="ifLogin" @click="loginHandler">登录</el-menu-item>
<!--  <el-menu-item index="2" class="navSelect">联系我们</el-menu-item>--> 
  <el-menu-item index="3" class="navSelect" @click="videoTutorial"> 视频教程  </el-menu-item>
  <el-menu-item index="4" class="navSelect" @click="goHome">  首页 </el-menu-item>
  <el-menu-item index="5" class="navSelect"  v-if="showBac" @click="mainRouter">进入后台</el-menu-item>
</el-menu>
</div>
</template>
<script>
import Storage from "@/utils/Storage";
export default {
    name:'HeadNav',
    props:{
      showBac:{
        type:Boolean,
        default:null
      },
      ifLogin:{
        type:Boolean,
        default:null
      }
    },
    data(){
      return{
         activeIndex: '4'
      }
    },
  methods:{
      mainRouter(){
        Storage.setItem("moduleIndex", '0');
        //进入后台
        this.$router.replace("/mainRouter");
      },
       handleSelect(key, keyPath) {
        console.log(key, keyPath);
      },
      //登录事件
      loginHandler(){
         this.$router.push("/login");
      },
      //回到首页
     goHome(){
       this.$router.replace("/");
     },
     //视频教程
    videoTutorial(){
        this.$router.replace("/videoTutorial")
    }
  }
}
</script>
<style lang="less" scoped>
.navBar{
  width: 100%;
  background-color: #475669;
  .el-menu-demo {
    border: 0px;
  }
}
.navSelect{
  float: right !important; 
}
</style>