<template>
  <div class="mainContainer">
    <div class="select">
      <span class="title">火警统计列表</span>

      <el-button type="text" size="small" icon="el-icon-upload2" class="select-button" @click="problemExport">导出
      </el-button>
    </div>
    <div class="gaojiSearch">
      <div style="width:100%;overflow: hidden;">
        <div style="float:left">
          <div class="toolbar-right">
            <span style="font-size:14px" class="toolbarTit">场地名称 ：</span>
            <el-input placeholder="请输入内容" v-model="placeName" size="mini" style="width: 11.25rem;;margin-right:2rem"
              clearable></el-input>
            <span style="font-size:14px" class="toolbarTit">设备IMEI号 ：</span>
            <el-input
              oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
              maxLength='15' placeholder="请输入内容" v-model="mac" size="mini"
              style="width: 11.25rem;;margin-right:2rem;margin-bottom:10px" clearable></el-input>
            <span style="font-size:14px">产品名称 ：</span>
            <el-input placeholder="请输入内容" v-model="productName" size="mini" style="width: 11.25rem;;margin-right:2rem"
              clearable></el-input>
            <br />
            <span style="font-size:14px" class="toolbarTit">项目名称 ：</span>
            <el-input placeholder="请输入内容" v-model="projectName" size="mini" style="width: 11.25rem;;margin-right:2rem"
              clearable></el-input>
            <span style="font-size:14px" class="toolbarTit">手机号 ：</span>
            <el-input placeholder="请输入内容" v-model="userMobile" size="mini" style="width: 11.25rem;;margin-right:2rem"
              clearable></el-input>
            <span style="font-size:14px">时间选择 ：</span>
            <el-date-picker style="width: 20.25rem;" v-model="timeValue" range-separator="~" start-placeholder="开始日期"
              end-placeholder="结束日期" size="mini" :picker-options="pickerOptions" value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']" type="datetimerange"
              @change="getTimeBlock"></el-date-picker>
          </div>
        </div>
      </div>
      <div style="width:100%;overflow: hidden;">
        <div style="float:right;">
          <div class="grid-content bg-purple-dark">
            <div class="searchBut">
              <el-button size="small" type="primary" @click="imeiSearch">搜索</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-vv">
      <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
        <div class="result-info">
          共有
          <span>{{ total }}</span>个数据
        </div>
      </div>
      <el-table :data="tableData" stripe size="small" class="product-table"
        :header-cell-style="{'background-color': '#eeeeee'}" @select="getIds" @select-all="getAllIds">
        <el-table-column type="selection" align="center" width="55"></el-table-column>
        <el-table-column label="序号" type="index" align="center"></el-table-column>
        <el-table-column v-for="(item,index) in labels" :key="index" :prop="props[index]" :label="item"
          v-if="relLabelsStatus[index]" align="center"></el-table-column>

        <el-table-column fixed="right" label="操作" align="center" width="200">
          <template slot-scope="scope">
            <el-button @click="questionDetails(scope.row)" type="text" size="small" icon="el-icon-document">详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination   :current-page.sync="currentPage"  background :page-sizes="[10, 20]" :page-size="pageSize" :total="total"
        layout="sizes,prev, pager, next" @size-change="handleSizeChange" @current-change="handlePageChange">
      </el-pagination>
    </div>
    <!-- <div class="firelist">
      <div class="block">
        <span class="demonstration">请选择月份：</span>
        <el-date-picker @change="changeMonth(month)" v-model="month" type="month" size="mini" value-format="yyyy-MM"
          format="yyyy-MM" placeholder="选择月"></el-date-picker>
      </div>
      <div class="block">
        <span class="demonstration">请选择周：</span>
        <el-date-picker v-model="weekData" type="week" id="getWeekData" format="yyyy-WW"
          :picker-options="{firstDayOfWeek:1}" placeholder="选择周">
        </el-date-picker>
      </div>
      <div class="charts" id="leftTop"></div>
      <div class="charts" id="rightTop"></div>
      <div class="charts" id="histogram"></div>
      <div class="charts" id="project"></div>
    </div> -->
  </div>
</template>


<script>
  import Bus from "../../../components/bus";
  import qs from "qs";
  import PubSub from "pubsub-js";
  import Storage from "@/utils/Storage";
  export default {
    name: "fireStatistics",
    data() {
      return {
        isActive: 1,
        month: "",
        weekData: null,
        weekDataNow: null,
        productDel: false,
          currentPage: 1,
        productAdd: false,
        ifSearchMany: false,
        form: {
          handlerUserId: []
        },
        formHandle: {
          assignUserId: "",
          ccUserIds: [],
          handleOpinion: "",
          handleResurt: ""
        },
        visible: false,
        total: 0,
        tableData: [],
        ids: [],
        chooseOnlineStatus: "", //搜索选择
        sysUserlist: [], //账号所属类型
        newSsUserlist: {
          value: "id",
          label: "typeName",
          children: "childs",
          expandTrigger: "hover"
          // emitPath: false
        },
        checked: false,
        //转派对话框标记
        dialogVisible: false,
        //修改账号弹出对话框标记
        handleDialogVisible: false,
        //协议类型列表
        protocols: [{
          id: 1,
          protocolName: "test"
        }],

        //产品品类列表
        categories: [],
        handleResurt: [{
            value: false,
            label: "不通过"
          },
          {
            value: true,
            label: "通过"
          }
        ],
        value: "",
        input: "",
        current: 1,
        week: "",
        pickerOptions: {
          disabledDate(time) {
            let cuttentDate = new Date(
              new Date(new Date().toLocaleDateString()).getTime() +
              24 * 60 * 60 * 1000 -
              1
            );
            let hmCurrentDate = cuttentDate.getTime();
            let three = 90 * 24 * 3600 * 1000;
            let threeMonths = hmCurrentDate - three;
            return time.getTime() > hmCurrentDate || time.getTime() < threeMonths;
          }
        },
        mon: "",
        year: "",
        yearweek: "", //选择周的月份
        pageSize: 10,
        delDialogVisible: false,
        productId: "",
        indexId: "", //反显id
        nameinput: "", //搜索输入
        indexIdlist: "", //反显列表
        formLabelWidth: "120px",
        realNameList: [], //当前处理人员列表
        projectName: "", //项目名称
        mac: "", //设备号
        productName: "", //产品名称
        placeName: "", //产品名称
        userMobile: "", //app注册用户手机号
        startTime: "",
        endTime: "",
        timeValue: "",
        echarts1: null,
        echartsdate: "",
        rules: {
          handlerUserId: [{
            required: true,
            message: "请输入问题状态",
            trigger: "blur"
          }],
          handleOpinion: [{
            required: true,
            message: "请输入问题状态",
            trigger: "blur"
          }]
        },
        rulesHandle: {
          assignUserId: [{
            required: true,
            message: "请选择指派人员",
            trigger: "change"
          }],
          ccUserIds: [{
            required: true,
            message: "请选择",
            trigger: "change"
          }],
          handleOpinion: [{
            required: true,
            message: "请输入处理意见",
            trigger: "blur"
          }],
          handleResurt: [{
            required: true,
            message: "请选择处理结果",
            trigger: "change"
          }]
        },
        props: [
          "location",
          "mac",
          "placeName",
          "productName",
          "projectName",
          "reportingTime",
          "roomName",
          "userMobile",
        ],
        labels: [
          "场地地址",
          "设备IMEI",
          "场地名称",
          "产品名称",
          "项目名称",
          "最新上报时间",
          "房间名称",
          "app注册用户手机号",
        ],
        relLabelsStatus: [true, true, true, true, true, true, true, true],
        accountLevel: "" //是否是超级账号
      };
    },
    watch: {
      weekData() {
        if (this.weekData != null) {
          this.$nextTick(() => {
            this.weekDataNow = document.querySelector("#getWeekData").value
            this.yearweek = this.weekDataNow.slice(0, 4);
            this.week = this.weekDataNow.slice(5, 7);
            this.fireTimeWeekStatistics()

          });
        }
      }
    },

    methods: {
   

      refresh() {
        this.problemList();
      },
      // 切换样式
      changeClass(i) {
        this.isActive = i;
        this.problemList();
      },
      //批量选中
      getIds(selection, row) {
        console.log(selection)
        var arr = [];
        for (var i = 0; i < selection.length; i++) {
          arr.push(selection[i].mac);
        }
        this.dispatchList = arr;
        var idArr = [];
        for (var i = 0; i < selection.length; i++) {
          idArr.push(selection[i].id);
        }
        this.ids = idArr;
      },
      //全部选中
      getAllIds(selection, row) {
        var arr = [];
        for (var i = 0; i < selection.length; i++) {
          arr.push(selection[i].mac);
        }
        this.dispatchList = arr;
        var idArr = [];
        for (var i = 0; i < selection.length; i++) {
          idArr.push(selection[i].id);
        }
        this.ids = idArr;
      },

      handlePageChange(val) {
        this.current = val;
        this.problemList();
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.problemList();
      },

      cancelDelete() {
        this.delDialogVisible = false;
        this.productId = "";
      },
      handleDelClose() {
        this.delDialogVisible = false;
        this.deleteProduct(this.productId);
      },
      // 详情跳转
      questionDetails(row) {
        console.log(row)
        this.$router.push({
          path: `/fireStatisticsDetails`,
          query: {
            mac: row.mac
          }
        });
      },
      // 高级搜索模糊查询
      imeiSearch() {
        this.problemList();
      },

      problemList() {
        //查询列表
        let macs = ""
        if (this.mac) {
          macs = this.mac
        }
        this.$api
          .fireStatistics({
            page: {
              current: this.current,
              size: this.pageSize,
            },
            query: {
              placeName: this.placeName,
              productName: this.productName,

              macs: macs,
              userMobile: this.userMobile,
              startTime:this.timeValue ? this.timeValue[0]:'',
              endTime: this.timeValue ? this.timeValue[1]:'',
            }
          })
          .then(res => {
            // console.log( res.data.data)
            this.tableData = res.data.data.records;

            this.total = res.data.data.total;
               this.currentPage = parseInt(this.current)

          })
          .catch(error => {
            console.log(error);
          });
      },
          getTime(val) {
                //   获取时间并格式化
                // 获取前val天的时间
                var dt = new Date();
                val === 0 ? "" : dt.setTime(dt.getTime() - 24 * 60 * 60 * 1000 * val);
                const y = dt.getFullYear();
                const m = (dt.getMonth() + 1 + "").padStart(2, "0");
                const d = (dt.getDate() + "").padStart(2, "0");
                const hh = (dt.getHours() + "").padStart(2, "0");
                const mm = (dt.getMinutes() + "").padStart(2, "0");
                const ss = (dt.getSeconds() + "").padStart(2, "0");

                return `${y}-${m}-${d} ${hh}:${mm}:${ss}`; // 获取的时间格式为 2021-04-09 09:34:00
                // return `${y}-${m}-${d}`;  // 获取的时间格式为 2021-04-09
                // return y+m+d+hh+mm+ss  // 获取的时间格式为 20210409093733
                // return y + m + d  // 获取的时间格式为  20210409
            },
      // 导出
      problemExport() {
        let properties = [];
        let titles = [];
        for (let i = 0; i < this.relLabelsStatus.length; i++) {
          if (this.relLabelsStatus[i]) {
            properties.push(this.props[i]);
            titles.push(this.labels[i]);
          }
        }
        let query = {};
        query.placeName = this.placeName;
        // query.ids = this.ids;
        query.productName = this.productName;
        query.macs = this.dispatchList;
        query.startTime  = this.timeValue ? this.timeValue[0]:'';
        query.endTime   = this.timeValue ? this.timeValue[1]:'';
        query.projectName = this.projectName;
        query.userMobile = this.userMobile;
        this.$api
          .exportDeviceFire({
            properties: properties,
            titles: titles,
            query: query
          })
          .then(res => {
            this.download(res.data);
          })
          .catch(error => {});
      },
      download(data) {
        if (!data) {
          return;
        }
        let url = window.URL.createObjectURL(
          new Blob([data], {
            type: "application/octet-binary"
            // type: "application/vnd.ms-excel"
          })
        );
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", "excel.xls");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      },
      //重置函数
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      showAddProductDialog() {
        this.dialogVisible = true;
        this.form.problemStatus = "";
        this.$nextTick(() => {
          this.$refs["form"].clearValidate();
        });
      },
      //获取时间函数2
      getTimeBlock() {
        console.log(this.timeValue);
        if (this.timeValue) {
          this.startTime = this.timeValue[0];
          this.endTime = this.timeValue[1];
      
          // 
        } else {
          this.startTime = "";
          this.endTime = "";
          // this.deviceDoneFireList();
        }
      },
    },
    created() {
      //查询产品列表
      this.startTime= this.getTime(7)
        this.endTime=this.getTime(0)
       
      // this.problemList();
      this.accountLevel = sessionStorage.getItem("accountLevel");

    },
     beforeRouteEnter(to, from, next) {
      // console.log(from.path, '组件独享守卫beforeRouteEnter第二个参数');
      next(vm => {
        if (from.path == '/fireStatisticsDetails') {
          vm.placeName = sessionStorage.getItem("firePlaceName")
          vm.mac = sessionStorage.getItem("fireMac")
          vm.productName = sessionStorage.getItem("fireProductName")
          vm.projectName = sessionStorage.getItem("fireProjectName")
          vm.userMobile = sessionStorage.getItem("fireUserMobile")
          vm.timeValue =sessionStorage.getItem("fireTimeValue").split(',');
       
          vm.current = sessionStorage.getItem("fireStatisCurrent")
       console.log( vm.timeValue)

          vm.problemList();
        } else {
          //因为当钩子执行前，组件实例还没被创建
          // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
          // console.log(vm);//当前组件的实例
          vm.current = 1
          vm.placeName = ""
          vm.mac = ""
          vm.productName = ""
          vm.projectName = ""
          vm.userMobile = ""
          // vm.timeValue = ""
        vm.timeValue = [ vm.getTime(7),vm.getTime(0)]
          vm.problemList();
        }

      });
    },

    beforeRouteLeave(to, from, next) {
      // 导航离开该组件的对应路由时调用
      if (to.path == '/fireStatisticsDetails') {
        sessionStorage.setItem("firePlaceName", this.placeName);
        sessionStorage.setItem("fireMac", this.mac);
        sessionStorage.setItem("fireProductName", this.productName);
        sessionStorage.setItem("fireProjectName", this.projectName);
        sessionStorage.setItem("fireUserMobile", this.userMobile);
        sessionStorage.setItem("fireTimeValue", this.timeValue);
        sessionStorage.setItem("fireStatisCurrent", this.current); 

      } else {
        sessionStorage.removeItem("firePlaceName");
        sessionStorage.removeItem("fireMac");
        sessionStorage.removeItem("fireProductName");
        sessionStorage.removeItem("fireProjectName");
        sessionStorage.removeItem("fireUserMobile");
        sessionStorage.removeItem("fireTimeValue");
        sessionStorage.removeItem("fireStatisCurrent");
      }
 
      next();
    }
  };
</script>
<style lang="less" scoped>
  @toolBarFontColor: rgba(17, 17, 17, 0.87);

  .firelist {
    width: 80%;
    margin: 20px;
    font-size: 0.8rem;
  }

  .charts {
    width: 45vw;
    height: 47vh;
    margin-top: 30px;
    min-width: 280px;
    min-height: 210px;
  }

  .el-row {
    margin: 1rem 3rem;
    line-height: 0;
    min-width: 900px;

    .platform-toolbar {
      position: relative;
      margin: 0;
      border: 1px solid #d9d9d9;

      .title-info {
        height: 46px;
        line-height: 46px;
        font-weight: bold;
        font-size: 0.8rem;
      }

      .el-col {
        padding: 0 3rem;

        .toolbar-right {
          float: right;
          margin-top: 7px;

          &.export {
            margin-left: 1rem;

            .toolbar-btn {
              color: @toolBarFontColor;
              font-size: 0.9rem;

              &.left {
                margin-left: 1.5rem;
              }

              &.right {
                margin-right: 0;
              }
            }
          }
        }
      }

      .title-info {
        float: left;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .result-info-wrapper {
      height: 1rem;
      margin: 1rem 0 0.5rem 0;
      position: relative;

      .setting-panel {
        position: absolute;
        float: right;
        top: 2rem;
        width: 200px;
        height: 200px;
        background: red;
        z-index: 1000;
        border-radius: 3px;
      }

      .result-info {
        height: 1rem;
        line-height: 1rem;
        font-size: 0.875rem;
        float: left;
        color: #898989;

        span {
          color: #292929;
          padding: 0 0.1rem;
        }
      }

      .data-setting {
        font-size: 0.875rem;
        color: #2673bb;
        float: right;

        i {
          padding-right: 0.2rem;
        }

        cursor: pointer;
      }
    }

    .el-table td,
    .el-table th {
      padding: 0 !important;
    }

    .el-pagination {
      margin: 1rem 0;
      float: right;
    }
  }

  .product-content {
    height: 6rem;

    .product-info-item {
      float: left;
      margin-right: 4rem;

      .label-info {
        margin-bottom: 0.5rem;

        label {
          font-size: 0.75rem;

          span {
            color: red;
            margin-right: 0.5rem;
          }
        }
      }

      .el-input {
        width: 10rem;
      }

      .el-select {
        width: 13rem;
      }
    }
  }

  .container-el-row {
    margin-top: 0px;
  }

  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .select-button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
      margin-left: 15px;
    }

    .active {
      color: #2673bb;
    }

    .select-input-right-input {
      float: right;
      margin-top: 7px;
    }
  }

  .gaojiSearch {
    margin-top: 10px;
    padding: 1rem 3rem;
    margin-bottom: 10px;
    background-color: #f4f6fa;

    .searchBut {
      float: right;
      margin-left: 20px;
    }
  }

  .toolbarTit {
    min-width: 101px;
    display: inline-block;
  }

  .table-vv {
    padding: 0rem 3rem;
    margin-top: 1rem;

    .result-info {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      float: left;
      color: #898989;

      span {
        color: #292929;
        padding: 0 0.1rem;
      }
    }

    .el-pagination {
      margin: 1rem 0;
      float: right;
    }
  }
</style>