<template>
  <div class="iphoneProduct">
    <div style="font-size: 18px;color: black;margin-top: 10px;margin-left: 10px" >文件下载 > {{ typeName}}</div>
    <div class="dropdown_box"    @click="openDropdown()">
      <el-dropdown trigger="click" @command="handleCommand" ref='messageDrop'>
        <span class="el-dropdown-link">
          {{ all_item}}<i class="el-icon-caret-bottom el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown" class="dropdownPop">
          <el-dropdown-item v-for="item in option" :key='item.id'
                            :command="{value:item.id,label:item.fileCategoryName,flag:1}">{{item.fileCategoryName}}
          </el-dropdown-item>
        </el-dropdown-menu>

      </el-dropdown>
    </div>
    <div style="margin: 20px;font-size: 20px" >  <!--分栏间隔-->
      <div  v-if="filesList" v-for="(item, index) in filesList" >  <!--共24份，xs超小型设备，sm小屏设备，md中屏，lg大屏-->
        <div style="text-align: center;margin-bottom: 30px">
          <div style="height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 18px;
            background: #efefef;">
            <div style="color: black;" v-html="item.remark"></div>
          </div>
          <a href='url' download="filename.ext" style="display: none"></a>
          <div style="width: 50%;height: 40px;line-height: 40px;text-align: center;
               margin-top: -20px;margin-left: 25%;background: #f28c02;color: white;cursor: pointer" @click="download(item)">立即下载</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios  from "axios";
export default {
  name: "iphoneDownload",

  data() {
    return {
      option: [],
      visible: false,
      productCategoryId: '',
      all_item: '',
      tableList: [],
      filesList:[],
      activeName:'',
      typeName:""
    };
  },
  watch: {
    $route(to, from) {
      this.getFileCategory()
    }
  },
  mounted() {
    this.getFileCategory()
  },
  methods: {
    async download(item){
      const link = document.createElement('a')
      link.href = item.fileUrl
      link.target = '_blank'
      let name = item.remark.replace('<br/>','')
      link.download = name
      link.style.display = 'none'
      document.body.append(link)
      link.click()

        // this.$refs.downloadlLink.click()
        // let u = navigator.userAgent
        // let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        // if(isIOS){
        //   this.$refs.downloadlLink.click()
        // }else {
        //   window.open(item.fileUrl);
        // }

      // console.log(item)
      // const response = await axios({
      //   url: item.fileUrl,
      //   method: 'GET',
      //   responseType:'blob'
      // })
      // let url = window.URL.createObjectURL(
      //     new Blob([response.data], {
      //       type: "application/pdf"
      //     })
      // );
      // let link = document.createElement("a");
      // link.style.display = "none";
      // link.href = url;
      // var name = item.remark.replace('<br/>','')
      // link.setAttribute("download", name);
      //
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      // URL.revokeObjectURL(url);
    },
    handleCommand(command){
      this.all_item = command.label
      this.activeName = command.value
      this.getFilesList()
    },
    // refresh(item){
    //   this.activeName = item.id
    //
    //   this.getFilesList()
    // },
    openDropdown() {
      this.$refs.messageDrop.show();
    },
    getFileCategory(){
      this.$api
          .getFileCategoryList()
          .then(res => {
            this.option = res.data.data[this.$route.query.id].sonFileCategorys
            this.typeName = res.data.data[this.$route.query.id].fileCategoryName
            console.log(this.typeName)
            this.all_item = this.option[0].fileCategoryName
            this.activeName = this.option[0].id

            this.getFilesList()
          })
          .catch(error => {

          });
    },
    getFilesList() {
      //查询设备列表
      this.$api
          .fileManagementListWeb({
            fileQueryDto: {
              fileCategoryId:this.activeName
            }

          })
          .then(res => {
            this.filesList = res.data.data.records;


          })
          .catch(error => {
            console.log(error);
          });},

    },



}
</script>
<style lang="less" scoped>
.iphoneProduct {
  margin-top: 14vw;
  width: 100%;
  min-height: 70vh;
  overflow: auto;
  background-color: #fff;

  .bannerImg {
    width: 100%;
  }



  .item-title {
    padding-top: 11.6vw;
    padding-bottom: 9vw;
    text-align: center;
    color: #333333;
    width: 100%;
    letter-spacing: 1px;
    margin: 0 auto;
    font-size: 5.6vw;

    span {
      width: 16.4vw;
      margin: 0 auto;
      height: 2px;
      padding-top: 1px;
      display: block;
      background: #F1A948;
    }
  }

  .article {
    width: 89.4vw;
    text-indent: 2em;
    margin: 0 auto;
    font-size: 4.2vw;
    line-height: 1.7em;
    color: #333;
    text-align-last: left; //最后一行左对齐
    text-align: justify;
    text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
    padding-bottom: 7.73vw;

    p {
      height: 11.6vw;
    }
  }

  .product_6101,
  .product_nb {
    width: 94.6vw;
    height: 50.6vw;
    margin: auto;
    margin-bottom: 2vw;
    border-radius: 5px;
    box-shadow: 0 0 2px 0 rgb(197, 196, 196);
    display: flex;
    background: #ffffff;

    .product_left {
      width: 50%;
      height: 100%;
      // border: #1da3f1 1px solid;
      display: flex;
      align-items: center;
      vertical-align: middle;

      img {
        width: 36vw;
        margin: 0 auto;
        display: inline-block;
        align-items: center;
      }
    }

    .product_right {
      // border: #1da3f1 1px solid;
      width: 50%;
      text-align: center;
      height: 100%;
      vertical-align: middle;

      b {
        color: #333;
        font-size: 4.2vw;
        display: inline-block;
        margin-top: 8vw;
      }

      p {
        color: #666;
        font-size: 3.7vw;
      }

      .grouplogo {
        width: 40vw;
        margin: 0 auto;
        padding-top: 8vw;
        display: flex;
        align-items: center;
        justify-content: space-around;
        vertical-align: middle;

        img {
          height: 15.7vw;
        }
      }
    }
  }

  .product_6200 {
    width: 94.6vw;
    min-height: 80vw;
    border-radius: 5px;
    //  height: 56.8vw;
    margin: auto;
    margin-bottom: 2vw;
    // box-shadow: 0 0 2px 0 rgb(197, 196, 196);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .product_right {
      width: 49%;
      height: 56.8vw;
      border-radius: 5px;
      text-align: center;
      margin-bottom: 2vw;
      vertical-align: middle;
      background: #f7f7f7;
      // border: #12b7f5 1px solid;
      // height: 100%;



      .img {
        padding-top: 4vw;
        display: inline-block;
        align-items: center;
        width: 32.6vw;
        height: 32.6vw;
      }


      b {
        color: #333;
        font-size: 4.2vw;
        display: inline-block;
        margin-top: 4vw;
      }

      p {
        color: #666;
        font-size: 3.7vw;
      }
    }

    .product_left {
      width: 49%;
      border-radius: 5px;
      text-align: center;
      vertical-align: middle;
      background: #f7f7f7;
      // border: #12b7f5 1px solid;
      height: 100%;

      .img {
        padding-top: 4vw;
        display: inline-block;
        align-items: center;
        width: 30.1vw;
        height: 32.6vw;
      }

      b {
        color: #333;
        font-size: 4.2vw;
        display: inline-block;
        margin-top: 4vw;
      }

      p {
        color: #666;
        font-size: 3.7vw;
      }
    }
  }

  .interval {
    height: 20.8vw;
  }
}

//element ui下拉弹窗 dropdownPop自定义
.dropdownPop {
  width: 96vw;
  background-color: #f7f7f7;

  // border: 1px solid #6b6d6e;
  /deep/.el-dropdown {
    width: 96vw;
  }

  .el-dropdown-menu__item {
    color: #000608;
  }

  .el-dropdown-menu__item:hover {
    background-color: #5f6263;
  }

  .popper__arrow {
    border: 1px solid #6b6d6e !important;
  }

  .popper__arrow::after {
    border-top-color: #6b6d6e !important;
    border-bottom-color: #6b6d6e !important;
  }
}

.el-dropdown-link {
  color: #000608;
  margin: auto;
}

.dropdown_box {
  margin-left: 3.2vw;
  margin-top: 4vw;
  margin-bottom: 4vw;
  padding: 0.5vw 2vw;
  background-color: #f7f7f7;
  width: 35vw;
  text-align: center;
  border-radius: 10px;
}
</style>
