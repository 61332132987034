<template>
  <div class="outting">
    <div class="select">
      <span class="title">版本列表</span>
      <el-button
        type="text"
        size="small"
        icon="el-icon-plus"
        @click="addUserHandler"
        class="addRoleButton"
      >添加版本</el-button>
    </div>
    <div class="table-vv">
      <el-table
        :data="tableData"
        style="width: 100%"
        stripe
        size="small"
        :header-cell-style="{'background-color': '#eeeeee'}"
      >
          <el-table-column
          prop="versionName"
          label="版本名称"
          min-width="1"
          fixed
          align="center"
        
        ></el-table-column>
        <el-table-column
          prop="type"
          label="版本类型"
          min-width="1"
          fixed
          align="center"
          :formatter="stateFormatAppType"
        ></el-table-column>
        <el-table-column
          prop="forceUpdate"
          label="强制更新"
          min-width="1"
          fixed
          align="center"
          :formatter="stateFormatForceUpdate"
        ></el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="2" fixed align="center">
          <template slot-scope="{row}">{{ row.createTime || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="2" fixed align="center">
          <template slot-scope="{row}">{{ row.updateTime || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="downloadUrl" label="下载地址" min-width="2" fixed align="center">
          <template slot-scope="{row}">{{ row.downloadUrl || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="versionDesc" label="版本更新描述" min-width="2" fixed align="center">
          <template slot-scope="{row}">{{ row.versionDesc || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="versionNum" label="版本号" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.versionNum || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="fileSize" label="文件大小" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.fileSize || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="versionCode" label="版本编号" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.versionCode || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column min-width="1" label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              @click="handleUpdate(scope.$index, scope.row)"
            >编辑</el-button>
            <el-popconfirm title="确定删除这个版本吗？" @onConfirm="deleteAddHandler()">
              <el-button
                type="text"
                size="small"
                icon="el-icon-delete"
                @click="handleDelete(scope.$index, scope.row)"
                style="margin-left:10px;"
                slot="reference"
              >删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        class="pagin"
        @current-change="changeCurrent"
      ></el-pagination>
    </div>
    <el-dialog title="新增版本" :visible.sync="addDialogFormVisible" :close-on-click-modal="false">
      <el-form
        :model="addForm"
        size="small"
        :inline="true"
        style="width:700px"
        :rules="addRules"
        ref="addRules"
        enctype="multipart/form-data"
      >
      <el-form-item label="版本名称" :label-width="formLabelWidth" prop="versionName">
          <el-input v-model.trim="addForm.versionName" autocomplete="off" style="width:215px"></el-input>
        </el-form-item>
        <el-form-item label="版本号" :label-width="formLabelWidth" prop="versionNum">
          <el-input v-model.trim="addForm.versionNum" autocomplete="off" style="width:215px"></el-input>
        </el-form-item>
        <el-form-item label="强制更新" :label-width="formLabelWidth" prop="forceUpdate">
          <el-select
            v-model="addForm.forceUpdate "
            placeholder="是否强制更新"
            @change="changeForceUpdate"
          >
            <el-option label="是" value="0"></el-option>
            <el-option label="否" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="版本类型" :label-width="formLabelWidth" prop="type">
          <el-select v-model="addForm.type " placeholder="请选择版本类型" @change="changeAppType">
            <el-option label="安卓" value="0"></el-option>
            <el-option label="IOS" value="1"></el-option>
            <el-option label="onenet" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="版本描述" :label-width="formLabelWidth" prop="versionDesc">
          <el-input
            v-model.trim="addForm.versionDesc"
            autocomplete="off"
            style="width:215px"
            type="textarea"
            :rows="2"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传版本" :label-width="formLabelWidth">
          <el-upload
            class="upload-demo"
            action
            ref="upload"
            :auto-upload="false"
            multiple
            :on-change="handleChange"
            :show-file-list="true"
            style="width:215px"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">文件大小不能超过50M</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="版本编号" :label-width="formLabelWidth" prop="versionCode">
          <el-input v-model.trim="addForm.versionCode" autocomplete="off" style="width:215px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="addAppListForm('addForm')" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="编辑版本" :visible.sync="editDialogFormVisible" :close-on-click-modal="false">
      <el-form
        :model="editForm"
        size="small"
        :inline="true"
        style="width:700px"
        :rules="editRules"
        :auto-upload="false"
        ref="editRules"
        enctype="multipart/form-data"
      >
        <el-form-item label="版本名称" :label-width="formLabelWidth" prop="versionName">
          <el-input v-model.trim="editForm.versionName" autocomplete="off" style="width:215px"></el-input>
        </el-form-item>
        <el-form-item label="版本号" :label-width="formLabelWidth" prop="versionNum">
          <el-input v-model="editForm.versionNum" autocomplete="off" style="width:215px"></el-input>
        </el-form-item>
        <el-form-item label="强制更新" :label-width="formLabelWidth" prop="forceUpdate">
          <el-select
            v-model="editForm.forceUpdate"
            placeholder="是否强制更新"
            @change="editchangeForceUpdate"
          >
            <el-option label="是" value="0"></el-option>
            <el-option label="否" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="版本类型" :label-width="formLabelWidth" prop="type">
          <el-select v-model="editForm.type" placeholder="请选择版本类型" @change="editchangeAppType">
            <el-option label="安卓" value="0"></el-option>
            <el-option label="IOS" value="1"></el-option>
            <el-option label="onenet" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="版本描述" :label-width="formLabelWidth" prop="versionDesc">
          <el-input
            v-model.trim="editForm.versionDesc"
            autocomplete="off"
            style="width:215px"
            type="textarea"
            :rows="2"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传app" :label-width="formLabelWidth">
          <el-upload
            class="upload-demo"
            action
            multiple
            :on-change="editHandleChange"
            :show-file-list="true"
            style="width:215px"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">文件大小不能超过50M</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="版本编号" :label-width="formLabelWidth" prop="versionCode">
          <el-input v-model.trim="editForm.versionCode" autocomplete="off" style="width:215px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="editAppListForm('editForm')" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "AppList",
  data() {
    return {
      current: 1,
      size: 10,
      total: 0,
      tableData: [],
      file: {},
      editFile: {},
      deleteId: "",
      editId: "",
      addDialogFormVisible: false,
      addForm: {
        versionNum: "",
        forceUpdate: "",
        versionDesc: "",
        type: "",
        versionCode: "",
       versionName : ""
      },
      editForm: {
        versionNum: "",
        forceUpdate: "",
        versionDesc: "",
        type: "",
        versionCode: "",
        versionName: "",
      },
      addforceUpdate: "",
      addappType: "",
      editDialogFormVisible: false,
      addRules: {
        versionCode: [
          { required: true, message: "请输入版本编号", trigger: "blur" }
        ],
        versionNum: [
          { required: true, message: "请输入版本号", trigger: "blur" }
        ],
        versionDesc: [
          { required: true, message: "请输入版本描述", trigger: "blur" }
        ],
        forceUpdate: [
          { required: true, message: "是否强制更新", trigger: "change" }
        ],
        type: [
          { required: true, message: "请选择版本类型", trigger: "change" }
        ],
          versionName: [
          { required: true, message: "请输入版本名称", trigger: "change" }
        ]
      },
      editRules: {
        versionCode: [
          { required: true, message: "请输入版本编号", trigger: "blur" }
        ],
        versionNum: [
          { required: true, message: "请输入版本号", trigger: "blur" }
        ],
        versionDesc: [
          { required: true, message: "请输入版本描述", trigger: "blur" }
        ],
        forceUpdate: [
          { required: true, message: "是否强制更新", trigger: "change" }
        ],
        type: [
          { required: true, message: "请选择版本类型", trigger: "change" }
        ],
        versionName:[
          { required: true, message: "请输入版本名称", trigger: "change" }
        ],
      },
      formLabelWidth: "100px",
      editforceUpdate: "",
      editappType: ""
    };
  },
  created() {
    this.appList();
  },
  methods: {
    //删除函数
    handleDelete(index, row) {
      this.deleteId = row.id;
    },
    //删除确定
    deleteAddHandler() {
      this.$api.deleteAppHandler(this.deleteId).then(res => {
        console.log(res.data);
        if (res.data.code == 200) {
          this.$message.success("删除成功!");
          this.appList();
        }
      });
    },
    //更新函数
    handleUpdate(index, row) {
      this.editId = row.id;
      this.editFile = {};
      this.editDialogFormVisible = true;
      if (this.$refs["editRules"] !== undefined) {
        this.resetForm("editRules");
      }
      console.log(row);
      this.editForm.versionNum = row.versionNum;
      this.editForm.versionDesc = row.versionDesc;
      this.editForm.versionCode = row.versionCode;
      this.editForm.versionName = row.versionName;
      if (row.forceUpdate == 0) {
        this.editForm.forceUpdate = "是";
      } else if (row.forceUpdate == 1) {
        this.editForm.forceUpdate = "否";
      }
      if (row.type == 0) {
        this.editForm.type = "安卓";
      } else if (row.type == 1) {
        this.editForm.type = "IOS";
      }else{
        this.editForm.type = "onenet";
      }
    },

    //重置函数
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    appList() {
      this.$api
        .appListHandler({
          current: this.current,
          size: 10
        })
        .then(res => {
          // console.log(res.data);
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        });
    },
    addUserHandler() {
      this.file = {};  
   
      this.addDialogFormVisible = true;

      if (this.$refs["addRules"] !== undefined) {
        this.resetForm("addRules");
      }
    },
    stateFormatAppType(row) {
      if (row.type == 0) {
        return "安卓";
      } else if (row.type == 1) {
        return "IOS";
      }else if (row.type == 2) {
        return "onenet";
      } else {
        return "暂无数据";
      }
    },
    stateFormatForceUpdate(row) {
      if (row.forceUpdate == 0) {
        return "是";
      } else if (row.forceUpdate == 1) {
        return "否";
      } else {
        return "暂无数据";
      }
    },
    changeCurrent(id) {
      this.current = id;
      this.appList();
    },
    changeForceUpdate(id) {
      // console.log(id);
      this.addforceUpdate = Number(id);
    },
    editchangeForceUpdate(id) {
      // console.log(id);
      this.editforceUpdate = Number(id);
    },
    changeAppType(id) {
      // console.log(id);
      this.addappType = Number(id);
    },
    editchangeAppType(id) {
      // console.log(id);
      this.editappType = Number(id);
    },
    addAppListForm(formName) {
      this.$refs["addRules"].validate(valid => {
        if (valid) {
          this.addDialogFormVisible = false;
          if (JSON.stringify(this.file) === "{}") {
            let formData = new FormData();
            formData.append("versionNum", this.addForm.versionNum);
            formData.append("versionDesc", this.addForm.versionDesc);
            formData.append("forceUpdate", this.addforceUpdate);
            formData.append("type", this.addappType);
            formData.append("versionCode", Number(this.addForm.versionCode));
            formData.append("versionName", this.addForm.versionName);
            this.$api.addAppHandler(formData).then(res => {
              if (res.data.code == 200) {
                this.$message.success("添加成功！");
                this.file = {};
                 this.$refs.upload.clearFiles()
                this.appList();
              }
            });
          } else {
            let formData = new FormData();
            formData.append("multipartFile", this.file);
            // formData.append("versionNum", this.addForm.versionNum);
            // formData.append("versionDesc", this.addForm.versionDesc);
            // formData.append("forceUpdate", this.addforceUpdate);
            // formData.append("appType", this.addappType);
            // formData.append("versionCode", Number(this.addForm.versionCode));
             formData.append("versionNum", this.addForm.versionNum);
            formData.append("versionDesc", this.addForm.versionDesc);
            formData.append("forceUpdate", this.addforceUpdate);
            formData.append("type", this.addappType);
            formData.append("versionCode", Number(this.addForm.versionCode));
            formData.append("versionName", this.addForm.versionName);
            this.$api.addAppHandler(formData).then(res => {
              if (res.data.code == 200) {
                this.$message.success("添加成功！");
                this.file = {};
                 this.$refs.upload.clearFiles()
                this.appList();
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    editAppListForm(formName) {
      this.$refs["editRules"].validate(valid => {
        if (valid) {
          this.editDialogFormVisible = false;
          if (JSON.stringify(this.editFile) === "{}") {
            console.log(this.editForm.forceUpdate)
            if (this.editForm.forceUpdate == "是" ||this.editForm.forceUpdate == "否") {

              this.editforceUpdate == '是'?0:1
            }
            if (this.editForm.type == "安卓" ||this.editForm.type == "IOS" ||this.editForm.type == "onenet" ) {
              console.log('没变')
              this.editappType == "安卓"  ? 0:this.editForm.type == "IOS" ?1:2
            }else{
               console.log('变')
            }

            let formData = new FormData();
            formData.append("id", Number(this.editId));
            formData.append("versionNum", this.editForm.versionNum);
            formData.append("versionDesc", this.editForm.versionDesc);
            formData.append("forceUpdate", this.editforceUpdate);
            formData.append("type", this.editappType);
            formData.append("versionName", this.editForm.versionName);
            formData.append("versionCode", Number(this.editForm.versionCode));
            this.$api.editAppHandler(formData).then(res => {
              if (res.data.code == 200) {
                this.$message.success("编辑成功！");
                this.editFile = {};
                this.appList();
              }
            });
          } else {
                 console.log('002')
             if (this.editForm.forceUpdate == "是" ||this.editForm.forceUpdate == "否") {

              this.editforceUpdate == '是'?0:1
            }
            if (this.editForm.type == "安卓" ||this.editForm.type == "IOS" ||this.editForm.type == "onenet" ) {
              console.log('没变')
              this.editappType == "安卓"  ? 0:this.editForm.type == "IOS" ?1:2
            }else{
               console.log('变')
            }
            console.log( this.editappType)
            let formData = new FormData();
            formData.append("id", Number(this.editId));
            formData.append("multipartFile", this.editFile);
            formData.append("versionNum", this.editForm.versionNum);
            formData.append("versionDesc", this.editForm.versionDesc);
            formData.append("forceUpdate", this.editforceUpdate);
            formData.append("type", this.editappType);
            formData.append("versionName", this.editForm.versionName);
            formData.append("versionCode", Number(this.editForm.versionCode));
            this.$api.editAppHandler(formData).then(res => {
              if (res.data.code == 200) {
                this.$message.success("编辑成功！");
                this.editFile = {};
                this.appList();
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    handleChange(file, fileList) {
      console.log(file);
      this.file = file.raw;
    },
    editHandleChange(file, fileList) {
      console.log(file);
      this.editFile = file.raw;
    }
  }
};
</script>
<style lang="less" scoped>
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;
.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .select-status {
      width: 9rem;
      margin-left: 1.7rem;
      margin-top: 7px;
      // margin-top:7px;
    }

    .select-input-timer {
      float: right;
    }

    .select-status {
      float: right;
    }

    .select-input-right-input {
      float: right;
      // width: 8rem;
      line-height: 46px;
    }

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .addRoleButton {
      margin-left: 15px;
    }
    button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
    }

    .update-input-timer {
      float: right;
      line-height: 46px;
      margin-left: 25px;
      height: 46px;

      .demonstrationTwo {
        float: left;
        font-size: 0.8rem;
      }

      .update-input-timer-select {
        width: 11rem;
        margin-left: 5px;
      }
    }
  }
  .table-vv {
    padding: 1rem 3rem;
    .pagin {
      margin-left: 30%;
      margin-top: 10px;
      float: right;
    }
  }
}
</style>