<template>
  <div class="outting">
    <div class="select">
      <span class="title">兑换详情</span>
      <el-button type="text" size="small" icon="el-icon-back" @click="gobackOrderListl" class="addRoleButton">返回兑换列表
      </el-button>
    </div>
    <el-card class="box-card">
      <div class="text item" v-for="(item,index) in detailList" :key="index">

        <span>
          兑换状态: <b class="contentlist"> {{item.codeStatus == 0?'待使用':item.codeStatus == 1?'使用中':'已使用' }} </b>
        </span>
     
        <span>
          兑换码  :<b class="contentlist"> {{item.exchangeCode }} </b>
        </span>
        <span>
          商品名称  :<b class="contentlistName"  @click="goodsNameList"  > {{item.goodsName }} </b>
        </span>
        <span>
          是否有效 :<b class="contentlist"> {{item.isEnable == 0?'无效':'有效' }} </b>
        </span>
        <span>
          订单编号  :<b class="contentlist">{{item.orderNo    }} </b>
        </span>
        <span>
          兑换用户终端id  :<b class="contentlist">{{item.userId }} </b>
        </span>
          <span>
          兑换时间 :<b class="contentlist"> {{item.createTime }} </b>
        </span>
        <span>  
           有效期开始 :<b class="contentlist">{{item.validityTimeStart  }} </b>
        </span>
        <span>
          有效期截止 :<b class="contentlist">{{item.validityTimeEnd  }} </b>
        </span>
        
          
      </div>
    </el-card>
  
 
    
  </div>
</template>
<script>
  export default {
    name: "exchangeDetail",
    data() {
      return {
        tableData: [],
        total: 0,
        detailList:[],
        orderToGoodsShowVos:[],
         fit: "fill",

        logisticsList: [
        ],
        dialogVisible: false,
      };
    },
    methods: {

      changeCurrent() {},
      gobackOrderListl() {
        this.$router.push({
          path: `/exchangeList`,
        });
      },
    goodsNameList(){

      },
      
    },
    created() {
        
    },
    mounted() {
      console.log('11111',this.$route.query)
      this.$api
        .exchangeDetail({
        params: {
              code: this.$route.query.key
            }
          }
        )
        .then(res => {
          // console.log(res.data.data)
          this.detailList  = [res.data.data]
         
        })
        .catch(error => {
          console.log(error);
        });

    }
  };
</script>
<style lang="less" scoped>
  @border-bottom-color: #e7eaed;
  @toolBarFontColor: #111d;

  .outting {
    height: 100%;
    overflow: hidden;
    background-color: #fafafa;

    .contentlist {
      color: rgb(68, 176, 248);
      padding: 0 0.4rem;
    }
    .contentlistName{
     color: rgb(68, 176, 248);
      padding: 0 0.4rem;
      cursor: pointer;
    }
  .track-rcol {}

  .track-list {
    position: relative;
  }

  .track-list li {
    position: relative;
    padding: 0px 0 1.5rem 25px;
    line-height: 1rem;
    border-left: 1px solid #d9d9d9;
    color: #999;
  }

  .track-list li.first {
    color: red;
    padding-top: 0;
    width: 100%;
    text-align: left;
    border-left: 1px solid #d9d9d9;
  }

  .track-list li .node-icon {
    position: absolute;
    left: -5.5px;
    border-radius: 0.25rem;
    width: 0.5rem;
    height: 0.5rem;
    top: 4px;
    background-color: #999999;
  }

  .track-list li.first .node-icon {
    background-position: 0-72px;
    background-color: red;
    width: 1rem;
    z-index: 2;
    height: 1rem;
    position: absolute;
    left: -9px;
    top: 0;
    border-radius: 0.5rem;
  }

  .track-list li .time {
    margin-right: 20px;
    position: relative;
    top: 4px;
    display: inline-block;
    vertical-align: middle;
    background-color: white;
    color: #999;
    width: 100%;
    text-align: left;
  }

  .track-list li .txt {
    position: relative;
    display: inline-block;
    vertical-align: top;
    color: #999;
  }

  .track-list li.first .time {
    text-align: left;
    width: 94%;
    color: red;
  }

  .track-list li.first .txt {
    color: red;
    text-align: left;
    width: 94%;
  }

  .track-list li.finall {
    position: relative;
    padding: 0px 0 1.5rem 25px;
    line-height: 18px;
    border-color: white;
    border-left: 1px solid #ffffff;
    color: #999;
  }

  .track-list li.finall .div-spilander {
    width: 1px;
    position: absolute;
    position: absolute;
    left: -1.5px;
    height: 0.5rem;
    background-color: #d9d9d9;
  }

    .select {
      height: 46px;
      overflow: hidden;
      border-bottom: 1px solid #d9d9d9;
      padding: 0rem 3rem;

      .select-status {
        width: 9rem;
        margin-left: 1.7rem;
        margin-top: 7px;
      }

      .select-input-timer {
        float: right;
      }

      .select-status {
        float: right;
      }

      .select-input-right-input {
        float: right;
        // width: 8rem;
        line-height: 46px;
      }

      .title {
        display: inline-block;
        line-height: 46px;
        font-weight: bold;
        font-size: 0.8rem;
      }

      button {
        float: right;
        color: @toolBarFontColor;
        margin-top: 7px;
      }

    }

    .text {
      font-size: 0.85rem;
    }

    .item {
      padding: 18px 0;
    }

    .box-card {
      margin: 1rem 3rem;

      span {
        padding: 1rem 1rem;
        display: inline-block;
        // border: 1px black solid;
        min-width: 14rem;
      }
    }

    .guzhangTable {
      padding: 0rem 3rem;
      margin-top: 1rem;

    }
  }
</style>