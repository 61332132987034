<template>
	<div style="background-color: #fafafa;" class="video-tutorial">
		<HeadNav />
    <div class="nav-breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>视频教程</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main-content">

<!--    <div >-->
<!--      <h2 style="margin: 3.5rem 0 0 10rem">平台视频教程</h2>-->
<!--    </div>-->
    <el-row :gutter="30">
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
        <router-link to="/videoTutorialDetail">
        <div class="video-item">
<!--          <div style="margin-bottom: 0">-->
          <div class="image">
          </div>
          <div class="video-introduction">
            <h3><span style="margin-right: 1rem">消防管理后台使用教程</span><el-tag>电脑端</el-tag></h3>
          </div>
        </div>
        </router-link>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
        <router-link to="/videoTutorialDetail">
        <div class="video-item">
          <!--          <div style="margin-bottom: 0">-->
          <div class="image">
          </div>
          <div class="video-introduction">
            <h3><span style="margin-right: 1rem">设备绑定与解绑视频教程</span><el-tag type="success">APP/小程序</el-tag></h3>
          </div>
        </div>
        </router-link>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" >
        <router-link to="/videoTutorialDetail">
        <div class="video-item">
          <!--          <div style="margin-bottom: 0">-->
          <div class="image">
          </div>
          <div class="video-introduction">
            <h3><span style="margin-right: 1rem">产测系统使用教程</span><el-tag>电脑端</el-tag></h3>
          </div>
        </div>
        </router-link>
      </el-col>
<!--    </el-row>-->
<!--    <el-row :gutter="10">-->
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" >
         <router-link to="/videoTutorialDetail">
        <div class="video-item">
          <div class="image">
          </div>
          <div class="video-introduction">
            <h3><span style="margin-right: 1rem">大屏视频教程</span><el-tag>电脑端</el-tag></h3>
          </div>
        </div>
        </router-link>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" >
        <router-link to="/videoTutorialDetail">
        <div class="video-item">
          <div class="image">
          </div>
          <div class="video-introduction">
            <h3><span style="margin-right: 1rem">设备安装教程</span><el-tag type="info">设备</el-tag></h3>
          </div>
        </div>
        </router-link>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" >
        <router-link to="/videoTutorialDetail">
        <div class="video-item">
          <div class="image">
          </div>
          <div class="video-introduction">
            <h3><span style="margin-right: 1rem">中间件使用教程</span><el-tag>电脑端</el-tag></h3>
          </div>
        </div>
        </router-link>
      </el-col>
    </el-row>
    </div>
		<FooterNav />
	</div>
</template>
<script>
	import HeadNav from '../../components/HeadNav'
	import FooterNav from '../../components/FooterNav'
  import { videoPlayer } from 'vue-video-player'
  import 'video.js/dist/video-js.css'
  import 'vue-video-player/src/custom-theme.css';
	export default {
		name: 'VideoTutorial',
		components: {
			HeadNav,
			FooterNav,
      videoPlayer
		},
		data() {
			return {
				activeName: 'first',
        playerOptions : {
          playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            src: 'https://jbufa-video.oss-cn-beijing.aliyuncs.com/yanshi2.mp4?Expires=1626429226&OSSAccessKeyId=TMP.3KeqqTBN7ketNbidQSbwtWKKe5xRhbvo6CxGLPPZTftM3jf8AXsWN9FvJr9esU1S24qmnaFXrQ7Bh7RQ5DXxrFGU4a8th2&Signature=ry1faHUqtnIVI1L0SmR8cYbPT7I%3D&versionId=CAEQERiBgIDpzOOf1RciIDE0ZTEyNDMzNmI0YTRiMmQ4MjBjOGQzMmYyYjc0YWZl&response-content-type=application%2Foctet-stream',  // 路径
            type: 'video/mp4'  // 类型
          }],
          poster: "https://jbufa-images.oss-cn-beijing.aliyuncs.com/five.jpg?Expires=1626332208&OSSAccessKeyId=TMP.3KinZPaez2A1tVcL8v49pfQgYw5Giyp8csaxevtymf6NoeAcKfShVhMAqGyXpKKuGFBq5bWoCgubAvTamTzAWh9doPD5ud&Signature=vngTBd4aFMX7bKpFcSga6hXbdag%3D&versionId=CAEQEhiBgIDM5fOf1RciIGE3MWVjODEwNzNkMDQ5YmRiOTczNjhjNDZkYjJiMjky&response-content-type=application%2Foctet-stream", //你的封面地址
          // width: document.documentElement.clientWidth,
          notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true  //全屏按钮
          }
        }
			}
		},
		methods: {
			handleClick(tab, event) {
				console.log(tab, event);
			},
      cellStyle({}){
			  return "border:none; background:#fafafa;margin-left:0;padding-left:0;"
      },
      headerCellStyle({}){
        return "border:none;background:#fafafa;margin-left:0;padding-left:0;"
      }
		}
	}
</script>
<style lang="less" scoped>


@media screen and (min-width: 0px) and (max-width: 1200px) {
  .video-tutorial {
    background-color: #fafafa;
    overflow-x: auto;
    //min-width: 1000px;
    width: 100%;
  }
}

@media screen and (min-width: 1201px) {
  .video-tutorial {
    background-color: #fafafa;
    overflow-x: auto;
    //min-width: 1300px;
    width: 100%;
  }
}
	.image{
    width: 100%;
    height: 18rem;
    background-image: url("../../assets/rotationChart/five.jpg");
    background-size:100% 100%;
    //min-width: 32rem;
    //border-radius: 0.5rem;
  //  &:hover{
  //  background-size:200% 200%;
  //}
  }
  .video-introduction{
    width: 100%;
    height: 5rem;
    background: #f0f0f0;
    //min-width: 32rem;
    //transition: all 1s;
    h3{
      padding-top: 2rem;
      padding-left: 1rem;
      //transition: all 0.5s;
    }
  }

  .video-item{
    background: red;
    //margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 0.2rem;
    cursor: pointer;
    overflow: hidden;
    width: 100%;
    color: #606060;
    //min-width: 20rem;


    &:hover{
      //.video-introduction{
      //  background: deepskyblue;
      //  color: #FAFAFA;
      //}
      //h3{
      //  font-size: 1.5rem;
      //  padding-top: 1rem;
      //}
    }
  }


  .nav-breadcrumb{
    margin-left: 5rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }

  .main-content{
    width: 70%;
    padding-left: 15%;
  }
</style>
