<template>
  <div>
    <el-container>
      <el-main class="row-margin" style="overflow-x:hidden;">
        <div class="select">
           
          <span class="title">批量卖出兑换码</span>
          <el-button type="text" size="small" icon="el-icon-back" @click="gobackPlaceDetail" class="addRoleButton">
            返回兑换列表</el-button>
        </div>
        <div class="content">
           <a href="./test.xls" download="兑换码"  class="down_template">下载兑换码导入模板</a>
          <div style="width:100%;display:flex;height:2rem;">
            <div style="flex:1;font-size:.8rem;text-align:right;line-height:2rem;">
              <span class="content-span">导入兑换码:</span>
            </div>
            <div style="flex:4.5;font-size:.8rem;line-height:2rem;font-size: 0.6rem;color: gray;">
              <el-upload class="upload-demo" action :on-change="handleChange" :on-remove="handleRemove"
                :on-exceed="handleExceed" accept=".xls, .xlsx" :file-list="fileList" :limit="limitUpload"
                :auto-upload="false" ref="my-upload">
                <el-button type="primary" round size="mini" class="input-button">兑换码</el-button>
                <span style="color: red">*</span>请导入xls/xlsx格式文件，文件内容设置为文本格式
                <br />
              </el-upload>
            </div>
            <div style="flex:1;line-height:2rem;">
              <el-button type="primary" round size="mini" class="query-button" @click="reset">重置</el-button>
            </div>
          </div>

          <div class="content-vv">
            <div style="flex:1;font-size:.8rem;text-align:right;line-height:2rem;height:2rem;">
              <span class="content-span">导入结果:</span>
            </div>
            <div style="flex:5.5;">
              <textarea class="input-height" disabled="disabled" v-model="tableData"></textarea>
            </div>
          </div>

          <div class="content-vv">
            <div style="flex:1;font-size:.8rem;text-align:right;line-height:2rem;height:2rem;">
              <span class="content-span">买方信息：</span>
            </div>
            <div style="flex:2.5;">
              <el-input v-model="resultId"></el-input>
            </div>
            <div style="flex:3;">

            </div>
          </div>

          <div class="content-vv" style="height:2rem;">
            <div style="flex:5.5;font-size:.8rem;text-align:right;line-height:2rem;"></div>
            <div style="flex:1;font-size:.8rem;line-height:2rem;">
              <el-button type="primary" round size="mini" class="query-button" @click="queryResult">提交</el-button>
            </div>
          </div>

          <div class="content-vv">
            <div style="flex:1;font-size:.8rem;text-align:right;line-height:2rem;">
              <span class="content-span">卖出成功：</span>
            </div>
            <div style="flex:5.5;">
              <textarea class="input-height" disabled="disabled" v-model="successMac"></textarea>
            </div>
          </div>

          <div class="content-vv">
            <div style="flex:1;font-size:.8rem;text-align:right;line-height:2rem;">
              <span class="content-span">卖出失败：</span>
            </div>
            <div style="flex:5.5;">
              <textarea class="input-height" disabled="disabled" v-model="failedMac"></textarea>
            </div>
          </div>
          <el-dialog title="项目选择" :visible.sync="dialogDispatchVisible" :lock-scroll="false"
            :close-on-click-modal="false">
            <el-table :data="dispatchTableData" style="width: 100%" stripe size="small"
              :header-cell-style="{'background-color': '#eeeeee'}" highlight-current-row @current-change="clickChange">
              <el-table-column label="选择" width="55">
                <template slot-scope="scope">
                  <el-radio v-model="tableRadio" :label="scope.row">
                    <i></i>
                  </el-radio>
                </template>
              </el-table-column>
              <el-table-column prop="projectName" label="项目" min-width="1" fixed align="center">
                <template slot-scope="{row}">{{ row.projectName || '暂无数据' }}</template>
              </el-table-column>
              <el-table-column prop="completeAddress" label="地址" min-width="1" fixed align="center">
                <template slot-scope="{row}">{{ row.completeAddress || '暂无数据' }}</template>
              </el-table-column>
            </el-table>
            <div>
              <el-pagination background layout="prev, pager, next" style="margin: 0 auto;padding-top:10px;"
                :total="listTotal" class="pagin" @current-change="changeCurrent" :current-page.sync="currentPage">
              </el-pagination>
            </div>

            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogDispatchVisible = false" style="margin-top:10px;">取 消</el-button>
              <el-button type="primary" @click="dispatchDeviceHandler" style="margin-top:10px;">确 定</el-button>
            </span>
          </el-dialog>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
  import Storage from "../../utils/Storage";
  export default {
    name: "batchCodes",
    data() {
      return {
        dialogDispatchVisible: false,
        listTotal: 0,
        currentPage: 0,
        tableRadio: "",
        span: 4,
        limitUpload: 1,
        tableData: [],
        fileList: [],
        dialogVisible: false,
        productId: "",
        netOperatorId: "",
        successMac: "",
        failedMac: "",
        options: [],
        resultId: "",
        errorList: [],
        successList: [],
        optionsOperator: [{
            name: "电信",
            code: "2"
          },
          {
            name: "联通",
            code: "3"
          },
          {
            name: "移动",
            code: "1"
          }
        ],
        dispatchTableData: []
      };
    },
    methods: {
      //选择项目打开函数
      changeProject() {
        this.currentPage = 1
        this.$api
          .childProjectlist({
            current: 1
          })
          .then(res => {
            this.dialogDispatchVisible = true;
            this.dispatchTableData = res.data.data.records;
            this.listTotal = res.data.data.total;
          });
      },
        //回到模块页面
            gobackPlaceDetail() {
                this.$router.push("/exchangeList");
            },
      //列表分页函数
      changeCurrent(id) {
        this.ifCurrent = true;
        this.$api
          .childProjectlist({
            current: id
          })
          .then(res => {
            this.dispatchTableData = res.data.data.records;
            this.listTotal = res.data.data.total;
          });
      },
      //单选函数
      clickChange(item) {
        // console.log(item)
        if (item) {
          this.tableRadio = item.projectName;
        }
      },
      dispatchDeviceHandler() {
        this.dialogDispatchVisible = false;
        this.resultId = this.tableRadio.projectName;
        this.tableRadio = ""
      },
      queryResult() {
        if (this.tableData.length == 0) {
          this.$message.error("请导入兑换码!");
        } else if (!this.resultId) {
          this.$message.error("请输入买方信息!");
        } else {
          this.$api
            .saleCode({
              codeList: this.tableData,
              buyerInfo: this.resultId
            })
            .then(res => {
              // console.log(res.data.data);
              this.errorList = [];
              this.successList = [];
              if (res.data.data.errorList.length !== 0) {
                for (var i = 0; i < res.data.data.errorList.length; i++) {
                  this.errorList.push(res.data.data.errorList[i]);
                }
              }
              this.failedMac = this.errorList;
              if (res.data.data.successList.length !== 0) {
                for (var i = 0; i < res.data.data.successList.length; i++) {
                  this.successList.push(res.data.data.successList[i]);
                }
              }
              this.successMac = this.successList;
            });
        }
      },
      reset() {
        this.clearFiles();
        this.tableData = [];
        (this.resultId = ""), (this.successMac = ""), (this.failedMac = "");
      },
      clearFiles() {
        this.$refs["my-upload"].clearFiles();
      },
      handleChange(file, fileList) {
        this.fileTemp = file.raw;
        if (this.fileTemp) {
          this.importfxx(this.fileTemp);
        } else {
          this.$message({
            type: "warning",
            message: "请上传附件！"
          });
        }
      },
      //超出最大上传文件数量时的处理方法
      handleExceed(file, fileList) {
        this.$message({
          type: "warning",
          message: "超出最大上传文件数量的限制！请删除已上传文件后重新上传"
        });
        return;
      },
      //移除文件的操作方法
      handleRemove(file, fileList) {
        this.fileTemp = null;
        this.tableData = [];
      },
      importfxx(obj) {
        let _this = this;
        let inputDOM = this.$refs.inputer;
        // 通过DOM取文件数据
        this.file = event.currentTarget.files[0];
        var rABS = false; //是否将文件读取为二进制字符串
        var f = this.file;
        var reader = new FileReader();

        //if (!FileReader.prototype.readAsBinaryString) {
        FileReader.prototype.readAsBinaryString = function (f) {
          var binary = "";
          var rABS = false; //是否将文件读取为二进制字符串
          var pt = this;
          var wb; //读取完成的数据
          var outdata;
          var reader = new FileReader();
          reader.onload = function (e) {
            var bytes = new Uint8Array(reader.result);
            var length = bytes.byteLength;
            for (var i = 0; i < length; i++) {
              binary += String.fromCharCode(bytes[i]);
            }

            //此处引入，用于解析excelnpm
            var XLSX = require("xlsx");

            if (rABS) {
              wb = XLSX.read(btoa(fixdata(binary)), {
                //手动转化
                type: "base64"
              });
            } else {
              wb = XLSX.read(binary, {
                type: "binary"
              });
            }
            outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
            //outdata就是读取的数据（不包含标题行即表头，表头会作为对象的下标）
            //此处可对数据进行处理
            let arr = [];
            let arr2 = [];
            outdata.map(v => {
              let mac = v["兑换码"];
              if (mac != "") {
                arr.push(mac);
              }
            });
            _this.tableData = arr;
            _this.$message({
              type: "success",
              message: "导入成功，共导入" + arr.length + "个兑换码"
            });
            return arr;
          };
          reader.readAsArrayBuffer(f);
        };
        if (rABS) {
          reader.readAsArrayBuffer(f);
        } else {
          reader.readAsBinaryString(f);
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  // .title {
  //   font-size: 0.8rem;
  //   margin: 2rem;
  //   font-weight: bold;
  // }
     .down_template{
         float: right;
         color: #898989;
         font-size: 0.8rem;
       margin-bottom: 10px;
     }
  textarea {
    outline: none;
    border: 0px;
    background-color: #fafafa;
    font-size: 0.8rem;
  }

  .row-height {
    height: 4rem;
  }

  .row-margin {
    margin-bottom: 0.5rem;
  }

  .left-title {
    font-size: 0.8rem;
    line-height: 3rem;
    text-align: right;
    margin-right: 1rem;
  }

  .input-small-height {
    padding-left: 5px;
    height: 1.7rem;
    width: 100%;
    border: 1px solid #d3d3d3;
    border-radius: 0.5rem;
  }

  .input-height {
    height: 12rem;
    margin-top: 0.6rem;
    width: 99%;
    border: 1px solid #d3d3d3;
    border-radius: 1rem;
  }

  .desc {
    line-height: 3rem;
    font-size: 0.6rem;
    color: gray;
  }

  .query-button {
    float: right;
    width: 6rem;
    height: 1.7rem;
    font-size: 0.7rem;
    min-height: 20px;
    min-width: 60px;
  }

  .input-button {
    width: 6rem;
    height: 1.7rem;
    font-size: 0.7rem;
    min-height: 20px;
    min-width: 60px;
  }

  .device-content {
    height: 6rem;

    .device-info-item {
      float: left;
      margin-right: 3rem;

      .label-info {
        margin-bottom: 0.5rem;

        label {
          font-size: 0.75rem;

          span {
            color: red;
            margin-right: 0.5rem;
          }
        }
      }

      .el-input {
        width: 10rem;
      }

      .el-select {
        width: 12rem;
      }
    }
  }

  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    button {
      float: right;
      color: #333;
      margin-top: 7px;
    }
  }

  .content {
    padding: 0rem 3rem;
    margin-top:0.5rem;

    .content-span {
      display: inline-block;
      margin-right: 10%;
    }

    .content-vv {
      width: 100%;
      display: flex;
      margin-top: 1.5rem;
    }
  }
</style>