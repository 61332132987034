<template lang="html">
  <div class="editor">
    <div ref="toolbar" class="toolbar">
    </div>
    <div ref="editor" class="text">
    </div>
  </div>
</template>

<script>
  import Storage from "../utils/Storage";
  import E from 'wangeditor'
  export default {

    name: 'appWangEnduit',
    data() {
      return {
        arr:[],
        // uploadPath,
        editor: null,
        info_: null
      }
    },
    model: {
      prop: 'value',
      event: 'change'
    },
    props: {
      value: {
        type: String,
        default: ''
      },
      isClear: {
        type: Boolean,
        default: false
      },
      uri: {
        type: String,
        default: ''
      },
    },
    watch: {
      isClear(val) {
        // 触发清除文本域内容
        if (val) {
          this.editor.txt.clear()
          this.info_ = null
        }
      },
      value: function (value) {
        if (value !== this.editor.txt.html()) {
          this.editor.txt.html(this.value)
        }
      }
      //value为编辑框输入的内容，这里我监听了一下值，当父组件调用得时候，如果给value赋值了，子组件将会显示父组件赋给的值
    },
    mounted() {
      console.log("Bearer " + Storage.getItem("token"))
      this.seteditor();
      this.editor.txt.html(this.value)
      console.log(this.value)
      console.log(this.uri)
    },
    methods: {
      seteditor() {
        this.editor = new E(this.$refs.toolbar, this.$refs.editor)
        this.editor.config.pasteFilterStyle = false
        this.editor.config.uploadImgShowBase64 = false // base 64 存储图片
        console.log(this.editor)


        // this.editor.config.uploadImgServer = this.$api// 配置服务器端地址
        this.editor.config.uploadImgServer = this.uri // 配置服务器端地址
        this.editor.config.uploadImgHeaders = {
          authorization: "Bearer " + Storage.getItem("token")
          // headers: {
          // 'Content-Type':'application/form-data'
          // },
        }

        this.editor.config.uploadFileName = 'multipartFiles' // 后端接受上传文件的参数名
        this.editor.config.uploadImgMaxSize = 1 * 1024 * 1024 // 将图片大小限制为 1M
        this.editor.config.uploadImgMaxLength = 6 // 限制一次最多上传 6 张图片
        this.editor.config.uploadImgTimeout = 3 * 60 * 1000 // 设置超时时间

        // 配置菜单
        this.editor.config.menus = [
          'head', // 标题
          'bold', // 粗体
          'fontSize', // 字号
          'fontName', // 字体
          'italic', // 斜体
          'underline', // 下划线
          'strikeThrough', // 删除线
          'foreColor', // 文字颜色
          'backColor', // 背景颜色
          'link', // 插入链接
          'list', // 列表
          'justify', // 对齐方式
          'quote', // 引用
          'emoticon', // 表情
          'image', // 插入图片
          'table', // 表格
          'video', // 插入视频
          'code', // 插入代码
          'undo', // 撤销
          'redo', // 重复
          'fullscreen' // 全屏
        ]

        this.editor.config.uploadImgHooks = {
          before: (xhr, editor, result) => {
            console.log(xhr, editor, result)
          },
          fail: (xhr, editor, result) => {
            console.log("Bearer " + sessionStorage.getItem("token"))
            console.log(xhr, editor, result)

            // 插入图片失败回调
          },
          success: (xhr, editor, result) => {
            // 图片上传成功回调
          },
          timeout: (xhr, editor) => {
            // 网络超时的回调
          },
          error: (xhr, editor) => {
            // 图片上传错误的回调
          },
          customInsert: (insertImg, result, editor) => {
            // 图片上传成功，插入图片的回调
            //result为上传图片成功的时候返回的数据，这里我打印了一下发现后台返回的是data：[{url:"路径的形式"},...]
            console.log(result.data)
            //insertImg()为插入图片的函数
            //循环插入图片
            // for (let i = 0; i < 1; i++) {
            // console.log(result)
            let url = result.data
            insertImg(url)
            // }
          }
        }
        // /////////
        this.editor.config.customUploadImg = (files, insert) => {

      
                      var formData = new FormData();
            for(var i = 0;i < files.length;i ++) {
                formData.append("multipartFiles", files[i], files[i].name);      //  多张图片文件都放进一个form-data，有些小伙伴说这里应该这样写：formData.append("file[" + i + "]", files[i], files[i].name)，后端才能拿到完整的图片数组，其实不然，用这个formData.getAll("file")方法恰好拿到数组，或者也可以forEach获取，有很多种办法。
            }

           console.log(formData)
          this.$api.goodsAddPic(formData).then(da => {
            console.log(da.data.data.split(','))
            let arr = da.data.data.split(',')

              for(var j=0;j<arr.length;j++){
                console.log(arr[j])
                            insert(arr[j]);    //  前面这个ip地址是服务器ip，后面的imgUrl是后台返回的图片地址，拼接上就是图片路径
                        }
          })
        };

        this.editor.config.onchange = (html) => {
          this.info_ = html // 绑定当前逐渐地值
          this.$emit('change', this.info_) // 将内容同步到父组件中
        }
        // 创建富文本编辑器
        this.editor.create()
      }
    }
  }
</script>

<style lang="css" scoped>
  .editor {
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 0;
  }

  .toolbar {
    border: 1px solid #ccc;
  }

  .text {
    border: 1px solid #ccc;
    min-height: 500px;
  }
</style>