<template>
  <div class="outting">
    <div class="select">
      <span class="title">订单详情</span>
      <el-button type="text" size="small" icon="el-icon-back" @click="gobackOrderListl" class="addRoleButton">返回订单列表
      </el-button>
    </div>
    <el-card class="box-card">
      <div class="text item" v-for="(item,index) in detailList" :key="index">

        <span>
          收货人: <b class="contentlist"> {{item.consignee }} </b>
        </span>
        <span>
          下单时间 :<b class="contentlist"> {{item.createTime }} </b>
        </span>
        <span>
          订单总额 :<b class="contentlist"> {{item.orderAmount }} </b>
        </span>
        <span> 
          订单编号 :<b class="contentlist"> {{item.tradeNo }} </b>
        </span>
        <span>
          订单状态 :<b class="contentlist"> {{item.orderStatus }} </b>
        </span>
        <s >
            <span >
          支付方式 :<b class="contentlist">{{item.paymentMethod == '0'?'现金支付':item.paymentMethod == '1'?'兑换码支付':"暂无" }} </b>
        </span>
       <span v-if="item.paymentMethod == '0'">
          
          支付时间 :<b class="contentlist">{{item.payTime  ||"暂无"   }} </b>
        </span>
          <span v-else>
          
          兑换时间 :<b class="contentlist">{{item.exchangeTime  ||"暂无"  }} </b>
        </span>
        </s>
       
       <span v-if="item.failureDuration">
          订单失效时间  :<b class="contentlist">{{item.failureDuration}} </b>
        </span>
           <span v-if="item.cancelTime ">
          订单取消时间   :<b class="contentlist">{{item.cancelTime }} </b>
        </span>
           <span v-if="item.endTime ">
          订单完成时间   :<b class="contentlist">{{item.endTime }} </b>
        </span>
        <span>
          收货人电话 :<b class="contentlist">{{item.phone  }} </b>
        </span>

        <span>
          物流状态 :<b class="contentlist">{{item.trackStatus ||"暂无" }} </b>
        </span>
         
        <span v-if="item.orderStatus ==  '待收货'|| item.orderStatus ==  '已完成'" >
          物流单号 : <el-input placeholder="请输入内容" size="mini" style="width:180px" v-model="item.trackNo"
            :disabled="disabledNo">
          </el-input> <i class="el-icon-edit" v-if="disabledNo" @click="editNoFn()"></i> <i v-else class="el-icon-check"
            @click="editNoFn()"></i>
          <el-button type="info" size="mini" @click="getTrack()">物流查询</el-button>
        </span>
          <span>
          订单地址 :<b class="contentlist">{{item.address ||"暂无" }} </b>
        </span>
      </div>
    </el-card>
    <div class="guzhangTable">
      <el-table :data="orderToGoodsShowVos" style="width: 100%" stripe size="small"
        :header-cell-style="{'background-color': '#eeeeee'}">
          <el-table-column prop="goodsName" label="商品名称" min-width="1" fixed align="center">


        </el-table-column>
        <el-table-column prop="actualPrice" label="商品价格 " min-width="1" fixed align="center">

        </el-table-column>
        <el-table-column prop="columnPicture" label="商品列表图片" min-width="1" fixed align="center">
          <template slot-scope="scope">
            <el-popover placement="top-start" title trigger="hover">
              <el-image :src="scope.row.columnPicture" :fit="fit" style="width:160px;height:160px"
                referrerpolicy="no-referrer"></el-image>
              <el-image slot="reference" :src="scope.row.columnPicture" style="width: 50px;height: 50px" :fit="fit"
                referrerpolicy="no-referrer"></el-image>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="goodsAmount" label="商品数量" min-width="1" fixed align="center">

        </el-table-column>
      
      </el-table>
      <el-pagination background layout="prev, pager, next" :total="total" style="float:right;margin-top:10px;"
        @current-change="changeCurrent"></el-pagination>
    </div>
    <el-dialog title="" :visible.sync="logDialogVisible" width="30%" :before-close="handleClose2">
      <span style="margin-bottom:1rem;" class="logistics">
        订单编号：
      </span>
      {{tradeNo}}

      <br>
      <span class="logistics">
        选择或输入快递公司：
      </span>
      <el-select v-model="value" style="margin-bottom:1rem;margin-right:1rem" filterable remote size="mini"
        @change="changeMethod" reserve-keyword placeholder="可下拉选择可输入框输入" clearable :remote-method="remoteMethod"
        :loading="loading">
        <el-option v-for="item in options" :key="item.deliveryCode" :label="item.deliveryName"
          :value="item.deliveryCode">
        </el-option>
      </el-select>
      <br>
      <span class="logistics">
        输入物流单号：
      </span>
      <el-input placeholder="请输入物流单号" v-model="shipmentNum" size="mini" style="width: 12rem;;margin-right:1rem"
        clearable></el-input>
      <br>

      <span class="dialog-footer">
        <el-button size="mini" @click="CancelFn()">取 消</el-button>
        <el-button size="mini" type="primary" @click="addTracks">修 改</el-button>
      </span>
      
    </el-dialog>
    <el-dialog title="" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div class="div-bg bg-white">

        <div style="padding-bottom: 0.5rem;">
          <div class="bg-white"
            style="width: 92%; margin-left: 4%;margin: auto;padding-left: 15px;padding-right: 15px;">
            <div style="font-size: 0.8rem;color: #111111;margin-bottom:1rem">物流跟踪
            </div>
            <div>
              <div class="track-rcol">
                <div class="track-list">
                  <ul>
                    <div v-for="(item,index) in logisticsList" :key="index">
                      <li class="first" v-if="index===0">
                        <div></div>
                        <i class="node-icon"></i>
                        <span class="txt">{{item.context}}</span>
                        <span class="time">{{item.time}}</span>
                      </li>
                      <li v-if="index > 0 && index !== logisticsList.length-1">
                        <i class="node-icon"></i>
                        <span class="txt">{{item.context}}</span>
                        <span class="time">{{item.time}}</span>
                      </li>
                      <li v-if="index === logisticsList.length-1" class="finall">
                        <i class="div-spilander"></i>
                        <i class="node-icon"></i>
                        <span class="txt">{{item.context}}</span>
                        <span class="time">{{item.time}}</span>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </el-dialog>
  </div>
</template>
<script>
  export default {
    name: "orderDetail",
    data() {
      return {
        tableData: [],
        total: 0,
        options: [],
        value: [],
        tradeNo: "",
        shipmentNum: "", //物流单号
        loading: false,
        disabledNo: true,
        detailList: [],
        orderToGoodsShowVos: [],
        fit: "fill",

        logisticsList: [],
        dialogVisible: false,
        logDialogVisible: false,
      };
    },
    methods: {

      changeCurrent() {},
      gobackOrderListl() {
        this.$router.push({
          path: `/orderList`,
        });
      },
      editNoFn() {
        this.disabledNo = !this.disabledNo
        if(this.disabledNo == false){
             console.log('000',this.detailList[0].trackNo)
             this.logDialogVisible =true 
             this.value =this.detailList[0].company
         this.shipmentNum = this.detailList[0].trackNo
        // this.shipmentNum=''
                this.$api
          .GetDeliveryCodes({})
          .then(res => {
            // console.log(res.data.data)
            this.options = res.data.data
          })
          .catch(error => {
            console.log(error);
          });
        }
      },
      // 物流查询
      getTrack() {
         if(this.detailList[0].trackNo == null){
          console.log('请先填写物流')
           this.$message({
            message: '请填物流公司和物流单号',
            type: 'warning'
          });
         }else{
            // this.dialogVisible = true
        this.$api
          .getTracks({
            company: this.detailList[0].company,
            orderNo: this.detailList[0].tradeNo,
            trackNo: this.detailList[0].trackNo
          })
          .then(res => {
            // console.log(res.data.data)
            console.log(JSON.parse(res.data.data))

            if(res.data.code == '200'){
            //       console.log(res.data.message)
            //          let arr = {}
            // arr = JSON.parse(res.data.data)
            // this.logisticsList = arr.data
            // this.dialogVisible = true
            if(JSON.parse(res.data.data).status=='200'){
            let arr = {}
            arr = JSON.parse(res.data.data)
            this.logisticsList = arr.data
            this.dialogVisible = true
            }else{
                  this.$message({
            message: JSON.parse(res.data.data).message,
            type: 'error'
          });
            }
            }
           
          })
          .catch(error => {
            console.log(error);
          });
         }
          
      
      },
      handleClose(done) {
        done();
        this.value = "";
      },
      remoteMethod(query) {
      
        if (query !== "") {
          console.log(query);
          this.loading = true;
          this.$api
            .GetDeliveryCodes({
              deliveryName: query
            })
            .then(res => {
              // console.log(res.data.data)
              this.options = res.data.data
            })
            .catch(error => {
              console.log(error);
            });
          setTimeout(() => {
            this.loading = false;
            this.options = this.list.filter(item => {
              return item;
            });
          }, 200);
        } else {

        }
      },
      changeMethod() {
        if (this.value == '') {
          console.log('11000')
          this.$api
            .GetDeliveryCodes({})
            .then(res => {
              console.log(res.data.data)
              this.options = res.data.data
            })
            .catch(error => {
              console.log(error);
            });
        }

      },
      // 点击取消
      CancelFn(){
       this.logDialogVisible = false
              this.disabledNo = true
      },
      handleClose2(){
         this.logDialogVisible = false
              this.disabledNo = true
      },
      addTracks() {
        if (this.value == '' || this.shipmentNum == "") {
          this.$message({
            message: '请填物流公司和物流单号',
            type: 'warning'
          });
        } else {
          this.$api
            .modifyTracks({
              company: this.value,
              orderNo: this.tradeNo,
              trackNo: this.shipmentNum
            })
            .then(res => {
              // console.log(res.data)
              this.logDialogVisible = false
              this.disabledNo = true
              // this.nodeList()
                    this.$api
        .GoodsOrderDetail({
            params: {
              orderId: this.$route.query.data
            }
          }

        )
        .then(res => {
          // console.log(res.data.data)
          this.detailList = [res.data.data]
          // console.log(this.detailList)
          this.tradeNo = res.data.data.tradeNo
          this.orderToGoodsShowVos = res.data.data.orderToGoodsShowVos
        })
        .catch(error => {
          console.log(error);
        });
            })
            .catch(error => {
              console.log(error);
            });
        }

      },
    },
    created() {

    },
    mounted() {
      // console.log(this.$route.query.data);
      this.$api
        .GoodsOrderDetail({
            params: {
              orderId: this.$route.query.data
            }
          }

        )
        .then(res => {
          console.log(res.data.data)
          this.detailList = [res.data.data]
          console.log(this.detailList)
          this.tradeNo = res.data.data.tradeNo
          this.orderToGoodsShowVos = res.data.data.orderToGoodsShowVos
        })
        .catch(error => {
          console.log(error);
        });

    }
  };
</script>
<style lang="less" scoped>
  @border-bottom-color: #e7eaed;
  @toolBarFontColor: #111d;

  .outting {
    height: 100%;
    overflow: hidden;
    background-color: #fafafa;

    .contentlist {
      color: rgb(68, 176, 248);
      padding: 0 0.4rem;
    }
     .logistics {
    display: inline-block;
    min-width: 140px;
    text-align: right
  }
    .dialog-footer {
    display: inline-block;
    margin: 2rem 0 0 30%;

  }

    .track-rcol {}

    .track-list {
      position: relative;
    }

    .track-list li {
      position: relative;
      padding: 0px 0 1.5rem 25px;
      line-height: 1rem;
      border-left: 1px solid #d9d9d9;
      color: #999;
    }

    .track-list li.first {
      color: red;
      padding-top: 0;
      width: 100%;
      text-align: left;
      border-left: 1px solid #d9d9d9;
    }

    .track-list li .node-icon {
      position: absolute;
      left: -5.5px;
      border-radius: 0.25rem;
      width: 0.5rem;
      height: 0.5rem;
      top: 4px;
      background-color: #999999;
    }

    .track-list li.first .node-icon {
      background-position: 0-72px;
      background-color: red;
      width: 1rem;
      z-index: 2;
      height: 1rem;
      position: absolute;
      left: -9px;
      top: 0;
      border-radius: 0.5rem;
    }

    .track-list li .time {
      margin-right: 20px;
      position: relative;
      top: 4px;
      display: inline-block;
      vertical-align: middle;
      background-color: white;
      color: #999;
      width: 100%;
      text-align: left;
    }

    .track-list li .txt {
      position: relative;
      display: inline-block;
      vertical-align: top;
      color: #999;
    }

    .track-list li.first .time {
      text-align: left;
      width: 94%;
      color: red;
    }

    .track-list li.first .txt {
      color: red;
      text-align: left;
      width: 94%;
    }

    .track-list li.finall {
      position: relative;
      padding: 0px 0 1.5rem 25px;
      line-height: 18px;
      border-color: white;
      border-left: 1px solid #ffffff;
      color: #999;
    }

    .track-list li.finall .div-spilander {
      width: 1px;
      position: absolute;
      position: absolute;
      left: -1.5px;
      height: 0.5rem;
      background-color: #d9d9d9;
    }

    .select {
      height: 46px;
      overflow: hidden;
      border-bottom: 1px solid #d9d9d9;
      padding: 0rem 3rem;

      .select-status {
        width: 9rem;
        margin-left: 1.7rem;
        margin-top: 7px;
      }

      .select-input-timer {
        float: right;
      }

      .select-status {
        float: right;
      }

      .select-input-right-input {
        float: right;
        // width: 8rem;
        line-height: 46px;
      }

      .title {
        display: inline-block;
        line-height: 46px;
        font-weight: bold;
        font-size: 0.8rem;
      }

      button {
        float: right;
        color: @toolBarFontColor;
        margin-top: 7px;
      }

    }

    .text {
      font-size: 0.85rem;
    }

    .el-icon-edit,
    .el-icon-check {
      margin: 0 20px;
      cursor: pointer;
    }

    .item {
      padding: 18px 0;
    }

    .box-card {
      margin: 1rem 3rem;

      span {
        padding: 1rem 1rem;
        display: inline-block;
        // border: 1px black solid;
        min-width: 14rem;
      }
    }

    .guzhangTable {
      padding: 0rem 3rem;
      margin-top: 1rem;

    }
  }
</style>