<template>
  <div class="outting">
    <div class="select">
      <span class="title">商品轮播编辑</span>
      <el-button
        type="text"
        size="small"
        icon="el-icon-back"
        @click="gobackAddList"
        class="addRoleButton"
      >返回商品轮播列表</el-button>
    </div>
    <div class="luobitu--vv">
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix">
          <span>编辑轮播</span>
        </div>
        <el-upload
          action
          :on-change="handleChange"
          list-type="picture-card"
          v-show="!value"
          :show-file-list="false"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog>
        <div v-show="value" class="el-upload-list el-upload-list--picture-card">
          <div class="el-upload-list__item is-success">
            <el-image
              :src="this.value"
              style="width:100%;height:100%;"
              fit="fill"
              class="avatar"
              v-if="value"
              referrerpolicy="no-referrer"
            ></el-image>
            <!-- <img class="avatar" v-if="value" :src="value" /> -->
            <label class="el-upload-list__item-status-label">
              <i class="el-icon-upload-success el-icon-check"></i>
            </label>
            <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-preview">
                <i class="el-icon-zoom-in" @click.stop="handlePictureCardPreview"></i>
              </span>
              <span class="el-upload-list__item-delete">
                <i class="el-icon-delete" @click.stop="handleRemove"></i>
              </span>
            </span>
          </div>
        </div>
        <div style="margin: 20px;"></div>
        <el-form
          label-position="left"
          label-width="80px"
          :model="formLabelAlign"
          style="width:70%;"
        >
          <el-form-item label="图片名称">
            <el-input v-model="formLabelAlign.adName"></el-input>
          </el-form-item>
          <el-form-item label="图片描述">
            <el-input v-model="formLabelAlign.adDesc"></el-input>
          </el-form-item>
          <el-form-item label="图片序号">
            <el-input v-model="formLabelAlign.adSort"></el-input>
          </el-form-item>
          <el-form-item label="跳转链接">
            <el-input v-model="formLabelAlign.adLinkUrl "></el-input>
          </el-form-item>
            <!-- <el-form-item label="展示区域">
           
               <el-select v-model="formLabelAlign.mark" size="mini" clearable
                style="display:inline-block;width: 10.25rem;;margin-right:1rem">
                <el-option v-for="item in mark" :key="item.lable" :label="item.value" :value="item.lable">
                </el-option>
              </el-select>
          </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="submitForm()">提交</el-button>
            <el-button @click="resetForm()">重置</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>
<script>
import bus from "@/components/bus";
export default {
  name: "editCarousel",
  data() {
    return {
      value: "",
      dialogImageUrl: "",
      dialogVisible: false,
      fit: "fill",
      file: "",
      ifUpload: true,
      ififUpload: false,
      id:'',
       mark:[{
        value:"系统首页",
         lable:"0",
      },
      {
        value:"商品顶部轮播",
         lable:"1",
      }],
      formLabelAlign: {
        adName: "",
        adDesc: "",
        adSort: "",
        adLinkUrl: "",

      }
    };
  },
  methods: {
    gobackAddList() {
      this.$router.push("/productCarousel");
    },
    handleChange(file, fileList) {
      console.log(file);
      this.value = file.url;
      this.file = file.raw;
      this.ififUpload = true;
      this.ifUpload = true
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = this.value;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.value = "";
      this.ifUpload = false;
      //   this.file = {};
    },
    submitForm() {
      if (this.ifUpload === false) {
        this.$message.error("请上传图片!");
      } else {
      
        if (this.ififUpload === true) {
          let formData = new FormData();
         
          formData.append("multipartFile", this.file);
          formData.append("adSort", Number(this.formLabelAlign.adSort));
          formData.append("adDesc", this.formLabelAlign.adDesc);
          formData.append("adLinkUrl", this.formLabelAlign.adLinkUrl);
          formData.append("adName", this.formLabelAlign.adName);
        //   formData.append("mark", this.formLabelAlign.mark);
          formData.append("id", this.id);
          this.$api.mallAdvertisementEditUrl(formData).then(res => {
            if (res.data.code == 200) {
              this.$message.success("编辑成功!");
              this.$router.replace("/productCarousel");
            }
          });
        }else if(this.ififUpload === false){
           let formData = new FormData();
        //   formData.append("multipartFile", this.file);
          formData.append("adSort", Number(this.formLabelAlign.adSort));
          formData.append("adDesc", this.formLabelAlign.adDesc);
          formData.append("adLinkUrl", this.formLabelAlign.adLinkUrl);
          formData.append("adName", this.formLabelAlign.adName);
        //   formData.append("mark", this.formLabelAlign.mark);
          formData.append("id", this.id);
          this.$api.mallAdvertisementEditUrl(formData).then(res => {
            if (res.data.code == 200) {
              this.$message.success("编辑成功!");
              this.$router.replace("/productCarousel");
            }
          }); 
        }
      }
    },
    resetForm() {}
  },
  created() {
    bus.$on("row", row => {
      this.value = row.adImgUrl;
      console.log(row)
      if( row.adName){
         this.formLabelAlign.adName = row.adName;
      }else{
        this.formLabelAlign.adName=""
      }
      if(row.adDesc){
        this.formLabelAlign.adDesc = row.adDesc
      }
     else{
       this.formLabelAlign.adDesc=""
     }
     if(row.adSort){
       this.formLabelAlign.adSort = row.adSort
     }
     else{
       this.formLabelAlign.adSort=""
     }
     
  

     if(row.adLinkUrl){
      this.formLabelAlign.adLinkUrl = row.adLinkUrl
     }
      else{
         this.formLabelAlign.adLinkUrl=""
      }
      this.id=row.id
    });
  }
};
</script>
<style lang="less" scoped>
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;
.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;
    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }
    button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
    }
  }
  .luobitu--vv {
    padding: 1rem 3rem;
  }
}
</style>