<template>
    <div>
        <div class="outting">
            <div class="select">
                <span class="title">解决方案修改</span>
                <el-button type="text" size="small" icon="el-icon-back" @click="gobackPlaceDetail"
                    class="addRoleButton">返回解决方案列表</el-button>
            </div>
            <div class="detail">
                <el-card class="box-card" shadow="never">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
                        <el-row>
                            <el-col :span="10">
                                <el-form-item label="新闻主标题" prop="newsTitle">
                                    <el-input v-model.trim="ruleForm.newsTitle" style="width:90%;margin-bottom:10px"
                                        placeholder="请输入新闻主标题"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="0">
                                <el-form-item label="选择日期" prop="releaseTime">
                                    <el-date-picker v-model="ruleForm.releaseTime " value-format="yyyy-MM-dd"
                                        format="yyyy-MM-dd" type="date" placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>

                           <el-form-item label="新增内容编辑" prop="newsContent">
                                   <editor-bar v-model="ruleForm.newsContent" :isClear="isClear" @change="change"></editor-bar>
                                </el-form-item>
                                   <el-row>
                            <el-col :span="10" style="margin-top:50px;margin-left:30%" >
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('ruleForm')">立即修改</el-button>
                                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <!-- <editor-bar v-model="content" :isClear="isClear"></editor-bar> -->
                    <!-- <div id="div1" height="500px">

                    </div> -->
                    <!-- <div>
                        <editor-bar v-model="detail" :isClear="isClear" @change="change"></editor-bar>
                    </div> -->
                </el-card>
            </div>
        </div>
    </div>
</template>
<script>
    import EditorBar from '../../../components/wangEnduit'

    import E from "wangeditor"
    export default {
        name: "PlaceDetail",
        components: {
            EditorBar
        },
        data() {
            return {
                formLabelWidth: "120px",

                detail: "",
                content: "",
                isClear: false, //清除富文本编辑器内容
                // 默认步骤数
                limitUpload: 1,
                limitUpload2: 1,
                fileList: [],
                fileMulLis: [],
                stepActive: "stepActive",

                dialogImageUrl: "",
                dialogVisible: false,
                value: "",
                file: {},
                dialogImageUrl2: "",
                dialogVisible2: false,
                value2: "",
                file2: {},
                id:"",
                ruleForm: {
                    file: "",
                    contentUrl: "",
                    newsTitle: "",
                    newsSubtitle: "",
                    releaseTime: "",
                    newsContent:"",
                },
                rules: {
                    newsTitle: [{
                        required: true,
                        message: "请选择主标题",
                        trigger: "blur"
                    }],
                    newsSubtitle: [{
                        required: true,
                        message: "请选择副标题",
                        trigger: "blur"
                    }],
                    releaseTime: [{
                        required: true,
                        message: "请选择日期",
                        trigger: "blur"
                    }],
                    file: [{
                        required: false,
                        message: "请上传详情页内容图片",
                        trigger: "change"
                    }],
                    newsContent: [{
                        required: true,
                        message: "请添加资讯内容",
                        trigger: "blur"
                    }]
                }
            };
        },
        created() {

        },
        mounted() {
            //   const editor = new E( document.getElementById('div1') )
            // editor.create()
                  console.log(this.$route.query.data)
              this.ruleForm.newsSubtitle = this.$route.query.data.newsSubtitle
              this.ruleForm.newsTitle = this.$route.query.data.newsTitle
              this.ruleForm.releaseTime = this.$route.query.data.releaseTime
              this.ruleForm.newsContent = this.$route.query.data.newsContent
              this.value = this.$route.query.data.newsImgUrl
              this.value2 = this.$route.query.data.newsContentUrl
              this.id = this.$route.query.data.id
        },
        methods: {
            change(val) {
                console.log(val)
             this.ruleForm.newsContent = val
            },
            submitForm(formName) {
                this.$refs[formName].validate(valid => {
                    let formData = new FormData();
                    console.log(this.value)
                    console.log(this.file)
                    console.log(this.$route.query.data.newsImgUrl)
                    if( this.value == this.$route.query.data.newsImgUrl){
                        this.file = null
                             formData.append("newsContent ", this
                        .ruleForm.newsContent);
                    formData.append("newsSubtitle", this.ruleForm.newsSubtitle);
                    formData.append("newsTitle", this.ruleForm.newsTitle);
                    formData.append("releaseTime", this.ruleForm.releaseTime);
                    formData.append("id", this.id);
                    }else{
                      formData.append("multipartFile", this.file); //此处一定是append file.raw 上传文件只需维护fileList file.raw.name要加上
                    formData.append("newsContent ", this
                        .ruleForm.newsContent);
                    formData.append("newsSubtitle", this.ruleForm.newsSubtitle);
                    formData.append("newsTitle", this.ruleForm.newsTitle);
                    formData.append("releaseTime", this.ruleForm.releaseTime);
                    formData.append("id", this.id);
                    }

                    if (this.value == "") {
                        this.$message({
                            showClose: true,
                            message: '请选择图片',
                            type: 'error'
                        });
                    } else {
                        if (valid) {
                            this.$api.modifyNews(formData).then(res => {
                                if (res.data.code == 200) {
                                    this.$message.success("修改成功!");
                                    this.$nextTick(() => {
                                          this.$router.push("/planConfiguration");
                                        this.$refs["ruleForm"].clearValidate();
                                        this.value = ''
                                        this.value2 = ''
                                        this.file = {}
                                        this.file2 = {}
                                        this.fileList = [];
                                        this.ruleForm.newsSubtitle = ""
                                        this.ruleForm.newsTitle = ""
                                        this.ruleForm.releaseTime = ""
                                        this.ruleForm.contentUrl = ""
                                        this.ruleForm.newsContent = ""

                                    });
                                } else if (res.data.code == 10110008) {
                                    //   this.$nextTick(() => {
                                    //   this.$refs["ruleForm"].clearValidate();
                                    // });
                                }
                            });
                        } else {
                            console.log("error submit!!");

                            return false;
                        }
                    }

                });
            },
            handleChange(file, fileList) {
                console.log(file.raw, fileList);
                this.fileList = file.raw;
                if (this.fileList == []) {
                    this.$message({
                        type: "warning",
                        message: "请上传图片！"
                    });
                }
            },
            //超出最大上传文件数量时的处理方法
            handleExceed(file, fileList) {
                this.$message({
                    type: "warning",
                    message: "超出最大上传文件数量的限制！请删除已上传文件后重新上传"
                });
                return;
            },
            //移除文件的操作方法
            handleRemove(file, fileList) {
                this.fileList = null;
                this.tableData = [];
            },
            handleChange2(file, fileList) {
                console.log(file.raw, fileList);
                this.fileMulLis = file.raw;
                if (this.fileMulLis == []) {
                    this.$message({
                        type: "warning",
                        message: "请上传图片！"
                    });
                }
            },
            //超出最大上传文件数量时的处理方法
            handleExceed2(file, fileList) {
                this.$message({
                    type: "warning",
                    message: "超出最大上传文件数量的限制！请删除已上传文件后重新上传"
                });
                return;
            },

            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            //回到模块页面
            gobackPlaceDetail() {
                this.$router.push("/planConfiguration");
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
                this.value = "";
                this.file = {};
            },
            handleChange(file, fileList) {
                this.value = file.url;
                this.file = file.raw;
            },
            handlePictureCardPreview2(file) {
                this.dialogImageUrl2 = file.url;
                this.dialogVisible2 = true;
            },


        }
    };
</script>
<style lang="less" scoped>
    @border-bottom-color: #e7eaed;
    @toolBarFontColor: #111d;

    .outting {
        height: 100%;
        overflow: hidden;
        background-color: #fafafa;

        .item {
            height: 80px;
            width: 80px;
            border: 1px solid blue;
            float: left;
        }

        .select {
            height: 46px;
            overflow: hidden;
            border-bottom: 1px solid #d9d9d9;
            padding: 0rem 3rem;

            .title {
                display: inline-block;
                line-height: 46px;
                font-weight: bold;
                font-size: 0.8rem;
            }

            button {
                float: right;
                color: @toolBarFontColor;
                margin-top: 7px;
            }
        }

        .detail {
            padding: 0rem 3rem;
            margin-top: 1rem;

            .detail-span {
                font-size: 12 /16rem;
                color: #898989;
            }

            .detail-vv {
                margin-top: 5 /16rem;
                font-size: 14 /16rem;
            }
        }
    }

    .el-row {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-col {
        border-radius: 4px;
    }

    .bg-purple-dark {
        background: #99a9bf;
    }

    //   .bg-purple {
    //     background: #d3dce6;
    //   }
    .bg-purple-light {
        background: #e5e9f2;
    }

    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }

    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }

    .stepShow {
        width: 80%;
        margin: 3rem auto;

        .downloadList {
            //  transform:translate(5.5rem, -4rem)
        }

        /deep/.el-step.is-horizontal.stepActive {
            .el-step__head.is-finish {
                .el-step__line {

                    // 当前步骤数横线样式设置
                    .el-step__line-inner {
                        width: 50% !important;
                        border-width: 1px !important;
                    }
                }

                // 当前步骤数圆圈样式设置
                .el-step__icon.is-text {
                    background: #409eff;
                    color: #fff;
                }
            }
        }
    }

    .outting .item {
        margin: 10px;
        height: auto;
        width: auto;
        cursor: pointer;
        border: none;
    }

    .right .el-tooltip__popper {
        padding: 8px 10px;
        border: none;
        cursor: pointer;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
