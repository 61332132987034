<template>
    <div class="iphoneSolve">
      <el-breadcrumb separator-class="el-icon-arrow-right">
  <el-breadcrumb-item  @click.native="backTo">    {{this.$route.query.crumbsTitle=="首页"? this.$route.query.crumbsTitle :"新闻资讯"}}</el-breadcrumb-item>
  <el-breadcrumb-item >  <span style="color:#333">
                        资讯详情
                    </span></el-breadcrumb-item>
</el-breadcrumb>
     <div class="item-title_new">
              <p>{{title}}</p>
              <span> </span>
            </div>
       <div v-html="outlink" class="texztaaa"></div>
    </div>
</template>

<script>

    export default {
        name: "iphoneSolve",

        data() {
            return {
                  outlink: "" ,// 外部链接,
                  title:"",
            };
        },
        created(){
               console.log(this.$route.query.id)
        },
        mounted(){
               this.$api
          .newsListApp({
             page: {
              current: this.$route.query.current,
              size: this.$route.query.size
            }
          })
          .then(res => {
            console.log(res.data.data)
           for (let index = 0; index < res.data.data.records.length; index++) {
             if(this.$route.query.id == res.data.data.records[index].id){
                    this.outlink  =  res.data.data.records[index].newsContent
                     this.title  =   res.data.data.records[index]. newsTitle
             }

           }

          })
          .catch(error => {
            console.log(error);
          });
        },
        methods:{

        backTo() {
                if (this.$route.query.crumbsTitle == "首页") {
                    let vm = this;
                    vm.$router.push({
                        path: `/`
                    });

                } else {
                    this.$router.replace({
                        path: "/iphoneNews"
                    });
                }
            }
        }

    }
</script>
<style lang="less" scoped>


  .iphoneSolve{
         margin-top: 14vw;

         width: 100%;
         .el-breadcrumb{
              background: #F6F6F6;
              line-height: 8vw;
              height: 8vw;
              font-size: 3.7vw;
              color: #333333;
              padding-left: 5.6vw;
         }

      background: #ffffff;
      .texztaaa{
          width: 94vw;
           margin: auto;
           text-align: center;
           padding-bottom: 4vw;
      }
        .item-title_new {
      padding-top: 3.6vw;
      text-align: center;
      padding-bottom: 4.8vw;
      color: #333333;
      width: 100%;
      letter-spacing: 1px;
      margin: 0 auto;
      font-size: 5.6vw;

      span {
        width: 17.4vw;
        margin: 0 auto;
        height: 2px;
        padding-top: 1px;
        display: block;
        background: #F1A948;
      }
    }
  }



</style>
