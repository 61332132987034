<template>
	<div class="video-tutorial-detail" style="background-color: #fafafa;width: 100%">

<!--    <el-container>-->
<!--      <el-header>-->

<!--      </el-header>-->

<!--      <el-main>-->
        <HeadNav />
        <div class="nav-breadcrumb">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/videoTutorial'}" >视频教程</el-breadcrumb-item>
            <el-breadcrumb-item>消防管理后台视频教程</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <el-row :gutter="20">
          <el-col :span="18"  :offset="2">
            <div class="image">
              <!--          <img src='../../assets/rotationChart/five.jpg'>-->
              <video-player  class="video-player vjs-custom-skin"
                             ref="videoPlayer"
                             :playsinline="true"
                             :options="playerOptions"
              ></video-player>

            </div>

          </el-col>
          <!--        <el-col :span="6"  :offset="2">-->
          <!--          <div class="title-content">-->
          <!--          <h1 style="margin-top: 3.5rem;color: #000000">JT-WSH6200N</h1>-->
          <!--          <h3 style="margin-top: 1rem">吻胜家用可燃气体探测器JT-WSH6200N</h3>-->
          <!--          </div>-->
          <!--          <el-button type="primary" style="margin-top: 1rem">立即购买</el-button>-->
          <!--        </el-col>-->
        </el-row>
<!--      </el-main>-->
<!--    </el-container>-->



<!--    <el-row class="tab-nav" >-->
<!--      <el-col :span="18"  :offset="2" >-->
<!--        <div >-->
<!--          <el-tabs v-model="activeName" @tab-click="handleClick">-->
<!--            <el-tab-pane label="产品概述" name="first" >-->
<!--              <ul class="product-desc">-->
<!--                <li>1,用于探测可燃气体的泄露，当浓度达到报警 动作值时，发出报警和控制信号</li>-->
<!--                <li>2,适用于使用天然气、液化气的场所或其他散发可燃气体和可燃蒸汽的场所</li>-->
<!--                <li>3,探测器为独立式安装设备，内置优质催化燃烧式气敏元件、高性能微处理器 ，采用 SMT 表面贴装工艺，可靠性高，一致性好</li>-->
<!--                <li>4,具有高灵敏度、 对酒精和温湿度的感知程度小等优点</li>-->
<!--                <li>5,设计遵照 GB 15322.2-2019《家用可燃气体探测器》国家标准</li>-->
<!--              </ul>-->
<!--            </el-tab-pane>-->
<!--            <el-tab-pane label="技术参数" name="second">-->
<!--              <el-table :cell-style="cellStyle({})" :header-cell-style="headerCellStyle({})"-->
<!--                  :data="tableData"-->
<!--                  style="width: 50%;background: #fafafa;border:none;padding-left: 0;margin-left: 0" :stripe="false" :border="false">-->
<!--                <el-table-column style="border:none; background: #FAFAFA"-->
<!--                    prop="name"-->
<!--                    label="参数名"-->
<!--                    width="180">-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                    prop="value"-->
<!--                    label="参数值">-->
<!--                </el-table-column>-->
<!--              </el-table>-->
<!--            </el-tab-pane>-->
<!--            <el-tab-pane label="型号" name="third">JT-WSH6200N</el-tab-pane>-->
<!--          </el-tabs>-->
<!--        </div>-->
<!--      </el-col>-->

<!--    </el-row>-->

		<FooterNav />
	</div>
</template>
<script>
	import HeadNav from '../../components/HeadNav'
	import FooterNav from '../../components/FooterNav'
  import { videoPlayer } from 'vue-video-player'
  import 'video.js/dist/video-js.css'
  import 'vue-video-player/src/custom-theme.css';
	export default {
		name: 'VedioTutorialDetail',
		components: {
			HeadNav,
			FooterNav,
      videoPlayer
		},
		data() {
			return {
				activeName: 'first',
				tableData: [{
					name: '供电方式',
					value: '电池（9V 碱性电池）'
				}, {
					name: '监视电流',
          value: '≤7uA'
				}, {
					name: '报警电流',
          value: '＜10mA'
				}, {
					name: '工作温度',
          value: '-10～+55℃'
				},{
          name: '推荐存储温度',
          value: '0℃～+25℃'
        }, {
          name: '相对湿度',
          value: '≤95%，无凝露'
        }, {
          name: '报警动作值',
          value: '10%LEL 甲烷（CH4）'
        }, {
          name: '稳定性',
          value: '≤±50×10-6(体积分数)'
        }, {
          name: '响应时间',
          value: '≤60s'
        }, {
          name: '报警声信号',
          value: '≥75dB(1m)'
        }],
        playerOptions : {
          playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            src: 'https://jbufa-video.oss-cn-beijing.aliyuncs.com/yanshi2.mp4?Expires=1626429226&OSSAccessKeyId=TMP.3KeqqTBN7ketNbidQSbwtWKKe5xRhbvo6CxGLPPZTftM3jf8AXsWN9FvJr9esU1S24qmnaFXrQ7Bh7RQ5DXxrFGU4a8th2&Signature=ry1faHUqtnIVI1L0SmR8cYbPT7I%3D&versionId=CAEQERiBgIDpzOOf1RciIDE0ZTEyNDMzNmI0YTRiMmQ4MjBjOGQzMmYyYjc0YWZl&response-content-type=application%2Foctet-stream',  // 路径
            type: 'video/mp4'  // 类型
          }],
          poster: "https://jbufa-images.oss-cn-beijing.aliyuncs.com/five.jpg?Expires=1626429303&OSSAccessKeyId=TMP.3KeyRgmdoTsB9W1qZXVmvaUDEiAEb3ohas6t5PZfUX8Uij5NpS3VYeMgJhynMn7vM6VHQAXDGQzFcGZNGAZZpccWj78yU2&Signature=1XDFyLC6Lv%2F957cDusxheW5G2TA%3D&versionId=CAEQEhiBgIDM5fOf1RciIGE3MWVjODEwNzNkMDQ5YmRiOTczNjhjNDZkYjJiMjky&response-content-type=application%2Foctet-stream", //你的封面地址
          // width: document.documentElement.clientWidth,
          notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true  //全屏按钮
          }
        }
			}
		},
		methods: {
			handleClick(tab, event) {
				console.log(tab, event);
			},
      cellStyle({}){
			  return "border:none; background:#fafafa;margin-left:0;padding-left:0;"
      },
      headerCellStyle({}){
        return "border:none;background:#fafafa;margin-left:0;padding-left:0;"
      }
		}
	}
</script>
<style lang="less" scoped>

@media screen and (min-width: 0px) and (max-width: 1200px) {
  .video-tutorial-detail {
    background-color: #fafafa;
    overflow-x: auto;
    min-width: 1000px;
  }
}

@media screen and (min-width: 1201px) {
  .video-tutorial-detail {
    background-color: #fafafa;
    overflow-x: auto;
    min-width: 1300px;
  }
}

	.detail-top {
		width: 80%;
		height: 300px;
		// background-color: #475669;
		margin: 0 auto;
		margin-top: 20px;

		div {
			float: left;
		}

		.text {
			width: 60%;
			height: 100%;
			// background-color: #FAFAFA;

			h1 {
				display: block;
				text-shadow: 5px 8px 6px #ADADAD;
			}

			h3 {
				display: block;
				margin-top: 50px;
			}
		}


	}

	.title {
		width: 100%;
		height: 100px;
		background-color: #fafafa;
		margin-top: 20px;
		color: #666666;

		div {
			width: 80%;
			height: 100%;
			margin: 0 auto;

			h1 {
				display: inline-block;
				margin-top: 30px;
			}
		}
	}

	.tab {
		width: 80%;
		margin: 0 auto;
		margin-bottom:20px;
	}


  .image {
    width: 60%;
    min-width: 600px;
    //height: 40rem;
   margin-top: 3.5rem;
    margin-bottom: 5rem;
    margin-left: 20%;
    border-radius: 2rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .title-content{
    border-bottom: 1px solid #a5a3a3;
    //border-bottom: 1px;
    width: 21rem;
    padding-bottom: 0.5rem;
  }

  .nav-breadcrumb{
    margin-left: 5rem;
    margin-top: 2rem;
  }
  .tab-nav{
    margin-bottom: 3rem;
    font-size: 1rem;
  }
  //.el-tabs__item is-top{
  //  font-size: large !important;
  //}
  .product-desc{
    font-size: 0.9rem;
    li{
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }
  }
  //.tab-nav .tab-pane-content{
  //  font-size: 5rem;
  //}

  .video-js .vjs-big-play-button{
    //margin-left: 5rem;
    //text-align: center;
    width: 72px;
    height: 72px;
    border-radius: 100%;
    z-index: 100;
    background-color: #ffffff;
    border: solid 1px #979797;
  }

</style>
