<template>
  <div class="outting">
    <div class="select">
      <span class="title">故障类型</span>
      <el-button
        type="text"
        size="small"
        icon="el-icon-plus"
        @click="addUserHandler"
        class="addRoleButton"
      >添加故障类型</el-button>
    </div>
    <div class="guzhangTable">
      <el-table
        :data="tableData"
        style="width: 100%"
        stripe
        size="small"
        :header-cell-style="{'background-color': '#eeeeee'}"
      >
        <el-table-column prop="faultType" label="故障类型" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.faultType || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="sysUserName" label="用户名" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.sysUserName || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.createTime || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.updateTime || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column min-width="1" label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-popconfirm title="确定删除这个故障吗？" @onConfirm="deleteDeviceFault()">
              <el-button
                type="text"
                size="small"
                icon="el-icon-delete"
                @click="handleDelete(scope.$index, scope.row)"
                slot="reference"
              >删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        style="float:right;margin-top:10px;"
        @current-change="changeCurrent"
      ></el-pagination>
    </div>
    <el-dialog
      title="增加设备故障类型"
      :visible.sync="dialogFormVisible"
      :lock-scroll="false"
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="故障类型" :label-width="formLabelWidth" prop="type">
          <el-input  v-model.trim="form.type" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "FaultType",
  data() {
    return {
      tableData: [],
      dialogFormVisible: false,
      form: {
        type: ""
      },
      formLabelWidth: "120px",
      rules: {
        type: [
          { required: true, message: "请输入故障类型", trigger: "blur" },
          { min: 1, max: 30, message: "长度在 1 到 30 个字符", trigger: "blur" }
        ]
      },
      total: 0,
      deleteId: "",
      current: ""
    };
  },
  methods: {
    //新增故障类型
    addUserHandler() {
      this.dialogFormVisible = true;
      if (this.$refs["form"] !== undefined) {
        this.resetForm("form");
      }
    },
    //重置函数
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //确认添加故障类型
    submitForm(formName) {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.dialogFormVisible = false;
          this.$api
            .addDeviceFault({
              faultType: this.form.type
            })
            .then(res => {
              if (res.data.code == 200) {
                this.$message.success("添加故障类型成功！");
                this.$api
                  .deviceFaultType({
                    page: {
                      current: this.current
                    }
                  })
                  .then(res => {
                    this.tableData = res.data.data.records;
                    this.total = res.data.data.total;
                  });
              }
            });
        } else {
          return false;
        }
      });
    },
    //删除故障类型
    handleDelete(index, row) {
      console.log(row.id);
      this.deleteId = row.id;
    },
    //删除故障类型确定函数
    deleteDeviceFault() {
      this.$api.deleteDeviceFault(this.deleteId).then(res => {
        console.log(res);
        if (res.data.code == 200) {
          this.$message.success("删除故障类型成功！");
          this.$api
            .deviceFaultType({
              page: {
                current: this.current
              }
            })
            .then(res => {
              this.tableData = res.data.data.records;
              this.total = res.data.data.total;
            });
        }
      });
    },
    //分页函数
    changeCurrent(val) {
      this.current = val;
      this.$api
        .deviceFaultType({
          page: {
            current: val
          }
        })
        .then(res => {
          console.log(res.data.data);
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        });
    }
  },
  created() {
    this.$api
      .deviceFaultType({
        page: {
          current: 1
        }
      })
      .then(res => {
        console.log(res.data.data);
        this.tableData = res.data.data.records;
      });
  }
};
</script>
<style lang="less" scoped>
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;

.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .select-status {
      width: 9rem;
      margin-left: 1.7rem;
      margin-top: 7px;
    }

    .select-input-timer {
      float: right;
    }

    .select-status {
      float: right;
    }

    .select-input-right-input {
      float: right;
      // width: 8rem;
      line-height: 46px;
    }

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .addRoleButton {
      margin-left: 15px;
    }

    .serachRoleButton {
      margin-left: 15px;
    }

    button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
    }

    .update-input-timer {
      float: right;
      line-height: 46px;
      margin-left: 25px;
      height: 46px;

      .demonstrationTwo {
        float: left;
        font-size: 0.8rem;
      }

      .update-input-timer-select {
        width: 11rem;
        margin-left: 5px;
      }
    }
  }
  .guzhangTable {
    padding: 0rem 3rem;
    margin-top: 1rem;
    // .el-table td,
    // .el-table th {
    //   padding: 0rem !important;
    // }
  }
}
</style>