<template>
  <div>
    <el-container class="main-content">
      <el-main>
        <el-row>
          <el-col :span="24">
            <div class="select">
              <span class="title">设备注册查询</span>
            </div>
          </el-col>
        </el-row>

        <el-row class="content-row">
          <el-col :span="4">
            <div class="content-label">
              <span>导入批量注册查询设备IMEI:</span>
            </div>
          </el-col>
          <el-col :span="16" class="content-right">
            <div style="font-size:.8rem;line-height:2rem;float: left;">
              <el-upload
                  action
                  :on-change="handleChange"
                  :on-remove="handleRemove"
                  :on-exceed="handleExceed"
                  accept=".xls, .xlsx"
                  :file-list="fileList"
                  :limit="limitUpload"
                  :auto-upload="false"
              >
                <el-button type="primary" round size="mini" class="input-button">导入IMEI号</el-button>
                <span style="color: red;margin-left: 0.5rem;">*</span>请导入xls/xlsx格式文件，文件内容设置为文本格式
              </el-upload>
            </div>
            <el-button type="primary" style="display:inline-block; float: right;" round size="mini" class="input-button"
                       @click="queryTestResult()">查询
            </el-button>
          </el-col>
        </el-row>

        <el-row class="content-row">
          <el-col :span="4">
            <div class="content-label">
              <span id="test-success">注册成功:</span><br>
              <span id="test-success"   v-if="successCount != '' ">(共{{successCount}}个)</span>
            </div>
          </el-col>
          <el-col :span="16">
            <textarea class="input-height" disabled="disabled" v-model="successMac"></textarea>
          </el-col>
        </el-row>

        <el-row class="content-row">
          <el-col :span="4">
            <div class="content-label">
              <span id="test-failed">注册失败:</span><br>
                <span id="test-success"  v-if="failCount != '' ">(共{{failCount}}个)</span>
            </div>
          </el-col>
          <el-col :span="16">
            <textarea class="input-height" disabled="disabled" v-model="failedMac"></textarea>
          </el-col>
        </el-row>
       <el-row class="content-row">
          <el-col :span="4">
            <div class="content-label">
              <span id="test-success">未注册:</span><br>
                <span id="test-success"  v-if="unRegisterCount != '' ">(共{{unRegisterCount}}个)</span>
            </div>
          </el-col>
          <el-col :span="16">
            <textarea class="input-height" disabled="disabled" v-model="unRegisterStr"></textarea>
          </el-col>
        </el-row>
        <el-row class="content-row">
          <el-col :span="20" style="margin-left: 1rem;">
            <el-button type="primary" style="float: right;" round size="mini" class="input-button" @click="export2Excel()">导出查询结果</el-button>
          </el-col>
        </el-row>

      </el-main>
    </el-container>
  </div>
</template>

<script>
import Storage from "../../../utils/Storage";

export default {
  name: "queryDeviceResult",
  data() {
    return {
      span: 4,
      limitUpload: 1,
      tableData: [],
      fileList: [],
      dialogVisible: false,
      productId: "",
      netOperatorId: "",
      successMac: "",
      failedMac: "",
      failCount: "",
      successCount: "",
      unRegisterCount: "",
      unRegisterStr: "",
      options: [],
      resultId: "",
      excelData: [{successMacs:"123",failedMacs:"456"}]
    };
  },
  methods: {
    commitReg() {
      let macs = this.tableData.toString().replace(/,/g, "-");
      macs.replace("[", "");
      macs.replace("]", "");
      this.$api
          .registDeivce({
            netOperatorId: this.netOperatorId,
            productId: this.productId,
            macStr: macs
          })
          .then(res => {
            if (res.data.code === "200") {
              this.$message({
                message: "正在注册，请稍后根据注册结果ID查看注册结果",
                type: "success"
              });
              this.resultId = res.data.data;
              Storage.setItem("registID", this.resultId);
              this.netOperatorId = "";
              this.productId = "";
            }
          })
          .catch(error => {
          });
    },
    handleChange(file, fileList) {
      this.fileTemp = file.raw;
      if (this.fileTemp) {
        this.importfxx(this.fileTemp);
      } else {
        this.$message({
          type: "warning",
          message: "请上传附件！"
        });
      }
    },
    //超出最大上传文件数量时的处理方法
    handleExceed(file, fileList) {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！请删除已上传文件后重新上传"
      });
      return;
    },
    //移除文件的操作方法
    handleRemove(file, fileList) {
      this.fileTemp = null;
      this.tableData = [];
    },
    importfxx(obj) {
      let _this = this;
      let inputDOM = this.$refs.inputer;
      // 通过DOM取文件数据
      this.file = event.currentTarget.files[0];
      var rABS = false; //是否将文件读取为二进制字符串
      var f = this.file;
      var reader = new FileReader();

      //if (!FileReader.prototype.readAsBinaryString) {
      FileReader.prototype.readAsBinaryString = function (f) {
        var binary = "";
        var rABS = false; //是否将文件读取为二进制字符串
        var pt = this;
        var wb; //读取完成的数据
        var outdata;
        var reader = new FileReader();
        reader.onload = function (e) {
          var bytes = new Uint8Array(reader.result);
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }

          //此处引入，用于解析excelnpm
          var XLSX = require("xlsx");

          if (rABS) {
            wb = XLSX.read(btoa(fixdata(binary)), {
              //手动转化
              type: "base64"
            });
          } else {
            wb = XLSX.read(binary, {
              type: "binary"
            });
          }
          outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
          //outdata就是读取的数据（不包含标题行即表头，表头会作为对象的下标）
          //此处可对数据进行处理
          let arr = [];
          let arr2 = [];
          outdata.map(v => {
            let mac = v["MAC"];
            if (mac !== "" && !arr.find(imei => imei === mac)) {
              arr.push(mac);
            }
          });
          _this.tableData = arr;
          _this.$message({
            type: "success",
            message: "导入成功，共导入" + arr.length + "个有效设备IMEI"
          });
          return arr;
        };
        reader.readAsArrayBuffer(f);
      };
      if (rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },
    queryTestResult() {
      console.log(this.tableData);
      console.log(macs);
      let macs = "";
      this.tableData.forEach((item) => {
        if (macs === "") {
          macs = item;
        } else {
          macs = macs + "-" + item;
        }

      });
      console.log(macs);
      this.$api
          .queryRegisterResult({
              macStr:macs
          })
          .then(res => {
            if (res.data.code === "200") {
               console.log(res.data.data);
              this.successMac = res.data.data.successStr;
              this.failedMac = res.data.data.failedStr;    
              this.unRegisterStr = res.data.data.unRegisterStr;    
              this.failCount = res.data.data.failCount;    
              this.successCount = res.data.data.successCount;    
              this.unRegisterCount = res.data.data.unRegisterCount;    
            }
          })
          .catch(error => {
          });
    },
    //触发按钮点击下载事件
    exportData() {
      this.export2Excel(); //调用export2Excel函数，填写表头（clomns里的type）和对应字段(historyList里的属性名)
    },
    //表格数据写入excel
      export2Excel() {

      let excelDatas = [];
      let _successMacs = this.successMac;
      let _failedMacs = this.failedMac;
      let _unRegisterStr = this.unRegisterStr;
      let successData = [];
      let failedData = [];
      let unRegisterData = [];
      if(_successMacs !== ""){
        successData = _successMacs.split("-");
      }

      if(_failedMacs !== ""){
        failedData = _failedMacs.split("-");
      }
       if(_unRegisterStr !== ""){
        unRegisterData = _unRegisterStr.split("-");
      }
      let maxLen = successData.length > failedData.length ? successData.length:failedData.length > unRegisterData.length ? failedData.length: unRegisterData.length  ;
      // let minLen = successData.length < failedData.length ? successData.length:failedData.length;
      for (let i=0; i<maxLen;i++){
        let curObj = {};
            curObj.successMacs = successData.length > i ?successData[i]:"";
            curObj.failedMacs = failedData.length > i ?failedData[i]:"";
            curObj.unRegisterStr = unRegisterData.length > i ?unRegisterData[i]:"";
            excelDatas.push(curObj);
      }

        var that = this;
        require.ensure([], () => {
          const {
            export_json_to_excel
          } = require("../../../vendor/excel/Export2Excel");
          const tHeader = ["注册成功", "注册失败","未注册"]; // 导出的excel表头名信息
          const filterVal = [
            "successMacs",
            "failedMacs",
            "unRegisterStr"
          ]; // 导出的excel表头字段名，需要导出表格字段名
          const data = that.formatJson(filterVal,excelDatas);
        //  
        console.log(export_json_to_excel)
          export_json_to_excel(tHeader, data, "设备注册查询"); // 导出的表格名称，根据需要自己命名
        });
      },
      //格式转换，直接复制即可,不需要修改什么
      formatJson(filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => v[j]));
      }
    },
    mounted() {
      this.resultId = Storage.getItem("registID", this.resultId);
    }
};
</script>

<style lang="less" scoped>
textarea {
  outline: none;
  border: 0;
  background-color: #fafafa;
  font-size: 0.8rem;
}

.query-btn {
  float: right;
}

.content-label {
  float: right;
  font-size: .8rem;
  line-height: 2rem;
  text-align: right;
  padding-left: 1rem;
}

.content-row {
  margin-top: 2rem;
}

.main-content {
  font-size: 0.8rem;
  line-height: 2rem;
}

.select {
  height: 46px;
  overflow: hidden;
  border-bottom: 1px solid #d9d9d9;
  padding: 0 3rem;

  .title {
    display: inline-block;
    line-height: 46px;
    font-weight: bold;
    font-size: 0.8rem;
  }
}

.content-right {
  margin-left: 1rem;
}

.input-height {
  height: 12rem;
  margin-top: 0.6rem;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 1rem;
  margin-left: 1rem;
}

</style>