<template>
  <div id="main-container" class="demo">
    <el-container>
      <el-header>
        <div id="nav" style="display: flex; background-color: #132230;">
          <div style=" height: 61px;width: 215px;">
            <div style="height: 61px;width: 61px;float: left;" @click="collapseChange" class="suofang">
              <span style="height: 61px;width: 61px;line-height: 61px;display: block;text-align: center;">
                <i v-if="!collapse" class="el-icon-s-fold" style="font-size: 22px;"></i>
                <i v-else class="el-icon-s-unfold" style="font-size: 22px;"></i>
              </span>
            </div>
            <div style="height: 61px;width: 139px;float: right;" @click="goHome" class="home-vv">
              <img src="../assets/bigScreen/logo_bird.png" class="logo_title" />
              <img src="../assets/circle.svg" style="height: 10px;width: 10px;margin: 15px;" />
              <img src="../assets/bigScreen/logo.png" style="width: 28px;margin-top: 16px;" />
            </div>
          </div>

          <div style="flex: 1;">
            <el-menu class="el-menu-demo" :default-active="activeIndex" mode="horizontal" background-color="#122230"
              text-color="#fff" active-text-color="#ffd04b">
              <el-menu-item v-for="(item,index) in menuList" :key="item.platformId" @click="changeMenu(index)"
                :index="index.toString()">
                <div>{{ item.platformName }}</div>
              </el-menu-item>

              <el-menu-item class="menu-item-right right-most-item" title="帮助中心" index="100000" @click="goUserHelp()">
                <i class="el-icon-question" style="font-size: 22px;"></i>
              </el-menu-item>
              <el-menu-item class="menu-item-right" @click="toSystemSetting()" title="系统设置" index="6">
                <i class="el-icon-s-tools" style="font-size: 22px;"></i>
              </el-menu-item>
              <el-submenu index="7" class="menu-item-right">
                <!-- <el-menu-item class="menu-item-right"
                index="7">-->
                <template slot="title">
                  <i class="el-icon-user-solid el-icon--right" style="font-size: 22px;"></i>
                </template>
                <el-menu-item index="7-1" style="width:150 /16rem" @click.native="goMyself">个人中心</el-menu-item>
                <el-menu-item index="7-2" style="width:150 /16rem" @click.native="logout">退出登录</el-menu-item>
              </el-submenu>
              <el-menu-item class="menu-item-right" @click="toSystemSetting2()" title="工单管理" index="7">
                <i class="el-icon-s-order" style="font-size: 22px;"></i>
              </el-menu-item>
              <!--              <el-menu-item class="menu-item-right" title="文字滚动" index="8">-->
              <!--                <div class="my-outbox">-->
              <!--                  <div class="my-inbox" ref="box">-->
              <!--                    <div class="my-list" v-for="(item, index) in sendVal" :key="index" ref="list">-->
              <!--                      <span class="my-uname">{{ item.detail }}</span>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </el-menu-item>-->

              <!--                <el-menu-item class="menu-item-right"  >-->

              <!--                </el-menu-item>-->

              <!--              <el-submenu index="8" class="menu-item-right">-->
              <!--                <template slot="title">-->
              <!--                  <el-badge :value="1344" class="item" style="position: relative;top: 5px;">-->
              <!--                    <i class="el-icon-message-solid"-->
              <!--                       style="font-size: 22px;position: relative;color: #909399;top: -15px;"></i>-->
              <!--                  </el-badge>-->
              <!--                </template>-->
              <!--                <div style="width: 300px;height: 500px;background-color:#909399;top: -5px">-->
              <!--                  <div class="tab-nav">-->
              <!--                    <div-->
              <!--                        class="tab-item left"-->
              <!--                        id="realTimeFireAlarm"-->
              <!--                        ref="realTimeFireAlarm"-->
              <!--                        @click="changeStyleAndView($event)"-->
              <!--                    >火警待处理列表-->
              <!--                    </div>-->
              <!--                    <div-->
              <!--                        class="tab-item"-->
              <!--                        id="todoFireAlarm"-->
              <!--                        ref="todoFireAlarm"-->
              <!--                        @click="changeStyleAndView($event)"-->
              <!--                    >故障待处理列表-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </el-submenu>-->

              <div class="menu-item-right"
                style="width:60px;height:61px;line-height: 61px;text-align: center;position: relative;cursor: pointer"
                @mouseover="mouseOver" @mouseleave="mouseLeave" :style="active">
                <el-badge :max="99" :value="messageNum" class="item" style="position: relative;top: 5px;">
                  <i class="el-icon-message-solid" style="font-size: 22px;position: relative;color: #909399;top: -25px;"
                    v-show="!disableMessage" @click="changeStatus"></i>

                  <!--                  <image src="../assets/message_disable.png" style="top: -25px;width: 30px;height: 30px"></image>-->
                  <img src="../assets/message_disable.png" style="width: 21px;height: 21px;margin-bottom: 22px"
                    v-show="disableMessage" @click="changeStatus" />
                </el-badge>

              </div>

              <!-- <audio class="audio" src="../../static/aduio/lingsheng.mp3" controls autoplay="autoplay" ></audio> -->
              <audio id="audio" preload="auto" loop>
                <source src="../../static/aduio/alarm.mp3" type="audio/ogg" />
              </audio>
              <!-- <button @click="play">daiji</button> + -->
            </el-menu>
          </div>
        </div>
      </el-header>

      <el-container>
        <el-aside width="auto" class="aside">
          <el-scrollbar style="height: 100%">
            <!-- <el-menu :default-active="activeIndex2"    @open="handleOpen"
      @close="handleClose" class="el-menu-vertical-demo" background-color="#122230"
              text-color="#fff" active-text-color="#ffd04b" :collapse="collapse" @select="selectMenu"
              :default-openeds="defaultOpeneds">

              <el-submenu   v-for="(item) in sideBar" :key="item.id" :index="item.router">
                <template slot="title">
                  <i :class="item.icon"  ></i>
                  <span>{{ item.name}}</span>
                </template>
                   
                <el-menu-item-group v-if="item.children != undefined">
                  <el-menu-item v-for="(child) in item.children" :key="child.id" :index="child.router">
                    <i :class="child.icon"></i>
                    {{ "\u3000" + child.name }}
                     <el-menu-item v-for="item2 in child.children" :index="item2.router" :key="item2.id">
            <i :class="item2.icon"></i><span slot="title">{{item2.name}}</span></el-menu-item>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </el-menu>  -->


            <el-menu  :default-active="activeIndex2"    @open="handleOpen"
      @close="handleClose" class="el-menu-vertical-demo" background-color="#122230"
              text-color="#fff" active-text-color="#ffd04b" :collapse="collapse" @select="selectMenu"
              :default-openeds="defaultOpeneds">

              <!-- 一级菜单 -->
              <template v-for="item in sideBar">
                <el-submenu v-if="item.children && item.children.length" :index="item.router" :key="item.id">
                  <template slot="title"><i :class="item.icon"></i><span>{{ "\u3000" +  item.name}}</span></template>

                  <!-- 二级菜单 -->
                  <template v-for="itemChild in item.children">
                    <el-submenu v-if="itemChild.children && itemChild.children.length" :index="itemChild.router"
                      :key="itemChild.id">
                      <template slot="title"><i :class="itemChild.icon"></i><span>{{  "\u3000" + itemChild.name}}</span></template>

                      <!-- 三级菜单 -->
                      <el-menu-item v-for="itemChild_Child in itemChild.children" :index="itemChild_Child.router"
                        :key="itemChild_Child.id">
                        <i :class="itemChild_Child.icon"></i><span slot="title">{{  "\u3000" + itemChild_Child.name}}</span>
                      </el-menu-item>
                    </el-submenu>

                    <el-menu-item v-else :index="itemChild.router" :key="itemChild.id"><i
                        :class="itemChild.icon"></i><span slot="title">{{  "\u3000" + itemChild.name}}</span></el-menu-item>
                  </template>
                </el-submenu>

                <el-menu-item v-else :index="item.router" :key="item.id"><i :class="item.icon"></i><span
                    slot="title">{{item.name}}</span></el-menu-item>
              </template>

            </el-menu>


          </el-scrollbar>
        </el-aside>
        <el-main>

          <el-scrollbar style="height: 100%">
            <!-- <el-row>
            <el-col :span="24">-->
            <div class="grid-content bg-purple-dark">
              <el-collapse-transition>
                <div v-if="showMessage" @mouseover="mouseOver2" @mouseleave="mouseLeave2"
                  style="position: absolute;width: 350px;background-color: #545c64;font-size: 0.8rem;right: 100px;z-index: 1000000;">
                  <div>
                    <div
                      style="width: 175px;line-height:40px;cursor: pointer;float: left;text-align: center;color: white;font-size: 0.9rem"
                      :class="{bg:isActive == 1}" @click="getFireList">火警待处理列表</div>
                    <div
                      style="width: 175px;line-height:40px;cursor: pointer;float: right;text-align: center;color: white;font-size: 0.9rem;"
                      :class="{bg:isActive == 0}" @click="getFaultList">故障待处理列表</div>
                    <div style="min-height: 120px">
                      <div v-show="this.faultOrFireList == 0" style="line-height: 120px;color:white;text-align: center">
                        <span>暂无待处理消息</span></div>
                      <router-link :to="{path:routePage,query:{type:2}}">
                        <div v-for="(item,index) in faultOrFireList" :key="index" :class="currentIdx==index?'itemBg':''"
                          style="cursor:pointer;overflow:hidden;padding: 10px;width: 330px;" @mouseenter="enter(index)"
                          @mouseleave="leave()" @click="closeMessageList()">
                          <div style="color:#fff;word-break: break-all; word-wrap: break-word;">
                            您在{{item.location}}{{item.placeName}}的设备{{item.deviceName}}发生{{item.dataPointShowName}}
                          </div>
                          <div style="color: #8d8d8d;">{{item.reportTime}}</div>
                        </div>
                      </router-link>
                    </div>

                    <router-link :to="{path:routePage,query:{type:2}}">
                      <div
                        style="height:30px;float: right;text-align: center;color: #8d8d8d;padding-right: 10px;cursor: pointer"
                        @click="closeMessageList()">查看全部</div>
                    </router-link>
                  </div>
                </div>
              </el-collapse-transition>
              <!-- <keep-alive>
                <router-view/>
              </keep-alive> -->
              <keep-alive :include="['DeviceList']">
                <router-view />
              </keep-alive>
              <div class="ipp" v-show="ifIp">
                <el-card class="box-card" shadow="never">
                  <div slot="header" class="clearfix">
                    <span>登录信息</span>
                    <el-button style="float: right; padding: 3px 0" type="text" @click="closeIp">关闭</el-button>
                  </div>
                  <!-- <div v-for="o in 4" :key="o" class="text item">{{'列表内容 ' + o }}</div> -->
                  <div class="text item">
                    <span class="ip-vv">上次登录IP：</span>
                    <span>{{ lastIp }}</span>
                  </div>
                  <div class="text item">
                    <span class="ip-vv">上次登录时间：</span>
                    <span>{{ lastTime }}</span>
                  </div>
                </el-card>
              </div>
            </div>
            <!-- </el-col>
            </el-row>-->
          </el-scrollbar>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
  import bus from "../components/bus";
  import Storage from "../utils/Storage";
  import {
    arrayToTree
  } from "../configs/index";
  import {
    mapActions
  } from "vuex";
  import base from "../api/base";

  export default {
    data() {
      return {
        request: false,
        routePage: "",
        active: "",
        collapse: false,
        disableMessage: false,
        menuList: [],
        sysList: [],
        ifShow: false,
        sideBar: [],
        sysMenuList: [],
        index: "",
        isActive: 0,
        isRouter: true,
        showMessage: false,
        leaveOne: true,
        leaveTwo: true,
        activeIndex: "0",
        activeIndex2: "",
        defaultOpeneds: [],
        defaultOpenedsArray: [],
        cloudList: [],
        ifIp: false,
        lastIp: "",
        lastTime: "",
        messageNum: "",
        heartCheck: null,
        timer: null,
        faultOrFireList: [],
        audio: null,
        currentIdx: null,
        startaudio: 0,
        statisticsName: '',
        nowTime: null, // 定时器标识
        disArr: [], // 每一个内容的宽度
        sendVal: [{
          detail: ""
        }]
      };
    },
    watch: {
      $route: {
        handler(val) {
          for (let i = 0; i < this.menuList.length; i++) {
            for (let j = 0; j < this.menuList[i].menuList.length; j++) {
              if (val.path == this.menuList[i].menuList[j].router) {
                if (this.activeIndex != i) {
                  this.changeMenu(i + "", true);
                }
                this.activeIndex2 = val.path;
                console.log('=-=-=--=')
                return;
              }
            }
          }
          for (let i = 0; i < this.sysList.length; i++) {
            for (let j = 0; j < this.sysList[i].menuList.length; j++) {
              if (val.path == this.sysList[i].menuList[j].router) {
                console.log(this.activeIndex);
                if (this.activeIndex === 6) {
                  console.log(this.activeIndex);
                  this.changeMenu("6", true);
                }
                if (this.activeIndex === 7) {
                  this.changeMenu("7", true);
                  console.log(this.activeIndex);
                }
                this.activeIndex2 = val.path;
                return;
              }
            }
          }
          // for (let j = 0; j < this.sysMenuList.length; j++) {
          //   if (val.path == this.sysMenuList[j].router) {
          //     if (this.activeIndex != "6") {
          //       this.changeMenu("6", true);
          //     }
          //     this.activeIndex2 = val.path;
          //     return;
          //   }
          // }
        }
      }
    },
    mounted() {
      bus.$on("refreshNum", msg => {
        if ((this.isActive = 0)) {
          this.getFireList();
        } else if ((this.isActive = 1)) {
          this.getFaultList();
        }
        this.getFireAndFaultNum();
      });
    },
    created() {
      this.$api
        .getMenuList()
        .then(res => {
          this.menuList = res.data.data.menuList;
          this.sysList = res.data.data.sysList;
          // this.ifShow = res.data.data.showSys;

          this.sideBar = Storage.toTree(res.data.data.menuList[0].menuList);
          //  this.statisticsName = res.data.data.menuList[0].platformName
          this.sysMenuList = res.data.data.sysMenuList;
          this.changeMenu(Storage.getItem("moduleIndex"), true);
          console.log(this.sideBar)
        })
        .catch(error => {
          console.log(error);
        });

      this.setLocalListActions({
        localList: base
      });
      this.userLimits();
      this.$api.getLastLogInfo().then(res => {
        //res.data.data==null是第一次登陆
        if (res.data.data) {
          this.lastIp = res.data.data.clientIp;
          sessionStorage.setItem("lastIp", res.data.data.clientIp);
          this.lastTime = res.data.data.loginTime;
          sessionStorage.setItem("ifNew", false);
        } else {
          sessionStorage.setItem("ifNew", true);
        }
      });
      // 长连接
      let that = this;
      this.heartCheck = {
        timeout: 10000,
        timeoutObj: null,
        serverTimeoutObj: null,
        start: function () {
          let self = this;
          this.timeoutObj && clearTimeout(this.timeoutObj);
          this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
          this.timeoutObj = setTimeout(function () {
            if (that.isDestory) {} else {
              that.websocketsend("p");
              console.log("p");
            }
            self.serverTimeoutObj = setTimeout(function () {
              that.websock.close();
            }, self.timeout);
          }, this.timeout);
        },
        reset: function () {
          clearTimeout(this.timeout);
          clearTimeout(this.serverTimeoutObj);
          this.start();
        }
      };

      this.initWebSocket();
      this.getFireList();
      this.getFireAndFaultNum();
    },
    methods: {
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
        if (key == '/appStatistics') {
          this.$router.push(key);
        }
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
        if (key == '/appStatistics') {
          this.$router.push(key);
        }
      },
      ...mapActions(["setLocalListActions"]),
      enter(idx) {
        this.currentIdx = idx;
      },
      leave() {
        this.currentIdx = null;
      },

      getFireList() {
        this.isActive = 0;
        this.$api
          .deviceFireList({})
          .then(res => {
            if (res.data.data.length > 0) {
              if (res.data.data.length > 5) {
                this.faultOrFireList = res.data.data.slice(0, 5);
              } else {
                this.faultOrFireList = res.data.data;
              }
            } else {
              this.faultOrFireList = [];
            }
            this.routePage = "/fireAlarmList";
          })
          .catch(error => {});
      },
      changeStatus() {
        this.disableMessage = !this.disableMessage;
      },
      getFireAndFaultNum() {
        this.$api
          .getFireAndFaultNum({})
          .then(res => {
            this.messageNum =
              res.data.data["fireCount"] + res.data.data["deviceFaultCount"];
            if (this.messageNum == 0) {
              this.messageNum = "";
            }
          })
          .catch(error => {});
      },
      getFaultList() {
        this.isActive = 1;
        this.$api
          .devicefaultList({})
          .then(res => {
            if (res.data.data.length > 0) {
              if (res.data.data.length > 5) {
                this.faultOrFireList = res.data.data.slice(0, 5);
              } else {
                this.faultOrFireList = res.data.data;
              }
            } else {
              this.faultOrFireList = [];
            }
            this.routePage = "/faultList";
          })
          .catch(error => {});
      },

      mouseOver: function () {
        if (this.showMessage == false) {
          this.active = "background-color: #545c64";
          this.showMessage = true;
          this.leaveOne = false;
        }
      },
      mouseLeave: function () {
        setTimeout(() => {
          if (this.leaveTwo) {
            this.active = "";
            this.showMessage = false;
          }
          this.leaveOne = true;
        }, 100);
      },
      mouseOver2: function () {
        this.leaveTwo = false;
      },
      mouseLeave2: function () {
        setTimeout(() => {
          if (this.leaveOne) {
            this.showMessage = false;
            this.active = "";
          }
        }, 100);
        this.leaveTwo = true;
      },
      logout() {
        this.$router.replace("/login");
        this.$api
          .logout()
          .then(res => {
            if (res.data.code === "200") {
              Storage.removeItem("token");
            }
          })
          .catch(err => {});
      },
      //回到主页
      goHome() {
        this.$router.push("/");
      },

      goUserHelp() {
        window.open(
          "https://bik09l3cf0.feishu.cn/docs/doccncnTsgXbJFHDvWeMQK4Lwsg",
          "_blank"
        );
      },
      toSystemSetting() {
        this.changeMenu("6", false);
      },
      toSystemSetting2() {
        this.changeMenu("7", false);
        console.log(this.activeIndex);
      },
      collapseChange() {
        this.collapse = !this.collapse;
        bus.$emit("collapse", this.collapse);
      },
      changeMenu(index, isCreate) {
        Storage.setItem("moduleIndex", index + "");
        this.activeIndex = index + "";
        console.log(index);
        if (index == "6") {
          this.statisticsName = ''
          this.sideBar = Storage.toTree(this.sysList[0].menuList);
          console.log(this.sideBar);
        } else if (index == "7") {
          // console.log(this.activeIndex)
          this.statisticsName = ''
          this.sideBar = Storage.toTree(this.sysList[1].menuList);
        } else {
          this.sideBar = Storage.toTree(this.menuList[index].menuList);

          console.log(this.sideBar);
          console.log(this.menuList[index].platformName);
          this.statisticsName = this.menuList[index].platformName

        }
        this.reset(isCreate);
      },
      selectMenu(index, indexPath) {
        console.log(index, indexPath);

        if (index == this.activeIndex2) {
          return;
        }
        this.$router.push(index);
        this.activeIndex2 = index;
      },
      reset(isCreate) {
        console.log(this.sideBar, isCreate)
        for (let i = 0, len = this.sideBar.length; i < len; i++) {
          this.defaultOpenedsArray[i] = this.sideBar[i].router;
        }
        this.defaultOpeneds = this.defaultOpenedsArray;
        if (this.sideBar[0].children != undefined) {
          this.activeIndex2 = this.sideBar[0].children[0].router
        } else {
          this.activeIndex2 = this.sideBar[0].router
        }

        if (isCreate) {
          if (this.$route.path != "/mainRouter") {
            this.activeIndex2 = this.$route.path;
          } else {
            if (this.sideBar[0].children != undefined) {
              //     if(this.sideBar[0].name == '商城管理'){
              // this.activeIndex2 ='/appStatistics'
              //     }else{
              this.activeIndex2 = this.sideBar[0].children[0].router
              // }
            } else {
              this.activeIndex2 = this.sideBar[0].router
            }
            // this.activeIndex2 = this.sideBar[0].children[0].router;
          }
        } else {
          if (this.sideBar[0].children != undefined) {
            //     if(this.sideBar[0].name == '商城管理'){
            // this.activeIndex2 ='/appStatistics'
            //     }else{
            this.activeIndex2 = this.sideBar[0].children[0].router
            // }

          } else {
            this.activeIndex2 = this.sideBar[0].router
          }
          // this.activeIndex2 = this.sideBar[0].children[0].router;
        }
        this.$router.push(this.activeIndex2);
        console.log(this.activeIndex2);
      },
      userLimits() {
        this.$api
          .userDetail()
          .then(res => {
            sessionStorage.setItem("accountLevel", res.data.data.accountLevel);
            sessionStorage.setItem("currentUserId", res.data.data.id);
            sessionStorage.setItem("projectName", res.data.data.projectName);

            for (let i = 0; i < res.data.data.permissions.length; i++) {
              if (res.data.data.permissions[i].permissionType == 2) {
                this.cloudList.push(res.data.data.permissions[i].uri);
              }
            }
            sessionStorage.setItem("cloudList", JSON.stringify(this.cloudList));
          })
          .catch(err => {
            console.log(err);
          });
      },
      //个人中心点击事件
      goMyself() {
        this.$router.push("/mySelf");
        this.activeIndex2 = "12345";
      },
      closeIp() {
        this.ifIp = false;
      },
      closeMessageList() {
        this.showMessage = false;
        if (this.$route.path == this.routePage) {
          bus.$emit("refresh", true);
        }
      },
      initWebSocket() {
        //初始化websocket
        const wsuri =
            "wss://jiayong360safe.cn/iotServer/socket/" + Storage.getItem("token");
        // const wsuri =
        //   "ws://47.105.137.41:9081/iotServer/socket/" + Storage.getItem("token");
        this.websock = new WebSocket(wsuri);
        this.websock.onmessage = this.websocketonmessage;
        this.websock.onopen = this.websocketonopen;
        this.websock.onerror = this.websocketonerror;
        this.websock.onclose = this.websocketclose;
      },
      websocketonopen() {
        //连接建立之后执行send方法发送数据
        this.heartCheck.start();
      },
      websocketonerror() {
        //连接建立失败重连
        this.reconnect();
      },
      websocketsend(Data) {
        //数据发送
        this.websock.send(Data);
      },
      websocketclose(e) {
        //关闭
        if (this.isDestory) {
          return;
        }
        this.reconnect();
      },
      reconnect() {
        if (this.lockReconnect) return;
        this.lockReconnect = true;
        let that = this;
        //没连接上会一直重连，设置延迟避免请求过多
        setTimeout(function () {
          that.initWebSocket();
          that.lockReconnect = false;
        }, 10000);
      },
      openMessage(message) {
        const h = this.$createElement;
        let bg = "";
        if (message.indexOf("消除") != -1) {
          bg = "custom-cancel-bg";
        } else if (message.indexOf("警") == -1 && message.indexOf("泄漏") == -1) {
          bg = "custom-fault-bg";
        } else {
          bg = "custom-fire-bg";
        }

        this.$notify({
          title: "通知",
          customClass: bg,
          message: h("i", {
            style: "color: teal"
          }, message),
          offset: 50,
          duration: 10000
        });
      },
      websocketonmessage(e) {
        //数据接收
        this.heartCheck.reset();
        const redata = JSON.parse(e.data);
        console.log(redata);
        if (redata.type == 3) {
          // this.sendVal= [{ detail:'',}]
          //  clearInterval(this.nowTime);
          // 清除定时器标识
          // this.nowTime = null;

          if (redata.body.status == 1) {
            // this.play()
            // this.sendVal.push({
            //    detail: redata.body.msgContent
            //  });
            //
            //  if (this.sendVal.length) {
            //    this.getData();
            //  }
            if (!this.disableMessage) {
              console.log(this.disableMessage);
              this.openMessage(redata.body.msgContent);
            }

            // if(this.$route.path == this.routePage){
            //   bus.$emit("refresh", true);
            // }
            if ((this.isActive = 0)) {
              this.getFireList();
            } else if ((this.isActive = 1)) {
              this.getFaultList();
            }
            this.getFireAndFaultNum();
          } else {
            if (!this.disableMessage) {
              this.openMessage(redata.body.msgContent);
            }

            //   console.log("解除警报");
            //     this.stop()
            //       this.sendVal= [{
            //     detail:'',
            // }]
          }
        }

        // console.log(redata.body.msgContent);
        //  this.handleSpeak(redata.body.msgContent)
        // this.handleSpeak(redata);
      },
      getData() {
        let that = this;
        this.$nextTick(() => {
          let item = that.$refs.list;
          let len = that.sendVal.length;
          let arr = [];
          var margin = that.getMargin(item[0]);
          for (var i = 0; i < len; i++) {
            arr.push(item[i].clientWidth + margin); // 把宽度和 margin 加起来就是每一个元素需要移动的距离
          }
          that.disArr = arr;
          that.moveLeft();
        });
      },
      // 获取margin属性
      getMargin(obj) {
        var marg = window.getComputedStyle(obj, null)["margin-right"];
        marg = marg.replace("px", "");
        return Number(marg); // 强制转化成数字
      },
      moveLeft() {
        var that = this;
        var outbox = this.$refs.box;
        // 初始位置
        var startDis = 0;
        this.nowTime = setInterval(function () {
          startDis -= 0.5;
          if (Math.abs(startDis) > Math.abs(that.disArr[0])) {
            that.disArr.push(that.disArr.shift());
            that.sendVal.push(that.sendVal.shift());
            startDis = 0;
          }
          outbox.style = `transform: translateX(${startDis}px)`;
        }, 1000 / 60);
      },

      handleSpeak(str) {},
      play() {
        this.lastRunTime = Date.now();
        let audio = document.querySelector("#audio");
        if (!this.isPlaying) {
          audio.play();
          this.isPlaying = true;
        }
        // let timeOut = setTimeout(() => {
        //   this.stop(timeOut);
        // }, 15000);
      },
      stop() {
        this.currentTime = Date.now();
        let audio = document.querySelector("#audio");
        if (this.currentTime - this.lastRunTime < 15000) {} else {
          if (this.isPlaying) {
            audio.currentTime = 0;
            audio.pause();
            this.isPlaying = false;
          }
        }
        // clearTimeout(timeOut);
      }
    },
    beforeDestroy() {
      // 清除计时器

      this.websock.close(); //离开路由之后断开websocket连接
      // 页面关闭清除定时器
      clearInterval(this.nowTime);
      // 清除定时器标识
      this.nowTime = null;

      this.isDestory = true;
      clearTimeout(this.timeout);
      clearTimeout(this.serverTimeoutObj);
      this.websock.close(); //离开路由之后断开websocket连接
      this.heartCheck = null;
    },

    //判断是否是登录页面而来，选择是否显示上次登录IP地址
    beforeRouteEnter(to, from, next) {
      if (from.path == "/login") {
        next(vm => {
          vm.ifIp = true;
        });
      }
      // 注意，在路由进入之前，组件实例还未渲染，所以无法获取this实例，只能通过vm来访问组件实例
      next(vm => {});
    }
  };
</script>
<style lang="less">
  .itemBg {
    background-color: #3f485b;
  }

  .custom-fault-bg {
    background-color: #e6a23c;
  }

  .custom-cancel-bg {
    background-color: #59c43a;
  }

  .el-notification__title {
    color: white;
  }

  .el-notification__content {
    i {
      color: white !important;
    }
  }

  .custom-fire-bg {
    background-color: #f56c6c;
  }

  .p-vv {
    position: absolute;
    top: 60px;
    left: -20px;
    width: 100px;
    height: 60px;
    background-color: red;
    z-index: 9999999;
  }

  .bg {
    background-color: rgb(18, 34, 48);
  }

  .item {
    margin-top: 5px;
    margin-right: 0px;
  }

  .suofang {
    &:hover {
      cursor: pointer;
      background-color: #545c64 !important;
    }
  }

  .home-vv {
    &:hover {
      cursor: pointer;
      background-color: #545c64 !important;
    }
  }

  .el-dropdown-link {
    color: #fff;
    cursor: pointer;
  }

  .iconsize {
    font-size: 23px;
  }

  .spName {
    color: #fff;
  }

  .logo_title {
    width: 32px;
    margin-top: 14px;
    margin-left: 20px;
  }

  .logo {
    background-image: url(../assets/bigScreen/logo_bird.png);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .el-dropdown-menu__item {
    text-align: center;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  #nav {
    padding-left: 0px;

    // min-width: 1200px;
    .el-menu-demo {
      font-weight: bold;
      color: #12b7f5;
      // z-index:9999999
    }
  }

  .demo::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }

  .demo {
    scrollbar-width: none;
    /* firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
    overflow-x: hidden;
    overflow-y: auto;
  }

  .el-menu.el-menu--horizontal {
    // border-bottom: none;
    border-bottom: solid 1px yellow;
  }

  .el-menu {
    border: 0;
  }

  #main-container {
    height: 100%;
  }

  html,
  body {
    margin: 0;
    height: 100%;
    font-family: Helvetica, Arial, Tahoma, "Microsoft YaHei", "黑体", SimSun;
    overflow: auto;
  }

  //页面主体部分
  #app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
  }

  //消除header内边距
  .el-header {
    padding: 0px;
    background: red;
    overflow: hidden;

    span {
      i {
        color: #dddddd;
      }
    }
  }

  //main区域撑满高度
  .el-main {
    background-color: #fafafa;
    color: #333;
    padding: 0px;
    line-height: 1.5rem;
    height: 100%;
    overflow: auto;

    .menu-btn {
      position: relative;
    }

    .el-row {
      line-height: 1.5rem;
      min-width: 1200px !important;

      .toolbar {
        margin: 0rem;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  //container 撑满页面
  .el-container {
    height: 100%;
  }

  //顶部导航栏右侧按钮样式
  .menu-item-right {
    float: right !important;
    height: 60px;
    font-size: 60px;
    text-align: center;
  }

  .my-outbox {
    overflow: hidden;
    color: #ffffff;
    // height: 35px;
    width: 10rem;
  }

  .my-inbox {
    white-space: nowrap;
  }

  .my-list {
    margin-right: 25px;
    display: inline-block;
    font-size: 0.3rem;
    height: 40px;
    line-height: 40px;
  }

  .my-uname {
    color: #ff8900;
  }

  .menu-item-right.right-most-item {
    margin-right: 1rem;
  }

  .menu_background {
    //background: 0 x392824 !important;
    font-size: 0.75rem;
    color: #dddddd !important;
  }

  .bg-purple-dark {
    width: 100%;
    height: 100%;
    // background-color: red;
  }

  .el-menu--horizontal {
    .el-menu-item {
      &:extend(.menu_background);

      &:hover {
        background: rgb(84, 92, 100) !important;
      }
    }
  }

  .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  .horizontal-collapse-transition {
    transition: 0s width ease-in-out, 0s padding-left ease-in-out,
      0s padding-right ease-in-out;
  }

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 215px;
    min-height: 400px;
  }

  .el-submenu__title:hover {
    background-color: #545c64 !important;
  }

  .el-menu-item:hover {
    background-color: #545c64 !important;
  }

  .el-menu--collapse .el-menu .el-submenu,
  .el-menu--popup {
    min-width: 120px !important;
  }

  .ipp {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 300 /16rem;
    height: 200 /16rem;
    z-index: 666;

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }

    .ip-vv {
      font-size: 12 /16rem;
      color: #898989;
    }

    .clearfix:before,
    .clearfix:after {
      display: table;
      content: "";
    }

    .clearfix:after {
      clear: both;
    }
  }

  .text-vv {
    background-color: #fafafa;

    .introjs-button {
      padding: 0.3rem 0.5rem;
    }
  }
</style>