<template>
    <div class="box" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)">

        <div id="container"></div>

    </div>
</template>

<script>
    import bus from "@/components/bus";
    export default {
        name: "heatmap",
        data() {
            return {
                map: null,
                loading: false,
                heatmap: null,
                heatmapData: []
            };
        },
        mounted() {
            // 延迟加载，防止出现AMap not defined
            setTimeout(() => {
                // this.initMap();
                // this.createHeatMap();
            }, 1000);
        },
        beforeDestroy() {
            this.map && this.map.destroy();
        },
        methods: {
            initMap() {
                this.map = new AMap.Map("container", {
                    resizeEnable: true,
                    center: [116.418261, 39.921984],
                    zoom: 11,
                    mapStyle: 'macaron', // 马卡龙(其他样式可在高德api中查找进行修改)
                    //自定义地图样式：https://lbs.amap.com/dev/mapstyle/index
                });
            },
            //判断浏览区是否支持canvas
            isSupportCanvas() {
                let elem = document.createElement("canvas");
                return !!(elem.getContext && elem.getContext("2d"));
            },
            createHeatMap() {
                if (!this.isSupportCanvas()) {
                    return this.$message({
                        message: '热力图仅对支持canvas的浏览器适用,您所使用的浏览器不能使用热力图功能,请换个浏览器试试。',
                        type: 'warning'
                    });
                }
                let __this = this;
                //                 this.$api.deviceRssiMap().then(res => {
                //     if (res.data.code == 200) {
                //    console.log(res.data.data)
                //    for (let index = 0; index < res.data.data.length; index++) {
                //        __this.heatmapData.push({
                //              "lng": res.data.data[index].longitude,
                //                 "lat":res.data.data[index].latitude,
                //                 "count": res.data.data[index].rssi
                //        })

                //    }
                //     }
                //   });


                this.map.plugin(["AMap.Heatmap"], function () {
                    //初始化heatmap对象
                    __this.heatmap = new AMap.Heatmap(__this.map, {
                        radius: 25, //给定半径
                        opacity: [0, 0.8],
                        // gradient: {
                        //     0.5: 'blue',
                        //     0.65: 'rgb(117,211,248)',
                        //     0.7: 'rgb(0, 255, 0)',
                        //     1: 'red',

                        // }
                    });

                    console.log('0000')
                    //设置数据集：该数据为北京部分“公园”数据
                    __this.heatmap.setDataSet({
                        data: __this.heatmapData,
                        max: 30
                    });
                });
                // this.loading = true
            }
        }
    };
</script>

<style scoped>
    /* @import url("https://a.amap.com/jsapi_demos/static/demo-center/css/demo-center.css"); */

    .box {
        width: 100%;
        height: 100vh;
        border-radius: 12px;
        background: #fff;
    }

    #container {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
    }
</style>