<template>
  <div class="outting">
    <div class="select">
      <span class="title">设备扫码</span>
    </div>
    <div class="code">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        size="mini"
        labelPosition="top"
        :inline="true"
        @submit.native.prevent
      >
        <el-form-item label="扫二维码" prop="input1" style="width:23%;">
          <el-input v-model="ruleForm.input1" ref="input1" @input="input1"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input2" style="width:23%;">
          <el-input v-model="ruleForm.input2" ref="input2" @input="input2"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input3" style="width:23%;">
          <el-input v-model="ruleForm.input3" ref="input3" @input="input3"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input4" style="width:23%;">
          <el-input v-model="ruleForm.input4" ref="input4" @input="input4"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input5" style="width:23%;">
          <el-input v-model="ruleForm.input5" ref="input5" @input="input5"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input6" style="width:23%;">
          <el-input v-model="ruleForm.input6" ref="input6" @input="input6"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input7" style="width:23%;">
          <el-input v-model="ruleForm.input7" ref="input7" @input="input7"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input8" style="width:23%;">
          <el-input v-model="ruleForm.input8" ref="input8" @input="input8"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input9" style="width:23%;">
          <el-input v-model="ruleForm.input9" ref="input9" @input="input9"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input10" style="width:23%;">
          <el-input v-model="ruleForm.input10" ref="input10" @input="input10"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input11" style="width:23%;">
          <el-input v-model="ruleForm.input11" ref="input11" @input="input11"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input12" style="width:23%;">
          <el-input v-model="ruleForm.input12" ref="input12" @input="input12"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input13" style="width:23%;">
          <el-input v-model="ruleForm.input13" ref="input13" @input="input13"></el-input>
        </el-form-item>

        <el-form-item label="扫二维码" prop="input14" style="width:23%;">
          <el-input v-model="ruleForm.input14" ref="input14" @input="input14"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input15" style="width:23%;">
          <el-input v-model="ruleForm.input15" ref="input15" @input="input15"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input16" style="width:23%;">
          <el-input v-model="ruleForm.input16" ref="input16" @input="input16"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input17" style="width:23%;">
          <el-input v-model="ruleForm.input17" ref="input17" @input="input17"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input18" style="width:23%;">
          <el-input v-model="ruleForm.input18" ref="input18" @input="input18"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input19" style="width:23%;">
          <el-input v-model="ruleForm.input19" ref="input19" @input="input19"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input20" style="width:23%;">
          <el-input v-model="ruleForm.input20" ref="input20" @input="input20"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input21" style="width:23%;">
          <el-input v-model="ruleForm.input21" ref="input21" @input="input21"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input22" style="width:23%;">
          <el-input v-model="ruleForm.input22" ref="input22" @input="input22"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input23" style="width:23%;">
          <el-input v-model="ruleForm.input23" ref="input23" @input="input23"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input24" style="width:23%;">
          <el-input v-model="ruleForm.input24" ref="input24" @input="input24"></el-input>
        </el-form-item>
        <el-form-item label="扫二维码" prop="input25" style="width:23%;">
          <el-input v-model="ruleForm.input25" ref="input25" @input="input25"></el-input>
        </el-form-item>
             <el-form-item label="扫二维码" prop="input26" style="width:23%;">
          <el-input v-model="ruleForm.input26" ref="input26" @input="input26"></el-input>
        </el-form-item>
             <el-form-item label="扫二维码" prop="input27" style="width:23%;">
          <el-input v-model="ruleForm.input27" ref="input27" @input="input27"></el-input>
        </el-form-item>
             <el-form-item label="扫二维码" prop="input28" style="width:23%;">
          <el-input v-model="ruleForm.input28" ref="input28" @input="input28"></el-input>
        </el-form-item>
             <el-form-item label="扫二维码" prop="input29" style="width:23%;">
          <el-input v-model="ruleForm.input29" ref="input29" @input="input29"></el-input>
        </el-form-item>
             <el-form-item label="扫二维码" prop="input30" style="width:23%;">
          <el-input v-model="ruleForm.input30" ref="input30" @input="input30"></el-input>
        </el-form-item>
             <el-form-item label="扫二维码" prop="input31" style="width:23%;">
          <el-input v-model="ruleForm.input31" ref="input31" @input="input31"></el-input>
        </el-form-item>
             <el-form-item label="扫二维码" prop="input32" style="width:23%;">
          <el-input v-model="ruleForm.input32" ref="input32" @input="input32"></el-input>
        </el-form-item>
        <el-form-item style="float:right;">
          <el-button
            type="primary"
            class="tiaozhuanBut"
            round
            size="small"
            @click="gotoTest('ruleForm')"

            >进行测试</el-button>
            <!-- <el-button
            type="primary"
            class="tiaozhuanBut"
            round
            size="small"
            icon="el-icon-loading"
             v-show="!keyi"
          ></el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <div>请输入要测试的设备！</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import bus from "../../components/bus";
export default {
  name: "SystemCode",
  data() {
    let checkMac1 = (rule, value, cb) => {
      const ifMac = /^86\d{13}$/;
      value = value.trim()
      if (value) {
        if (ifMac.test(value) == true) {
          // console.log(value)
          if(this.checkRepeat(cb,0,value)) {
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                cb();
                this.ifPass.ifPass1 = true;
                this.$refs.input1.blur();
                this.$refs.input2.focus();
              } else {
                this.ifPass.ifPass1 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }


        } else {
          this.ifPass.ifPass1 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass1 = true;
        // this.$refs.input1.blur();
        // this.$refs.input2.focus();
      }
    };
    let checkMac2 = (rule, value, cb) => {
      const ifMac = /^86\d{13}$/;
      value = value.trim()
      if (value) {
        if (ifMac.test(value) == true) {
            //  console.log(value)
          if(this.checkRepeat(cb,1,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass2 = true;
                cb();
                this.$refs.input2.blur();
                this.$refs.input3.focus();
                console.log("已注册")
              } else {
                this.ifPass.ifPass2 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass2 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        // this.$refs.input2.blur();
        // this.$refs.input3.focus();
        this.ifPass.ifPass2 = true;
      }
    };
    let checkMac3 = (rule, value, cb) => {
      const ifMac = /^86\d{13}$/;
      value = value.trim()
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,2,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass3 = true;
                cb();
                this.$refs.input3.blur();
                this.$refs.input4.focus();
                console.log("已注册")
              } else {
                this.ifPass.ifPass3 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass3 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        // this.$refs.input3.blur();
        // this.$refs.input4.focus();
        this.ifPass.ifPass3 = true;
      }
    };
    let checkMac4 = (rule, value, cb) => {
      const ifMac = /^86\d{13}$/;
      value = value.trim()
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,3,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass4 = true;
                cb();
                this.$refs.input4.blur();
                this.$refs.input5.focus();
              } else {
                this.ifPass.ifPass4 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass4 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass4 = true;
        // this.$refs.input4.blur();
        // this.$refs.input5.focus();
      }
    };
    let checkMac5 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,4,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass5 = true;
                cb();
                this.$refs.input5.blur();
                this.$refs.input6.focus();
              } else {
                this.ifPass.ifPass5 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass5 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass5 = true;
        // this.$refs.input5.blur();
        // this.$refs.input6.focus();
      }
    };
    let checkMac6 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,5,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass6 = true;
                cb();
                this.$refs.input6.blur();
                this.$refs.input7.focus();
              } else {
                this.ifPass.ifPass6 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass6 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass6 = true;
        // this.$refs.input6.blur();
        // this.$refs.input7.focus();
      }
    };
    let checkMac7 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,6,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass7 = true;
                cb();
                this.$refs.input7.blur();
                this.$refs.input8.focus();
              } else {
                this.ifPass.ifPass7 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass7 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass7 = true;
        // this.$refs.input7.blur();
        // this.$refs.input8.focus();
      }
    };
    let checkMac8 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,7,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass8 = true;
                cb();
                this.$refs.input8.blur();
                this.$refs.input9.focus();
              } else {
                this.ifPass.ifPass8 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass8 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass8 = true;
        // this.$refs.input8.blur();
        // this.$refs.input9.focus();
      }
    };
    let checkMac9 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,8,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass9 = true;
                cb();
                this.$refs.input9.blur();
                this.$refs.input10.focus();
              } else {
                this.ifPass.ifPass9 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass9 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass9 = true;
        // this.$refs.input9.blur();
        // this.$refs.input10.focus();
      }
    };
    let checkMac10 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,9,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass10 = true;
                cb();
                this.$refs.input10.blur();
                this.$refs.input11.focus();
              } else {
                this.ifPass.ifPass10 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass10 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass10 = true;
        // this.$refs.input10.blur();
        // this.$refs.input11.focus();
      }
    };
    let checkMac11 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,10,value)){
            this.$api.checkMacHandler(value).then(res => {



              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass11 = true;
                cb();
                this.$refs.input11.blur();
                this.$refs.input12.focus();
              } else {
                this.ifPass.ifPass11 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass11 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass11 = true;
        // this.$refs.input11.blur();
        // this.$refs.input12.focus();
      }
    };
    let checkMac12 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,11,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass12 = true;
                cb();
                this.$refs.input12.blur();
                this.$refs.input13.focus();
              } else {
                this.ifPass.ifPass12 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass12 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass12 = true;
        // this.$refs.input12.blur();
        // this.$refs.input13.focus();
      }
    };
    let checkMac13 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,12,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass13 = true;
                cb();
                this.$refs.input13.blur();
                this.$refs.input14.focus();
              } else {
                this.ifPass.ifPass13 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass13 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass13 = true;
        // this.$refs.input13.blur();
        // this.$refs.input14.focus();
      }
    };
    let checkMac14 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,13,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass14 = true;
                cb();
                this.$refs.input14.blur();
                this.$refs.input15.focus();
              } else {
                this.ifPass.ifPass14 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass14 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass14 = true;
        // this.$refs.input14.blur();
        // this.$refs.input15.focus();
      }
    };
    let checkMac15 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {

          if(this.checkRepeat(cb,14,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass15 = true;
                cb();
                this.$refs.input15.blur();
                this.$refs.input16.focus();
              } else {
                this.ifPass.ifPass15 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass15 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass15 = true;
        // this.$refs.input15.blur();
        // this.$refs.input16.focus();
      }
    };
    let checkMac16 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,15,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass16 = true;
                cb();
                this.$refs.input16.blur();
                this.$refs.input17.focus();
              } else {
                this.ifPass.ifPass16 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass16 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass16 = true;
        // this.$refs.input16.blur();
        // this.$refs.input17.focus();
      }
    };
    let checkMac17 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,16,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass17 = true;
                cb();
                this.$refs.input17.blur();
                this.$refs.input18.focus();
              } else {
                this.ifPass.ifPass17 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass17 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass17 = true;
        // this.$refs.input17.blur();
        // this.$refs.input18.focus();
      }
    };
    let checkMac18 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,17,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass18 = true;
                cb();
                this.$refs.input18.blur();
                this.$refs.input19.focus();
              } else {
                this.ifPass.ifPass18 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass18 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass18 = true;
        // this.$refs.input18.blur();
        // this.$refs.input19.focus();
      }
    };

    let checkMac19 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,18,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass19 = true;
                cb();
                this.$refs.input19.blur();
                this.$refs.input20.focus();
              } else {
                this.ifPass.ifPass19 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass19 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass19 = true;
        // this.$refs.input19.blur();
        // this.$refs.input20.focus();
      }
    };
    let checkMac20 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,19,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass20 = true;
                cb();
                this.$refs.input20.blur();
                this.$refs.input21.focus();
              } else {
                this.ifPass.ifPass20 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass20 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass20 = true;
        // this.$refs.input20.blur();
        // this.$refs.input21.focus();
      }
    };
    let checkMac21 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,20,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass21 = true;
                cb();
                this.$refs.input21.blur();
                this.$refs.input22.focus();
              } else {
                this.ifPass.ifPass21 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass21 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass21 = true;
        // this.$refs.input21.blur();
        // this.$refs.input22.focus();
      }
    };
    let checkMac22 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,21,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass22 = true;
                cb();
                this.$refs.input22.blur();
                this.$refs.input23.focus();
              } else {
                this.ifPass.ifPass22 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass22 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass22 = true;
        // this.$refs.input22.blur();
        // this.$refs.input23.focus();
      }
    };
    let checkMac23 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,22,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass23 = true;
                cb();
                this.$refs.input23.blur();
                this.$refs.input24.focus();
              } else {
                this.ifPass.ifPass23 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass23 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass23 = true;
        // this.$refs.input23.blur();
        // this.$refs.input24.focus();
      }
    };
    let checkMac24 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,23,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass24 = true;
                cb();
                this.$refs.input24.blur();
                this.$refs.input25.focus();
              } else {
                this.ifPass.ifPass24 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass24 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass24 = true;
        // this.$refs.input24.blur();
        // this.$refs.input25.focus();
      }
    };
    let checkMac25 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,24,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass25 = true;
                cb();
                this.$refs.input25.blur();
                 this.$refs.input26.focus();
              } else {
                this.ifPass.ifPass25 = false;
                cb(new Error("该设备未注册!"));
              }

            });
          }

        } else {
          this.ifPass.ifPass25 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass25 = true;
        // this.$refs.input25.blur();
      }
    };
        let checkMac26 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,25,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass26 = true;
                cb();
                this.$refs.input26.blur();
                this.$refs.input27.focus();
              } else {
                this.ifPass.ifPass26 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass26 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass26 = true;
      }
    };
      let checkMac27 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,26,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass27 = true;
                cb();
                this.$refs.input27.blur();
                this.$refs.input28.focus();
              } else {
                this.ifPass.ifPass27 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass27 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass27 = true;
      }
    };
        let checkMac28 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,27,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass28 = true;
                cb();
                this.$refs.input28.blur();
                this.$refs.input29.focus();
              } else {
                this.ifPass.ifPass28 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass28 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass28 = true;
      }
    };
 let checkMac29 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,28,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass29 = true;
                cb();
                this.$refs.input29.blur();
                this.$refs.input30.focus();
              } else {
                this.ifPass.ifPass29 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass29 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass29 = true;
      }
    };
     let checkMac30 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,29,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass30 = true;
                cb();
                this.$refs.input30.blur();
                this.$refs.input31.focus();
              } else {
                this.ifPass.ifPass29 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass30 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass30 = true;
      }
    };
     let checkMac31 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,30,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass31 = true;
                cb();
                this.$refs.input31.blur();
                this.$refs.input32.focus();
              } else {
                this.ifPass.ifPass29 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass31 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass31 = true;
      }
    };
     let checkMac32 = (rule, value, cb) => {
      value = value.trim()
      const ifMac = /^86\d{13}$/;
      if (value) {
        if (ifMac.test(value) == true) {
          if(this.checkRepeat(cb,31,value)){
            this.$api.checkMacHandler(value).then(res => {
              this.ifRegister = res.data.data;
              if (res.data.data == true) {
                this.ifPass.ifPass32 = true;
                cb();
                this.$refs.input32.blur();
                // this.$refs.input30.focus();
              } else {
                this.ifPass.ifPass32 = false;
                cb(new Error("该设备未注册!"));
              }
            });
          }

        } else {
          this.ifPass.ifPass32 = false;
          cb(new Error("请输入正确的MAC号！"));
        }
      } else {
        cb();
        this.ifPass.ifPass32 = true;
      }
    };
    return {

      ifJeep:false,
      ifTotalPass:true,
      ifPass: {
        ifPass1: true,
        ifPass2: true,
        ifPass3: true,
        ifPass4: true,
        ifPass5: true,
        ifPass6: true,
        ifPass7: true,
        ifPass8: true,
        ifPass9: true,
        ifPass10: true,
        ifPass11: true,
        ifPass12: true,
        ifPass13: true,
        ifPass14: true,
        ifPass15: true,
        ifPass16: true,
        ifPass17: true,
        ifPass18: true,
        ifPass19: true,
        ifPass20: true,
        ifPass21: true,
        ifPass22: true,
        ifPass23: true,
        ifPass24: true,
        ifPass25: true,
        ifPass26: true,
        ifPass27: true,
        ifPass28: true,
        ifPass29: true,
        ifPass30: true,
        ifPass31: true,
        ifPass32: true,
      },
      ifRegister: "",
      ruleForm: {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
        input7: "",
        input8: "",
        input9: "",
        input10: "",
        input11: "",
        input12: "",
        input13: "",
        input14: "",
        input15: "",
        input16: "",
        input17: "",
        input18: "",
        input19: "",
        input20: "",
        input21: "",
        input22: "",
        input23: "",
        input24: "",
        input25: "",
        input26: "",
        input27: "",
        input28: "",
        input29: "",
        input30: "",
        input31: "",
        input32: "",
      },
      rules: {
        input1: [
          // { required: true, message: "请输入要测试的设备！" },
          { validator: checkMac1, trigger: "change" }
        ],
        input2: [{ validator: checkMac2, trigger: "change" }],
        input3: [{ validator: checkMac3, trigger: "change" }],
        input4: [{ validator: checkMac4, trigger: "change" }],
        input5: [{ validator: checkMac5, trigger: "change" }],
        input6: [{ validator: checkMac6, trigger: "change" }],
        input7: [{ validator: checkMac7, trigger: "change" }],
        input8: [{ validator: checkMac8, trigger: "change" }],
        input9: [{ validator: checkMac9, trigger: "change" }],
        input10: [{ validator: checkMac10, trigger: "change" }],
        input11: [{ validator: checkMac11, trigger: "change" }],
        input12: [{ validator: checkMac12, trigger: "change" }],
        input13: [{ validator: checkMac13, trigger: "change" }],
        input14: [{ validator: checkMac14, trigger: "change" }],
        input15: [{ validator: checkMac15, trigger: "change" }],
        input16: [{ validator: checkMac16, trigger: "change" }],
        input17: [{ validator: checkMac17, trigger: "change" }],
        input18: [{ validator: checkMac18, trigger: "change" }],
        input19: [{ validator: checkMac19, trigger: "change" }],
        input20: [{ validator: checkMac20, trigger: "change" }],
        input21: [{ validator: checkMac21, trigger: "change" }],
        input22: [{ validator: checkMac22, trigger: "change" }],
        input23: [{ validator: checkMac23, trigger: "change" }],
        input24: [{ validator: checkMac24, trigger: "change" }],
        input25: [{ validator: checkMac25, trigger: "change" }],
        input26: [{ validator: checkMac26, trigger: "change" }],
        input27: [{ validator: checkMac27, trigger: "change" }],
        input28: [{ validator: checkMac28, trigger: "change" }],
        input29: [{ validator: checkMac29, trigger: "change" }],
        input30: [{ validator: checkMac30, trigger: "change" }],
        input31: [{ validator: checkMac31, trigger: "change" }],
        input32: [{ validator: checkMac32, trigger: "change" }],
      },

      ifTrue: true,
      noChuce: "",
      dialogVisible: false,
      //正则匹配是否符合
      ifMac: /^86\d{13}$/,
      productName: "",
      srt: "IMEI:",
      inputAll: {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
        input7: "",
        input8: "",
        input9: "",
        input10: "",
        input11: "",
        input12: "",
        input13: "",
        input14: "",
        input15: "",
        input16: "",
        input17: "",
        input18: "",
        input19: "",
        input20: "",
        input21: "",
        input22: "",
        input23: "",
        input24: "",
        input25: "",
        input26: "",
        input27: "",
        input28: "",
        input29: "",
        input30: "",
        input31: "",
        input32: "",
      },

      ifFocus1: true,
      checkPass: "",
      productNameOptions: [],
      netIdOptions: [
        {
          id: 1,
          name: "移动"
        },
        { id: 2, name: "电信" },
        { id: 3, name: "联通" }
      ]
    };
  },
  methods: {
    checkRepeat (cb,index,value){
      let arrValue = Object.values(this.ruleForm);
      arrValue.forEach(function(item, index) {
        arrValue[index] = item.trim();
      })
      arrValue.splice(index,1)
      console.log(  arrValue.splice(index,1))
      if(arrValue.indexOf(value) != -1){
        switch (index) {
          case 0: this.ifPass.ifPass1 = false;
              break;
          case 1: this.ifPass.ifPass2 = false;
              break;
          case 2: this.ifPass.ifPass3 = false;
            break;
          case 3: this.ifPass.ifPass4 = false;break;
          case 4: this.ifPass.ifPass5 = false;break;
          case 5: this.ifPass.ifPass6 = false;break;
          case 6: this.ifPass.ifPass7 = false;break;
          case 7: this.ifPass.ifPass8 = false;break;
          case 8: this.ifPass.ifPass9 = false;break;
          case 9: this.ifPass.ifPass10 = false;break;
          case 10: this.ifPass.ifPass11 = false;break;
          case 11: this.ifPass.ifPass12 = false;break;
          case 12: this.ifPass.ifPass13 = false;break;
          case 13: this.ifPass.ifPass14 = false;break;
          case 14: this.ifPass.ifPass15 = false;break;
          case 15: this.ifPass.ifPass16 = false;break;
          case 16: this.ifPass.ifPass17 = false;break;
          case 17: this.ifPass.ifPass18 = false;break;
          case 18: this.ifPass.ifPass19 = false;break;
          case 19: this.ifPass.ifPass20 = false;break;
          case 20: this.ifPass.ifPass21 = false;break;
          case 21: this.ifPass.ifPass22 = false;break;
          case 22: this.ifPass.ifPass23 = false;break;
          case 23: this.ifPass.ifPass24 = false;break;
          case 24: this.ifPass.ifPass25 = false;break;
          case 25: this.ifPass.ifPass26 = false;break;
          case 26: this.ifPass.ifPass27 = false;break;
          case 27: this.ifPass.ifPass28 = false;break;
          case 28: this.ifPass.ifPass29 = false;break;
          case 29: this.ifPass.ifPass30 = false;break;
          case 30: this.ifPass.ifPass31 = false;break;
          case 31: this.ifPass.ifPass32 = false;break;

        }
        // Vue.set(this.ifPass, name, value)
        cb(new Error("MAC号重复"));
        return  false;
      }else{
        return true;
      }
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    gotoTest(formName) {

      // this.$refs[formName].validate(valid => {
      //   if (valid) {

      //  this.keyi=false
       this.ifTotalPass = true;
      for(const [key, value] of Object.entries(this.ruleForm)){
        if(value!==""){
          this.ifJeep=true
        }
      }

      if(this.ifJeep){
      for (const [key, value] of Object.entries(this.ifPass)) {
          console.log(value)
          if (value == false) {
            this.ifTotalPass = false;
            this.ifJeep=false
          }
        }
        if (this.ifTotalPass) {

          let allIds = [];
          for (let i in this.ruleForm) {
            if (this.ruleForm[i]) {

              allIds.push(this.ruleForm[i].trim());
            }
          }
          this.ifJeep=false
          let obj = {};
          obj.macs = allIds;
          obj.testStatus = 0;
          sessionStorage.setItem("obj", JSON.stringify(obj));
          this.$router.push("/testDevice");
        }

      }else{
        this.dialogVisible=true
          this.ifJeep=false
      }


      // if (this.ifPass == true) {
      //   var allIds = [];
      //   for (var i in this.ruleForm) {
      //     if (this.ruleForm[i]) {
      //       allIds.push(this.ruleForm[i]);
      //     }
      //   }
      //   var obj = {};
      //   obj.macs = allIds;
      //   obj.testStatus = 0;
      //   sessionStorage.setItem("obj", JSON.stringify(obj));
      //   this.$router.push("/testDevice");
      // }
      // } else {
      //   return false;
      // }
      // });
    },
    refresh() {},
    //产品名称的改变函数
    changeProduct(id) {
      console.log(id);
      let obj = {};
      obj = this.productNameOptions.find(item => {
        return item.id === id;
      });
      this.productId = obj.id;
    },
    changeNetId(id) {
      this.netId = id;
    },
    //检测是否注册mac
    ifZhuce(mac) {
      this.$api.checkMacHandler(mac).then(res => {
        if (res.data.data == false) {
          return false;
        }
      });
    },

    changeInput25(val) {
      if (!this.ifMac.test(val) && val !== "") {
        this.$message.error("请输入正确格式的MAC号!");
      }
    },
    input1(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          console.log("触发");
          this.ruleForm.input1 = val.split("IMEI:")[1];
        }
      }
    },
    input2(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input2 = val.split("IMEI:")[1];
        }
      }
    },
    input3(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input3 = val.split("IMEI:")[1];
        }
      }
    },
    input4(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input4 = val.split("IMEI:")[1];
        }
      }
    },
    input5(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input5 = val.split("IMEI:")[1];
        }
      }
    },
    input6(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input6 = val.split("IMEI:")[1];
        }
      }
    },

    input7(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input7 = val.split("IMEI:")[1];
        }
      }
    },
    input8(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input8 = val.split("IMEI:")[1];
        }
      }
    },
    input9(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input9 = val.split("IMEI:")[1];
        }
      }
    },
    input10(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input10 = val.split("IMEI:")[1];
        }
      }
    },
    input11(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input11 = val.split("IMEI:")[1];
        }
      }
    },
    input12(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input12 = val.split("IMEI:")[1];
        }
      }
    },

    input13(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input13 = val.split("IMEI:")[1];
        }
      }
    },
    input14(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input14 = val.split("IMEI:")[1];
        }
      }
    },
    input15(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input15 = val.split("IMEI:")[1];
        }
      }
    },
    input16(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input16 = val.split("IMEI:")[1];
        }
      }
    },
    input17(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input17 = val.split("IMEI:")[1];
        }
      }
    },
    input18(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input18 = val.split("IMEI:")[1];
        }
      }
    },
    input19(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input19 = val.split("IMEI:")[1];
        }
      }
    },
    input20(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input20 = val.split("IMEI:")[1];
        }
      }
    },
    input21(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input21 = val.split("IMEI:")[1];
        }
      }
    },
    input22(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input22 = val.split("IMEI:")[1];
        }
      }
    },
    input23(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input23 = val.split("IMEI:")[1];
        }
      }
    },
    input24(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input24 = val.split("IMEI:")[1];
          //   if (!this.ifMac.test(this.inputAll.input24)) {
          //     // this.$message.error("请输入正确格式的MAC号!");
          //   } else {
          //     this.$api.checkMacHandler(this.inputAll.input24).then(res => {
          //       if (res.data.data == false) {
          //         this.noChuce = this.inputAll.input24;
          //         this.dialogVisible = true;
          //       } else {
          //         this.$refs.input24.blur();
          //         this.$refs.input25.focus();
          //       }
          //     });
          //   }
          // } else {
          //   if (!this.ifMac.test(val)) {
          //     this.$message.error("请输入正确格式的MAC号!");
          //   } else {
          //     this.$api.checkMacHandler(this.inputAll.input24).then(res => {
          //       if (res.data.data == false) {
          //         this.noChuce = this.inputAll.input24;
          //         this.dialogVisible = true;
          //       } else {
          //         this.$refs.input24.blur();
          //         this.$refs.input25.focus();
          //       }
          //     });
          //   }
        }
      }
    },
    input25(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input25 = val.split("IMEI:")[1];
        }
      }
    },
        input26(val) {
          console.log(val)
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input26 = val.split("IMEI:")[1];
        }
      }
    },
          input27(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input27 = val.split("IMEI:")[1];
        }
      }
    },
          input28(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input28 = val.split("IMEI:")[1];
        }
      }
    },
          input29(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input29 = val.split("IMEI:")[1];
        }
      }
    },
          input30(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input30 = val.split("IMEI:")[1];
        }
      }
    },
          input31(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input31 = val.split("IMEI:")[1];
        }
      }
    },
          input32(val) {
      if (val.length >= 15) {
        if (val.indexOf(":") != -1) {
          this.ruleForm.input32 = val.split("IMEI:")[1];
        }
      }
    },
  },

  created() {
    // this.$api
    //   .productListForPullUrl({
    //     page: {
    //       current: 1,
    //       size: 1000
    //     }
    //   })
    //   .then(res => {
    //     this.productNameOptions = res.data.data;
    //   })
    //   .catch(error => {});
  },
  mounted() {
    this.$refs.input1.focus();
  }
};
</script>
<style lang="less" scoped>
@toolBarFontColor: rgba(17, 17, 17, 0.87);
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;
.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  .select {
    height: 46px;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;
    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }
    .select-button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
      margin-left: 15 /16rem;
    }
  }
  .code {
    padding: 0rem 3rem;
    margin-top: 1rem;
    .tiaozhuanBut {
      float: right;
      margin-bottom: 5px;
    }
    .line-code {
      margin-top: 1rem;
      width: 100%;
      height: 90 /16rem;
      //   background-color: red;
      .line-code-vv {
        width: 18%;
        margin-right: 2.5%;
        height: 100%;
        // background-color: yellow;
        float: left;
        span {
          font-size: 12px;
          color: #898989;
          margin-top: 3px;
        }
        div {
          margin-top: 2px;
        }
      }
    }
  }
}
</style>
