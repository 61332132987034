<template>
  <div>

    <div class="iphoneF">
      <!-- <div class="iphoneF_top">
        <ul>
          <li >关于我们</li>
          <li @click="iphoneOurselves()">公司简介</li>
          <li @click="iphoneNews()">新闻资讯</li>
        </ul>
         <ul>
          <li>技术支持</li>
          <li >解决方案</li>

        </ul>
         <ul>
          <li @click="iphoneProduct()">了解产品</li>
          <li>应急手电</li>
          <li>可燃气体</li>
          <li style="letter-spacing:0.5em;">灭火器</li>
          <li style="letter-spacing:0.5em;">灭火毯</li>
          <li>烟<span>烟感</span>感</li>
          <li>控<span>烟感</span>烟</li>
        </ul>
         </div>
              <div class="iphoneF_middle">
        <ul>
          <li>友情链接</li>
          <li> <a href="http://www.jbufa.com/" >青鸟消防</a></li>
        </ul>
         <ul>
          <li>联系我们</li>
          <li>400-0692-119</li>
          <li>jiayong@jbufa.com</li>
          <li>北京市海淀区成府路207号</li>
        </ul>
       <div></div>
         </div> -->
      <!-- <div class="foot_logo">
           <div class="footerFive">
              <img src="../assets/app.png" alt="APP" />
              <h4>APP</h4>
            </div>

            <div class="footerFive">
              <img src="../assets/wensheng.jpg" alt="公众号" />
              <h4>公众号</h4>
            </div>
            <div class="footerFive">
              <img src="../assets/xiaochengxu.jpg" alt="小程序" />
              <h4>小程序</h4>
            </div>
         </div> -->
      <div class="foot_bottom">
        <div class="foot_box">
          <div>
            <!-- <img src="../assets/iphonelogo/Frame.png" alt="Frame" /> -->

            <div class="contact_us">
              联系电话：400-0692-119 <br>

              邮箱：jiayong@jbufa.com
            </div>
          </div>
          <div class="footerFive">
            <img src="../assets/wensheng.jpg" alt="公众号" />
            <h4>公众号</h4>
          </div>
        </div>
        <p>版权所有© 2021 北京青鸟美好生活科技有限公司</p>
        <p>京ICP备2021016382号-1</p>
        <div class="contact_us">

        </div>
      </div>
    </div>

  </div>

</template>
<script>
  export default {
    name: "FooterNav",
    mounted() {
      window.addEventListener('scroll', this.scrollToTop)
      const that = this
      let ispeed = Math.floor(-that.scrollTop / 5)
      document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
    },
    methods: {
      // 公司介绍
      iphoneOurselves() {

        this.$router.push({
          path: "/iphoneOurselves"
        });
      },
      // 新闻资讯
      iphoneNews() {
        this.$router.push({
          path: "/iphoneNews"
        });
      },
      //了解产品
      iphoneProduct() {

        this.$router.push({
          path: "/iphoneProduct"
        });
      },
      loginIn() {
        this.$router.push({
          path: "/login"
        });
      },
      //解决方案
      iphoneSolve() {
        this.$router.push({
          path: "/iphoneSolve"
        });
      },

      scrollToTop() {
        const that = this
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        that.scrollTop = scrollTop
        that.btnFlag = false
      },
    },
    destroyed() {
      window.removeEventListener('scroll', this.scrollToTop)
    },

  };
</script>
<style lang="less" scoped>
  .footer {
    display: none;

  }

  .iphoneF {
    width: 92%;
    // height: 181.8vw;
    display: block;
    background-color: #666666;
    padding: 0 4vw;

    .foot_bottom {
      width: 100%;
      // height: 19.6vw;
      margin: auto;

      padding-top: 6vw;
      .foot_box{
         display: flex;
         justify-content: space-between;
      } 

      .contact_us {
        color: #fff;
        padding:8vw 0 0 0;
        font-size: 3.6vw;
      }

      img {
        width: 19.6vw;
      }

      .footerFive {
        padding: 2vw 0vw 5vw 0;
        width: 20vw;
        // margin: auto;
        text-align: center;
        font-size: 3.7vw;
        color: #fff;

        img {
          width: 100%;
        }
      }

      p {
        text-align: center;
        color: #fff;
        font-size: 2.6vw;

      }
    }

    // .iphoneF_top{
    //   height: 46vw;
    //   padding: 6.1vw 0 8.8vw 0 ;
    //   margin:  auto;
    //   width: 86.4vw;
    //   // border: #ffffff 1px solid;
    //   color: #fff;
    //   display: flex;
    //   justify-content:space-between;
    //   ul{ 
    //     li{
    //         font-size: 3.7vw;
    //          padding-bottom: 1vw;
    //            span {
    //             color: #505050;
    //           }

    //     }
    //      li:nth-child(1) {
    //      font-size: 4vw;
    //       padding-bottom: 5.6vw;
    //    }
    //   } 
    // } 
    // .iphoneF_middle{
    //     height: 36vw;
    //   padding: 6.1vw 0 8.8vw 0 ;
    //   margin:  auto;
    //   width: 86.4vw;
    //   // border: #ffffff 1px solid;
    //   color: #fff;
    //   display: flex;
    //   justify-content:space-between;
    //    ul:nth-child(1){
    //      margin-right: 10vw;
    //    }
    //   ul{ 
    //     li{
    //         font-size: 3.7vw;
    //          padding-bottom: 1vw;
    //            span {
    //             color: #505050;
    //           }
    //              a{
    //                font-size: 3.7vw;
    //          padding-bottom: 1vw;
    //           color: #fff;
    //           }
    //     }
    //      li:nth-child(1) {
    //      font-size: 4vw;
    //       padding-bottom: 5.6vw;

    //    }
    //   } 
    // }
    // .foot_logo{
    //   margin: auto;
    //    width: 78.9vw;
    //    height: 20vw;
    //   //  background-color: #fff;
    //    display: flex;
    //    justify-content: space-between;
    //    text-align: center;
    //      color: #fff;
    //    .footerFive{
    //      width: 20vw;
    //      font-size: 3.7vw;
    //      img{
    //        width:100%;
    //      }
    //    }
    // } 

  }
</style>