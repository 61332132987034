<template>
  <div class="outting">

    <div class="select">
      <span class="title">个人中心</span>
    </div>
    <div class="middle">
      <el-card shadow="always" style="width:100%">
        <div>
          <el-divider content-position="left">账户详情</el-divider>
        </div>
        <div style="width:100%;display:flex;">
          <div style="flex:1">
            <div class="grid-content bg-purple-dark">
              <span class="name-vv">用户名</span>
              <span class="value-vv">{{username}}</span>
            </div>
          </div>
          <div style="flex:1">
            <div class="grid-content bg-purple-dark">
              <span class="name-vv">用户昵称</span>
              <span class="value-vv">{{nickName}}</span>
            </div>
          </div>
          <div style="flex:1">
            <div class="grid-content bg-purple-dark">
              <span class="name-vv">用户手机</span>
              <span class="value-vv">{{mobile}}</span>
            </div>
          </div>
          <div style="flex:1">
            <div class="grid-content bg-purple-dark">
              <span class="name-vv">账号深度</span>
              <span class="value-vv">{{accountDegree}}级</span>
            </div>
          </div>
        </div>

        <div style="width:100%;display:flex;margin-top:1rem;">
          <div style="flex:1;">
            <div class="grid-content bg-purple-dark">
              <span class="name-vv">账户等级</span>
              <span class="value-vv">{{accountLevelView}}</span>
            </div>
          </div>
          <div style="flex:1;">
            <div class="grid-content bg-purple-dark">
              <span class="name-vv">创建时间</span>
              <span class="value-vv">{{createTime}}</span>
            </div>
          </div>
          <div style="flex:1;">
            <div class="grid-content bg-purple-dark">
              <span class="name-vv">修改时间</span>
              <span class="value-vv">{{updateTime}}</span>
            </div>
          </div>
          <div style="flex:1;">
            <div class="grid-content bg-purple-dark">
              <span class="name-vv">所属角色</span>
              <span class="value-vv">{{roleName}}</span>
            </div>
          </div>
        </div>
        <div>
          <el-divider content-position="left">项目详情</el-divider>
        </div>

        <div style="width:100%;display:flex;">
          <div style="flex:1;">
            <div class="grid-content bg-purple-dark">
              <span class="name-vv">项目名称</span>
              <span class="value-vv">{{projectName}}</span>
            </div>
          </div>
          <div style="flex:1;">
            <div class="grid-content bg-purple-dark">
              <span class="name-vv">项目联系人</span>
              <span class="value-vv">{{projectContact}}</span>
            </div>
          </div>
          <div style="flex:1;">
            <div class="grid-content bg-purple-dark">
              <span class="name-vv">联系人手机</span>
              <span class="value-vv">{{projectMobile}}</span>
            </div>
          </div>
          <div style="flex:1;">
            <div class="grid-content bg-purple-dark">
              <span class="name-vv">创建人</span>
              <span class="value-vv">{{sysUserName}}</span>
            </div>
          </div>
        </div>

        <div style="width:100%;display:flex;margin-top:1rem;">
          <div style="flex:1;">
            <div class="grid-content bg-purple-dark">
              <span class="name-vv">所属地区</span>
              <span class="value-vv">{{province}}/{{area}}/{{city}}</span>
            </div>
          </div>
          <div style="flex:1;">
            <div class="grid-content bg-purple-dark">
              <span class="name-vv">详细地址</span>
              <span class="value-vv">{{address}}</span>
            </div>
          </div>
          <div style="flex:1;">
            <div class="grid-content bg-purple-dark">
              <span class="name-vv">全部设备数量</span>
              <span class="value-vv">{{deviceCount}}</span>
            </div>
          </div>
          <div style="flex:1;">
            <div class="grid-content bg-purple-dark">
              <span class="name-vv">当前账号设备</span>
              <span class="value-vv">{{deviceBelongCount}}</span>
            </div>
          </div>
        </div>
        <div style="width:100%;display:flex;margin-top:1rem;">
          <div style="flex:1;">
            <div class="grid-content bg-purple-dark">
              <span class="name-vv">报警方式</span>
              <span class="value-vv" v-show="ifYuyin">语音</span>
              <span class="value-vv" v-show="ifDuanxin">短信</span>
            </div>
          </div>
<!--          <div style="flex:1;">-->
<!--            <button @click="pay">充值</button>-->
<!--          </div>-->
          <div style="flex:1;">
          </div>
          <div style="flex:1;">
          </div>
        </div>
        <div>
          <el-divider content-position="left">绑定第三方账号</el-divider>
        </div>
        <div style="flex:1;">
          <div v-if="showBind">
            <span class="name-vv" style="vertical-align:middle">绑定微信</span>
            <img @click="get_wx_qrcode" class="value-vv" src="../../assets/icons/weixin-01.png" width="25" style="vertical-align:middle">
          </div>

          <div v-if="!showBind">
            <span class="name-vv" style="vertical-align:middle" >解绑微信</span>
            <el-button type="text" class="value-vv" @click="open">{{wxNickName}}</el-button>
<!--            <span class="value-vv"  @click="unBind"></span>-->
          </div>
        </div>
      </el-card>
    </div>
    <vue-qr :text="qrUrl"    :size="250"  ></vue-qr>
  </div>
</template>
<script>
import vueQr from 'vue-qr'
export default {
  name: "MySelf",
  components:{
    vueQr
  },
  data() {
    return {
      qrUrl:"",
      showBind:true,
      logoUrl:'../../assets/login/suo_default.png',
      username: "",
      nickName: "",
      mobile: "",
      wxNickName:"",
      accountDegree: "",
      accountLevelView: "",
      createTime: "",
      updateTime: "",
      roleName: "",
      projectName: "",
      projectContact: "",
      projectMobile: "",
      sysUserName: "",
      province: "",
      area: "",
      city: "",
      address: "",
      deviceCount: "",
      deviceBelongCount: "",
      ifYuyin: false,
      ifDuanxin: false
    };
  },
  methods: {
    unBind(){
      this.$api.wxUnBind().then(res => {
          this.getUserDetail();
      })
    },
    open() {
      this.$confirm('解绑后将无法使用该微信登录此账号，确认解绑吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.unBind()
      }).catch(() => {
      });
    },
    Get_wechat_callback_information() {
      let Wechat_return_code = window.location.href;
      //获取微信回调的code
      let wx_parseUrl = Wechat_return_code.split("=")[1];
      if(wx_parseUrl && wx_parseUrl != null) {
        let wx_code = wx_parseUrl.split('&')[0];
        if (wx_code && wx_code != null) {
          this.$api.wxBind({
            code: wx_code,
          }).then(res => {
            console.log(res)
            this.getUserDetail();
          })
          // let parse_bind_params = {
          //   code: wx_parseUrl.code,
          //   codeUuid: analysis_wx_code_url.codeUuid,
          //   codeValue: analysis_wx_code_url.codeValue,
          //   phone: analysis_wx_code_url.phone,
          // };
          // withdrawAccountBinding(parse_bind_params).then((response) => {
          //   this.$message({
          //     message: response.msg,
          //     type: "success",
          //     center: true,
          //     duration: "2000",
          //   });
          //   //绑定成功之后需要删除之前存储的验证码和验证uuid.这样方便在导航守卫里边做操作
          //   localStorage.removeItem("QRCODEWX");
          //   //当你绑定成功之后需要删除url后边的微信回调的code，如果不做处理那就在路由守卫里边一直进入这个页面，然后陷入无限的死循环。
          //   if (Wechat_return_code.indexOf("?") != -1) {
          //     Wechat_return_code = Wechat_return_code.replace(/(\?|#)[^'"]*/, "");
          //     window.history.pushState({}, 0, Wechat_return_code);
          //   }
          //   //删除之后再次更新路由地址，以确保路由地址没有code。保证不会进入死循环
          //   this.$router.replace({
          //     path: "/agentManger/agentMoneyList",
          //   });
          // });
        }
      }
    },
    get_wx_qrcode(){
      let DDNS_resolution = encodeURIComponent("https://jiayong360safe.cn/mySelf");
      window.location.href =  "https://open.weixin.qq.com/connect/qrconnect?appid=wxb2611ef16e200cab&redirect_uri="+DDNS_resolution+"&response_type=code&scope=snsapi_login#wechat_redirect"
      window.open()
    },
    pay(){
      this.$api.getPayUrl(
          {
            total:1,
            orderType:0,
          }
      ).then(res => {
        this.qrUrl = res.data.data.codeUrl
      })
    },
    getUserDetail(){
      this.$api
          .userDetail()
          .then(res => {
            console.log(res.data.data);
            this.username = res.data.data.username;
            this.nickName = res.data.data.nickName;
            if(res.data.data.wxNickName != null){
              this.wxNickName = res.data.data.wxNickName + "(解绑)";
              this.showBind = false;
            }else {
              this.showBind = true;
            }

            this.mobile = res.data.data.mobile;
            this.accountDegree = res.data.data.accountDegree;
            this.accountLevelView = res.data.data.accountLevelView;
            this.createTime = res.data.data.createTime;
            this.updateTime = res.data.data.updateTime;
            this.projectName = res.data.data.projectName;
            this.roleName = res.data.data.roleName;
            this.projectContact = res.data.data.projectContact;
            this.projectMobile = res.data.data.projectMobile;
            this.sysUserName = res.data.data.sysUserName;
            this.province = res.data.data.province;
            this.area = res.data.data.area;
            this.city = res.data.data.city;
            this.address = res.data.data.address;
            this.deviceCount = res.data.data.deviceCount;
            this.deviceBelongCount = res.data.data.deviceBelongCount;
            if (res.data.data.isCallMobile == 1) {
              this.ifYuyin = true;
            } else {
              this.ifYuyin = false;
            }
            if (res.data.data.isSendMsg == 1) {
              this.ifDuanxin = true;
            } else {
              this.ifDuanxin = false;
            }
          })
          .catch(err => {
            console.log(err);
          });
    }
  },
  created() {
    this.Get_wechat_callback_information();
    this.getUserDetail()
  }
};
</script>
<style lang="less" scoped>
.outting {
  height: 100%;
  background-color: #fafafa;
  overflow: auto;

  .loading-vv {
    position: fixed;
    top: 45%;
    left: 49%;
    z-index: 666;
  }
  .icon-weixin{

    background: url("../../assets/icons/weixin-02.png");

  }
  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;
    line-height: 1.5rem;

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }
  }

  .middle {
    padding: 0 3rem;
    margin-top: 1rem;
    .el-row-item {
      float: left;
      width: 215px;
      height: 60px;
      background-color: red;
      margin-right: 7%;
      .el-row-item-span {
        display: inline-block;
        color: #898989;
        font-size: 1rem;
        font-weight: normal;
      }
    }
  }
  //layout布局css
  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  // .bg-purple-dark {
  //   background: #99a9bf;
  // }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
}
.name-vv {
  font-size: 0.7rem;
  color: #898989;
}
.value-vv {
  display: inline-block;
  margin-left: 1rem;
  font-size: 0.8rem;
}
</style>
