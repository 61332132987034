<template>
  <div class="mainContainer">
    <div style="width:100%;margin-left:1rem;overfloat：hidden;">
      <el-tooltip class="item" effect="dark" content="火警" placement="top">
        <i class="el-icon-s-opportunity" style="color:#F56C6C;margin-right:1rem;" v-show="alert"></i>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" content="故障" placement="top">
        <i class="el-icon-s-opportunity" style="color:#E6A23C;margin-right:1rem;" v-show="fault"></i>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" content="正常" placement="top">
        <i class="el-icon-s-opportunity" style="color:#67C23A;margin-right:1rem;" v-show="normal"></i>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" content="离线" placement="top">
        <i class="el-icon-s-opportunity" style="color:#909399;" v-show="offLine"></i>
      </el-tooltip>
    </div>
<!-- 电气火灾最新数据
 -->
    <el-card class="box-card" shadow="never" v-if="ifSunshDeviceData">
      <div slot="header" class="clearfix" v-intro-step="1" v-intro="'这一板块显示设备上报的最新数据'">
        <span>最新数据</span>
        <span style="display:inline-block;margin-left:20px;color:#898989">{{ newTime }}</span>
        <el-button style="float: right; padding: 3px 0" type="text" size="small" icon="el-icon-d-arrow-right"
          v-if="ifMore" @click="dialogVisible=true">查看更多数据</el-button>
      </div>
      <div class="none" v-if="ifData" style="margin-left:45%;color:#898989">暂无数据</div>

      <div style="width:100%;overflow:hidden;">
        <div style="width:16.66%;float:left;" v-for="(item,index) in newList" :key="index">
          <div class="grid-content bg-purple-dark">
            <div>
              <span class="new-span">数据点</span>
              <div class="new-vv">{{item.Label}}</div>
            </div>
            <div style="margin-top:20px">
              <span class="new-span">数据点值</span>
              <div class="new-vv">{{item.Display +item.DataUnit }}</div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
     <el-card class="box-card" shadow="never" v-else>
      <div slot="header" class="clearfix" v-intro-step="1" v-intro="'这一板块显示设备上报的最新数据'">
        <span>最新数据</span>
        <span style="display:inline-block;margin-left:20px;color:#898989">{{ newTime }}</span>
        <el-button style="float: right; padding: 3px 0" type="text" size="small" icon="el-icon-d-arrow-right"
          v-if="ifMore" @click="dialogVisible=true">查看更多数据</el-button>
      </div>
      <div class="none" v-if="ifData" style="margin-left:45%;color:#898989">暂无数据</div>

      <div style="width:100%;overflow:hidden;">
        <div style="width:16.66%;float:left;" v-for="(item,index) in newList" :key="index">
          <div class="grid-content bg-purple-dark">
            <div>
              <span class="new-span">数据点</span>
              <div class="new-vv">{{item.dataPointKey}}</div>
            </div>
            <div style="margin-top:20px">
              <span class="new-span">数据点值</span>
              <div class="new-vv">{{item.dataPointValue }}</div>
            </div>
          </div>
        </div>
      </div>
    </el-card>

<!-- 电气火灾设备设备信息 -->
<div v-if="ifSunshDeviceData">
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix" v-intro-step="2" v-intro="'这一板块显示设备的详细信息'">
        <span>设备信息</span>
      </div>
      <div style="display:flex;" >
        <div style="flex:1">
          <div class="info-item">
            <div>
              <span>设备IMEI</span>
            </div>
            <div class="info-vv">{{detailList.IMEI}}</div>
          </div>
        </div>
        <div style="flex:1">
          <div class="info-item">
            <div>
              <span>设备名称</span>
            </div>
            <div class="info-vv">{{detailList.MeterName}}</div>
          </div>
        </div>
        <div style="flex:1">
          <div class="info-item">
            <div>
              <span>产品型号</span>
            </div>
            <div class="info-vv">{{detailList.ModelCode}}</div>
          </div>
        </div>
        <div style="flex:1">
          <div class="info-item">
            <div>
              <span>产品名称</span>
            </div>
            <div class="info-vv">{{detailList.ModelName}}</div>
          </div>
        </div>
      </div>
      <div style="display:flex;"  >
        <div style="flex:1">
          <div class="info-item">
            <div>
              <span>运营商</span>
            </div>
            <div class="info-vv">{{ detailList.ISP }}</div>
          </div>
        </div>
        <div style="flex:1">
          <div class="info-item">
            <div>
              <span>设备ID</span>
            </div>
            <div class="info-vv">{{ detailList.MeterID }}</div>
          </div>
        </div>
        <div style="flex:1">
          <div class="info-item">
            <div>
              <span>终端用户ID</span>
            </div>
            <div class="info-vv">{{ detailList.CustomerID }}</div>
          </div>
        </div>
        <div style="flex:1">
          <div class="info-item">
            <div>
              <span>经销商ID</span>
            </div>
            <div class="info-vv">{{detailList.DealerID}}</div>
          </div>
        </div>
      </div>
    
    </el-card>
   
</div>
   

     
 <div v-else>
   <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix" v-intro-step="2" v-intro="'这一板块显示设备的详细信息'">
        <span>设备信息</span>
      </div>
      <div style="display:flex;" >
        <div style="flex:1">
          <div class="info-item">
            <div>
              <span>设备IMEI</span>
            </div>
            <div class="info-vv">{{detailList.mac}}</div>
          </div>
        </div>
        <div style="flex:1">
          <div class="info-item">
            <div>
              <span>设备名称</span>
            </div>
            <div class="info-vv">{{detailList.deviceName}}</div>
          </div>
        </div>
        <div style="flex:1">
          <div class="info-item">
            <div>
              <span>出厂时间</span>
            </div>
            <div class="info-vv">{{detailList.exFactoryTime}}</div>
          </div>
        </div>
        <div style="flex:1">
          <div class="info-item">
            <div>
              <span>产品名称</span>
            </div>
            <div class="info-vv">{{detailList.productName}}</div>
          </div>
        </div>
      </div>
      <div style="display:flex;"  >
        <div style="flex:1">
          <div class="info-item">
            <div>
              <span>最后上线时间</span>
            </div>
            <div class="info-vv">{{ detailList.lastOnlineTime }}</div>
          </div>
        </div>
        <div style="flex:1">
          <div class="info-item">
            <div>
              <span>安装日期</span>
            </div>
            <div class="info-vv">{{ detailList.installDate }}</div>
          </div>
        </div>
        <div style="flex:1">
          <div class="info-item">
            <div>
              <span>有效日期</span>
            </div>
            <div class="info-vv">{{ detailList.effectiveDate }}</div>
          </div>
        </div>
        <div style="flex:1">
          <div class="info-item">
            <div>
              <span>在线状态</span>
            </div>
            <div class="info-vv">{{detailList.onlineStatus}}</div>
          </div>
        </div>
      </div>
      <div style="display:flex;" >
        <div style="flex:1">
          <div class="info-item">
            <div>
              <span>场地地址</span>
            </div>
            <div class="info-vv">{{detailList.placeName}}</div>
          </div>
        </div>
        <div style="flex:1">
          <div class="info-item">
            <div>
              <span>房间名称</span>
            </div>
            <div class="info-vv">{{detailList.roomName}}</div>
          </div>
        </div>
        <div style="flex:1">
          <div class="info-item">
            <div>
              <span>紧急联系人</span>
            </div>
            <div class="info-vv">
              <div v-show="ifDataData">暂无数据</div>
              <div v-show="!ifDataData" style="width:35%;float:left;"
                v-for="(item,index) in detailList.emergencyContact" :key="index">{{item}}</div>
            </div>
          </div>
        </div>
        <div style="flex:1">
          <div class="info-item">
            <div>
              <span>二维码</span>
            </div>
            <div class="info-vv">
              <el-image style="width: 100px; height: 100px" :src="detailList.contentUrl" :fit="fit"
                referrerpolicy="no-referrer"></el-image>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix" v-intro-step="3" v-intro="'这一板块显示设备的实时状态'">
        <span>实时状态</span>
        <span style="display:inline-block;margin-left:20px;color:#898989">{{ statusNewTime }}</span>
      </div>
      <div class="none" v-if="ifStatus" style="margin-left:45%;color:#898989">暂无数据</div>
      <el-card shadow="never" v-if="ifShowStatus">
        <el-divider content-position="left">报警信息</el-divider>
        <div class="none" v-if="ifAlarm" style="margin-left:45%;color:#898989">暂无数据</div>
        <div style="width:100%; overflow:hidden;">
          <div style="width:25%;float:left" v-for="(item,index) in alarmList" :key="index">
            <div class="grid-content bg-purple-dark normalVv" style="margin-bottom:30px;">
              <div>
                <span class="new-span">数据点名称</span>
                <i class="el-icon-s-opportunity" style="color:#F56C6C;"
                  v-show="item.dataPointValue=='true'&&item.dataPointType==0"></i>
                <i class="el-icon-s-opportunity" style="color:#E6A23C;"
                  v-show="item.dataPointValue=='true'&&item.dataPointType==1"></i>
                <div class="new-vv">{{item.dataPointShowName}}</div>
              </div>
              <div style="margin-top:10px">
                <span class="new-span">数据点值</span>
                <div class="new-vv">{{item.dataPointValue}}</div>
              </div>
            </div>
          </div>
        </div>
        <el-divider content-position="left">故障信息</el-divider>
        <div class="none" v-if="ifFault" style="margin-left:45%;color:#898989">暂无数据</div>
        <div style="width:100%; overflow:hidden;">
          <div style="width:25%;float:left" v-for="(item,index) in faultList" :key="index">
            <div class="grid-content bg-purple-dark normalVv" style="margin-bottom:30px;">
              <div>
                <span class="new-span">数据点名称</span>
                <i class="el-icon-s-opportunity" style="color:#F56C6C;"
                  v-show="item.dataPointValue=='true'&&item.dataPointType==0"></i>
                <i class="el-icon-s-opportunity" style="color:#E6A23C;"
                  v-show="item.dataPointValue=='true'&&item.dataPointType==1"></i>
                <div class="new-vv">{{item.dataPointShowName}}</div>
              </div>
              <div style="margin-top:10px">
                <span class="new-span">数据点值</span>
                <div class="new-vv">{{item.dataPointValue}}</div>
              </div>
            </div>
          </div>
        </div>
        <el-divider content-position="left">其他信息</el-divider>
        <div class="none" v-if="ifNormal" style="margin-left:45%;color:#898989">暂无数据</div>
        <div style="width:100%; overflow:hidden;">
          <div style="width:25%;float:left" v-for="(item,index) in normolList" :key="index">
            <div class="grid-content bg-purple-dark normalVv" style="margin-bottom:30px;">
              <div>
                <span class="new-span">数据点名称</span>
                <div class="new-vv">{{item.dataPointShowName}}</div>
              </div>
              <div style="margin-top:10px">
                <span class="new-span">数据点值</span>
                <div class="new-vv">{{item.dataPointValue}}</div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </el-card>
 </div>
  

    <el-dialog :title="title" :visible.sync="dialogVisible" :lock-scroll="false"  v-if="ifSunshDeviceData">
      <div style="display:flex; justify-content:flex-start;flex-wrap: wrap;">
        <div style="margin-right:5%;margin-bottom:5%;width:20%;	" v-for="(item,index) in totalNewList" :key="index">
          <div style="margin-top:10px">
            <span class="new-span">数据点11111名称</span>
            <div class="new-vv" style="font-size:12px">{{item.Label}}</div>
          </div>
          <div style="margin-top:10px">
            <span class="new-span">数据点值</span>
            <div class="new-vv" style="font-size:12px">{{item.Display +item.DataUnit}}</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :title="title" :visible.sync="dialogVisible" :lock-scroll="false" v-else>
      <div style="display:flex; justify-content:flex-start;flex-wrap: wrap;">
        <div style="margin-right:5%;margin-bottom:5%;width:20%;	" v-for="(item,index) in totalNewList" :key="index">
          <div style="margin-top:10px">
            <span class="new-span">数据点名称</span>
            <div class="new-vv" style="font-size:12px">{{item.dataPointKey}}</div>
          </div>
          <div style="margin-top:10px">
            <span class="new-span">数据点值</span>
            <div class="new-vv" style="font-size:12px">{{item.dataPointValue}}</div>
          </div>
        </div>
      </div>
    </el-dialog>
    
  </div>
</template>


<script>
  export default {
    name: "DeviceInfo",
    data() {
      return {
        ifSunshDeviceData:false,//是否是电气火灾详情
        offLine: false,
        fault: false,
        normal: false,
        alert: false,
        ifShowStatus: false,
        statusNum: "",
        status: "",
        colorBlue: false,
        colorRed: false,
        colorYellow: false,
        ifData: false,
        ifMore: false,
        dialogVisible: false,
        ifStatus: false,
        ifDataData: false,
        detailList: [],
        totalNewList: [],
        statusList: [],
        alarmList: [],
        faultList: [],
        normolList: [],
        fit: "fill",
        title: "",
        newTime: "",
        statusNewTime: "",
        ifAlarm: false,
        ifFault: false,
        ifNormal: false,
        // url:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        url: "https://jbufa-qrcode.oss-cn-qingdao.aliyuncs.com/qrCode/222_34567454.jpg",
        testList: [{
            name: "1",
            value: "1"
          },
          {
            name: "2",
            value: "2"
          },
          {
            name: "3",
            value: "3"
          },
          {
            name: "4",
            value: "4"
          },
          {
            name: "5",
            value: "5"
          },
          {
            name: "6",
            value: "6"
          },
          {
            name: "7",
            value: "7"
          }
          // { name: "8", value: "8" },
          // { name: "9", value: "9" }
        ],
        newList: []
      };
    },
    methods: {
      //新手教程函数
      guide() {
        this.$intro()
          .setOptions({
            prevLabel: "上一步",
            nextLabel: "下一步",
            skipLabel: "跳过",
            doneLabel: "结束",
            exitOnOverlayClick: false, //是否点击空白处退出
            showStepNumbers: true, //是否显示数字
            scrollToElement: true, //是否滑动到高亮的区域
            overlayOpacity: 0.7,
            tooltipClass: "text-vv",
            tooltipPosition: "top"
          })
          .oncomplete(function () {
            //点击跳过按钮后执行的事件
          })
          .onexit(function () {
            //点击结束按钮后， 执行的事件
          })
          .start();
      },
      displayHandler(arr, len) {
        var len = parseInt(len);
        if (len <= 1 || arr.length < len) return arr;
        var groups = [],
          loop = Math.ceil(arr.length / len);
        for (var i = 0; i < loop; i++)
          groups.push(arr.slice(len * i, len * (i + 1)));
        console.log(groups);
        return groups;
      }
    },
    created() {
      //最新数据上报  - 判定是不是电气火灾
      // console.log(sessionStorage.getItem("tabLabel"))
      var tabLabel = sessionStorage.getItem("tabLabel");
      if (tabLabel == '电气火灾') {
          this.ifSunshDeviceData = true
        var deviceName = sessionStorage.getItem("deviceName");
        this.$api.sunshDeviceData({
          page: {},
          query: {
            "deviceName": deviceName
          }
        }).then(res => {
          // console.log(res.data.data.records[0])
          // 电气火灾获取最新时间
          this.newTime = res.data.data.records[0].RealMains[0].TheTime.substring(0,res.data.data.records[0].RealMains[0].TheTime.length-4);

           
          if (res.data.data.records[0].RealMains[0].RealDetails <= 6 && res.data.data.records[0].RealMains[0].RealDetails.length > 0) {
            this.newList = res.data.data;
          } else if (res.data.data.records[0].RealMains[0].RealDetails.length == 0) {
            this.ifData = true;
          } else {
            this.newList = res.data.data.records[0].RealMains[0].RealDetails.splice(0, 6);
            this.ifMore = true;
          }
          this.totalNewList = res.data.data.records[0].RealMains[0].RealDetails;
          this.title = "全部数据" + "\xa0\xa0\xa0" + this.newTime;
// 电气火灾设备详情
            this.detailList = res.data.data.records[0];
        });


      } else {


       this.ifSunshDeviceData = false
        var deviceMac = sessionStorage.getItem("deviceMac");
        // console.log(deviceMac);
        this.$api.recentlyReportedData(deviceMac).then(res => {
          if (res.data.data.length !== 0) {
            this.newTime = res.data.data[0].reportingTime;
          }
          console.log(res.data.data)
          if (res.data.data.length <= 6 && res.data.data.length > 0) {
            this.newList = res.data.data;
            console.log(this.newList)
            
          } else if (res.data.data.length == 0) {
            this.ifData = true;
          } else {
            this.newList = res.data.data.splice(0, 6);
            this.ifMore = true;
          }
          this.totalNewList = res.data.data;
          this.title = "全部数据" + "\xa0\xa0\xa0" + this.newTime;
        });

        //设备详情
        var deviceId = Number(sessionStorage.getItem("deviceId"));
        // console.log(deviceId);
        this.$api.deviceDetail(deviceId).then(res => {
          this.detailList = res.data.data;
          // console.log(this.detailList.contentUrl);
          if (!this.detailList.mac) {
            this.detailList.mac = "暂无数据";
          }
          if (!this.detailList.deviceName) {
            this.detailList.deviceName = "暂无数据";
          }
          if (!this.detailList.exFactoryTime) {
            this.detailList.exFactoryTime = "暂无数据";
          }

          if (!this.detailList.productName) {
            this.detailList.productName = "暂无数据";
          }
          if (!this.detailList.lastOnlineTime) {
            this.detailList.lastOnlineTime = "暂无数据";
          }
          if (!this.detailList.installDate) {
            this.detailList.installDate = "暂无数据";
          }
          if (!this.detailList.effectiveDate) {
            this.detailList.effectiveDate = "暂无数据";
          }

          if (!this.detailList.placeName) {
            this.detailList.placeName = "暂无数据";
          }
          if (!this.detailList.roomName) {
            this.detailList.roomName = "暂无数据";
          }
          if (!this.detailList.emergencyContact) {
            this.detailList.emergencyContact = "暂无数据";
            this.ifDataData = true
          } else {
            this.detailList.emergencyContact = this.detailList.emergencyContact.split(",")
          }
          if (this.detailList.onlineStatus == 1) {
            this.detailList.onlineStatus = "在线";
          } else if (this.detailList.onlineStatus == 0) {
            this.detailList.onlineStatus = "离线";
          } else {
            this.detailList.onlineStatus = "未知";
          }
          console.log(this.detailList.emergencyContact)
        });
        //实时状态
        this.$api.deviceStatus(deviceId).then(res => {
          // console.log(res.data.data);
          if (res.data.data == null) {
            this.offLine = false;
            this.fault = false;
            this.normal = false;
            this.alert = false;
          } else {
            if (res.data.data.deviceStatus == null) {} else {
              for (var i = 0; i < res.data.data.deviceStatus.length; i++) {
                if (res.data.data.deviceStatus.indexOf(2) > -1) {
                  this.offLine = true;
                }
                if (res.data.data.deviceStatus.indexOf(6) > -1) {
                  this.fault = true;
                }
                if (res.data.data.deviceStatus.indexOf(8) > -1) {
                  this.normal = true;
                }
                if (res.data.data.deviceStatus.indexOf(9) > -1) {
                  this.alert = true;
                }
              }
            }

            if (res.data.data.deviceDataPointRealTimeDtoList !== null) {
              this.ifShowStatus = true;
              this.statusList = res.data.data.deviceDataPointRealTimeDtoList;
              for (var i = 0; i < this.statusList.length; i++) {
                if (this.statusList[i].dataPointType == 0) {
                  this.alarmList.push(this.statusList[i]);
                } else if (this.statusList[i].dataPointType == 1) {
                  this.faultList.push(this.statusList[i]);
                } else {
                  this.normolList.push(this.statusList[i]);
                }
              }
              if (this.alarmList.length == 0) {
                this.ifAlarm = true
              }
              if (this.faultList.length == 0) {
                this.ifFault = true
              }
              if (this.normolList.length == 0) {
                this.ifNormal = true
              }

              this.statusNewTime =
                res.data.data.deviceDataPointRealTimeDtoList[0].reportingTime;
            } else {
              this.ifStatus = true;
              this.ifShowStatus = false;
            }
          }
        });
      }



    },
    mounted() {
      if (sessionStorage.getItem("ifNew") == "true") {
        if (sessionStorage.getItem("ifDisplay1") !== "true") {
          this.guide();
        }

        sessionStorage.setItem("ifDisplay1", true);
      }
    }
  };
</script>
<style lang="less" scoped>
  .text-vv {
    background-color: #fafafa;

    .introjs-button {
      padding: 0.3rem 0.5rem;
    }
  }

  .mainContainer {
    padding: 1rem 2rem;

    .currrent-vv {
      text-align: center;
      margin-bottom: 1rem;

      .current-sp {
        font-weight: bold;
      }
    }

    .deviceInfoData {
      height: 500px;
    }

    .text {
      font-size: 12px;
    }

    .item {
      margin-bottom: 18px;
    }

    .clearfix:before,
    .clearfix:after {
      display: table;
      content: "";
    }

    .clearfix:after {
      clear: both;
    }

    .box-card {
      //width: 480px;
      background: #fcfcfc;
      margin-bottom: 1rem;
      border: none;

      //font-family: Helvetica,Arial,Tahoma,"Microsoft YaHei","黑体",SimSun;
      span {
        font-weight: bold;
        //   font-size: 1.35rem;
        font-size: 12px;
      }

      .info-item {
        width: 100%;

        span {
          color: #898989;
          font-size: 12px;
          font-weight: normal;
        }

        padding: 1rem 1rem 1rem 0;
        display: inline-block;
        line-height: 2rem;

        &.realtime-data {
          span {
            color: #12b7f5;
          }
        }

        .info-vv {
          font-size: 14 / 16rem;
        }
      }
    }
  }

  .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .new-span {
    font-size: 12 /16rem;
    color: #898989;
  }

  .newSpan {
    font-size: 12 /16rem;
    color: red;
  }

  .new-vv {
    font-size: 13 / 16rem;
    margin-top: 5 /16rem;
  }

  .newVv {
    font-size: 14 /16rem;
    margin-top: 5 /16rem;
    color: whitesmoke;
  }

  // .bg-purple-dark {
  //   // background: #99a9bf;
  //    box-shadow: -2px 1px 2px #a5a3a3;
  // }

  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }

  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }

  .customWidth {
    width: 80%;
  }

  .tanchu {
    position: fixed;
    top: 15%;
    left: 20%;
    width: 60%;
    height: 50%;
    background-color: #ffffff;
    z-index: 66;
    border: 1px solid #bebebe;
  }

  .el-dialog {
    width: 60%;
  }

  .colorBlue {
    color: #409eff;
  }

  .colorRed {
    color: #f56c6c;
  }

  .colorYellow {
    color: #e6a23c;
  }

  // .normalVv{
  //    box-shadow: -2px 1px 2px #a5a3a3;
  // }
  .redVv {
    background-color: #f56c6c;
    // opacity:0.5;
    // box-shadow:-2px 1px 2px #F56C6C;
  }

  .el-divider__text.is-left {
    font-size: 12 /16rem;
    color: #898989;
  }
</style>