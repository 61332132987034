<template>
  <div class="outting">
    <div class="select">
      <span class="title">测试列表</span>
      <!-- <el-button
        type="text"
        size="small"
        icon="el-icon-refresh"
        class="select-button"
        @click="refresh"
      >刷新</el-button>-->
    </div>
    <div class="table">
      <div style="float:right;margin-bottom:.5rem;">
        <el-button type="primary" round size="small" @click="confirmChoose" style="margin-right: 50px">批量通过</el-button>
<!--        <el-button type="primary" round size="small" @click="startTest"  >批量不通过</el-button>-->


        <el-button type="primary" round size="small" @click="startTest" v-show="!ifTest">开始测试</el-button>
        <el-button round size="small" @click="endTest" v-show="ifTest" type="danger">结束测试</el-button>
      </div>
      <el-table
        :data="tableData"
        style="width:100%"
        stripe
        size="small"
        :header-cell-style="{'background-color': '#eeeeee'}"
        :cell-style="cellStyle"
        @select="getSelected"
        ref="singleTable"
      >
        <el-table-column type="selection" width="50" align="center"></el-table-column>
        <el-table-column type="index" width="50" label="序号" align="center" fixed> </el-table-column>
        <el-table-column prop="mac" label="mac" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.mac || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="productName" label="产品名称" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.productName || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column
          prop="detectorLossFault"
          label="丟失"
          min-width="1"
          fixed
          align="center"
          :formatter="stateFormatDetectorLossFault"
        ></el-table-column>
        <el-table-column
          prop="selfChecking"
          label="自检"
          min-width="1"
          fixed
          align="center"
          :formatter="stateFormatSelfChecking"
        ></el-table-column>
        <el-table-column
          prop="fireAlarm"
          label="火警/燃气泄漏"
          min-width="1"
          fixed
          align="center"
          :formatter="stateFormatFireAlarm"
        ></el-table-column>
           <el-table-column
          prop="wirelessModuleElectricity"
          label="电池电量比"
          min-width="1"
          fixed
          align="center"
        :formatter="stateFormatLightAlarm"
        >
         </el-table-column>
        <el-table-column
            prop="rssi"
            label="信号强度"
            min-width="1"
            fixed
            align="center"
        >
          <template slot-scope="{row}">{{ row.rssi || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="statusTime" label="时间" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.statusTime || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column
          prop
          label="测试状态"
          min-width="1"
          fixed
          align="center"
          :formatter="stateFormatResult"
        ></el-table-column>
        <el-table-column prop="id" label="操作" min-width="2" fixed align="center">
          <template slot-scope="scope">
            <el-button type="primary" round size="mini" @click="ifHege(scope.$index, scope.row)">合格</el-button>
            <el-button round size="mini" @click="ifNotHege(scope.$index, scope.row)">不合格</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-table
        ref="singleTable"
        :data="tableData"
        highlight-current-row
        @current-change="handleCurrentChange"
        style="width: 100%"
      >
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="mac" label="mac" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.mac || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="productName" label="产品名称" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.productName || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column
          prop="detectorLossFault"
          label="丟失"
          min-width="1"
          fixed
          align="center"
          :formatter="stateFormatDetectorLossFault"
        ></el-table-column>
        <el-table-column
          prop="selfChecking"
          label="自检"
          min-width="1"
          fixed
          align="center"
          :formatter="stateFormatSelfChecking"
        ></el-table-column>
        <el-table-column
          prop="fireAlarm"
          label="火警/燃气泄漏"
          min-width="1"
          fixed
          align="center"
          :formatter="stateFormatFireAlarm"
        ></el-table-column>
        <el-table-column prop="statusTime" label="时间" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.statusTime || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column
          prop
          label="测试状态"
          min-width="1"
          fixed
          align="center"
          :formatter="stateFormatResult"
        ></el-table-column>
        <el-table-column prop="id" label="操作" min-width="2" fixed align="center">
          <template slot-scope="scope">
            <el-button type="primary" round size="mini" @click="ifHege(scope.$index, scope.row)">合格</el-button>
            <el-button round size="mini" @click="ifNotHege(scope.$index, scope.row)">不合格</el-button>
          </template>
        </el-table-column>
      </el-table> -->
    </div>
  </div>
</template>
<script>
import bus from "../../components/bus";
import Storage from "../../utils/Storage";
export default {
  name: "TestDevice",
  data() {
    return {
      ifTest: false,
      lockReconnect: false,
      timer: null,
      isDestory: false,
      tableData: [],
      //  tableData: [{
      //     date: '2016-05-02',
      //     name: '王小虎',
      //     address: '上海市普陀区金沙江路 1518 弄'
      //   }, {
      //     date: '2016-05-04',
      //     name: '王小虎',
      //     address: '上海市普陀区金沙江路 1517 弄'
      //   }, {
      //     date: '2016-05-01',
      //     name: '王小虎',
      //     address: '上海市普陀区金沙江路 1519 弄'
      //   }, {
      //     date: '2016-05-03',
      //     name: '王小虎',
      //     address: '上海市普陀区金沙江路 1516 弄'
      //   }],
      allIds: [],
      productId: "",
      netId: "",
      websock: null,
      ifshauxin: false,
      ifTestClick: false,
      obj: {}
    };
  },
  methods: {
    getSelected(selection, row){
      let macArr = [];
      for (let i = 0; i < selection.length; i++) {
        macArr.push(selection[i].mac);
      }
      let idArr = [];
      for (let i = 0; i < selection.length; i++) {
        idArr.push(selection[i].id);
      }
    },

    allPass(){
      let passMacList = [];
      for (let i = 0;i < this.tableData.length;i++){
        if(this.tableData[i].result == 2) {
          let passData = {
            mac:this.tableData[i].mac,
            message:JSON.stringify(this.tableData[i]),
            testTime:this.tableData[i].statusTime,
          }
          passMacList.push(passData);
        }
      }
      this.$api
          .ifPassHandler({
            deviceQcRecordDtos: passMacList,
            isPass: 1
          })
          .then(res => {
            if (res.data.code == 200) {
              console.log(res.data.message)
              for (let i in passMacList) {
                for(let j in this.tableData){
                  if (passMacList[i].mac == this.tableData[j].mac) {
                    this.tableData[j].result = 1;
                  }
                }
              }
            }
          });
    },
    confirmChoose(){
      this.$confirm('确认批量通过吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.allPass()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    cellStyle(row, column, rowIndex, columnIndex) {
      // if(row.column.label=="丟失"&&row.row.detectorLossFault=="false"){
      //    return "color:#F56C6C";
      // }
      // if(row.column.label=="丟失"&&row.row.detectorLossFault=="true"){
      //    return "color:#67C23A";
      // }
      if (row.column.label == "火警/燃气泄漏" && row.row.fireAlarm == "false") {
        return "color:#F56C6C";
      }
      if (row.column.label == "火警/燃气泄漏" && row.row.fireAlarm == "true") {
        return "color:#67C23A";
      }
      if (row.column.label == "自检" && row.row.selfChecking == "false") {
        return "color:#F56C6C";
      }
      if (row.column.label == "自检" && row.row.selfChecking == "true") {
        return "color:#67C23A";
      }
      if (row.column.label == "测试状态" && row.row.result == "1") {
        return "color:#67C23A";
      }
      if (row.column.label == "测试状态" && row.row.result == "0") {
        return "color:#F56C6C";
      }
      if (row.column.label == "丟失" && row.row.detectorLossFault == "false") {
        return "color:#F56C6C";
      }
      if (row.column.label == "丟失" && row.row.detectorLossFault == "true") {
        return "color:#67C23A";
      }
    },
    //故障渲染函数
    stateFormatDetectorLossFault(row) {
      if (row.detectorLossFault == "true") {
        return "是";
      } else if (row.detectorLossFault == "false") {
        return "否";
      } else {
        return "暂无数据";
      }
    },
    //自检渲染函数
    stateFormatSelfChecking(row) {
      if (row.selfChecking == "true") {
        return "是";
      } else if (row.selfChecking == "false") {
        return "否";
      } else {
        return "暂无数据";
      }
    },
    //火警渲染函数
    stateFormatFireAlarm(row) {
      if (row.fireAlarm == "true") {
        return "是";
      } else if (row.fireAlarm == "false") {
        return "否";
      } else {
        return "暂无数据";
      }
    },
      //电池电量比渲染函数
    stateFormatLightAlarm(row) {
      if (row.wirelessModuleElectricity) {
        return row.wirelessModuleElectricity + '%' ;
      }  else {
        return "暂无数据";
      }
    },
    //测试结果渲染函数
    stateFormatResult(row) {
      if (row.result == 0) {
        return "不通过";
      } else if (row.result == 1) {
        return "通过";
      } else {
        return "测试中";
      }
    },
    //合格请求函数
    ifHege(index, row) {
      this.$api
        .ifPassHandler({
          deviceQcRecordDtos: [
            {
              mac: row.mac,
              message: JSON.stringify(row),
              testTime: row.statusTime
            }
          ],
          isPass: 1
        })
        .then(res => {
          console.log(res.data);
          if (res.data.code == 200) {
            for (let i in this.tableData) {
              if (this.tableData[i].mac == row.mac) {
                this.tableData[i].result = 1;
              }
            }
          }
        });
    },
    //不合格请求函数
    ifNotHege(index, row) {
      this.$api
        .ifPassHandler({
          deviceQcRecordDtos: [
            {
              mac: row.mac,
              message: JSON.stringify(row),
              testTime: row.statusTime
            }
          ],
          isPass: 0
        })
        .then(res => {
          if (res.data.code == 200) {
            for (let i in this.tableData) {
              if (this.tableData[i].mac == row.mac) {
                this.tableData[i].result = 0;
              }
            }
          }
        });
    },
    startTest() {
      this.ifTestClick = true;
      sessionStorage.setItem("ifTestClick", true);
      this.ifTest = true;
      this.initWebSocket();
    },
    endTest() {
      this.ifTest = false;
      this.websocketclose();
    },
    //跳转页面请求函数
    firstQrcode() {
      this.$api
        .FirstQrcodeHandler({
          macs: this.allIds,
          netOperatorId: this.netId,
          productId: this.productId,
          testStatus: 0
        })
        .then(res => {
          console.log(res.data);
        });
    },
    //初始化websocket
    initWebSocket() {
      const wsuri =
        "wss://jiayong360safe.cn/iotServer/socket/" + Storage.getItem("token"); //正式
      // const wsuri =
      //   "ws://47.105.137.41:9081/iotServer/socket/" + Storage.getItem("token");
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonmessage(e) {
      this.heartCheck.reset();
      const res = JSON.parse(e.data);
      // console.log(res)
      if (res.type == 13) {
        for (let i in this.tableData) {
          if (this.tableData[i].mac == res.body.mac && this.tableData[i].result == 2) {
            this.tableData.splice(i, 1, res.body);
          }
        }

      }
    },
    websocketonopen() {
      //连接建立之后执行send方法发送数据
      this.heartCheck.start();
      // console.log('12121')
    },
    websocketonerror() {
      //连接建立失败重连
      this.reconnect();
    },
    websocketclose() {
      if (this.isDestory) {
        return; //已经关闭页面
      } else if (this.ifTest) {
        //还在测试中，重连websocket
        this.reconnect();
      } else {
        //点击结束测试，可以结束websocket连接
        clearTimeout(this.timeout);
        clearTimeout(this.serverTimeoutObj);
        this.websock.close(); //离开路由之后断开websocket连接
      }
    },
    websocketsend(Data) {
      //数据发送
      this.websock.send(Data);
    },
    //重连函数
    reconnect() {
      if (this.lockReconnect) return;
      this.lockReconnect = true;
      let that = this;
      //没连接上会一直重连，设置延迟避免请求过多
      setTimeout(function() {
        that.initWebSocket();
        that.lockReconnect = false;
      }, 10000);
    },
    //刷新token函数
    refreshToken() {
      let subDate = {
        grant_type: "refresh_token",
        client_id: "test-client",
        client_secret: "test-secret",
        refresh_token: Storage.getItem("refresh_token")
      };
      let subDateStr = qs.stringify(subDate);
      this.$api
        .getLogin(subDateStr)
        .then(res => {
          if (res.status === 200) {
            Storage.setItem("token", res.data.access_token);
            Storage.setItem("refresh_token", res.data.refresh_token);
            this.refreshTime = res.data.expires_in;
            let refreshTime = this.refreshTime / 3 - 10;
            console.log("刷新token成功");
            this.timer = setInterval(() => {
              this.refreshTokenTimer();
            }, refreshTime * 1000);
          }
        })
        .catch(error => {});
    },
    refreshTokenTimer() {
      let subDate = {
        grant_type: "refresh_token",
        client_id: "test-client",
        client_secret: "test-secret",
        refresh_token: Storage.getItem("refresh_token")
      };
      let subDateStr = qs.stringify(subDate);
      this.$api
        .getLogin(subDateStr)
        .then(res => {
          if (res.status === 200) {
            Storage.setItem("token", res.data.access_token);
            Storage.setItem("refresh_token", res.data.refresh_token);
            this.refreshTime = res.data.expires_in;
          }
        })
        .catch(error => {});
    }
  },
  //页面销毁之前，关闭定时器和webSocket
  beforeDestroy() {
    if (this.ifTest) {
      clearInterval(this.timer);
      this.timer = null;
      this.isDestory = true;
      clearTimeout(this.timeout);
      clearTimeout(this.serverTimeoutObj);
      if (this.websock) {
        this.websock.close(); //离开路由之后断开websocket连接
      }
      this.heartCheck = null;
      sessionStorage.setItem("ifTestClick", false);
    } else {
      return;
    }
  },
  created() {
    if (sessionStorage.getItem("obj")) {
      let macs = JSON.parse(sessionStorage.getItem("obj")).macs;
      this.obj = JSON.parse(sessionStorage.getItem("obj"));
    }

    if (sessionStorage.getItem("ifshuaxin") == "true") {
      if (sessionStorage.getItem("ifTestClick") == "true") {
        let obj = this.obj;
        obj.testStatus = 1;

        this.$api.refrashTestResultHandler(obj).then(res => {
          this.tableData = res.data.data;
        });
      } else {
        let obj = this.obj;
        obj.testStatus = 0;
        this.$api.refrashTestResultHandler(this.obj).then(res => {
          this.tableData = res.data.data;
        });
      }
    } else {
      if (sessionStorage.getItem("obj")) {
        let obj = JSON.parse(sessionStorage.getItem("obj"));
        this.$api.FirstQrcodeHandler(obj).then(res => {
          console.log(res.data.data);
          this.tableData = res.data.data;
        });
      }
    }

    //心跳控制
    let that = this;
    this.heartCheck = {
      timeout: 10000,
      timeoutObj: null,
      serverTimeoutObj: null,
      start: function() {
        let self = this;
        this.timeoutObj && clearTimeout(this.timeoutObj);
        this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
        this.timeoutObj = setTimeout(function() {
          if (that.isDestory) {
          } else {
            that.websocketsend("p");
            console.log("p");
          }
          self.serverTimeoutObj = setTimeout(function() {
            that.websock.close();
          }, self.timeout);
        }, this.timeout);
      },
      reset: function() {
        clearTimeout(this.timeout);
        clearTimeout(this.serverTimeoutObj);
        this.start();
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    console.log(from.path);
    if (from.path == "/") {
      sessionStorage.setItem("ifshuaxin", true);
      next(vm => {});
    } else {
      sessionStorage.setItem("ifshuaxin", false);
      next(vm => {});
    }

    next(vm => {});
  },
  beforeRouteLeave(to, from, next) {
    console.log(to, from);
    if (this.obj.macs) {
      if (this.obj.macs.length !== 0) {
        this.$confirm("离开本页面时本页面现有数据会清空，确定离开吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          sessionStorage.removeItem("obj");
          next();
        });
      }
    } else {
      sessionStorage.removeItem("obj");
      next();
    }
  }
};
</script>
<style lang="less" scoped>
@toolBarFontColor: rgba(17, 17, 17, 0.87);
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;
.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  .select {
    height: 46px;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;
    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }
    .select-button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
      margin-left: 15 /16rem;
    }
  }
  .table {
    padding: 0rem 3rem;
    margin-top: 1rem;
  }
}
</style>
