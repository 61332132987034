<template>
    <div class="iphoneOurselves">
        <img src="../../assets/gsjjbanner.jpg" />
        <div class="item-title">
            <p>公司简介</p>
            <span> </span>
        </div>
        <div class="yyzz_class">
             <img src="../../assets/yyzz.png" />
             <p>营业执照</p>
         </div>
          <div class="article" >
            青鸟消防股份有限公司（简称“公司”、“青鸟消防”）成立于2001年6月，是国内首家也是唯一一家以消防电子产品为主营业务的A股上市公司（证券简称：青鸟消防，证券代码：002960）。公司自成立以来始终聚焦于消防安全系统的研发、生产和销售，是国内规模最大、品种最全、技术实力最强的消防电子产品供应商。
        </div>
        <div class="article">
          2018年吻胜品牌诞生，是青鸟消防股份有限公司家用事业部品牌，专注于家用消防领域，后因市场增长需求于2021年正式注册“北京青鸟美好生活科技有限公司”，致力于成为可信赖的家用消防智能硬件服务商，为家居和类家居场景提供具有消防安全功能的物联网智能硬件。基于此，吻胜将不断迭代自有的产品、后台、APP、小程序和服务能力水平，为用户打造一个安全、智能的居住环境，享受科技带来的轻松、舒适和愉悦的生活。
        </div>
         <div class="article">
           吻胜构建“4+N”智能消防报警家居生态，以安全为核心，以吻胜云为中心，搭载包括智能烟感火灾探测报警器、智能燃气探测报警器、智能一氧化碳探测报警器、灭火器等在内的四大自研硬件，实现家居及类家居场景的全屋消防安全智能化，同时利用互联互通的吻胜云开放平台，与合作伙伴分享云平台服务能力，共同打造物联网云生态。
        </div>
        <div class="item-title">
            <p>品牌文化</p>
            <span> </span>
        </div>
        <div class="article">
          吻胜，吻指的就是鸱吻，胜指的是厌胜之物。鸱吻，为瑞兽龙之第九子。“海为鱼，虬尾似鸱，用以喷浪则降雨”，鸱吻喜爱在险要处东张西望，也喜欢吞火。鸱吻属厌胜之物，是古代在民俗信仰上的具体体现。由此吻胜代表的是灭火、防范的含义。从另外一个角度吻胜音同稳胜，寓指吻胜品牌定会一往无前，旗开得胜。
        </div>


    </div>
</template>

<script>
    export default {
        name: "iphoneOurselves",

        data() {
            return {

            };
        },
        methods: {

        }

    }
</script>
<style lang="less" scoped>


        .iphoneOurselves {
            margin-top: 14vw;
            width: 100%;
            background: #fff;

            img {
                width: 100%;
            }

          .yyzz_class{
               margin:4vw 6vw 0 2vw;
                width:51vw;
                 float: right;
                 text-align: center;
            //   display: flex;
            //   justify-content:space-between;
              img{
                  display: inline-block;
                  width:45.4vw;
                  height: 30vw;

              }

          }
            .item-title {
                padding-top: 11.6vw;
                padding-bottom: 9vw;
                text-align: center;
                color: #333333;
                width: 100%;
                letter-spacing: 1px;
                margin: 0 auto;
                font-size: 5.6vw;

                span {
                    width: 16.4vw;
                    margin: 0 auto;
                    height: 2px;
                    padding-top: 1px;
                    display: block;
                    background: #F1A948;
                }
            }

            .article {
                width: 89.4vw;
                text-indent: 2em;
                margin: 0 auto;
                font-size: 4.2vw;
                color: #333;
                 line-height: 1.7em;
                padding-bottom: 7.73vw;
            //   letter-spacing:0.3em;
                text-align-last:left;//最后一行左对齐
text-align:justify;
text-justify:distribute-all-lines; // 这行必加，兼容ie浏览器
                p {
                    height: 11.6vw;
                }
            }

        }



</style>
