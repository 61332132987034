<template>
    <div>
        <div class="outting">
            <div class="select">
                <span class="title">批量设备注册</span>
                <el-button type="text" size="small" icon="el-icon-back" @click="gobackPlaceDetail"
                    class="addRoleButton">返回注册列表</el-button>
            </div>
            <div class="detail">
                <el-card class="box-card" shadow="never">
                    <el-form :model="ruleForm" :rules="rules" :inline="true" ref="ruleForm" class="demo-ruleForm">
                        <el-form-item label="注册数量 " prop="registerCount ">
                            <el-input v-model.number="ruleForm.registerCount" size="mini"
                                style="width:10rem;display:inline-block" placeholder="请输入注册数量"></el-input>
                        </el-form-item>

                        <el-form-item label="产品名称" prop="productId ">
                            <el-select v-model="ruleForm.productId" placeholder="请选择产品名称" size="mini"
                                style="display:inline-block;width:10rem">
                                <el-option v-for="item in goodsList" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item style="margin-left:20px">
                            <el-button type="primary" size="mini" @click="submitForm('ruleForm')">立即注册</el-button>
                            <el-button size="mini" @click="resetForm('ruleForm')">重置</el-button>
                        </el-form-item>
                    </el-form>
                    <template>
                        <div v-if="tableDataList">
                            <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
                                <div class="result-info">
                                    共有
                                    <span>{{ total }}</span>个兑换码
                                    <el-button type="text" size="small" icon="el-icon-download" class="select-button"
                                        @click="goodsExport">导出
                                    </el-button>
                                </div>
                            </div>
                            <el-table :data="tableDataList" stripe size="small" class="product-table"
                                :header-cell-style="{'background-color': '#eeeeee'}">
                                <!-- <el-table-column type="selection" align="center" width="55"></el-table-column> -->
                                <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
                                <el-table-column prop="tencentProductId" label="腾讯平台产品id" align="center">
                                </el-table-column>
                                <el-table-column prop="deviceName" label="设备名称" align="center"></el-table-column>

                                <el-table-column prop="devicePsk" label="设备秘钥" align="center">
                                </el-table-column>
                                <el-table-column prop="productName" label="产品名称" align="center"></el-table-column>

                                <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
                            </el-table>
                            <el-pagination background :page-sizes="[10, 20]" :page-size="pageSize" :total="total"
                                layout="sizes,prev, pager, next" @size-change="handleSizeChange"
                                @current-change="handlePageChange">
                            </el-pagination>
                        </div>
                    </template>
                </el-card>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "wifiLotRegistDevice",
        data() {
            var checkAge = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('不能为空'))
                }
                setTimeout(() => {
                    // console.log('number', Number.isInteger(value))
                    if (!/(^[1-9]\d*$)/.test(value)) {
                        callback(new Error('请输入数字值'))
                    } else {
                        if (value > 10000) {
                            callback(new Error('不能超过10000'))
                        } else {
                            callback()
                        }
                    }
                }, 1000)
            }
            return {
                formLabelWidth: "120px",
                uri: "",
                timer: "",
                goodName: "",
                radio: '1',
                fixCodeDis: true,
                fileLDis: false,
                goodsList: [],
                tableData: [],
                tableDataList: [],
                detail: "",
                content: "",
                current: 1,
                pageSize: 10,
                total: 0,

                // 默认步骤数
                limitUpload: 5,
                limitUpload2: 1,
                fileList: [],
                fileMulLis: [],
                stepActive: "stepActive",

                dialogImageUrl: "",
                dialogVisible: false,
                value: "",
                file: {},
                dialogImageUrl2: "",
                dialogVisible2: false,
                value2: "",
                file2: {},
                batchId: "",


                ruleForm: {
                    productId: "",

                    registerCount: "",

                },
                rules: {


                    productId: [{
                        required: true,
                        message: '请选择商品名称',
                        trigger: 'change'
                    }],

                    registerCount: [{
                        required: true,
                        validator: checkAge,
                        trigger: 'change'
                    }, ],
                }
            };
        },
        created() {

        },
        mounted() {
            this.goodsListPull()
               this.$api
                                        .wifiRegisterList({
                                            page: {
                                                current: 1,
                                                size: 10
                                            },
                                            queryDto: {

                                            }
                                        })
                                        .then(res => {
                                            this.total = res.data.data.total;
                                            this.tableDataList = res.data.data
                                                .records;
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
        },
        methods: {
            change(val) {
                // console.log(val);
                this.ruleForm.newsContent = val;
            },
            goodsListPull() {
                this.$api.productListForPullUrl({
                    page: {
                        current: 1,
                        size: 1000
                    }
                }).then(res => {
                    // console.log(res.data.data)
                    this.goodsList = res.data.data

                });
            },

            handlePageChange(val) {
                this.current = val;
                this.nodeList();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.nodeList();
            },
            nodeList() {
                this.$api
                    .wifiRegisterList({
                        page: {
                            current: this.current,
                            size: this.pageSize
                        },
                        codeShowQueryVo: {
                            registerId: this.batchId
                        }
                    })
                    .then(res => {
                        // console.log(res.data.data.records)
                        this.total = res.data.data.total;
                        this.tableDataList = res.data.data.records;

                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            submitForm(ruleForm) {

                this.$refs[ruleForm].validate(valid => {

                    if (valid) {
                        this.$api.wifiRegistDevice(this.ruleForm).then(res => {
                            if (res.data.code == 200) {
                                this.batchId = res.data.data
                                this.$message.success("新增成功!");
                                this.$nextTick(() => {
                                    this.$api
                                        .wifiRegisterList({
                                            page: {
                                                current: 1,
                                                size: 10
                                            },
                                            queryDto: {
                                                registerId: res.data.data
                                            }
                                        })
                                        .then(res => {
                                            this.total = res.data.data.total;
                                            this.tableDataList = res.data.data
                                                .records;
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });

                                });
                            }
                        });
                    } else {
                        console.log("error submit!!");

                        return false;
                    }
                });






            },

            resetForm(ruleForm) {
                 this.$nextTick(() => {
                this. ruleForm= {
                    productId: "",

                    registerCount: "",

                }
                 })


            },
            //回到模块页面
            gobackPlaceDetail() {
                this.$router.push("/wifiDeviceRegisterList");
            },



            // 导出
            goodsExport() {
              let querylist = {}
              if(this.batchId){
                 querylist ={
                         registerId: this.batchId
                 }
              }
                this.$api
                    .exportDeviceRegister({
                        query: querylist,
                        properties: [
                            "tencentProductId",
                            "deviceName",
                            "devicePsk",
                            "productName",
                            "createTime",
                        ],
                        titles: [
                            "腾讯平台产品id",
                            "设备名称",
                            "设备秘钥",
                            "产品名称",
                            "创建时间",

                        ]
                    })
                    .then(res => {
                        console.log(res.data);
                        // for (let index = 0; index < this.goodsList.length; index++) {
                        //     if (this.ruleForm.productId == this.goodsList[index].id) {
                        //         this.goodName = this.goodsList[index].name
                        //     }
                        //     // console.log(this.goodName)
                        // }
                        this.download(res.data);
                    })
                    .catch(error => {});
            },
            //导出函数
            download(data) {


                if (!data) {
                    return;
                }
                let url = window.URL.createObjectURL(
                    new Blob([data], {
                        type: "application/octet-binary"
                    })
                );
                let link = document.createElement("a");
                link.style.display = "none";
                link.href = url;
                link.setAttribute("download", "excel.xls");
                // link.setAttribute("download", this.goodName + "excel.xls");

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            },

        },
        beforeDestroy() {
            //   clearTimeout(this.timer);
        }
    };
</script>
<style lang="less" scoped>
    @border-bottom-color: #e7eaed;
    @toolBarFontColor: #111d;

    /deep/.el-upload-list {
        display: inline-block;
    }

    .down_template {
        float: right;
        color: #898989;
        font-size: 0.8rem;
    }

    .outting {
        height: 100%;
        overflow: hidden;
        background-color: #fafafa;

        .item {
            height: 80px;
            width: 80px;
            border: 1px solid blue;
            float: left;
        }

        .select {
            height: 46px;
            overflow: hidden;
            border-bottom: 1px solid #d9d9d9;
            padding: 0rem 3rem;

            .title {
                display: inline-block;
                line-height: 46px;
                font-weight: bold;
                font-size: 0.8rem;
            }

            button {
                float: right;
                color: @toolBarFontColor;
                margin-top: 7px;
            }
        }

        .select-button {
            float: right;
            color: @toolBarFontColor;
            //   margin-top: 7px;
            margin-left: 15px;
        }

        .detail {
            padding: 0rem 3rem;
            margin-top: 1rem;

            .detail-span {
                font-size: 12 /16rem;
                color: #898989;
            }

            .detail-vv {
                margin-top: 5 /16rem;
                font-size: 14 /16rem;
            }
        }

        .upload-demo {
            margin-left: 0.5em;
            display: inline-block;
        }

    }

    .el-row {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-pagination {
        margin: 1rem 0;
        float: right;
    }

    .el-col {
        border-radius: 4px;
    }

    .bg-purple-dark {
        background: #99a9bf;
    }

    //   .bg-purple {
    //     background: #d3dce6;
    //   }
    .bg-purple-light {
        background: #e5e9f2;
    }

    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }

    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }

    .stepShow {
        width: 80%;
        margin: 3rem auto;

        .downloadList {
            //  transform:translate(5.5rem, -4rem)
        }

        /deep/.el-step.is-horizontal.stepActive {
            .el-step__head.is-finish {
                .el-step__line {

                    // 当前步骤数横线样式设置
                    .el-step__line-inner {
                        width: 50% !important;
                        border-width: 1px !important;
                    }
                }

                // 当前步骤数圆圈样式设置
                .el-step__icon.is-text {
                    background: #409eff;
                    color: #fff;
                }
            }
        }
    }
</style>
