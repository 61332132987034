<template>
    <div class="outting">
        <div class="left-card">
            <div class="left_tit">
                <h3 style=";margin-left:2rem">火警高发时间分布月度统计
                </h3>
                <span style="font-size:12px;;margin-left:2rem">时间 ：</span>
                <el-date-picker style="width: 22.25rem;;margin-right:2rem;margin-top:10px" v-model="month" type="month"
                    size="mini" value-format="yyyy-MM" format="yyyy-MM" placeholder="选择月"></el-date-picker>
                <el-button size="mini" type="primary" @click="changeMonth">搜索</el-button>
            </div>
            <div class="charts" id="leftTop"></div>
        </div>
        <div class="right-card">
            <div class="left_tit">
                <h3 style=";margin-left:2rem">火警高发时间分布周统计 </h3>
                <span style="font-size:12px;;margin-left:2rem">时间 ：</span>

                <el-date-picker style="width: 22.25rem;;margin-right:2rem;margin-top:10px" v-model="weekData"
                    type="week" id="getWeekData" size="mini" format="yyyy-WW" placeholder="选择周">
                </el-date-picker>

                <el-button size="mini" type="primary" @click="weekDataFn">搜索</el-button>
            </div>
            <div class="charts" id="rightTop"></div>
        </div>
        <div class="left-card">
            <div class="left_tit">
                <h3 style=";margin-left:2rem">火警高发场地top统计

                </h3>
                <span style="font-size:12px;;margin-left:2rem">时间 ：</span>
                <el-date-picker style="width: 22.25rem;;margin-right:2rem;margin-top:10px" clearable
                    v-model="histogramTime" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"
                    size="mini" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00','23:59:59']" type="datetimerange"></el-date-picker>
                <el-button size="mini" type="primary" @click="histogramFn">搜索</el-button>
            </div>
            <div class="charts" id="histogram"></div>
        </div>
        <div class="right-card">
            <div class="left_tit">
                <h3 style=";margin-left:2rem"> 火警高发项目top统计</h3>
                <span style="font-size:12px;;margin-left:2rem">时间 ：</span>

                <el-date-picker style="width: 22.25rem;;margin-right:2rem;margin-top:10px" clearable
                    v-model="projectTime" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"
                    size="mini" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00','23:59:59']" type="datetimerange"></el-date-picker>
                <el-button size="mini" type="primary" @click="projectFn">搜索</el-button>
            </div>
            <div class="charts" id="project"></div>
        </div>
    </div>
</template>
<script>
import bus from "@/components/bus";
    export default {
        name: "statistics",
        data() {
            return {
                histogramTime: '',
                projectTime: "",
                month: "",
                msmTime:"",
                voiceTime:"",
                weekData: "22",
                echartsdate: "",
                yearweek: "",
                monthList: "",
                
            };
        },
        methods: {
            histogramFn() {
                this.firePlaceTopStatistics()
            },
            projectFn() {
                this.fireProjectTopStatistics()
            },
            // 选择月份
            changeMonth() {

                this.echartsdate = this.month
                this.year = this.month.slice(0, 4);
                this.mon = this.month.slice(5, 7);

                this.fireTimeMonthStatistics();
            },
            weekDataFn() {
                this.weekDataNow = document.querySelector("#getWeekData").value
                this.yearweek = this.weekDataNow.slice(0, 4);
                this.week = this.weekDataNow.slice(5, 7);
                this.fireTimeWeekStatistics()
            },
            getTime(val) {
                //   获取时间并格式化
                // 获取前val天的时间
                var dt = new Date();
                val === 0 ? "" : dt.setTime(dt.getTime() - 24 * 60 * 60 * 1000 * val);
                const y = dt.getFullYear();
                const m = (dt.getMonth() + 1 + "").padStart(2, "0");
                const d = (dt.getDate() + "").padStart(2, "0");
                const hh = (dt.getHours() + "").padStart(2, "0");
                const mm = (dt.getMinutes() + "").padStart(2, "0");
                const ss = (dt.getSeconds() + "").padStart(2, "0");

                return `${y}-${m}-${d} ${hh}:${mm}:${ss}`; // 获取的时间格式为 2021-04-09 09:34:00
                // return `${y}-${m}-${d}`;  // 获取的时间格式为 2021-04-09
                // return y+m+d+hh+mm+ss  // 获取的时间格式为 20210409093733
                // return y + m + d  // 获取的时间格式为  20210409
            },
            getMonth(val) {
                //   获取时间并格式化
                // 获取前val天的时间
                var dt = new Date();
                val === 0 ? "" : dt.setTime(dt.getTime() - 24 * 60 * 60 * 1000 * val);
                const y = dt.getFullYear();
                const m = (dt.getMonth() + 1 + "").padStart(2, "0");
                const d = (dt.getDate() + "").padStart(2, "0");
                const hh = (dt.getHours() + "").padStart(2, "0");
                const mm = (dt.getMinutes() + "").padStart(2, "0");
                const ss = (dt.getSeconds() + "").padStart(2, "0");

                // return `${y}-${m}-${d} ${hh}:${mm}:${ss}`; // 获取的时间格式为 2021-04-09 09:34:00
                return `${y},${m}`; // 获取的时间格式为 2021-04-09
                // return y+m+d+hh+mm+ss  // 获取的时间格式为 20210409093733
                // return y + m + d  // 获取的时间格式为  20210409
            },
            getWeek() {
                let d1 = new Date()
                let d2 = new Date()
                d2.setMonth(0)
                d2.setDate(1)
                let rq = d1 - d2
                const y = d1.getFullYear();
                let s1 = Math.ceil(rq / (24 * 60 * 60 * 1000))
                let s2 = Math.ceil(s1 / 7)
                console.log('第' + s1 + '天,第' + s2 + '周', y)
                this.yearweek = y,
                    this.week = s2

            },

            //   //    火警高发场地top统计
            firePlaceTopStatistics() {
                this.$api
                    .firePlaceTopStatistics({
                        startTime: this.histogramTime[0],
                        endTime: this.histogramTime[1]
                    })
                    .then(res => {
                        console.log(res.data.data);
                        this.echarts3 = this.$echarts.init(document.getElementById("histogram"));
                        let option = {
                            tooltip: {
                                trigger: 'axis'
                            },
                                toolbox: {
                                feature: {
                                    dataZoom: {
                                        show: false
                                    },
                                    restore: {},
                                    saveAsImage: {}
                                }
                            },
                            xAxis: {
                                type: 'category',
                                data: res.data.data.names,
                            },
                            yAxis: {
                                type: 'value'
                            },
                            series: [{
                                data: res.data.data.values,
                                type: 'bar',
                                showBackground: true,
                                backgroundStyle: {
                                    color: 'rgba(180, 180, 180, 0.2)'
                                }
                            }]
                        };



                        this.echarts3.setOption(option);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            //   //    火警高发项目top统计
            fireProjectTopStatistics() {
                this.$api
                    .fireProjectTopStatistics({
                        //   FireTimeQueryDto: {
                        startTime: this.projectTime[0],
                        endTime: this.projectTime[1]
                        //   }
                    })
                    .then(res => {
                        console.log(res.data.data);
                        this.echarts4 = this.$echarts.init(document.getElementById("project"));
                        let option = {
                            tooltip: {
                                trigger: 'axis'
                            },
                            xAxis: {
                                type: 'category',
                                data: res.data.data.names,
                            },
                                toolbox: {
                                feature: {
                                    dataZoom: {
                                        show: false
                                    },
                                    restore: {},
                                    saveAsImage: {}
                                }
                            },
                            yAxis: {
                                type: 'value'
                            },
                            series: [{
                                data: res.data.data.values,
                                type: 'bar',
                                showBackground: true,
                                backgroundStyle: {
                                    color: 'rgba(180, 180, 180, 0.2)'
                                }
                            }]
                        };



                        this.echarts4.setOption(option);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },

            //    火警高发时间分布月度统计
            fireTimeMonthStatistics() {
                this.$api
                    .fireTimeMonthStatistics({
                        //   FireTimeQueryDto: {
                        year: this.year,
                        month: this.mon
                        //   }
                    })
                    .then(res => {
                        // console.log(res.data.data.fireTrueTimeDtos);
                        this.echarts = this.$echarts.init(document.getElementById("leftTop"));
                        const hours = [
                            0,
                            1,
                            2,
                            3,
                            4,
                            5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23


                        ];
                        /**
                         * 获取一个月多少天，并获取月初星期几
                         */
                        const daxier = ['一', '二', '三', '四', '五', '六', '日'];
                        const date = new Date(this.echartsdate)
                        const y = date.getFullYear()
                        const m = date.getMonth() + 1
                        console.log(m)
                        var date2 = new Date(y, m, 0)
                        var rq = date2.getDate() // 日 本月最后一天

                        var xq = date2.getDay(); // 星期 本月第一天星期几  new Date(0).getDay()
                        var rq2 = rq % 7
                        if (rq2 === 0) {
                            xq = rq2 + 1
                        } else {
                            if (rq2 > xq) xq += 7
                            xq = xq - rq2
                        }

                        var data = [];
                        for (var i = 1; i <= rq; i++) {
                            data.push(m + '月' + i + '日'
                                // 'xq': daxier[xq]//星期几
                            )

                            xq = (++xq == 7) ? 0 : xq
                        }

                        console.log(data)


                        const days = data;
                        var currentData;
                        let option = {

                            tooltip: {
                                // trigger: 'axis',
                                showDelay: 0,
                                enterable: true,
                                position: function (point, params, dom, rect, size) {
                                    return [point[0], point[1]]
                                },
                                formatter: function (params) {
                                    if (params.value.length > 1) {
                                        return (
                                            params.seriesName +
                                            ' :<br/>' +


                                            params.value[1] + params.value[0] + '小时'

                                        );
                                    } else {
                                        return (
                                            params.seriesName +
                                            ' :<br/>' +
                                            params.name +
                                            ' : ' +
                                            params.value +
                                            'kg '
                                        );
                                    }
                                },
                                axisPointer: {
                                    show: true,
                                    type: 'cross',
                                    lineStyle: {
                                        type: 'dashed',
                                        width: 1
                                    }
                                }
                            },
                            grid: {
                                x: 56,
                                //   y:50,
           
                                borderWidth: 10
                            },
                            title: {
                                text: '火警高发时间分布月度统计',
                                left: 'center'
                            },
                            legend: {
                                data: ['火警'],
                                icon: 'circle',
                                // left: "center",
                                left: '8%',
                        
                                show: true
                            },

                            toolbox: {
                                feature: {
                                    dataZoom: {
                                        show: false
                                    },
                                    restore: {},
                                    saveAsImage: {}
                                }
                            }, //h还原 保存图片
                            // dataZoom: [{
                            //     type: "inside",
                            //     start: 0,
                            //     end: 100
                            // }],

                            dataZoom: [{
                                    type: 'inside'
                                },
                                {
                                    type: 'slider',
                                        height: '2%',
                                    showDataShadow: false,
                                    handleIcon: 'path://M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                                    handleSize: '80%'
                                },
                        //         {
                        //             type: 'inside',
                        //             orient: 'vertical',
                             
                        
                        //         },
                        //         {
                        //             type: 'slider',
                        //               width: '2%',
                        //    orient: 'vertical',
                        //             showDataShadow: false,
                        //             handleIcon: 'path://M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                        //             handleSize: '80%'
                        //         }
                            ],
                            animation: false,
                            xAxis: {
                                // type: 'category',
                                // data: hours,
                                name: '小时',
                             max: 24,

                                // boundaryGap: true,
                                // splitLine: {
                                //     show: true
                                // },
                                // axisLine: {
                                //     show: true
                                // }
                            },
                            yAxis: {
                                // type: 'category',
                                data: days,
                                name: '月份',

                                // splitLine: {
                                //     show: true
                                // },
                                // axisLine: {
                                //     show: true
                                // }
                            },
                            series: [{
                                name: '火警',
                                symbolSize: 10,
                                data: res.data.data.fireTrueTimeDtos,
                                type: "scatter"
                            }]
                        };
                        this.echarts.on('mouseover', function (params) {
                            currentData = params.data;
                        })
                        this.echarts.setOption(option);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },

            //   //    火警高发时间分布周统计
            fireTimeWeekStatistics() {
                this.$api
                    .fireTimeWeekStatistics({
                        //   FireTimeQueryDto: {
                        year: this.yearweek,
                        week: this.week
                        //   }
                    })
                    .then(res => {
                        // console.log(res.data.data.fireTrueTimeDtos);
                        this.echarts2 = this.$echarts.init(document.getElementById("rightTop"));
                        const hours = [
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                            "7",
                            "8",
                            "9",
                            "10",
                            "11",
                            "12",
                            "13",
                            "14",
                            "15",
                            "16",
                            "17",
                            "18",
                            "19",
                            "20",
                            "21",
                            "22",
                            "23",
                            "24"
                        ];
                        var data = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];
                        const days = data;
                        let option = {
                            tooltip: {
                                // trigger: 'axis',
                                showDelay: 0,
                                enterable: true, // 鼠
                                position: function (point, params, dom, rect, size) {
                                    return [point[0], point[1]]
                                },
                                formatter: function (params) {
                                    if (params.value.length > 1) {
                                        return (
                                            params.seriesName +
                                            ' :<br/>' +


                                            params.value[1] + params.value[0] + '小时'

                                        );
                                    } else {
                                        return (
                                            params.seriesName +
                                            ' :<br/>' +
                                            params.name +
                                            ' : ' +
                                            params.value +
                                            'kg '
                                        );
                                    }
                                },
                                axisPointer: {
                                    show: true,
                                    type: 'cross',
                                    lineStyle: {
                                        type: 'dashed',
                                        width: 1
                                    }
                                }
                            },
                                toolbox: {
                                feature: {
                                    dataZoom: {
                                        show: false
                                    },
                                    restore: {},
                                    saveAsImage: {}
                                }
                            },
                            title: {
                                text: '火警高发时间分布周统计',
                                left: 'center'
                            },
                            dataZoom: [{
                                    type: 'inside'
                                },
                                {
                                    type: 'slider',
                                      height: '2%',
                                     showDataShadow: false,
                                    handleIcon: 'path://M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                                    handleSize: '80%'
                                },
                                // {
                                //     type: 'inside',
                                //     orient: 'vertical'
                                // },
                                // {
                                //     type: 'slider',
                                //   width: '2%',
                                //     orient: 'vertical',
                                //     showDataShadow: false,
                                //     handleIcon: 'path://M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                                //     handleSize: '80%'
                                // }
                            ],
                            animation: false,


                            legend: {
                                data: ['火警'],
                                icon: 'circle',
                                // left: "center",
                                left: '8%',
                                show: true
                            },
                            // tooltip: {
                            //     position: 'top',
                            //     formatter: function (params) {
                            //         console.log(params)
                            //         // return (
                            //         //   days[params.value[1]] + params.value[0]
                            //         // );
                            //     }
                            // },

                            xAxis: {
                                type: 'category',
                                // data: hours,
                                name: '小时',

                                boundaryGap: true,
                                splitLine: {
                                    show: true
                                },
                                axisLine: {
                                    show: true
                                }
                            },
                            yAxis: {
                                type: 'category',
                                data: days,
                                name: '周',
                                splitLine: {
                                    show: true
                                },
                                axisLine: {
                                    show: false
                                }
                            },
                            series: [{
                                name: '火警',
                                symbolSize: 5,
                                // data: [
                                //     [11, '星期一'],
                                //    [14, '星期二'],
                                //    [15, '星期三'],
                                //    [18, '星期六'],
                                // ],
                                data: res.data.data.fireTrueTimeDtos,
                                type: "scatter"
                            }]
                        };
                        this.echarts2.setOption(option);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
    
        },
        created() {
            this.histogramTime = [this.getTime(7), this.getTime(0)]
            this.projectTime = [this.getTime(7), this.getTime(0)]
          

            this.monthList = this.getMonth(0).split(',')
            this.echartsdate = this.getMonth(0)
            this.year = this.monthList[0]
            this.mon = this.monthList[1]
            console.log(this.monthList[0])
        },
        mounted() {

            this.getWeek()
            this.month = this.year + '-' + this.mon
            this.firePlaceTopStatistics()
            this.fireProjectTopStatistics()
            this.fireTimeWeekStatistics()
          

            this.fireTimeMonthStatistics()
          

            document.querySelector("#getWeekData").value = this.yearweek + '-' + this.week
            console.log(this.weekData)
        
           

        }
    };
</script>
<style lang="less" scoped>
    @border-bottom-color: #e7eaed;
    @toolBarFontColor: #111d;

    .outting {
        height: 100%;
        overflow: hidden;
        // display: flex;
        text-align: center;
        // justify-content: space-around;
        background-color: #fafafa;

        .left-card,
        .right-card {
            width: 47%;
            display: inline-block;

            // margin-top: 1rem;
            // margin: 1rem auto;
            .left_tit {
                text-align: left;
                margin: 20px 20px;
            }

            .charts {
                width: 90%;
                height: 58vh;
                margin-top: 30px;
                margin-bottom: 3rem;
                min-width: 280px;
                min-height: 210px;
            }
        }




    }
</style>