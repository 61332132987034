<template>
  <div class="outting">
    <div class="select">
      <span class="title">维修记录</span>
      <el-button
        type="text"
        size="small"
        icon="el-icon-plus"
        @click="addUserHandler"
        class="addRoleButton"
      >添加维修记录</el-button>
      <div class="select-input-right-input">
        <el-input
          v-model="inputMac"
          prefix-icon="el-icon-search"
          placeholder="请输入MAC"
          size="small"
          @keyup.enter.native="search"
        ></el-input>
      </div>
    </div>
    <div class="repairTable">
      <el-table
        :data="tableData"
        style="width: 100%"
        stripe
        size="small"
        :header-cell-style="{'background-color': '#eeeeee'}"
      >
        <el-table-column prop="mac" label="MAC" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.mac || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="faultType" label="故障类型" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.faultType || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="reason" label="维修原因" min-width="2" fixed align="center">
          <template slot-scope="{row}">{{ row.reason || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="repairman" label="维修人员" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.repairman || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="mobile" label="手机" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.mobile || '暂无数据' }}</template>
        </el-table-column>

        <el-table-column prop="success" label="是否成功" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.success==1 ? "是":"否"}}</template>
        </el-table-column>
        <el-table-column prop="sysUsername" label="用户名" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.sysUsername || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="2" fixed align="center">
          <template slot-scope="{row}">{{ row.createTime || '暂无数据' }}</template>
        </el-table-column>
        <!-- <el-table-column prop="updateTime" label="更新时间" min-width="2" fixed align="center">
          <template slot-scope="{row}">{{ row.updateTime || '暂无数据' }}</template>
        </el-table-column> -->
        <el-table-column min-width="1" label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-popconfirm title="确定删除这条维修记录吗？" @onConfirm="deleteDeviceRepair()">
              <el-button
                type="text"
                size="small"
                icon="el-icon-delete"
                @click="handleDelete(scope.$index, scope.row)"
                slot="reference"
              >删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
        <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        style="float:right;margin-top:10px;"
        @current-change="changeCurrent"
      ></el-pagination>
    </div>
    <el-dialog
      title="添加设备维修记录"
      :visible.sync="dialogFormVisible"
      :lock-scroll="false"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="MAC" prop="mac">
          <el-input v-model="ruleForm.mac"></el-input>
        </el-form-item>
        <el-form-item label="维修人员" prop="repairman">
          <el-input v-model="ruleForm.repairman"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="mobile">
          <el-input v-model="ruleForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="维修原因" prop="reason">
          <el-input type="textarea" v-model="ruleForm.reason"></el-input>
        </el-form-item>
        <el-form-item label="故障类型" prop="faultTypeId">
          <el-select v-model="ruleForm.faultTypeId" placeholder="请选择故障类型" @change="changeFaultType">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.faultType"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否成功" prop="success">
          <el-select v-model="ruleForm.success" placeholder="是否维修成功" @change="changeSuccess">
            <el-option label="成功" value="1"></el-option>
            <el-option label="失败" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">添加</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "DeviceRepair",
  data() {
    //手机号验证
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|6|7|8][0-9]{9}$/;
      if (!value) {
        return callback(new Error("电话号码不能为空"));
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error("请输入数字值"));
        } else {
          if (phoneReg.test(value)) {
            callback();
          } else {
            callback(new Error("电话号码格式不正确"));
          }
        }
      }, 100);
    };
    return {
      inputMac: "",
      tableData: [],
      current: 1,
      total: 0,
      deviceMac: "",
      options: [],
      faultTypeId: "",
      dialogFormVisible: false,
      success: "",
      deleteId:"",
      ruleForm: {
        repairman: "",
        mobile: "",
        faultTypeId: "",
        success: "",
        reason: "",
        mac: ""
      },
      rules: {
        mac: [{ required: true, message: "请填写mac号", trigger: "blur" }],
        repairman: [
          { required: true, message: "请输入维修人员", trigger: "blur" },
          { min: 1, max: 10, message: "长度在 1 到 10 个字符", trigger: "blur" }
        ],
        mobile: [{ required: true, validator: checkPhone, trigger: "blur" }],
        faultTypeId: [
          { required: true, message: "请选择故障类型", trigger: "change" }
        ],
        success: [
          { required: true, message: "请选择是否维修成功", trigger: "change" }
        ],
        reason: [{ required: true, message: "请填写维修原因", trigger: "blur" }]
      }
    };
  },
  methods: {
    //重置函数
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //增加维修记录函数
    addUserHandler() {
      this.$api.deviceRepairList().then(res => {
        console.log(res.data.data);
        this.options = res.data.data;
      });
      this.dialogFormVisible = true;
      if (this.$refs["ruleForm"] !== undefined) {
        this.resetForm("ruleForm");
      }
    },
    //选择故障类型
    changeFaultType(val) {
      this.faultTypeId = val;
    },
    //选择是否成功
    changeSuccess(val) {
      this.success = Number(val);
    },
    //添加维修记录确定函数
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.dialogFormVisible = false;
          this.$api
            .addDeviceRepari({
              faultTypeId: this.faultTypeId,
              mac: this.ruleForm.mac,
              mobile: this.ruleForm.mobile,
              reason: this.ruleForm.reason,
              repairman: this.ruleForm.repairman,
              success: this.success
            })
            .then(res => {
              console.log(res.data);
              if (res.data.code == 200) {
                this.$message.success("添加维修记录成功!");
                this.$api
                  .deviceRepair({
                    page: {
                      current: this.current,
                      size: 10
                    }
                  })
                  .then(res => {
                    console.log(res.data.data);
                    this.tableData = res.data.data.records;
                    this.total = res.data.data.total;
                  });
              }
            });
        } else {
          return false;
        }
      });
    },
    //mac号搜索函数
    search() {
      this.$api
        .deviceRepair({
          page: {
            current: this.current,
            size: 10
          },
          query: {
            mac: this.inputMac
          }
        })
        .then(res => {
          console.log(res.data.data);
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        });
    },
    //删除维修记录
    handleDelete(index,row) {
         this.deleteId = row.id;
    },
    //确认维修记录删除
    deleteDeviceRepair() {
    this.$api.deleteDeviceRepair(this.deleteId).then(res => {
        if (res.data.code == 200) {
          this.$message.success("删除设备维修记录成功!");
          this.$api
            .deviceRepair({
              page: {
                current: this.current,
                size: 10
              },
              query: {
                mac: this.inputMac
              }
            })
            .then(res => {
              this.tableData = res.data.data.records;
              this.total = res.data.data.total;
            });
        }
      });
    },
        //分页函数
    changeCurrent(val) {
      this.current = val;
      this.$api
        .deviceRepair({
          page: {
            current: this.current,
            size: 10
          },
          query: {
            mac: this.inputMac
          }
        })
        .then(res => {
          console.log(res.data.data);
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        });
    }
  },
  created() {
    this.$api
      .deviceRepair({
        page: {
          current: this.current,
          size: 10
        }
      })
      .then(res => {
        console.log(res.data.data);
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
  }
};
</script>
<style lang="less" scoped>
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;

.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .select-status {
      width: 9rem;
      margin-left: 1.7rem;
      margin-top: 7px;
    }

    .select-input-timer {
      float: right;
    }

    .select-status {
      float: right;
    }

    .select-input-right-input {
      float: right;
      // width: 8rem;
      line-height: 46px;
    }

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .addRoleButton {
      margin-left: 15px;
    }

    .serachRoleButton {
      margin-left: 15px;
    }

    button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
    }

    .update-input-timer {
      float: right;
      line-height: 46px;
      margin-left: 25px;
      height: 46px;

      .demonstrationTwo {
        float: left;
        font-size: 0.8rem;
      }

      .update-input-timer-select {
        width: 11rem;
        margin-left: 5px;
      }
    }
  }
  .repairTable {
    padding: 0rem 3rem;
    margin-top: 1rem;
  }
}
</style>