<template>
    <div style="background-color: #FFF">
    </div>
</template>

<script>
export default {
  name: "binddevice",
  mounted() {
    this.isWeiXin();
  },
  methods: {

    isWeiXin(){
      let ua = window.navigator.userAgent.toLowerCase();

      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      let mac = this.$route.query.mac;


      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //微信内置浏览器,提醒浏览器打开  -我这边ui提供的是图片

        this.$api
            .getScheme( mac)
            .then(res => {
              if (res.data.code == "200") {
                location.href = res.data.data;
              }
            })
            .catch(error => {
              console.log(error);
            });
        return;
      }
      //ios
      if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
        window.location = ("wsfirefighting://binddevice?mac=" + mac);//schema链接或者universal link
        window.setTimeout(() => { //如果没有安装app,便会执行setTimeout跳转下载页
          window.location.href = "https://apps.apple.com/cn/app/%E5%90%BB%E8%83%9C/id1558977884"; //ios下载地址3
        }, 3000);
        //android
      } else if (navigator.userAgent.match(/android/i)) {
        try {
          window.location.href = ("wsfirefighting://binddevice?mac=" + mac); //schema链接或者universal link
          window.setTimeout(() => {
            window.location = "https://www.pgyer.com/iUgu"; //android下载地址
          }, 3000);
        } catch (e) {
        }
      }

    }
  }

}


</script>

<style scoped>

</style>
