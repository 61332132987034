<template>
  <div class="mainContainer"  >
    <el-dialog title="提示" :visible.sync="delDialogVisible" width="30%">
      <span>确定删除该设备吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteDevice">确 定</el-button>
      </span>
    </el-dialog>
    <!-- <el-row class="platform-toolbar">
      <el-col :span="24">
        <label class="title-info">设备列表</label>

        <div class="toolbar-right export">
          <el-button type="text" size="small" icon="el-icon-refresh" class="toolbar-btn right" @click="refresh">刷新
          </el-button>
        </div>
        <div class="toolbar-right export">
          <el-button type="text" size="small" icon="el-icon-upload2" class="toolbar-btn right" @click="exportFile">导出
          </el-button>
        </div>
        <div class="toolbar-right export">
          <el-button
              type="text"
              size="small"
              icon="el-icon-remove-outline"
              class="toolbar-btn right"
              @click="sureUnbinding"
          >解绑
          </el-button>
        </div>
        <div class="toolbar-right export">
          <el-button
              type="text"
              size="small"
              icon="el-icon-circle-plus-outline"
              class="toolbar-btn right"
              @click="dispatchDisplay"
          >分配
          </el-button>
        </div>
        <div class="toolbar-right">
          <el-select v-model="chooseOnlineStatus" clearable size="small" style="width: 7rem;margin-left: 1rem">
            <el-option
                v-for="item in onlineStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="toolbar-right">
          <el-input placeholder="请输入搜索关键字" v-model="searchValue" class="input-with-select" size="small" @keyup.13.native="imeiSearch">
            <el-select style="width: 7rem" slot="prepend"  v-model="value" size="small" placeholder="请选择">
              <el-option
                  v-for="item in option"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <el-button slot="append" icon="el-icon-search" @click="imeiSearch" size="small"></el-button>
          </el-input>
        </div>

      </el-col>
    </el-row>-->
<div class="select">
  <span class="title">设备列表</span>
</div>
    <el-tabs v-model="activeName" class="tab-nav" @tab-click="handleClick">
      <el-tab-pane v-for="item in categoryNameList" :key="item.categoryId" :label="item.categoryName"
        :name="item.categoryName" :closable="item.close">
        <!-- {{item.categoryName}} -->
      </el-tab-pane>
    </el-tabs>
    <div class="select2">
      <!-- <span class="title">设备列表</span> -->

      <el-button type="text"  v-if="thirdParty" size="small" icon="el-icon-refresh" class="select-button" @click="refresh">刷新</el-button>

      <el-button type="text"  v-if="thirdParty" size="small" icon="el-icon-upload2" class="select-button" @click="exportFile">导出
      </el-button>

      <el-button type="text" size="small" icon="el-icon-remove-outline" class="select-button" @click="sureUnbinding"
        v-intro-step="3" v-intro="'勾选列表前的小框再点击这里解绑'">解绑</el-button>

      <el-button type="text" size="small" icon="el-icon-circle-plus-outline" class="select-button"
        @click="dispatchDisplay" v-intro-step="2" v-intro="'勾选列表前的小框再点击这里分配设备'">分配</el-button>
      <el-button type="text"  v-if="thirdParty" size="small" icon="el-icon-bottom" class="select-button" @click="playShow">高级查询</el-button>
      <div v-if="thirdParty" class="select-input-right-input" v-intro-step="1" v-intro="'点击选择搜索维度进行设备搜索'">
        <el-input placeholder="请输入搜索关键字" v-model="searchValue" class="input-with-select" size="small"
          @keyup.13.native="imeiSearch" @blur="ifMohuHandler($event)">
          <el-select style="width:8rem" slot="prepend" v-model="value" size="small" placeholder="请选择">
            <el-option v-for="item in option" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search" @click="imeiSearch" size="small"></el-button>
        </el-input>

        <div class="mohusousuo" v-show="ifMohu">
          <i class="el-icon-loading" v-show="loading" style="margin-left:46%;"></i>
          <li v-for="(item,index) in searchData" :key="index" @mousedown="searchLi(item)">{{ item }}</li>
        </div>
      </div>
    </div>
    <div class="gaojiSearch" v-show="ifSearchMany">
      <div style="width:100%;overflow: hidden;">
        <div style="float:left">
          <div class="toolbar-right">
            <el-select v-model="chooseOnlineStatus" clearable size="small" style="width: 11.25rem;">
              <el-option v-for="item in onlineStatus" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div style="width:100%;overflow: hidden;">
        <div style="float:right;">
          <div class="grid-content bg-purple-dark">
            <div class="searchBut">
              <el-button size="small" type="primary" @click="imeiSearch">搜索</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <el-row>
      <div class="result-info-wrapper">
        <div class="result-info">
          共有:
          <span>{{ deviceTotal }} 台设备</span>
        </div>

        <div class="data-setting">
          <el-popover
            placement="bottom-start"
            width="500"
            v-model="visible"
            popper-class="data-type-list"
          >
            <el-checkbox
              v-for="(item,index) in labels"
              :key="index"
              v-model="checkLabelsStatus[index]"
            >{{ item }}</el-checkbox>
            <div style="text-align: right; margin: 1rem 0 0 0">
              <el-button size="mini" type="text" @click="visible = false">取消</el-button>
              <el-button type="primary" size="mini" @click="changeTableTitle">确定</el-button>
            </div>

            <i class="el-icon-s-tools" slot="reference">列表设置</i>
          </el-popover>
        </div>
      </div>
      <el-table
        :data="tableData"
        stripe
        size="small"
        class="device-table"
        :header-cell-style="{'background-color': '#eeeeee'}"
        @select="getIds"
        @select-all="getAllIds"
      >
        <el-table-column type="selection" width="50" align="center"></el-table-column>
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column
          v-for="(item,index) in labels"
          :key="index"
          :prop="props[index]"
          :label="item"
          v-if="relLabelsStatus[index]"
          align="center"
        ></el-table-column>

        <el-table-column fixed="right" label="操作" width="50" align="center">
          <template slot-scope="scope">
            <el-button @click="deviceDetail(scope.row)" type="text" size="small">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :page-sizes="[10, 20,50,100]"
        :page-size="deviceSize"
        :total="deviceTotal"
        layout="sizes,prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
      ></el-pagination>
    </el-row>-->
    <div class="table-vv">
      <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
        <div class="result-info">
          共有
          <span>{{ deviceTotal }}</span>台设备
        </div>
        <div class="data-setting">
          <el-popover placement="bottom-start" width="500" v-model="visible" popper-class="data-type-list">
            <el-checkbox v-for="(item,index) in labels" :key="index" v-model="checkLabelsStatus[index]">{{ item }}
            </el-checkbox>
            <div style="text-align: right; margin: 1rem 0 0 0">
              <el-button size="mini" type="text" @click="visible = false">取消</el-button>
              <el-button type="primary" size="mini" @click="changeTableTitle">确定</el-button>
            </div>

            <i class="el-icon-s-tools" slot="reference">列表设置</i>
          </el-popover>
        </div>
      </div>

      <!-- 第三方 -->
      <el-table :data="tableData" v-if="thirdTable" stripe size="small" class="device-table"
        :header-cell-style="{'background-color': '#eeeeee'}" @select="getIds" @select-all="getAllIds">
        <el-table-column type="selection" width="50" align="center"></el-table-column>
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column prop="imei" label="设备IMEI"  align="center"></el-table-column>
        <el-table-column prop="meterName" label="设备名称"  align="center"></el-table-column>
        <el-table-column prop="isp" label="运营商类型"  align="center"></el-table-column>
        <el-table-column prop="faultState" label="当前是否故障"  align="center">
           <template slot-scope="scope">
          <span>{{scope.row.faultState == 1 ?"是":'否'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="faultContent" label="故障内容"  align="center"></el-table-column>
        <el-table-column prop="alarmState" label="当前是否报警">
            <template slot-scope="scope">
          <span>{{scope.row.alarmState == 1 ?"是":'否'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="rmLastTime" label="最新接收数据时间"  align="center"></el-table-column>
        <el-table-column prop="modelName" label="型号名称"  align="center"></el-table-column>
        <el-table-column prop="dealerId" label="经销商ID"  align="center"></el-table-column>
        <el-table-column prop="customerId" label="用户ID"  align="center"></el-table-column>
        <el-table-column prop="meterId" label="设备ID"  align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" width="50" align="center">
          <template slot-scope="scope">
            <el-button @click="deviceDetail(scope.row)" type="text" size="small">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="tableData" v-else stripe size="small" class="device-table"
        :header-cell-style="{'background-color': '#eeeeee'}" @select="getIds" @select-all="getAllIds">
        <el-table-column type="selection" width="50" align="center"></el-table-column>
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column v-for="(item,index) in labels" :key="index" :prop="props[index]" :label="item"
          v-if="relLabelsStatus[index]" align="center"></el-table-column>

        <el-table-column fixed="right" label="操作" width="50" align="center">
          <template slot-scope="scope">
            <el-button @click="deviceDetail(scope.row)" type="text" size="small">详情</el-button>
          </template>
        </el-table-column>
      </el-table>


      <el-pagination background :page-sizes="[10, 20,50,100]" :page-size="deviceSize" :total="deviceTotal"
        layout="sizes,prev, pager, next" @size-change="handleSizeChange" @current-change="handlePageChange"
        :current-page="deviceCurrent"></el-pagination>
    </div>
    <el-dialog title="新增设备" :visible.sync="dialogVisible" width="48rem">
      <div class="device-content">
        <div class="device-info-item">
          <div>
            <div class="label-info">
              <label>
                <span>*</span>设备名称
              </label>
            </div>
            <el-input v-model="device.deviceName" placeholder="设备名称" size="small"></el-input>
          </div>
        </div>
        <div class="device-info-item">
          <div>
            <div class="label-info">
              <label>
                <span>*</span>设备IMEI
              </label>
            </div>
            <el-input v-model="device.mac" placeholder="设备IMEI" size="small"></el-input>
          </div>
        </div>
        <div class="device-info-item">
          <div>
            <div class="label-info">
              <label>
                <span>*</span>产品类型
              </label>
            </div>
            <el-select v-model="device.productId" placeholder="请选择" size="small" @change="selectDeviceId">
              <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
        </div>
        <div class="device-info-item" style="margin-top:30px;">
          <div>
            <div class="label-info">
              <label>
                <span>*</span>所属运营商
              </label>
            </div>
            <el-select v-model="device.netOperator" placeholder="请选择" size="small" @change="selectNetOperatorId">
              <el-option v-for="item in netOperatorOptions" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="addDevice" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="设备分配" :visible.sync="dialogDispatchVisible" :lock-scroll="false" :close-on-click-modal="false">
      <el-table :data="dispatchTableData" style="width: 100%" stripe size="small"
        :header-cell-style="{'background-color': '#eeeeee'}" highlight-current-row @current-change="clickChange">
        <el-table-column label="选择" width="55">
          <template slot-scope="scope">
            <el-radio v-model="tableRadio" :label="scope.row">
              <i></i>
            </el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="projectName" label="项目" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.projectName || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="completeAddress" label="地址" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.completeAddress || '暂无数据' }}</template>
        </el-table-column>
      </el-table>
      <div>
        <el-pagination background layout="prev, pager, next" style="margin: 0 auto;padding-top:10px;" :total="listTotal"
          class="pagin" @current-change="changeCurrent" :current-page.sync="currentPage"></el-pagination>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDispatchVisible = false" style="margin-top:10px;">取 消</el-button>
        <el-button type="primary" @click="dispatchDeviceHandler" style="margin-top:10px;">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="分配结果" :visible.sync="dialogDispatchResultVisible">
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix">
          <span>分配成功IMEI</span>
        </div>
        <div v-for="(item,index) in successList" :key="index">{{ item }}</div>
      </el-card>
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix">
          <span>分配失败IMEI</span>
        </div>
        <div v-for="(item,index) in errorList" :key="index" style="margin-bottom:5px;">
          <span>{{ item.mac }}</span>
          <div>{{ item.info }}</div>
        </div>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDispatchResultVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogDispatchResultVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="设备解绑" :visible.sync="dialogUnbindingVisible">
      <span>确定解绑这些设备吗?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogUnbindingVisible = false">取 消</el-button>
        <el-button type="primary" @click="unbindingDevice">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="解绑结果" :visible.sync="dialogUnbingdingResultVisible">
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix">
          <span>解绑成功MAC</span>
        </div>
        <div>{{ unbindingSuccessList }}</div>
      </el-card>
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix">
          <span>解绑失败MAC</span>
        </div>
        <div v-for="(item,index) in unbindingErrorList" :key="index" style="margin-bottom:5px;">
          <span>{{ item.mac }}</span>
          <div>{{ item.info }}</div>
        </div>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogUnbingdingResultVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogUnbingdingResultVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
  import bus from "@/components/bus";
  import qs from "qs";
  import PubSub from "pubsub-js";
  // import axios from "axios" 
  import Storage from "@/utils/Storage";
  import {
    nextTick
  } from 'q';
  export default {
    name: "DeviceList",
    data() {
      return {
        activated: false,
        ids: [],
        loadingText: false,
        activeName: '',
        ifMohu: false,
        thirdParty: true, //是第三方电气火灾则隐藏
        thirdTable: false,
        restaurants: [],
        searchData: [],
        ifCurrent: false,
        ifMounse: false,
        ifSearchMany: false,
        currentPage: 0,
        categoryNameList: [],
        loading: true,
        ifKeyup: false,
        ifSousuo: false,
        option: [{
            value: "mac",
            label: "设备IMEI"
          },
          {
            value: "productName",
            label: "产品名称"
          },
          {
            value: "placeName",
            label: "场地名称"
          },
          {
            value: "roomName",
            label: "房间名称"
          },
          {
            value: "appUser",
            label: "用户手机"
          },
          {
            value: "deviceProject",
            label: "所属项目"
          }
        ],
        onlineStatus: [{
            value: "",
            label: "在线状态"
          },
          {
            value: "1",
            label: "在线"
          },
          {
            value: "0",
            label: "离线"
          }
        ],
        chooseOnlineStatus: "",
        device: {
          deviceName: "",
          mac: "",
          productId: "",
          netOperator: ""
        },
        searchValue: "",
        visible: false,
        delDialogVisible: false,
        tableData: [],
        checked: false,
        dialogDispatchVisible: false,
        //新增设备弹出对话框标记
        dialogVisible: false,
        dialogDispatchResultVisible: false,
        options: [],
        value: "mac",
        input: "",
        tableRadio: false,
        dialogUnbindingVisible: false,
        dialogUnbingdingResultVisible: false,
        dispatchList: [],
        dispatchTableData: [],
        errorList: [],
        unbindingErrorList: [],
        successList: [],
        unbindingSuccessList: [],
        listTotal: 0,
        deviceTotal: 0,
        deviceCurrent: 1,
        deviceSize: 10,
        delImei: "",
        tabIndex:"",
        tabLabel: "",
        categoryId: "",
        apiUrl: "",
        backuri:"",
        netOperatorOptions: [{
            id: "1",
            name: "电信"
          },
          {
            id: "2",
            name: "联通"
          },
          {
            id: "0",
            name: "移动"
          }
        ],
        props: [
          "mac",
          "deviceName",
          "productName",
          "netOperator",
          "placeName",
          "placeLocation",
          "roomName",
          "installTime",
          "deviceProject",
          "projectLocation",
          "appUser",
          "linkedUser",
          "onlineStatus"
        ],
        labels: [
          "设备IMEI",
          "设备名称",
          "产品名称",
          "运营商类型",
          "场地名称",
          "场地地址",
          "房间名称",
          "安装时间",
          "所属项目",
          "项目地区",
          "用户",
          "紧急联系人",
          "在线状态"
        ],
        checkLabelsStatus: [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true
        ],
        relLabelsStatus: [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true
        ]
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab.label,this.activeName)
        this.deviceCurrent = 1
        this.activeName = tab.label
        this.loadingText = true
        this.tabLabel = tab.label
        let config = {
          headers: {
            'authorization': "Bearer " + Storage.getItem("token")
          }
        }
         
        for (let index = 0; index < this.categoryNameList.length; index++) {
          if (this.tabLabel == this.categoryNameList[index].categoryName) {

            this.categoryId = this.categoryNameList[index].categoryId
            this.apiUrl = this.categoryNameList[index].uri
            console.log(this.apiUrl)

          }
        }
        if (this.tabLabel == '电气火灾') {
          this.thirdParty = false
          this.thirdTable = true
        } else {
          this.thirdParty = true
          this.thirdTable = false
        }
        // if (query == null) {
        let query = {
          onlineStatus: this.chooseOnlineStatus
        };
        this.$set(query, this.value, this.searchValue);

        this.$set(query, 'categoryId', this.categoryId);
        // console.log(query)
        // }
          this.$api
          .deviceApiUrl(this.apiUrl,{
            page: {
              current: this.deviceCurrent,
              size: this.deviceSize
            },
            query: query
          })
          .then(res => {
            this.tableData = res.data.data.records;
            this.deviceTotal = res.data.data.total;
            this.deviceCurrent = res.data.data.current;
            this.deviceSize = res.data.data.size;
            this.loadingText = false
          })
          .catch(error => {
            console.log(error);
          });
        // this.$axios.post('/api' + this.apiUrl, {
        //     page: {
        //       current: this.deviceCurrent,
        //       size: this.deviceSize
        //     },
        //     query: query
        //   }, config)
        //   .then(res => {
        //     this.tableData = res.data.data.records;
        //     this.deviceTotal = res.data.data.total;
        //     this.deviceCurrent = res.data.data.current;
        //     this.deviceSize = res.data.data.size;
        //     this.loadingText = false
        //   })
        //   .catch(error => {});
        //查询设备列表
        //  let config = {
        //             headers: {
        //                 'authorization':"Bearer " + Storage.getItem("token")
        //             }
        //         }

        // let vm = this 
        // console.log(vm.$axios)
        // vm.$axios.post('/api' + '/iotServer/device/list', {
        //   page: {
        //     current: vm.deviceCurrent,
        //     size: vm.deviceSize
        //   },
        //   query: ""
        // },config).then(function (res) {}).catch(function (error) {
        //   console.log(error)
        // })

      },
      changeStyleAndView() {},
      ifMohuHandler(e) {
        console.log(e);
         console.log(this.categoryId)
        this.ifMohu = false;
        this.searchData = [];
      },
      searchLi(item) {
        console.log(item);
        this.searchValue = item;
        this.ifMohu = false;
        this.ifMounse = true;
        this.deviceList();
      },
      // querySearchAsync(queryString, cb) {
      //   var restaurants = this.restaurants;
      //   var results = queryString
      //     ? restaurants.filter(this.createStateFilter(queryString))
      //     : restaurants;

      //   clearTimeout(this.timeout);
      //   this.timeout = setTimeout(() => {
      //     cb(results);
      //   }, 3000 * Math.random());
      // },
      // createStateFilter(queryString) {
      //   return state => {
      //     return (
      //       state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      //     );
      //   };
      // },
      debounce(func, delay) {
        let timer;
        return function (...args) {
          if (timer) {
            clearTimeout(timer);
          }
          timer = setTimeout(() => {
            func.apply(this, args);
          }, delay);
        };
      },
      guide() {
        this.$intro()
          .setOptions({
            prevLabel: "上一步",
            nextLabel: "下一步",
            skipLabel: "跳过",
            doneLabel: "结束",
            exitOnOverlayClick: false, //是否点击空白处退出
            showStepNumbers: true, //是否显示数字
            // scrollToElement: true,//是否滑动到高亮的区域
            overlayOpacity: 0.7,
            tooltipClass: "text-vv",
            tooltipPosition: "left"
          })
          .oncomplete(function () {
            //点击跳过按钮后执行的事件
          })
          .onexit(function () {
            //点击结束按钮后， 执行的事件
          })
          .start();
      },

      imeiSearch() {
        this.ifMohu = false;
        this.searchData = [];
        this.ifKeyup = true;
        console.log(this.tabLabel)
        if ((this.ifCurrent = false)) {
          let query = {
            onlineStatus: this.chooseOnlineStatus
          };
          this.$set(query, this.value, this.searchValue);
          this.$set(query, 'categoryId', this.categoryId);
          this.deviceList(query);
            console.log(this.categoryId)
      //  this.$nextTick(() => {
      //       this.activeName = this.tabLabel
      //   })
         
        } else {
          this.deviceCurrent = 1;
          let query = {
            onlineStatus: this.chooseOnlineStatus
          };
          this.$set(query, this.value, this.searchValue);
          this.$set(query, 'categoryId', this.categoryId);
          this.deviceList(query);
        //          this.$nextTick(() => {
        //     this.activeName = this.tabLabel
        // })
        }
      },

      exportFile() {
        let properties = [];
        let titles = [];
        for (let i = 0; i < this.relLabelsStatus.length; i++) {
          if (this.relLabelsStatus[i]) {
            properties.push(this.props[i]);
            titles.push(this.labels[i]);
          }
        }
        let query = {
          onlineStatus: this.chooseOnlineStatus
        };
        this.$set(query, this.value, this.searchValue);
        this.$set(query, 'categoryId', this.categoryId);
        // this.$set(query, ids, this.ids);
        query.ids = this.ids;
        console.log();
        this.$api
          .exportFile({
            properties: properties,
            titles: titles,
            query: query
          })
          .then(res => {
            this.download(res.data);
            // console.log(res.data)
          })
          .catch(error => {});
      },
      download(data) {
        if (!data) {
          return;
        }
        let url = window.URL.createObjectURL(
          new Blob([data], {
            type: "application/octet-binary"
          })
        );
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", "excel.xls");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      },

      changeTableTitle() {
        this.visible = false;
        for (let i = 0; i < this.checkLabelsStatus.length; i++) {
          this.relLabelsStatus[i] = this.checkLabelsStatus[i];
        }
      },
      handlePageChange(val) {
        // console.log(val);
        this.deviceCurrent = val;
            this.$api
          .deviceCategory()
          .then(res => {
             console.log(res.data.data)
             for (let index = 0; index < res.data.data.length; index++) {
             if( this.categoryId ==  res.data.data[index].categoryId ){
                  this.backuri =  res.data.data[index].uri
             }
               
             }
             console.log(this.backuri)
           })
        this.deviceList();
      },
      handleSizeChange(val) {
        this.deviceSize = val;
        this.deviceList();
      },
      deviceDetail(data) {
        // console.log(data,this.tabLabel)
        this.$router.push("/deviceInfo");
        sessionStorage.setItem("deviceId", data.id);
        sessionStorage.setItem("tabLabel", this.tabLabel );
        sessionStorage.setItem("deviceName", data.meterName);
        sessionStorage.setItem("deviceMac", data.mac);
      },
      confirmDel(data) {
        this.delImei = data;
        this.delDialogVisible = true;
      },
      deleteDevice() {
        this.$api
          .deleteDevice(this.delImei)
          .then(res => {
            this.delDialogVisible = false;
            this.refresh();
          })
          .catch(error => {
            console.log(error);
          });
      },
      addDevice() {
        if (this.device.deviceName == "") {
          this.$message({
            message: "设备名称不能为空",
            type: "error"
          });
        } else if (this.device.mac == "") {
          this.$message({
            message: "设备IMEI不能为空",
            type: "error"
          });
          return;
        } else if (this.device.netOperator == "") {
          this.$message({
            message: "运营商类型不能为空",
            type: "error"
          });
          return;
        } else if (this.device.productId == "") {
          this.$message({
            message: "产品类型不能为空",
            type: "error"
          });
          return;
        }
        this.dialogVisible = false;

        this.$api
          .addDevice(this.device)
          .then(res => {
            this.device.deviceName = "";
            this.device.mac = "";
            this.device.productId = "";
            this.device.netOperator = "";
            if (res.data.code == "200") {
              this.$message({
                message: "新增设备成功",
                type: "success"
              });
              //查询设备列表
              this.deviceCurrent = 1;
              this.refresh();
            }
          })
          .catch(error => {
            console.log(error);
          });
      },
      showAddDeviceDialog() {
        this.dialogVisible = true;
        this.$api
          .productListForPullUrl({})
          .then(res => {
            this.options = res.data.data;
          })
          .catch(error => {
            console.log(error);
          });
      },
      //清空搜索条件函数
      emptyHandler() {},
      //产品列表选择函数
      selectDeviceId(id) {
        this.device.productId = id;
      },
      //运营商选择函数
      selectNetOperatorId(id) {
        this.device.netOperator = id;
      },
      //批量选中
      getIds(selection, row) {
        var arr = [];
        for (var i = 0; i < selection.length; i++) {
          arr.push(selection[i].mac);
        }
        this.dispatchList = arr;
        var idArr = [];
        for (var i = 0; i < selection.length; i++) {
          idArr.push(selection[i].id);
        }
        this.ids = idArr;
        // console.log(this.ids);
      },
      //全部选中
      getAllIds(selection, row) {
        var arr = [];
        for (var i = 0; i < selection.length; i++) {
          arr.push(selection[i].mac);
        }
        this.dispatchList = arr;
        var idArr = [];
        for (var i = 0; i < selection.length; i++) {
          idArr.push(selection[i].id);
        }
        this.ids = idArr;
        console.log(this.ids);
      },
      //分配开启函数
      dispatchDisplay() {
        if (this.dispatchList.length == 0) {
          this.$message.error("请先勾选要分配的设备!");
        } else {
          this.dialogDispatchVisible = true;
          this.currentPage = 1;
          this.$api
            .childProjectlist({
              current: 1
            })
            .then(res => {
              this.dispatchTableData = res.data.data.records;
              this.listTotal = res.data.data.total;
            });
        }
      },
      //单选函数
      clickChange(item) {
        this.tableRadio = item;
      },
      //批量分配确定函数
      dispatchDeviceHandler() {
        if (this.tableRadio.projectName) {
          this.dialogDispatchVisible = false;
          this.$api
            .deviceDispatch({
              macs: this.dispatchList,
              projectName: this.tableRadio.projectName
            })
            .then(res => {
              this.dispatchList = [];
              this.errorList = [];
              this.successList = [];
              if (res.data.data.errorList.length !== 0) {
                for (var i = 0; i < res.data.data.errorList.length; i++) {
                  this.errorList.push(res.data.data.errorList[i]);
                }
              }
              if (res.data.data.successList.length !== 0) {
                for (var i = 0; i < res.data.data.successList.length; i++) {
                  this.successList.push(res.data.data.successList[i].mac);
                }
              }
              this.dialogDispatchResultVisible = true;
              this.refresh();
            });
        } else {
          this.$message.error("请选择项目名称!");
        }
      },
      //确定解绑设备函数
      sureUnbinding() {
        if (this.dispatchList.length == 0) {
          this.$message.error("请先勾选要解绑的设备!");
        } else {
          this.dialogUnbindingVisible = true;
        }
      },
      //解绑设备确定函数
      unbindingDevice() {
        this.dialogUnbindingVisible = false;

        this.$api.deviceUnbinding(this.dispatchList).then(res => {
          this.dispatchList = [];
          this.unbindingErrorList = [];
          this.unbindingSuccessList = [];
          if (res.data.data.errorList.length !== 0) {
            for (var i = 0; i < res.data.data.errorList.length; i++) {
              this.unbindingErrorList.push(res.data.data.errorList[i]);
            }
          } else {
            this.unbindingErrorList = "没有设备解绑失败！";
          }
          if (res.data.data.successList.length !== 0) {
            for (var i = 0; i < res.data.data.successList.length; i++) {
              this.unbindingSuccessList.push(res.data.data.successList[i].mac);
            }
          } else {
            this.unbindingSuccessList = "没有设备解绑成功！";
          }
          this.dialogUnbingdingResultVisible = true;
          this.refresh();
        });
      },
      //高级搜索点击函数
      playShow() {
        if (this.ifSearchMany == false) {
          this.ifSearchMany = true;
        } else {
          this.ifSearchMany = false;
        }
      },
      //列表分页函数
      changeCurrent(id) {
        this.ifCurrent = true;
        this.$api
          .childProjectlist({
            current: id
          })
          .then(res => {
            this.dispatchTableData = res.data.data.records;
            this.listTotal = res.data.data.total;
          });
      },
      refresh() {
        this.deviceList();
        console.log('x9jxx9j')
      },
      deviceList(query) {
         console.log(this.categoryId)
        this.loadingText = true
        let config = {
          headers: {
            'authorization': "Bearer " + Storage.getItem("token")
          }
        }
        this.$api
          .deviceCategory()
          .then(res => {
              this.categoryNameList = res.data.data
            if(res.data.data.length>0){
              
            this.activeName = this.tabLabel||this.categoryNameList[0].categoryName
            this.categoryId = this.categoryId ||this.categoryNameList[0].categoryId
           
            this.apiUrl = this.backuri || this.categoryNameList[0].uri 
                console.log(this.apiUrl,this.backuri)
            if (this.activeName == '电气火灾') {
              this.thirdParty = false
              console.log('是电气火灾')
              this.thirdTable = true
            } else {
              this.thirdParty = true
              this.thirdTable = false
            }
             this.$nextTick(() => {
              if (query == null) {
                query = {
                  onlineStatus: this.chooseOnlineStatus
                };
                this.$set(query, this.value, this.searchValue);

                this.$set(query, 'categoryId', this.categoryId);
                // console.log(query)
              }
                this.$api
          .deviceApiUrl(this.apiUrl,{
            page: {
              current: this.deviceCurrent,
              size: this.deviceSize
            },
            query: query
          })
          .then(res => {
            console.log(res.data.data.records)
            this.tableData = res.data.data.records;
            this.deviceTotal = res.data.data.total;
            this.deviceCurrent = res.data.data.current;
            this.deviceSize = res.data.data.size;
            this.loadingText = false
              console.log(this.tableData)
          })
          .catch(error => {
            console.log(error);
          });
              // this.$axios.post('/api' + this.apiUrl, {
              //     page: {
              //       current: this.deviceCurrent,
              //       size: this.deviceSize
              //     },
              //     query: query
              //   }, config)
              //   .then(res => {
              //     this.tableData = res.data.data.records;
              //     this.deviceTotal = res.data.data.total;
              //     this.deviceCurrent = res.data.data.current;
              //     this.deviceSize = res.data.data.size;
              //     this.loadingText = false
              //   })
              //   .catch(error => {});
            });
            }
             this.loadingText = false
            // console.log(res.data.data, this.tabLabel)
          
           
          });


      }
    },
    mounted() {
      if (sessionStorage.getItem("ifNew") == "true") {
        if (sessionStorage.getItem("ifDisplay") !== "true") {
          this.guide();
        }

        sessionStorage.setItem("ifDisplay", true);
      }
    
    },
    created() {
      this.$watch(
        "searchValue",
        this.debounce(newQuery => {
          if (newQuery && !this.ifMounse && !this.ifKeyup) {
            this.ifMohu = true;
            this.loading = true;
            this.searchData = [];
            this.ifSousuo = true;
            this.$api
              .quickQueryHandler({
                key: this.value,
                value: newQuery
              })
              .then(res => {
                if (res.data.data) {
                  if (res.data.data.length > 6) {
                    this.searchData = res.data.data.slice(0, 7);
                  } else {
                    this.searchData = res.data.data;
                  }
                } else {
                  this.ifMohu = false;
                  this.ifKeyup = false;
                }
                this.loading = false;
                this.ifSousuo = false;
                this.ifKeyup = false;
              });
          } else {
            this.ifMounse = false;
            this.ifMohu = false;
            this.ifKeyup = false;
          }
          this.ifKeyup = false;
        }, 300)
      );
      this.chooseOnlineStatus = this.$route.query.status;
      


    },
    beforeRouteLeave(to, from, next) {
  
      if (to.path === "/deviceDetail") {
        this.$store.commit("getDeviceListState", ["DeviceList"]);
          
      } else {
        this.$store.commit("getDeviceListState", []);
      
      }
      next();
    },
    beforeRouteEnter(to, from, next) {
     
      if (
        from.path !== "/deviceInfo" &&
        from.path !== "/deviceHistoryData" &&
        from.path !== "/managementRecords"
      ) {
        next(vm => {
           console.log( vm.$route.query.status)
          vm.chooseOnlineStatus = vm.$route.query.status;
          vm.deviceCurrent = 1;
                vm.categoryId = ""
                vm.backuri = ""
                    console.log(vm.backuri)
          vm.deviceList();
          vm.tabLabel = ""
           vm.searchValue = ""
     
           
          console.log("寻找",vm.categoryId )
          //       this.value = ""
          // this.chooseOnlineStatus = ""
          // vm.activated = false
        });
      }else{
        next(vm => {
          console.log('返回了')
          console.log( vm.categoryId)
            vm.$api
          .deviceCategory()
          .then(res => {
             console.log(res.data.data)
             for (let index = 0; index < res.data.data.length; index++) {
             if( vm.categoryId ==  res.data.data[index].categoryId ){
                  vm.backuri =  res.data.data[index].uri
             }
             }
             console.log(vm.backuri)
           })
            vm.deviceList(); 
        });
        
      }
      next(vm => {});
    }
  };
</script>
<style lang="less" scoped>
  @toolBarFontColor: rgba(17, 17, 17, 0.87);

  .mainContainer {
    height: 100vh;
  }

  .tab-nav {
    /deep/.el-tabs__header {
      padding: 0rem 3rem;
    }

    //background: red;
    // border-bottom: 1px solid #d9d9d9;

    //  padding: 0rem 3rem;
    .tab-item {
      cursor: pointer;
      display: inline-block;
      padding: 0 1.5rem;
      line-height: 2rem;
      font-weight: bold;
      font-size: small;

      &.left {
        margin-left: 3rem;
        border-bottom: 3px solid #4faaf0;
      }

      &.right {
        margin-right: 3rem;
      }

      &:hover {
        color: #4faaf0;
      }
    }
  }

  .text-vv {
    background-color: #fafafa;

    .introjs-button {
      padding: 0.3rem 0.5rem;
    }
  }

  .el-row {
    margin: 1rem 3rem;
    line-height: 0;
    min-width: 900px;

    .platform-toolbar {
      position: relative;
      margin: 0;
      border: 1px solid #d9d9d9;

      .title-info {
        height: 46px;
        line-height: 46px;
        font-weight: bold;
        font-size: 0.8rem;
      }

      .el-col {
        padding: 0 3rem;

        .toolbar-right {
          float: right;
          margin-top: 7px;

          &.export {
            margin-left: 1rem;

            .toolbar-btn {
              color: @toolBarFontColor;
              font-size: 0.9rem;

              &.left {
                margin-left: 1.5rem;
              }

              &.right {
                margin-right: 0;
              }
            }
          }
        }
      }

      .title-info {
        float: left;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .result-info-wrapper {
      height: 1rem;
      margin: 0rem 0 0.5rem 0;
      position: relative;

      .setting-panel {
        position: absolute;
        float: right;
        top: 2rem;
        width: 200px;
        height: 200px;
        background: red;
        z-index: 1000;
        border-radius: 3px;
      }

      .result-info {
        height: 1rem;
        line-height: 1rem;
        font-size: 0.875rem;
        float: left;
        color: #898989;

        span {
          color: #292929;
          padding: 0 0.1rem;
        }
      }

      .data-setting {
        font-size: 0.875rem;
        color: #2673bb;
        float: right;

        i {
          padding-right: 0.2rem;
        }

        cursor: pointer;
      }
    }

    .el-table td,
    .el-table th {
      padding: 0 !important;
    }

    .el-table--scrollable-x {
      overflow-x: hidden;
    }

    .el-pagination {
      margin: 1rem 0;
      float: right;
    }
  }

  .device-content {
    height: 6rem;

    .device-info-item {
      float: left;
      margin-right: 4rem;

      .label-info {
        margin-bottom: 0.5rem;

        label {
          font-size: 0.75rem;

          span {
            color: red;
            margin-right: 0.5rem;
          }
        }
      }

      .el-input {
        width: 10rem;
      }

      .el-select {
        width: 13rem;
      }
    }
  }

  .select2 {
    height: 46px;
    // overflow: hidden;
    // border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .select-button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
      margin-left: 15 /16rem;
    }

    .select-input-right-input {
      float: right;
      margin-top: 7px;
      position: relative;
    }
  }

 .select {
    height: 46px;
    // width: 90%;
    margin: 0 auto;
    border-bottom: 1px solid #d9d9d9;
    margin: 0rem 3rem;

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

  
  }

  .gaojiSearch {
    margin-top: 10px;
    padding: 1rem 3rem;
    margin-bottom: 10px;
    background-color: #f4f6fa;

    .searchBut {
      float: right;
      margin-left: 20px;
    }
  }

  .table-vv {
    padding: 0rem 3rem;
    margin-top: 1rem;

    .result-info {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      float: left;
      color: #898989;

      span {
        color: #292929;
        padding: 0 0.1rem;
      }
    }

    .data-setting {
      font-size: 0.875rem;
      color: #2673bb;
      float: right;
      line-height: 1rem;

      i {
        padding-right: 0.2rem;
      }

      cursor: pointer;
    }

    .el-pagination {
      margin: 1rem 0;
      float: right;
    }
  }

  .mohusousuo {
    z-index: 9999;
    position: absolute;
    top: 40 /16rem;
    left: 130/ 16rem;
    list-style-type: none;
    font-size: 10 /16rem;
    background-color: white;
    width: 180 /16rem;
    box-shadow: 2px 2px 10px #909090;
    border-radius: 7px;

    li {
      margin-left: 6px;
      cursor: pointer;

      &:hover {
        color: #409eff;
      }
    }
  }
</style>