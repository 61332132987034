<template>
  <div class="mainContainer">
    <el-dialog title="提示" :visible.sync="delDialogVisible" width="30%">
      <span>确定删除该类型吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDelete">取 消</el-button>
        <el-button type="primary" @click="handleDelClose">确 定</el-button>
      </span>
    </el-dialog>
    <div class="select">
      <span class="title">问题类型列表</span>
      <el-button type="text" size="small" icon="el-icon-refresh" class="select-button" @click="refresh">刷新</el-button>
      <el-button type="text" size="small" icon="el-icon-plus" class="select-button" @click="showAddProductDialog">新增
      </el-button>
    </div>
    <div class="table-vv">
      <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
        <div class="result-info">
          共有
          <span>{{ total }}</span>个问题
        </div>
      </div>
      <el-table :data="tableData" stripe size="small" class="product-table"
        :header-cell-style="{'background-color': '#eeeeee'}">
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column prop="typeName" label="类型名称" align="center"></el-table-column>
        <el-table-column prop="handlerUserName" label="默认处理人" align="center"></el-table-column>
        <el-table-column prop="templateName" label="处理模板" align="center"></el-table-column>
        <el-table-column prop="remark" label="备注信息" align="center"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="deleteProductD(scope.row.id)" type="text" size="small" icon="el-icon-delete">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background :page-sizes="[10, 20]" :page-size="pageSize" :total="total"
        layout="sizes,prev, pager, next" @size-change="handleSizeChange" @current-change="handlePageChange">
      </el-pagination>
    </div>
    <el-dialog title="新增问题类型" :visible.sync="dialogVisible" :lock-scroll="false" :close-on-click-modal="false">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="类型名称" :label-width="formLabelWidth" prop="typeName">
          <el-input v-model="form.typeName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注信息" :label-width="formLabelWidth" prop="remark">
          <el-input v-model="form.remark" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="处理模板" :label-width="formLabelWidth" prop="templateId">
          <el-select v-model="form.templateId" placeholder="请选择处理模板" size="small" >
            <el-option v-for="item in templateId" :key="item.id" :label="item.templateName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="默认处理者" :validate-on-rule-change="false"  :label-width="formLabelWidth" prop="handlerUserId">
          <el-select v-model="form.handlerUserId" filterable placeholder="请选择默认处理者" size="small">
            <el-option v-for="(item,index) in realNameList" :key="index" :label="item.realName" :value="item.sysUserId">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
  import Bus from "../../../components/bus";
  export default {
    name: "problemStatus",
    data() {
      return {
        productDel: false,
        productAdd: false,
        form: {
          typeName: "",
          remark: "",
          templateId:"",
          handlerUserId:"",
        },
        visible: false,
        templateId: [],
        total: 0,
        tableData: [],
        optionType: [{
            value: "problemStatus",
            label: "真实姓名"
          },
          {
            value: "sysUserName",
            label: "平台账号"
          },
          {
            value: "typeName",
            label: "平台账号"
          }
        ], //搜索下拉选择
        chooseOnlineStatus: "", //搜索选择
        sysUserlist: [], //账号所属类型
        newSsUserlist: {
          value: "id",
          label: "typeName",
          children: "childs",
          expandTrigger: "hover"
          // emitPath: false
        },
        checked: false,
        //新增账号弹出对话框标记
        dialogVisible: false,
        //修改账号弹出对话框标记
        modifydialogVisible: false,
         //默认处理者数组
      realNameList: [],
        //协议类型列表
        protocols: [{
          id: 1,
          protocolName: "test"
        }],

        //产品品类列表
        categories: [],

        value: "",
        input: "",
        current: 1,
        pageSize: 10,
        delDialogVisible: false,
        productId: "",
        indexId: "", //反显id
        nameinput: "", //搜索输入
        indexIdlist: "", //反显列表
        formLabelWidth: "120px",
        rules: {
          remark: [{
              required: true,
              message: "请输入备注信息",
              trigger: "blur"
            },

          ],
          typeName: [{
            required: true,
            message: "请输入类型名称",
            trigger: "blur"
          }, ],
          templateId: [{
              required: true,
              message: "请选择处理模板",
              trigger: "change"
            },

          ],
          handlerUserId: [{
            required: true,
            message: "请选择默认处理者",
            trigger: "change"
          }, ],
        }
      };
    },
    methods: {
      refresh() {
        this.problemTypeList();
      },
      ifMohuHandler(e) {
        // console.log(e);
        this.ifMohu = false;
        this.searchData = [];
      },
      // changex清空
      upOptionType() {
        this.nameinput = "";
      },
      handlePageChange(val) {

        this.current = val;
        this.problemTypeList();
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.problemTypeList();
      },

      changeDetSelect(key, treeData) {
        let arr = []; // 在递归时操作的数组
        let returnArr = []; // 存放结果的数组
        let depth = 0; // 定义全局层级
        // 定义递归函数
        function childrenEach(childrenData, depthN) {
          for (var j = 0; j < childrenData.length; j++) {
            depth = depthN; // 将执行的层级赋值 到 全局层级
            arr[depthN] = childrenData[j].id;
            if (childrenData[j].id == key) {
              returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
              break;
            } else {
              if (childrenData[j].typeName) {
                depth++;
                childrenEach(childrenData[j].typeName, depth);
              }
            }
          }

          return returnArr;
        }
        return childrenEach(treeData, depth);
      },

      deleteProductD(data) {
        this.delDialogVisible = true;
        this.productId = data;
      },
      cancelDelete() {
        this.delDialogVisible = false;
        this.productId = "";
      },
      handleDelClose() {
        this.delDialogVisible = false;
        this.deleteProduct(this.productId);
      },
      deleteProduct(data) {

        this.$api
          .problemTypeDelete(data)
          .then(res => {
            if (res.data.code == 200) {
              this.$message.success("删除类型成功!");
              this.problemTypeList();
            }
          })
          .catch(error => {
            console.log(error);
          });
      },

      problemTypeList() {
        //查询设备列表
        this.$api
          .problemTypeList({

            current: this.current,
            size: this.pageSize

          })
          .then(res => {
            this.tableData = res.data.data.records;

            this.total = res.data.data.total;
            let cloudList = JSON.parse(sessionStorage.getItem("cloudList"));
            for (let i = 0; i < cloudList.length; i++) {
              if (
                this.$store.state.localList.localList.productDel == cloudList[i]
              ) {
                this.productDel = true;
              }
              if (
                this.$store.state.localList.localList.productAdd == cloudList[i]
              ) {
                this.productAdd = true;
              }
            }
          })
          .catch(error => {
            console.log(error);
          });
      },
      //重置函数
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      showAddProductDialog() {
        this.dialogVisible = true;
          // 默认处理者下拉框接口
        this.$api.moduleselectorList().then(res => {

          this.realNameList = res.data.data;
        });
         // 问题模板下拉框接口
    this.$api.processTemplateselector().then(res => {
      this.templateId = res.data.data;
    });
        this.$nextTick(() => {
          this.$refs["form"].clearValidate();
        });
      },
      // 新增账号接口
      submitForm(formName) {
        this.$refs["form"].validate(valid => {
          if (valid) {

            this.dialogVisible = false;
            this.$api.problemTypeAdd(this.form).then(res => {
              if (res.data.code == 200) {
                this.$message.success("新增类型成功!");
                this.form = {}
                this.problemTypeList();
              }
            });
          } else {
            return false;
          }
        });
      },

    },
    created() {
      //查询产品列表
      this.problemTypeList();

    }
  };
</script>
<style lang="less" scoped>
  @toolBarFontColor: rgba(17, 17, 17, 0.87);

  .el-row {
    margin: 1rem 3rem;
    line-height: 0;
    min-width: 900px;

    .platform-toolbar {
      position: relative;
      margin: 0;
      border: 1px solid #d9d9d9;

      .title-info {
        height: 46px;
        line-height: 46px;
        font-weight: bold;
        font-size: 0.8rem;
      }

      .el-col {
        padding: 0 3rem;

        .toolbar-right {
          float: right;
          margin-top: 7px;

          &.export {
            margin-left: 1rem;

            .toolbar-btn {
              color: @toolBarFontColor;
              font-size: 0.9rem;

              &.left {
                margin-left: 1.5rem;
              }

              &.right {
                margin-right: 0;
              }
            }
          }
        }
      }

      .title-info {
        float: left;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .result-info-wrapper {
      height: 1rem;
      margin: 1rem 0 0.5rem 0;
      position: relative;

      .setting-panel {
        position: absolute;
        float: right;
        top: 2rem;
        width: 200px;
        height: 200px;
        background: red;
        z-index: 1000;
        border-radius: 3px;
      }

      .result-info {
        height: 1rem;
        line-height: 1rem;
        font-size: 0.875rem;
        float: left;
        color: #898989;

        span {
          color: #292929;
          padding: 0 0.1rem;
        }
      }

      .data-setting {
        font-size: 0.875rem;
        color: #2673bb;
        float: right;

        i {
          padding-right: 0.2rem;
        }

        cursor: pointer;
      }
    }

    .el-table td,
    .el-table th {
      padding: 0 !important;
    }

    .el-pagination {
      margin: 1rem 0;
      float: right;
    }
  }

  .product-content {
    height: 6rem;

    .product-info-item {
      float: left;
      margin-right: 4rem;

      .label-info {
        margin-bottom: 0.5rem;

        label {
          font-size: 0.75rem;

          span {
            color: red;
            margin-right: 0.5rem;
          }
        }
      }

      .el-input {
        width: 10rem;
      }

      .el-select {
        width: 13rem;
      }
    }
  }

  .container-el-row {
    margin-top: 0px;
  }

  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .select-button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
      margin-left: 15px;
    }

    .select-input-right-input {
      float: right;
      margin-top: 7px;
    }
  }

  .table-vv {
    padding: 0rem 3rem;
    margin-top: 1rem;

    .result-info {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      float: left;
      color: #898989;

      span {
        color: #292929;
        padding: 0 0.1rem;
      }
    }

    .el-pagination {
      margin: 1rem 0;
      float: right;
    }
  }
</style>