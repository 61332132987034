import { render, staticRenderFns } from "./questionAdd.vue?vue&type=template&id=e8a94e66&scoped=true&"
import script from "./questionAdd.vue?vue&type=script&lang=js&"
export * from "./questionAdd.vue?vue&type=script&lang=js&"
import style0 from "./questionAdd.vue?vue&type=style&index=0&id=e8a94e66&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8a94e66",
  null
  
)

export default component.exports