<template>
  <div>
    <div class="first">
      <!-- 移动端头部 -->
      <div class="header">

        <div class="homelogos">
          <!-- <img src="../../assets/iphonelogo/meunlogos.svg"> -->
          <img src="../../assets/iphonelogo/homelogo.svg" @click="homelogo()">
        </div>
        <div class="menulogos" @click="expandMenu()">
          <img src="../../assets/iphonelogo/meunlogos.svg">
        </div>
        <!-- <div class="crosslogos" @click="noneMenu">
          <img v-show="isExpandMenu" src="../../assets/iphonelogo/cross.png">
        </div> -->
      </div>

      <div class="content">

        <router-view> </router-view>

        <!-- 移动端下部 -->
        <div class="iphone" v-if="ishomeiphone == 1">
          <!-- 轮播图 -->
          <el-carousel class="banner" height="140vw">
            <el-carousel-item v-for="item in iphonebaner" :key="item.id">
              <img :src="item.adImgUrl" alt="">
            </el-carousel-item>
          </el-carousel>
          <div>
            <!-- 产品中心 -->
            <div class="item-title">
              <p>产品中心</p>
              <span> </span>
            </div>
<!--            <div class="product_6101" @click="ws6101n()">-->
<!--              <div class="product_left">-->
<!--                <img src="../../assets/iphonelogo/6101N1.png">-->
<!--              </div>-->
<!--              <div class="product_right">-->
<!--                <b>NB智能烟雾报警器</b>-->
<!--                <p>JTY-GF-WSH6101N</p>-->
<!--                <div class="grouplogo">-->
<!--                  <img src="../../assets/iphonelogo/Group2.png">-->
<!--                  <img src="../../assets/iphonelogo/Group3.png">-->
<!--                  <img src="../../assets/iphonelogo/Group4.png">-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="product_6200">-->
<!--              <div class="product_right" @click="ws6101()">-->
<!--                <img src="../../assets/iphonelogo/6101_1.png">-->
<!--                <b>独立式烟雾报警器</b>-->
<!--                <p>JTY-GF-WSH6101</p>-->
<!--              </div>-->
<!--              <div class="product_left" @click="ws6200()">-->
<!--                <img src="../../assets/iphonelogo/6200_ 2.png">-->
<!--                <b>独立式燃气报警器</b>-->
<!--                <p>JT-WSH6200 甲烷</p>-->
<!--              </div>-->

<!--            </div>-->
<!--            <div class="product_nb" @click="ws6200n()">-->
<!--              <div class="product_right">-->
<!--                <b>NB智能燃气报警器</b>-->
<!--                <p>JT-WSH6200N 甲烷</p>-->
<!--                <div class="grouplogo">-->
<!--                  <img src="../../assets/iphonelogo/Group6.png">-->
<!--                  <img src="../../assets/iphonelogo/Group5.png">-->

<!--                </div>-->
<!--              </div>-->
<!--              <div class="product_left">-->
<!--                <img src="../../assets/iphonelogo/6101N1.png">-->
<!--              </div>-->

<!--            </div>-->
<!--            <div class="product_6200">-->
<!--              <div class="product_right" @click="ws6201n()">-->
<!--                <img src="../../assets/iphonelogo/6101_1.png">-->
<!--                <b>NB智能燃气报警器</b>-->
<!--                <p>JM-WSH6201N 一氧化碳</p>-->
<!--              </div>-->
<!--              <div class="product_left" @click="ws6201()">-->
<!--                <img src="../../assets/iphonelogo/6200_ 2.png">-->
<!--                <b>独立式燃气报警器</b>-->
<!--                <p>JM-WSH6201 一氧化碳</p>-->
<!--              </div>-->
<!--            </div>-->


            <div class="product_6200">
              <div class="product_right" v-for="(item,index) in this.productList" :key="index"  @click="ws6101(item)">
                <img :src="item.newsImgUrl" class="img">
                <b>{{item.newsTitle}}</b>
                <p>{{ item.newsSubtitle }}</p>
              </div>

            </div>
            <div class="see_more" @click="productPage()">
              查看更多>>
            </div>
            <!-- 新闻资讯 -->
            <div class="item-title_new">
              <p>新闻资讯</p>
              <span> </span>
            </div>
            <div class="news_one" v-if="tableList" @click="newDetails(tableList)">
              <img :src="tableList.newsImgUrl" />
              <div class="news_title">
                <p>{{ tableList.newsTitle |ellipsis }}</p>
                <div class="new_tit_bottom">
                  <span>
                    {{ tableList.newsSubtitle |ellipsistit  }}
                  </span>
                  <span>
                    日期： {{tableList.releaseTime}}
                  </span>
                </div>
              </div>
            </div>
            <p class="segmentation"></p>
            <div class="news_two" v-if="tableList2" @click="newDetails(tableList2)">
              <div class="news_title">
                <p>{{ tableList2.newsTitle |ellipsis }}</p>
                <div class="new_tit_bottom">
                  <span>
                    {{ tableList2.newsSubtitle |ellipsistit  }}
                  </span>
                  <span>
                    日期：{{tableList2.releaseTime}}
                  </span>
                </div>
              </div>
            </div>
            <p class="segmentation"></p>
            <div class="news_two" v-if="tableList3" @click="newDetails(tableList3)">
              <div class="news_title">
                <p>{{ tableList3.newsTitle |ellipsis }}</p>
                <div class="new_tit_bottom">
                  <span>
                    {{ tableList3.newsSubtitle  |ellipsistit }}
                  </span>
                  <span>
                    日期：{{tableList3.releaseTime}}
                  </span>
                </div>
              </div>
            </div>
            <div class="see_more" @click="newspage()">
              查看更多>>
            </div>
          </div>
          <!-- <FooterNav /> -->
        </div>
        <el-dialog :visible.sync="dialogVisible" width="100%" style="margin:0px" top="0vh" height="100vh">
          <div class="iphoneNewscollapse">
            <div class="collapse">
              <span class="titlabe" @click="iphoneProduct">产品中心</span>
              <!-- <el-divider></el-divider> -->
              <el-collapse v-model="activeName" accordion>
                <el-collapse-item title="解决方案" name="1">
                  <div class="solurone" @click="iphoneSolve(item.id)" v-for="(item,key) in solutList" :key="key">
                    <span>{{item.newsTitle}}</span>
                    <el-divider class="divider"></el-divider>

                  </div>

                </el-collapse-item>
              </el-collapse>
              <span class="titlabe" @click="iphoneAppcase()">应用案例</span>
              <el-divider></el-divider>
              <!-- <p class="solutClass"> <span>解决方案</span>  <i class="el-icon-arrow-down"></i></p>  -->
              <!-- <el-divider></el-divider> -->
              <span class="titlabe" @click="iphoneNews()">新闻资讯</span>
              <el-divider></el-divider>
              <span class="titlabe" @click="iphoneExpandMenu()">关于我们</span>

<!--              <span class="titlabe" @click="iphoneDownload()">下载中心</span>-->
              <el-collapse  accordion>
                <el-collapse-item title="下载中心" name="2">
                  <div class="solurone"  v-for="(item,key) in typeList" :key="key" @click="iphoneDownload(key)">
                    <span>{{item}}</span>
                    <el-divider class="divider"></el-divider>

                  </div>

                </el-collapse-item>
              </el-collapse>

              <span class="titlabe" @click="iphoneJoin()">招商加盟</span>
              <el-divider></el-divider>
            </div>



          </div>
        </el-dialog>

        <FooterNav />
      </div>
    </div>

  </div>

</template>
<script>
  import HeadNav from "../../components/HeadNav";

  import FooterNav from "../../components/FooterNav";

  import Storage from "../../utils/Storage";
  import productCenter from "./productCenter";


  export default {
    name: "First",
    components: {
      HeadNav,
      FooterNav,
      productCenter,

    },
    data() {
      return {
        isExpandMenu: false,
        productList:[],
        iphonebaner: [
          // {
          //   id: "1",
          //   url: require("../../assets/iphonelogo/app01.jpg")
          // },
          // {
          //   id: "2",
          //   url: require("../../assets/iphonelogo/app02.jpg")
          // },
          // {
          //   id: "3",
          //   url: require("../../assets/iphonelogo/app03.jpg")
          // }
        ],

        select: "",
        // 图片父容器高度
        bannerHeight: 0,
        bannerWidth: 0,
        // 浏览器宽度
        screenWidth: 0,
        showBac: "",
        ifLogin: "",
        isTop: true,
        tianmaopng: require("../../assets/login/tm.svg"),
        jingdongpng: require("../../assets/login/jd.svg"),
        loginChanges: "吻胜云登录",
        input: "",
        menu_listones: 1,
        shouYe: true,
        selectedIndex: 4,
        show: false,
        show2: false,
        show3: false,
        ishomeiphone: 1,
        show4: false,
        tableList: [], //新闻资讯列表
        tableList2: [], //新闻资讯列表
        tableList3: [], //新闻资讯列表
        tableList4: [], //新闻资讯列表
        solutList: [],
        typeList: ["产品证书","检验报告","物料"],
        current: 1,
        pageSize:4,
        activeName: '2',
        dialogVisible: false,
        innerWidth: "",
        iphonePath: '',
      };
    },
    filters: {
      ellipsis(value) {
        if (!value) return "";
        if (value.length > 24) {
          return value.slice(0, 24) + "...";
        }
        return value;
      },
      ellipsistit(value) {
        if (!value) return "";
        if (value.length > 9) {
          return value.slice(0, 9) + "...";
        }
        return value;
      },
    },
    watch: {
      $route(to, from) {

        // console.log(to.path);
        if (to.path == '/') {

          this.ishomeiphone = 1
          document.querySelector(".content").scrollTop = 0

          // firefox浏览器中是这句
          document.querySelector(".content").scrollTop = 0
          // console.log('首页')
        } else if (to.path == '/iphoneProduct') {
          this.ishomeiphone = 2
          this.dialogVisible = false
          this.isExpandMenu = false
        } else if (to.path == '/iphoneSolve' || to.path == '/iphoneAppCase' ||to.path == '/iphoneDownload' ||to.path == '/appCaseDetail') {
          this.ishomeiphone = 2
          this.isExpandMenu = false
          this.dialogVisible = false
        } else if (to.path == '/iphoneOurselves') {
          this.ishomeiphone = 2
          this.isExpandMenu = false
          this.dialogVisible = false
        } else if (to.path == '/iphoneNews') {
          this.ishomeiphone = 2
          this.isExpandMenu = false
          this.dialogVisible = false
        } else if (to.path == '/iphoneJoin') {
          this.ishomeiphone = 2
          this.isExpandMenu = false
          this.dialogVisible = false
        } else if (to.path == '/iphoneExpandMenuelse') {
          this.ishomeiphone = 2

        } else if (to.path == '/iphoneNewsDetails') {
          this.ishomeiphone = 2
          this.dialogVisible = false
        } else if (to.path == '/ws6101n') {
          this.ishomeiphone = 2
          this.dialogVisible = false
        } else if (to.path == '/ws6101' || to.path == '/ws6200' ||
          to.path == '/ws6200n' || to.path == '/ws6201n' ||
          to.path == '/ws6201' || to.path == '/ws6103w' ||
          to.path == '/ws6103' || to.path == '/ws6103n' ||
          to.path == '/wsYjsd' || to.path == '/wsMht' ||
          to.path == '/wsJylh' || to.path == '/wsj500' || to.path == '/wsj950' ||to.page == '/dynamicPage'
        ) {
          this.ishomeiphone = 2
          this.dialogVisible = false
        }
        if (from.path == '/iphoneExpandMenuelse') {
          this.isExpandMenu = sessionStorage.getItem("isExpandMenu")

        }


      },
      bannerWidth(item) {
        if (item < 750) {
          // this.$router.replace({
          //   path: `/iphone`,

          // })
        }
      }
    },
    mounted() {
      this.bannerWidth = window.innerWidth;
      this.bannerHeight = window.innerWidth * 0.44
      console.log(this.bannerHeight, this.bannerWidth)
      document.body.scrollTop = 0
      // firefox浏览器中是这句
      document.documentElement.scrollTop = 0
      // 首次加载时,需要调用一次
      this.screenWidth = window.innerWidth;
      this.setSize();
      // 窗口大小发生改变时,调用一次
      window.onresize = () => {
        this.screenWidth = window.innerWidth;
        this.setSize();
      }
      // console.log(this.$route.path)
      if (this.$route.path == '/productCenter') {
        this.menu_listones = 2
      } else if (this.$route.path == '/iphoneProduct') {
        this.ishomeiphone = 2
      } else if (this.$route.path == '/iphoneSolve') {
        this.ishomeiphone = 2
      } else if (this.$route.path == '/iphoneNews') {
        this.ishomeiphone = 2
      } else if (this.$route.path == '/iphoneOurselves') {
        this.ishomeiphone = 2
      } else if (this.$route.path == '/iphoneJoin') {
        this.ishomeiphone = 2
      } else if (this.$route.path == '/iphoneExpandMenu') {

        this.ishomeiphone = 2
      } else if (this.$route.path == '/iphoneAppCase' || this.$route.path == '/iphoneDownload') {

        this.ishomeiphone = 2
      } else if (this.$route.path == '/iphoneNewsDetails') {
        this.ishomeiphone = 2
      } else if (this.$route.path == '/ws6101n') {
        this.ishomeiphone = 2
      } else if (this.$route.path == '/ws6101' || this.$route.path == '/ws6200' ||
        this.$route.path == '/ws6200n' || this.$route.path == '/ws6201n' ||
        this.$route.path == '/ws6201' || this.$route.path == '/ws6103w' ||
        this.$route.path == '/ws6103' || this.$route.path == '/ws6103n' ||
        this.$route.path == '/wsYjsd' || this.$route.path == '/wsMht' ||
        this.$route.path == '/wsJylh' || this.$route.path == '/wsj500' || this.$route.path == '/wsj950' || this.$route.path  == '/dynamicPage') {
        this.ishomeiphone = 2
      }
      this.newsList()
      this.solutionList()
      this.advertisementList()
      this.getProductList()

    },

    created() {
      if (Storage.getItem("token")) {
        let Base64 = require("js-base64").Base64;
        let token = Storage.getItem("token");
        let array = token.split(".");
        let userinfo = Base64.decode(array[1]);
        let time = JSON.parse(userinfo).exp * 1000;
        // chrome

        if (Date.now() < time) {
          this.showBac = true;
          this.ifLogin = false;
        } else {
          this.showBac = false;
          this.ifLogin = true;
        }
      } else {
        this.showBac = false;
        this.ifLogin = true;
      }
    },

    methods: {
          //请求列表数据函数
    advertisementList() {
         this.$api
        .advertisementList({
          queryDto: {
            mark: '3',
          }
        })
        .then(res => {
          // console.log(res.data.data)
            this.iphonebaner = res.data.data.records
        });
      },
      iphoneExpandMenu() {
        this.$router.push({
          path: "/iphoneOurselves"
        });
         this.dialogVisible = false
          this.isExpandMenu = false
      },
      iphoneDownload(index) {
        this.$router.push({
          path: "/iphoneDownload",
          query:{
            id:index
          }
        });
        this.dialogVisible = false
        this.isExpandMenu = false
      },
      iphoneJoin() {
        this.$router.push({
          path: "/iphoneJoin"
        });
         this.dialogVisible = false
          this.isExpandMenu = false
      },
      iphoneAppcase() {
        this.$router.push({
          path: "/iphoneAppCase"
        });
        this.dialogVisible = false
        this.isExpandMenu = false
      },
      iphoneProduct() {

        this.$router.push({
          path: "/iphoneProduct"
        });
              this.dialogVisible = false
          this.isExpandMenu = false
      },
      iphoneSolve(id) {
        this.$router.push({
          path: "/iphoneSolve",
          query: {
            id: id
          }
        });
         this.dialogVisible = false
          this.isExpandMenu = false
      },
      iphoneNews() {
        this.$router.push({
          path: "/iphoneNews"
        });
         this.dialogVisible = false
          this.isExpandMenu = false
      },
      setSize() {
        // 通过浏览器宽度(图片宽度)计算高度

        // this.bannerHeight = 850 / 1920 * this.screenWidth;
        // this.bannerHeight = this.screenWidth*0.44
      },
      newspage() {
        this.$router.push({
          path: "/iphoneNews"
        });
      },
      productPage() {
        this.$router.push({
          path: "/iphoneProduct"
        });
      },
      homelogo() {
        // this.isExpandMenu = false
        this.$router.push({
          path: "/"
        });
      },
      // 首页跳转新闻资讯详情
      newDetails(data) {

        if(data.clickUrl == null || data.clickUrl == ""){
          this.$router.push({
            path: "/iphoneNewsDetails",
            query: {
              crumbsTitle: "首页",
              id: data.id,
              current: this.current,
              size: this.pageSize
            }
          });
        }else {
          window.open(
              data.clickUrl,
              "_blank"
          );

        }
      },
      // ws6101n() {
      //   this.$router.push({
      //     path: "/ws6101n",
      //     query: {
      //       crumbsTitle: "首页",
      //     }
      //   });
      // },
      ws6101(data) {
        this.ishomeiphone = 2
        this.$router.push({
          path: `/dynamicPage`,
          query: {
            id: data.id,
            newsContent: data.newsContent,
            crumbsTitle: "首页",
            // activeName:this.activeName,

          }
        });
      },
      ws6200() {
        this.$router.push({
          path: "/ws6200",
          query: {
            crumbsTitle: "首页",
          }
        });
      },
      ws6200n() {
        this.$router.push({
          path: "/ws6200n",
          query: {
            crumbsTitle: "首页",
          }
        });
      },
      ws6201n() {
        this.$router.push({
          path: "/ws6201n",
          query: {
            crumbsTitle: "首页",
          }
        });
      },
      ws6201() {
        this.$router.push({
          path: "/ws6201",
          query: {
            crumbsTitle: "首页",
          }
        });
      },


      goBackstage() {
        this.$router.replace("/mainRouter");
      },
      goUrl(url) {
        window.open(url, "_blank");
      },

      checkMore() {
        this.menu_listones = 2;
        this.$router.push({
          path: "/productCenter"
        });
      },
      //查看更多跳转新闻资讯
      checkMoreNew() {
        this.menu_listones = 4;
        this.$router.push({
          path: "/newsInformation"
        });
      },
      getProductList() {
        this.$api
            .productListForAppHome({

            })
            .then(res => {
              this.productList = res.data.data.records;

            })
            .catch(error => {
              console.log(error);
            });
      },

      //新闻资讯列表
      newsList() {
        this.$api
          .newsListApp({
            page: {
              current: this.current,
              size: this.pageSize
            }
          })
          .then(res => {
            if (res.data.code == "200") {
              // console.log(res.data.data.records)
              this.tableList = res.data.data.records[0];
              this.tableList2 = res.data.data.records[1];
              this.tableList3 = res.data.data.records[2];
              this.tableList4 = res.data.data.records[3];

            }
          })
          .catch(error => {
            console.log(error);
          });
      },
      //解决方案列表
      solutionList() {
        this.$api
          .solutionListApp()
          .then(res => {
            // console.log(res.data.data)
            this.solutList = res.data.data
          })
          .catch(error => {
            console.log(error);
          });
      },
      handleCommand(command) {
        console.log(command)
        this.$router.push({
          path: "/solution",
          query: {
            id: command.id
          }
        });
        // console.log(command.id)
      },
      mouseOver() {
        this.tianmaopng = require("../../assets/login/tm-h.svg")
      },
      mouseLeave() {
        this.tianmaopng = require("../../assets/login/tm.svg")
      },
      mouseOverJd() {
        this.jingdongpng = require("../../assets/login/jd-h.svg")
      },
      mouseLeaveJd() {
        this.jingdongpng = require("../../assets/login/jd.svg")
      },
      expandMenu() {
        console.log('点开菜单')
        this.dialogVisible = true
        // this.ishomeiphone = 2
        //  this.ishomeiphone =  3
        // this.isExpandMenu = !this.isExpandMenu
        // console.log(this.isExpandMenu)


        // console.log(this.isExpandMenu)
      },
      // 关掉菜单
      noneMenu() {
        this.isExpandMenu = false
        this.$router.go(-1)
      }

    },
    destroyed() {
      //  window.removeEventListener('scroll', this.scrollToTop)
    },

  };
</script>
<style lang="less" scoped>
  .el-scrollba {
    // &.is-vertical{
    width: 50px; //滚动条宽度
    // }
  }







  /* 移动端的CSS样式代码 */
  /* 移动端的CSS样式代码 */
  /* 移动端的CSS样式代码 */


  html,
  body {
    // overflow: hidden;
    // overflow-y: auto;
    background-color: #F2F2F6;
  }

  .first {
    min-width: 100px;
  }

  .content {
    overflow-y: auto;
  }

  .webpage,
  .menuNavigation {
    display: none;
  }

  .iphone,
  .header {
    display: block;

  }

  .iphone {
    margin-top: 14vw;
    padding-bottom: 13.3vw;

    background-color: #F2F2F6;

  }

  .header {
    width: 96%;
    // height:7rem;
    height: 14vw;
    padding: 0 2%;
    background-color: #ffffff;
    box-shadow: 0 0 2px 0 rgb(197, 196, 196);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
  }

  .header {


    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  .header .homelogos {

    display: inline-block;
    // height: 8vw;
    text-align: left;
    // border:1px solid red;
    width: 17.88vw;
    vertical-align: middle;
  }

  .header .menulogos {
    display: inline-block;
    width: 4vw;
    vertical-align: middle;
    padding-left: 5.3vw;

  }

  .header .crosslogos {
    display: inline-block;
    padding-right: 5.3vw;
    // width: 4vw;
  }

  .header .homelogos img,
  .crosslogos img,
  .menulogos img {
    height: 100%;
    width: 100%;
    vertical-align: middle;
    //  border:1px solid red;
  }

  .banner img {
    width: 100%;
  }

  .expandMenu {
    margin: 2rem 0;
    font-size: 1.5rem;
    color: #F1CD69;
  }


  .item-title {
    padding-top: 10vw;
    padding-bottom: 4.8vw;
    text-align: center;
    color: #333333;
    width: 100%;
    letter-spacing: 1px;
    margin: 0 auto;
    font-size: 5.6vw;

    span {
      width: 17.4vw;
      margin: 0 auto;
      height: 2px;
      padding-top: 1px;
      display: block;
      background: #F1A948;
    }
  }

  .item-title_new {
    padding-top: 10.6vw;
    text-align: center;
    padding-bottom: 4.8vw;
    color: #333333;
    width: 100%;
    letter-spacing: 1px;
    margin: 0 auto;
    font-size: 5.6vw;

    span {
      width: 17.4vw;
      margin: 0 auto;
      height: 2px;
      padding-top: 1px;
      display: block;
      background: #F1A948;
    }
  }

  .product_6101,
  .product_nb {
    width: 94.6vw;
    height: 50.6vw;
    margin: auto;
    margin-bottom: 2vw;
    border-radius: 5px;
    box-shadow: 0 0 2px 0 rgb(197, 196, 196);
    display: flex;
    background: #ffffff;

    .product_left {
      width: 50%;
      height: 100%;
      // border: #1da3f1 1px solid;
      display: flex;
      align-items: center;
      vertical-align: middle;

      img {
        width: 36vw;
        margin: 0 auto;
        display: inline-block;
        align-items: center;
      }
    }

    .product_right {
      // border: #1da3f1 1px solid;
      width: 50%;
      text-align: center;
      height: 100%;
      vertical-align: middle;

      b {
        color: #333;
        font-size: 4.2vw;
        display: inline-block;
        margin-top: 8vw;
      }

      p {
        color: #666;
        font-size: 3.7vw;
      }

      .grouplogo {
        width: 40vw;
        margin: 0 auto;
        padding-top: 8vw;
        display: flex;
        align-items: center;
        justify-content: space-around;
        vertical-align: middle;

        img {
          height: 15.7vw;
        }
      }
    }
  }
  .product_6200 {
    width: 94.6vw;
    min-height: 80vw;
    border-radius: 5px;
    //  height: 56.8vw;
    margin: auto;
    margin-bottom: 2vw;
    // box-shadow: 0 0 2px 0 rgb(197, 196, 196);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .product_right {
      width: 49%;
      height: 56.8vw;
      border-radius: 5px;
      text-align: center;
      margin-bottom: 2vw;
      vertical-align: middle;
      background: #f7f7f7;
      // border: #12b7f5 1px solid;
      // height: 100%;



      .img {
        padding-top: 4vw;
        display: inline-block;
        align-items: center;
        width: 32.6vw;
        height: 32.6vw;
      }


      b {
        color: #333;
        font-size: 4.2vw;
        display: inline-block;
        margin-top: 4vw;
      }

      p {
        color: #666;
        font-size: 3.7vw;
      }
    }

    .product_left {
      width: 49%;
      border-radius: 5px;
      text-align: center;
      vertical-align: middle;
      background: #f7f7f7;
      // border: #12b7f5 1px solid;
      height: 100%;

      .img {
        padding-top: 4vw;
        display: inline-block;
        align-items: center;
        width: 30.1vw;
        height: 32.6vw;
      }

      b {
        color: #333;
        font-size: 4.2vw;
        display: inline-block;
        margin-top: 4vw;
      }

      p {
        color: #666;
        font-size: 3.7vw;
      }
    }
  }

  //.product_6200 {
  //  width: 94.6vw;
  //  border-radius: 5px;
  //  height: 56.8vw;
  //  margin: auto;
  //  margin-bottom: 2vw;
  //  box-shadow: 0 0 2px 0 rgb(197, 196, 196);
  //  display: flex;
  //  justify-content: space-between;
  //
  //  .product_right {
  //    width: 49%;
  //    border-radius: 5px;
  //    text-align: center;
  //    vertical-align: middle;
  //    background: #ffffff;
  //    // border: #12b7f5 1px solid;
  //    height: 100%;
  //
  //    img {
  //      padding-top: 4vw;
  //      display: inline-block;
  //      align-items: center;
  //      width: 32.6vw;
  //      height: 32.6vw;
  //    }
  //
  //    b {
  //      color: #333;
  //      font-size: 4.2vw;
  //      display: inline-block;
  //      margin-top: 4vw;
  //    }
  //
  //    p {
  //      color: #666;
  //      font-size: 3.7vw;
  //    }
  //  }
  //
  //  .product_left {
  //    width: 49%;
  //    border-radius: 5px;
  //    text-align: center;
  //    vertical-align: middle;
  //    background: #ffffff;
  //    // border: #12b7f5 1px solid;
  //    height: 100%;
  //
  //    img {
  //      padding-top: 4vw;
  //      display: inline-block;
  //      align-items: center;
  //      width: 30.1vw;
  //      height: 32.6vw;
  //    }
  //
  //    b {
  //      color: #333;
  //      font-size: 4.2vw;
  //      display: inline-block;
  //      margin-top: 4vw;
  //    }
  //
  //    p {
  //      color: #666;
  //      font-size: 3.7vw;
  //    }
  //  }
  //}

  .see_more {
    padding-top: 6.4vw;
    width: 94.6vw;
    margin: auto;
    font-size: 4vw;
    color: #666;
    text-align: right;
  }

  .news_one {
    width: 94.6vw;
    border-radius: 5px;
    height: 70.6vw;
    margin: auto;
    background: #ffffff;

    img {
      width: 100%;
      height: 40.8vw;
    }

    .news_title {
      width: 85.3vw;
      margin: auto;

      p {
        padding: 4.4vw 0;
        font-size: 4.2vw;
        color: #333;
      }

      .new_tit_bottom {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;

        span:nth-child(1) {
          font-size: 3.7vw;
          color: #666;
        }

        span:nth-child(2) {
          font-size: 2.9vw;
          color: #999;
        }
      }
    }
  }

  .segmentation {
    height: 2.4vw;
  }

  .news_two {
    width: 94.6vw;
    border-radius: 5px;
    height: 32vw;
    margin: auto;
    background: #ffffff;

    .news_title {
      width: 85.3vw;
      margin: auto;

      p {
        padding: 4.4vw 0;
        font-size: 4.2vw;
        color: #333;
      }

      .new_tit_bottom {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;

        span:nth-child(1) {
          font-size: 3.7vw;
          color: #666;
        }

        span:nth-child(2) {
          font-size: 2.9vw;
          color: #999;
        }
      }
    }





  }

  /deep/.el-dialog__body {
    //  border: #333 1px solid;
  }

  .iphoneNewscollapse {
    // margin-top: 14vw;
    height: 100vh;
    font-size: 4vw;
    color: #333;
    background: #ffffff;
    padding: 0 7.6vw;
  }

  .collapse {
    padding-top: 8vw;
  }

  /deep/.el-collapse {
    // margin: 4vw 0;
    font-size: 4vw;

    .solutClass {
      width: 100%;
      display: block;
      border: #333 1px solid;
      justify-content: space-between;
    }
  }

  /deep/.el-collapse-item__header {
    font-size: 4vw;
    padding: 1vw 0;
    color: #333333 !important;
    font-weight: 400;
  }

  .el-collapse-item {
    font-size: 4vw;
  }

  /* el-divider 修改高度&虚线效果 */
  /deep/.el-divider {
    margin: 0vw 0;
    background: 0 0;
    border-top: 1px solid #e8eaec;
  }

  .divider {
    border-top: 1px dashed #999;
    margin: 3vw 0;
  }

  .solurone:last-child {
    .divider {
      display: none;
    }
  }

  .solurone {
    color: #666;
    margin-top: 2vw;
    padding: 0 2vw;
  }

  .titlabe {
    margin: 4vw 0;
    width: 100%;
    display: inline-block;
  }
</style>
