<template>
    <div> <el-menu
      default-active="2"
      class="el-menu-vertical-demo"
     
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b">
      <el-submenu index="1">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>导航一</span>
        </template>
        <el-menu-item-group>
         
          <el-menu-item index="1-1">选项1</el-menu-item>
          <el-menu-item index="1-2">选项2</el-menu-item>
        </el-menu-item-group>
      
      </el-submenu>
        <el-submenu index="2">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>导航二</span>
        </template>
        <el-menu-item-group>
         
          <el-menu-item index="2-1">选项3</el-menu-item>
          <el-menu-item index="2-2">选项4</el-menu-item>
        </el-menu-item-group>
      
      </el-submenu>
    </el-menu></div>
</template>
<script>
export default {
  name: 'SideNav',
//   props: {
//     msg: String
//   }
}
</script>