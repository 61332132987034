<template>
  <div>
    <div class="outting">
      <div class="select">
        <span class="title">场地列表</span>
        <el-button
          type="text"
          size="small"
          icon="el-icon-delete"
          @click="deleteUserHandler"
          class="addRoleButton"
        >批量删除</el-button>
        <!-- <el-button
          type="text"
          size="small"
          icon="el-icon-plus"
          @click="addUserHandler"
          class="addRoleButton"
        >新增</el-button>-->
        <el-button
          type="text"
          size="small"
          icon="el-icon-bottom"
          @click="playShow"
          class="serachRoleButton"
        >高级查询</el-button>
        <div style="float:right;line-height:46px;">
          <el-input
            v-model="inputPlaceName"
            prefix-icon="el-icon-search"
            placeholder="请输入名称"
            size="small"
            @keyup.enter.native="search"
          ></el-input>
        </div>
      </div>
      <div class="gaojiSearch" v-show="ifSearchMany">
        <div style="width:100%;overflow: hidden;">
          <div style="float:left">
            <div class="grid-content bg-purple-dark">
              <span>手机查询</span>
              <div style="margin-top:5px;">
                <el-input
                  v-model="inputMobile"
                  prefix-icon="el-icon-search"
                  placeholder="请输入手机号"
                  size="small"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
        <div style="width:100%;overflow: hidden;margin-top:.5rem;">
          <div style="float:right;">
            <div class="grid-content bg-purple-dark">
              <div class="searchBut">
                <el-button size="small" type="primary" @click="checkHandler">搜索</el-button>
              </div>
              <div class="emptyBut">
                <el-button size="small" @click="emptyHandler">清空</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="placeTable">
        <el-table
          :data="tableData"
          style="width: 100%"
          stripe
          size="small"
          :header-cell-style="{'background-color': '#eeeeee'}"
          @select="getDeleteIds"
          @select-all="getAllDeleteIds"
        >
          <el-table-column type="selection" min-width="1"></el-table-column>
          <el-table-column label="序号" type="index" min-width="1" fixed align="center"></el-table-column>
          <el-table-column prop="placeId" label="场地ID" min-width="1" fixed align="center">
            <template slot-scope="{row}">{{ row.placeId || '暂无数据' }}</template>
          </el-table-column>
          <el-table-column prop="placeName" label="名称" min-width="1" fixed align="center">
            <template slot-scope="{row}">{{ row.placeName || '暂无数据' }}</template>
          </el-table-column>
          <el-table-column prop="location" label="地址" min-width="1" fixed align="center">
            <template slot-scope="{row}">{{ row.location || '暂无数据' }}</template>
          </el-table-column>
          <el-table-column prop="createUserName" label="用户名" min-width="1" fixed align="center">
            <template slot-scope="{row}">{{ row.createUserName || '暂无数据' }}</template>
          </el-table-column>
          <el-table-column prop="mobile" label="用户手机" min-width="1" fixed align="center">
            <template slot-scope="{row}">{{ row.mobile || '暂无数据' }}</template>
          </el-table-column>
          <el-table-column min-width="1" label="操作" width="180" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                icon="el-icon-info"
                @click="handleDetail(scope.$index, scope.row)"
              >详情</el-button>
              <el-popconfirm title="确定删除这个场地吗？" @onConfirm="deleteDataPoint()">
                <el-button
                  type="text"
                  size="small"
                  icon="el-icon-delete"
                  slot="reference"
                  @click="handleDelete(scope.$index, scope.row)"
                  style="margin-left:10px;"
                >删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          class="pagin"
          @current-change="changeCurrent"
          :page-size="10"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PlaceList",
  data() {
    return {
      inputPlaceName: "",
      inputMobile: "",
      ifSearchMany: false,
      tableData: [],
      total: 0,
      current: "",
      deletePlaceId: "",
      //批量删除id
      deleteManyList: []
    };
  },
  created() {
    this.$api
      .placeList({
        page: {
          current: 1,
          size: 10
        }
      })
      .then(res => {
        console.log(res.data.data.records);
        this.total = res.data.data.total;
        this.tableData = res.data.data.records;
      });
  },
  methods: {
    //新增场地函数
    addUserHandler() {},
    //高级查询函数
    playShow() {
      if (this.ifSearchMany == false) {
        this.ifSearchMany = true;
      } else {
        this.ifSearchMany = false;
      }
    },
    //查询回车函数
    search() {
      console.log("触发回车");
      this.$api
        .placeList({
          page: {
            current: 1,
            size: 10
          },
          query: {
            mobile: this.inputMobile,
            placeName: this.inputPlaceName
          }
        })
        .then(res => {
          console.log(res.data.data);
          this.total = res.data.data.total;
          this.tableData = res.data.data.records;
        });
    },
    //批量删除
    deleteUserHandler() {
      if (this.deleteManyList.length == 0) {
        this.$message.error("请先勾选要批量删除的场地!");
      } else {
        this.$api.deletePlace(this.deleteManyList).then(res => {
          console.log(res);
          if (res.data.code == 200) {
            this.$message.success("删除成功!");
            this.$api
              .placeList({
                page: {
                  current: this.current,
                  size: 10
                },
                query: {
                  mobile: this.inputMobile,
                  placeName: this.inputPlaceName
                }
              })
              .then(res => {
                console.log(res.data.data);
                this.total = res.data.data.total;
                this.tableData = res.data.data.records;
              });
          }
        });
      }
    },
    //查询函数
    checkHandler() {
      this.$api
        .placeList({
          page: {
            current: 1,
            size: 10
          },
          query: {
            mobile: this.inputMobile,
            placeName: this.inputPlaceName
          }
        })
        .then(res => {
          console.log(res.data.data);
          this.total = res.data.data.total;
          this.tableData = res.data.data.records;
        });
    },
    //清空函数
    emptyHandler() {
      this.inputPlaceName = "";
      this.inputMobile = "";
    },
    //表格详情函数
    handleDetail(index, row) {
      this.$router.push({
        path: `/placeDetail`,
        query: { placeId: row.placeId }
      });
    },
    //表格删除函数
    handleDelete(index, row) {
      console.log(index, row);
      this.deletePlaceId = Number(row.placeId);
    },
    //分页查询函数
    changeCurrent(id) {
      console.log(id);
      this.current = id;
      this.$api
        .placeList({
          page: {
            current: this.current,
            size: 10
          },
          query: {
            mobile: this.inputMobile,
            placeName: this.inputPlaceName
          }
        })
        .then(res => {
          console.log(res.data.data);
          this.total = res.data.data.total;
          this.tableData = res.data.data.records;
        });
    },
    //单个删除
    deleteDataPoint() {
      var deleteArr = [];
      deleteArr.push(this.deletePlaceId);
      this.$api.deletePlace(deleteArr).then(res => {
        console.log(res);
        if (res.data.code == 200) {
          this.$message.success("删除成功!");
          this.$api
            .placeList({
              page: {
                current: this.current,
                size: 10
              },
              query: {
                mobile: this.inputMobile,
                placeName: this.inputPlaceName
              }
            })
            .then(res => {
              console.log(res.data.data);
              this.total = res.data.data.total;
              this.tableData = res.data.data.records;
            });
        }
      });
    },
    //批量选中删除
    getDeleteIds(selection, row) {
      var arr = [];
      for (var i = 0; i < selection.length; i++) {
        arr.push(selection[i].placeId);
      }
      console.log(arr);
      this.deleteManyList = arr;
    },
    //全部批量选中
    getAllDeleteIds(selection) {
      var arr = [];
      for (var i = 0; i < selection.length; i++) {
        arr.push(selection[i].placeId);
      }
      console.log(arr);
      this.deleteManyList = arr;
    }
  }
};
</script>
<style lang="less" scoped>
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;
.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  .gaojiSearch {
    margin-top: 10px;
    padding: 1rem 3rem;
    margin-bottom: 10px;
    background-color: #f4f6fa;
    .searchBut {
      float: right;
      margin-left: 20px;
    }

    .emptyBut {
      float: right;
    }
  }
  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;
    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }
    .addRoleButton {
      margin-left: 15px;
    }

    .serachRoleButton {
      margin-left: 15px;
    }

    button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
    }
  }
  .placeTable {
    padding: 0rem 3rem;
    margin-top: 1rem;
    .el-pagination {
      margin: 1rem 0;
      float: right;
    }
  }
}
.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

// .bg-purple-dark {
//   background: #99a9bf;
// }
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>