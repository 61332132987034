<template>
  <div class="aboutUs">

    <div class="block">
      <div class="lunbotu">
        <!-- <el-carousel-item > -->
        <!-- <img src="../../assets/merchantJoin/joinbanner.jpg" class="image" /> -->
        <!-- </el-carousel-item> -->
      </div>
    </div>

    <div class="titleDisplay" :style="backgroundDiv">
      <div class="major_service">
        <!-- <p> -->
           <img src="../../assets/merchantJoin/10_01.jpg" class="text_list" />
                <img src="../../assets/merchantJoin/10_02.jpg" class="text_list" />
                <img src="../../assets/merchantJoin/10_03.jpg" class="text_list" />
                <img src="../../assets/merchantJoin/10_04.jpg" class="text_list" />
                <img src="../../assets/merchantJoin/10_05.jpg" class="text_list" />
                <img src="../../assets/merchantJoin/10_06.jpg" class="text_list" />
                <img src="../../assets/merchantJoin/10_07.jpg" class="text_list" />
                <img src="../../assets/merchantJoin/10_08.jpg" class="text_list" />
                <img src="../../assets/merchantJoin/10_09.jpg" class="text_list" />
                <img src="../../assets/merchantJoin/10_10.jpg" class="text_list" />
        <!-- </p> -->

      </div>


      <div style="clear: both;"></div>
    </div>
  </div>
</template>
<script>
  import Storage from "../../utils/Storage";

  export default {
    name: "aboutUs",
    components: {},
    data() {
      return {
    //       backgroundDiv : {
    //    backgroundImage:'url('+ require('../../assets/merchantJoin/bg2.jpg') + ')',
    // backgroundRepeat: 'no-repeat',
    // backgroundSize:' 100% ',
    //   },  
        select: "",
        showBac: "",
        ifLogin: "",
        isTop: true,
        loginChanges: "登录",
        input: "",
        menu_listones: 5,

      };
    },
    mounted() {
      window.addEventListener('scroll', this.scrollToTop)
      const that = this
      let ispeed = Math.floor(-that.scrollTop / 5)
      console.log(ispeed)
      document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
    },
    created() {

    },
    methods: {
      scrollToTop() {
        const that = this
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        that.scrollTop = scrollTop
        that.btnFlag = false
      },

      goUrl(url) {
        window.open(url, "_blank");
      }
    },
    destroyed() {
      window.removeEventListener('scroll', this.scrollToTop)
    },
  };
</script>
<style lang="less" scoped>
  @media screen and (min-width: 0px) and (max-width: 1200px) {
    .aboutUs {
      background-color: #fafafa;
      overflow-x: auto;
      min-width: 1000px;
    }
  }

  @media screen and (min-width: 1201px) {
    .aboutUs {
      background-color: #fafafa;
      overflow-x: auto;
      min-width: 1300px;
    }
  }


  @media screen and (min-width: 0px) and (max-width: 1370px) {
    .block {
      width: 100%;

      //  margin-top:5rem;
      .lunbotu {
        /deep/.el-carousel__container {
          width: 100%;
          height: 36rem;

        }

        height: 100%;
        width: 100%;
        background-color: #efefef;

        .image {
          width: 100%;
          height: 100%;
        }
      }

      /deep/.el-carousel__button {
        border: 3px solid #f28c02;
        border-radius: 5px 5px;
        padding: 5px 10px;
      }
    }
  }

  @media screen and (min-width: 1371px) {
    .block {
      width: 100%;

      // margin-top:5rem;
      .lunbotu {
        /deep/.el-carousel__container {
          width: 100%;
          height: 40.625rem;

        }

        height: 100%;
        width: 100%;
        background-color: #efefef;

        .image {
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      /deep/.el-carousel__button {
        border: 3px solid #f28c02;
        border-radius: 5px 5px;
        padding: 5px 10px;
      }

      // .el-carousel__container {
      //   position: relative;
      //   height: 450 / 16rem;
      // }
    }

  }

  .major_service {
    width: 100%;
    // background: #ffffff;
    padding-bottom: 8rem;
    padding-top: 8rem;
    text-align: center;



    .text_list {
      width: 62.5%;
      // margin: 0 auto;
      font-size: 0;
      line-height: 2.5rem;
      padding: 0;
      //  display: block;
      color: #333333;
      vertical-align: middle;
      // letter-spacing: 5px;
      // padding: 3rem 0 20px 0;
    }
  }

  // 菜单导航样式
  .menuNavigation {
    // margin-top: 41px;
    width: 100%;
    margin-left: 10px;
    height: 80px;
    // padding-left: 20%;
    //  border: 1px rebeccapurple solid;
    background-color: #fff;

    .el-button {
      padding: 1px 1px 1px 10px;
    }

    .imgLogo {
      // border: #12b7f5 1px solid;
    }

    img {
      padding-left: 78%;
      width: 50px;
      height: 50px;
    }

    /deep/.el-input-group__append,
    .el-input-group__prepend {
      background-color: #fff !important;
    }

    .menu_list {
      height: 80px;
      color: #a1a1a1;
      // border: 1px rebeccapurple solid;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .active {
        color: red;
        transition: all 0.8s;
        background: #000;
        color: #fff;
      }
    }

    ul {
      width: 100%;
      padding-left: 3rem;
      text-align: center;
      border-bottom: #dddddd;

      div {
        padding: 0;
        margin: 0;
      }

      li {
        color: #a1a1a1;
        display: inline-block;
        height: 80px;
        line-height: 80px;
        margin: 0 auto;

        vertical-align: middle;
        text-align: center;

        span {
          display: inline-block;
          // padding: 8px 2px 0 2px;
        }

        list-style: none;
        cursor: pointer;

        //transition: font-size 0.5s, font-weight 0.5s;
        //navSelect
        .selected_marker {
          width: 0;
          background: #a1a1a1;
          //text-align: center;
          margin-top: 17px;
          margin-left: 0;
          margin-right: 0;
          height: 3px;
          border: none;
          transition: width 0.2s;
          overflow: hidden;
        }

        .selected_marker_left {
          float: left;
        }

        .selected_marker_right {
          float: right;
        }

        //.navSelect{
        //  font-weight: bold;
        //}
        //margin-bottom: 1px;
        &:hover {
          //background: #128bf533;
          //font-size: 1rem;
          //font-weight: bolder;
          //border-bottom: 2px solid #12b7f5;

          .selected_marker {
            //vertical-align: bottom;
            //text-align: center;
            width: 100%;
          }
        }
      }
    }
  }

  .middle_show {
    width: 80%;
    // border: 1px solid red;
    margin: 0 auto;
    height: auto;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;

    .middle_showchild {
      width: 20%;
      text-align: center;
      height: 100%;
      border: 1px solid #fff;
      background-color: #fff;

      img {
        width: 70%;
        // border: 1px solid red;
        margin: 0 auto;
        margin-top: 7%;
      }

      b {
        font-size: 1rem;
        color: #000;
      }

      p {
        padding: 15px 0 15px 0;
      }

      .el-button {
        margin-bottom: 20px;
      }
    }
  }

  .titleDisplay {
    // background-color: #efefef;
    width: 100%;
  
   height: 100%;
    // padding-bottom: 3rem;
    .item-title {
      padding-top: 6rem;
      padding-bottom: 1rem;
      text-align: center;
      color: #333333;
      width: 100%;
      margin: 0 auto;
      font-size: 2.1rem;
      font-weight: 500;

      p {
        text-shadow: 1px 1px 1px #666;
        //  text-decoration: underline;
        // border-bottom: 8px solid #f28c02;
        display: inline-block;
        padding: 0 50px;
        letter-spacing: 5px;
        font-weight: 550;
      }

      span {
        width: 14%;
        margin: 0 auto;
        height: 8px;
        margin-top: -10px;
        display: block;
        // background: #f28c02;
        background: rgba(242, 140, 2, 0.7);
      }
    }

    .center_content {
      width: 62.5%;
      margin: 0 auto;
      padding-top: 3rem;
      //  border: 1px solid red;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left_content {
        width: 30%;

        .image {
          width: 80%;
        }
      }

      .right_content {
        width: 65%;

        .text_list2 {
          width: 98%;
          margin: 0 auto;
          font-size: 1.5rem;
          line-height: 2.5rem;
          color: #333333;
          letter-spacing: 3px;
          padding: 1.5rem 0 10px 0;
        }
      }
    }
  }
</style>