<template>
    <div>
        <div class="outting">
            <div class="select">
                <span class="title">商品修改</span>
                <el-button type="text" size="small" icon="el-icon-back" @click="gobackPlaceDetail"
                    class="addRoleButton">返回商品列表</el-button>
            </div>
            <div class="detail">
                <el-card class="box-card" shadow="never">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
                    
                        <el-row>
                            <el-col :span="10">
                                <el-form-item label="商品名称" prop="name">
                                    <el-input v-model.trim="ruleForm.name" style="width:90%;margin-bottom:10px"
                                        placeholder="请输入商品名称"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="0">
                                <el-form-item label="商品原价" prop="oriPrice">
                                    <el-input v-model.trim="ruleForm.oriPrice" style="width:90%;margin-bottom:10px"
                                        placeholder="请输入商品原价"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                   <el-row>
                            <el-col :span="10">
                                <el-form-item label="商品实际价格" prop="actualPrice">
                                    <el-input v-model.trim="ruleForm.actualPrice" style="width:90%;margin-bottom:10px"
                                        placeholder="请输入商品实际价格"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="0">
                             <el-form-item label="商品库存" prop="stock">
                                    <el-input v-model.number="ruleForm.stock" style="width:90%;margin-bottom:10px"
                                        placeholder="请输入商品库存"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                          <el-row>
                            <el-col :span="10">
                                <el-form-item label="已兑换数量" prop="newsTitle">
                                    <el-input v-model="ruleForm.exchangeAmount"  disabled style="width:90%;margin-bottom:10px"
                                        placeholder="">
                                        </el-input>
                                        
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="0">
                                <el-form-item label="发货时间" prop="deliveryTime">
                                    <el-input v-model.trim="ruleForm.deliveryTime" style="width:90%;margin-bottom:10px"
                                        placeholder="请填写发货时间"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                            <el-row>
                            <el-col :span="10">
                                <el-form-item label="上架时间" prop="shelfTime">
                                     <el-date-picker style="width:90%;margin-bottom:10px" clearable v-model="ruleForm.shelfTime"
                range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" 
                value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']"
                type="datetimerange" ></el-date-picker>
      
                                </el-form-item>
                            </el-col>
                            <!-- <el-col :span="10" :offset="0">
                                <el-form-item label="下架时间" prop="offShelfTime">
                                     <el-date-picker v-model="ruleForm.offShelfTime" value-format="yyyy-MM-dd HH:mm:ss"
                                        format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col> -->
                        </el-row>
                            <el-row>
                            <el-col :span="10">
                                <el-form-item label="商品列表图片"  ref="formImg"  prop="colFile">
                                    <el-upload action="#"  :before-upload="beforeAvatarUpload" :on-change="handleChange" list-type="picture-card" v-show="!value"
                                        :show-file-list="false">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible">
                                        <img width="100%" :src="dialogImageUrl" alt />
                                    </el-dialog>
                                    <div v-show="value" class="el-upload-list el-upload-list--picture-card">
                                        <div class="el-upload-list__item is-success">
                                            <img class="avatar" v-show="value" :src="value" />
                                            <label class="el-upload-list__item-status-label">
                                                <i class="el-icon-upload-success el-icon-check"></i>
                                            </label>
                                            <span class="el-upload-list__item-actions">
                                                <span class="el-upload-list__item-preview">
                                                    <i class="el-icon-zoom-in"
                                                        @click.stop="handlePictureCardPreview"></i>
                                                </span>
                                                <span class="el-upload-list__item-delete">
                                                    <i class="el-icon-delete" @click.stop="handleRemove"></i>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="0">
                                <el-form-item label="商品详情图片"  ref="formImg2" prop="detailFiles">
                                    <el-upload class="upload-demo" action :on-change="handleChangeDetail" :on-remove="handleRemoveDetail"
                    :on-exceed="handleExceed" list-type="picture-card"  :file-list="fileList" :limit="limitUpload"
                    :auto-upload="false" ref="my-upload">
                    <el-button type="primary" round size="mini" class="input-button">上传附件</el-button>
                    <br />
                  </el-upload>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item label="商品详情" prop="details">
                            <editor-bar  :uri='uri' v-model="ruleForm.details" :isClear="isClear" @change="change"></editor-bar>
                        </el-form-item>
                        <el-row>
                            <el-col :span="10" style="margin-top:50px;margin-left:30%">
                                <el-form-item class="footer">>
                                    <el-button type="primary" @click="submitForm('ruleForm')">立即修改</el-button>
                                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>

                </el-card>
            </div>
        </div>
    </div>
</template>
<script>
import EditorBar from "../../components/appWangEnduit";

import E from "wangeditor";
export default {
  name: "goodsUpdate",
  components: {
    EditorBar
  },
  data() {
    var valiIcon = (rule, value, callback) => {
      if (!this.value) {
        callback(new Error("请上传图片"));
      } else {
        callback();
      }
    };
    var valiIcon2 = (rule, value, callback) => {
      console.log(value);
      if (!this.fileList[0]) {
        callback(new Error("请上传图片"));
      } else {
        callback();
      }
    };
    return {
      formLabelWidth: "120px",
      uri: "",
      detail: "",
      content: "",
      detailFiles: [],
      isClear: false, //清除富文本编辑器内容
      // 默认步骤数
      limitUpload: 5,
      limitUpload2: 1,
      fileList: [],
      fileMulLis: [],
      stepActive: "stepActive",

      dialogImageUrl: "",
      dialogVisible: false,
      value: "",
      addphoto: "",
      file: {},
      dialogImageUrl2: "",
      dialogVisible2: false,
      value2: "",
      file2: {},
      oriUrl: "",
      filesall:[],
      colFile: "",
      ruleForm: {
        name: "",
        oriPrice: "",
        actualPrice: "",
        stock: "",
        exchangeAmount:"",
        deliveryTime: "",
        shelfTime: "",
        offShelfTime: "",
        colFile: "",
        details: "",
        detailFilesjpg: ""
      },
      rules: {
        name: [
          {
            required: true,
            message: "请填写商品名称",
            trigger: "blur"
          }
        ],
        deliveryTime: [
          {
            required: true,
            message: "请填写发货时间",
            trigger: "blur"
          }
        ],
        shelfTime: [
          { required: true, message: "请选择上架时间", trigger: "change" }
        ],
        offShelfTime: [
          { required: true, message: "请选择下架时间", trigger: "change" }
        ],
        oriPrice: [
          { required: true, message: "请输入商品原价", tigger: "blur" },
          {
            pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: "请输入正确格式,可保留两位小数"
          }
        ],
        actualPrice: [
          { required: true, message: "请输入商品实际价格", tigger: "blur" },
          {
            pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: "请输入正确格式,可保留两位小数"
          }
        ],
        stock: [
          { required: true, message: "请输入商品库存" },
          {
            type: "number",
            required: true,
            message: "请填写数字",
            trigger: "blur"
          }
        ],
        colFile: [{ required: true, validator: valiIcon, trigger: "change" }], // 图片验证,
        detailFiles: [
          { required: true, validator: valiIcon2, trigger: "change" }
        ] // 图片验证,
      }
    };
  },
  created() {
    this.uri = "api/iotServer/goods/addPic";
  },
  mounted() {
    console.log(this.$route.query.data);

    this.$api
      .goodsDetail(this.$route.query.data.id)
      .then(res => {
        console.log(res.data.data);
        this.ruleForm.name = res.data.data.name;
        this.ruleForm.oriPrice = res.data.data.oriPrice;
        this.ruleForm.actualPrice = res.data.data.actualPrice;
        this.dialogImageUrl = res.data.data.columnPicture;
        this.value = res.data.data.columnPicture;
        console.log(res.data.data.detailPicture.split(","));
        this.detailFilesjpg = res.data.data.detailPicture.split(",");
        this.ruleForm.stock = res.data.data.stock;
        this.ruleForm.exchangeAmount = res.data.data.exchangeAmount;
        this.ruleForm.details = res.data.data.details;

        this.detailFilesjpg.forEach(item => {
          let obj = {
            url: item
          };
          this.fileList.push(obj);
        });
        this.ruleForm.shelfTime = [res.data.data.shelfTime,res.data.data.offShelfTime]
        // this.ruleForm.offShelfTime = ;
        this.ruleForm.deliveryTime = res.data.data.deliveryTime;
      })
      .catch(error => {
        console.log(error);
      });
  },
  methods: {
        beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
                     console.log('2200')
          this.$message.error('上传图片大小不能超过 2MB!');
           this.value = ''
        }
        return  isLt2M;
      },
    change(val) {
      // console.log(val);
      this.ruleForm.newsContent = val;
    },
    submitForm(ruleForm) {
      //  this.ruleForm.colFile = this.file
      //     this.ruleForm.detailFiles = this.fileList
      this.$refs[ruleForm].validate(valid => {
        this.ruleForm.colFile = this.file;
        this.ruleForm.detailFiles = this.filesall;
        let formData = new FormData();
//  单张图片是否删除
        if (this.value == this.dialogImageUrl) {
              console.log("没有替换");
        }else{
              formData.append("colFile",this.ruleForm.colFile);
        }

        if (this.addphoto === 1) {

          console.log("有新增");
            formData.append("goodsDetailPicDto.oriUrl", this.oriUrl);
          this.ruleForm.detailFiles.forEach(file => {
            formData.append("goodsDetailPicDto.files", file.raw); //此处一定是append file.raw 上传文件只需维护fileList file.raw.name要加上
          });
        } else if (this.addphoto === 2) {
          formData.append("goodsDetailPicDto.oriUrl", this.oriUrl);
          console.log("有删除");
        } else {
          console.log("无新增，无删除");
          //  formData.append("goodsDetailPicDto.files", null)
          formData.append("goodsDetailPicDto.oriUrl", this.detailFilesjpg);
         
        }
        formData.append("id", this.$route.query.data.id);
          formData.append("name", this.ruleForm.name);
          formData.append("oriPrice", this.ruleForm.oriPrice);
          formData.append("actualPrice", this.ruleForm.actualPrice);
          formData.append("details", this.ruleForm.details);
          formData.append("stock", this.ruleForm.stock);
          formData.append("shelfTime", this.ruleForm.shelfTime[0]);
          formData.append("offShelfTime", this.ruleForm.shelfTime[1]);
          formData.append("deliveryTime", this.ruleForm.deliveryTime);
        // console.log(this.ruleForm);
        if (valid) {
          this.$api.goodsUpdate(formData).then(res => {
            if (res.data.code == 200) {
              this.$message.success("更新成功!");
              this.$nextTick(() => {
                this.$router.push("/mallList");
                console.log("00000");
                this.$refs["ruleForm"].clearValidate();
                this.value = "";
                this.value2 = "";
                this.file = {};
                this.file2 = {};
                this.fileList = [];
                this.ruleForm.newsSubtitle = "";
                this.ruleForm.newsTitle = "";
                this.ruleForm.releaseTime = "";
                this.ruleForm.contentUrl = "";
                this.ruleForm.newsContent = "";
              });
            }
          });
        } else {
          console.log("error submit!!");

          return false;
        }
      });
    },
    handleChange(file, fileList) {
      // console.log(file.raw, fileList);
      this.value = file.url;
      this.file = file.raw;
      if (file.raw) {
        this.$nextTick(() => {
          //等待照片上传完成，
          this.$refs["formImg"].clearValidate();
        });
      }
    },
    //超出最大上传文件数量时的处理方法
    handleExceed(file, fileList) {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！请删除已上传文件后重新上传"
      });
      return;
    },
    //移除文件的操作方法
    handleRemoveDetail(file, fileList) {
      this.fileList = fileList;
     // 反显的照片，剩下的url
      let arrurl = [];
      for (let index = 0; index < this.fileList.length; index++) {
        if (this.fileList[index].name) {
          // console.log(this.fileList[index]);
          //  判定是否有新增
          this.addphoto = 1;
        } else if (this.fileList[index].name == null) {
          //  有删除的照片还剩的url
          // console.log(this.fileList[index]);
          this.addphoto = 2;
          arrurl.push(this.fileList[index].url);
        }
        // console.log(arrurl);
        this.oriUrl = arrurl.toString();
        // console.log(this.oriUrl);
      }

      console.log(fileList);
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //回到模块页面
    gobackPlaceDetail() {
      this.$router.push("/mallList");
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      // this.fileList = fileList;
      this.value = "";
      this.file = {};
    },
    handleChangeDetail(file, fileList) {
                 const isLt5M = file.size / 1024 / 1024 < 2;
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过2MB!');
        fileList.splice(-1,1); //移除选中图片
        return false;
      }else{
      // console.log(fileList);
      this.filesall = []
      this.fileList = fileList;
      if (file.raw) {
        this.$nextTick(() => {
          //等待照片上传完成，
          this.$refs["formImg2"].clearValidate();
        });
      }
      // 反显的照片，剩下的url
      let arrurl = [];
      for (let index = 0; index < this.fileList.length; index++) {
        if (this.fileList[index].name) {
          // console.log(this.fileList[index]);
          // console.log(this.fileList);
          //  判定是否有新增
          this.addphoto = 1;
          this.filesall.push(this.fileList[index])
          // this.filesall={
          //   name:this.fileList[index].name,
          //   percentage:this.fileList[index].percentage,
          //   raw:this.fileList[index].raw,
          //   size:this.fileList[index].size,
          //   uid:this.fileList[index].uid,
          //   status:this.fileList[index].status,
          //   url:this.fileList[index].url,
          // }
      
        } else if (this.fileList[index].name == null) {
          //  有删除的照片还剩的url
          // console.log(this.fileList[index]);
          this.addphoto = 2;
          arrurl.push(this.fileList[index].url);
        }
        // console.log(arrurl);
        this.oriUrl = arrurl.toString();
        // console.log(this.oriUrl);
        // console.log(this.filesall);
      }
    }
    },
    handlePictureCardPreview2(file) {
      this.dialogImageUrl2 = file.url;
      this.dialogVisible2 = true;
    }
  }
};
</script>
<style lang="less" scoped>
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;
.footer{
  position: fixed;
  bottom: 0;
left:40%;
//   width:30%;
  line-height: var(--footer-height);
//   background: #42b983;
  color: #fff;
}
.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;

  .item {
    height: 80px;
    width: 80px;
    border: 1px solid blue;
    float: left;
  }

  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
    }
  }

  .detail {
    padding: 0rem 3rem;
    margin-top: 1rem;

    .detail-span {
      font-size: 12 /16rem;
      color: #898989;
    }

    .detail-vv {
      margin-top: 5 /16rem;
      font-size: 14 /16rem;
    }
  }
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

//   .bg-purple {
//     background: #d3dce6;
//   }
.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.stepShow {
  width: 80%;
  margin: 3rem auto;

  .downloadList {
    //  transform:translate(5.5rem, -4rem)
  }

  /deep/.el-step.is-horizontal.stepActive {
    .el-step__head.is-finish {
      .el-step__line {
        // 当前步骤数横线样式设置
        .el-step__line-inner {
          width: 50% !important;
          border-width: 1px !important;
        }
      }

      // 当前步骤数圆圈样式设置
      .el-step__icon.is-text {
        background: #409eff;
        color: #fff;
      }
    }
  }
}

.outting .item {
  margin: 10px;
  height: auto;
  width: auto;
  cursor: pointer;
  border: none;
}

.right .el-tooltip__popper {
  padding: 8px 10px;
  border: none;
  cursor: pointer;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>