<template>
  <div>
    <el-container>
      <el-main class="row-margin" style="overflow-x:hidden;">
        <!-- <div class="title">批量解绑设备</div>
        <el-row>
          <el-col :span="span">
            <div class="left-title">导入待注册的设备IMEI:</div>
          </el-col>
          <el-col :span="9">
            <div class="desc">
              <el-upload
                class="upload-demo"
                action
                :on-change="handleChange"
                :on-remove="handleRemove"
                :on-exceed="handleExceed"
                accept=".xls, .xlsx"
                :file-list="fileList"
                :limit="limitUpload"
                :auto-upload="false"
                ref="my-upload"
              >
                <el-button type="primary" round size="mini" class="input-button">导入IMEI号</el-button>
                <span style="color: red">*</span>请导入xls/xlsx格式文件，文件内容设置为文本格式
                <div style="margin-left:100px;">(格式为首行为MAC,其余行每行为一个IMEI号)</div>
              </el-upload>
            </div>
          </el-col>
          <el-col :span="9">
            <el-button type="primary" round size="mini" class="query-button" @click="reset">重置</el-button>
          </el-col>
        </el-row>
        <el-row class="row-margin">
          <el-col :span="span">
            <div class="left-title">导入结果:</div>
          </el-col>
          <el-col :span="18">
            <textarea class="input-height" disabled="disabled" v-model="tableData"></textarea>
          </el-col>
        </el-row>
        <el-row class="row-height" type="flex" align="middle" :gutter="2">
          <el-col :span="16"></el-col>
          <el-col :span="3">
          </el-col>
          <el-col :span="3">
            <el-button
              type="primary"
              round
              size="mini"
              class="query-button"
              @click="queryResult"
            >提交</el-button>
          </el-col>
        </el-row>
        <el-row class="row-margin">
          <el-col :span="span">
            <div class="left-title">解绑成功:</div>
          </el-col>
          <el-col :span="18">
            <textarea class="input-height" disabled="disabled" v-model="successMac"></textarea>
          </el-col>
        </el-row>
        <el-row class="row-margin">
          <el-col :span="span">
            <div class="left-title">解绑失败:</div>
          </el-col>
          <el-col :span="18">
            <textarea class="input-height" disabled="disabled" v-model="failedMac"></textarea>
          </el-col>
        </el-row>-->
        <div class="select">
          <span class="title">批量解绑设备</span>
        </div>
        <div class="content">
          <div style="width:100%;display:flex;height:2rem;">
            <div style="flex:1;font-size:.8rem;text-align:right;line-height:2rem;">
              <span class="content-span">导入待解绑设备IMEI:</span>
            </div>
            <div style="flex:4.5;font-size:.8rem;line-height:2rem;font-size: 0.6rem;color: gray;">
              <el-upload
                class="upload-demo"
                action
                :on-change="handleChange"
                :on-remove="handleRemove"
                :on-exceed="handleExceed"
                accept=".xls, .xlsx"
                :file-list="fileList"
                :limit="limitUpload"
                :auto-upload="false"
                ref="my-upload"
              >
                <el-button type="primary" round size="mini" class="input-button">导入IMEI号</el-button>
                <span style="color: red">*</span>请导入xls/xlsx格式文件，文件内容设置为文本格式
              </el-upload>
            </div>
            <div style="flex:1;line-height:2rem;">
              <el-button type="primary" round size="mini" class="query-button" @click="reset">重置</el-button>
            </div>
          </div>

          <div class="content-vv">
            <div style="flex:1;font-size:.8rem;text-align:right;line-height:2rem;height:2rem;">
              <span class="content-span">导入结果:</span>
            </div>
            <div style="flex:5.5;">
              <textarea class="input-height" disabled="disabled" v-model="tableData"></textarea>
            </div>
          </div>

          <div class="content-vv" style="height:2rem;">
            <div style="flex:5.5;font-size:.8rem;text-align:right;line-height:2rem;"></div>
            <div style="flex:1;font-size:.8rem;line-height:2rem;">
              <el-button
                type="primary"
                round
                size="mini"
                class="query-button"
                @click="queryResult"
              >提交</el-button>
            </div>
          </div>

          <div class="content-vv">
            <div style="flex:1;font-size:.8rem;text-align:right;line-height:2rem;">
              <span class="content-span">解绑成功：</span>
            </div>
            <div style="flex:5.5;">
              <textarea class="input-height" disabled="disabled" v-model="successMac"></textarea>
            </div>
          </div>

          <div class="content-vv">
            <div style="flex:1;font-size:.8rem;text-align:right;line-height:2rem;">
              <span class="content-span">解绑失败：</span>
            </div>
            <div style="flex:5.5;">
              <textarea class="input-height" disabled="disabled" v-model="failedMac"></textarea>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Storage from "../../../utils/Storage";
export default {
  name: "ManyUnbundling",
  data() {
    return {
      span: 4,
      limitUpload: 1,
      tableData: [],
      fileList: [],
      dialogVisible: false,
      productId: "",
      netOperatorId: "",
      successMac: "",
      failedMac: "",
      options: [],
      errorList: [],
      successList: [],
      optionsOperator: [
        { name: "电信", code: "2" },
        { name: "联通", code: "3" },
        { name: "移动", code: "1" }
      ]
    };
  },
  methods: {
    queryResult() {
      if (this.tableData.length == 0) {
        this.$message.error("请导入设备!");
      } else {
        this.$api.deviceUnbinding(this.tableData).then(res => {
          console.log(res.data.data);
          this.errorList = [];
          this.successList = [];
          if (res.data.data.errorList.length !== 0) {
            for (var i = 0; i < res.data.data.errorList.length; i++) {
              this.errorList.push(res.data.data.errorList[i].mac);
            }
          }
          this.failedMac = this.errorList;
          if (res.data.data.successList.length !== 0) {
            for (var i = 0; i < res.data.data.successList.length; i++) {
              this.successList.push(res.data.data.successList[i].mac);
            }
          }
          this.successMac = this.successList;
        });
      }
    },
    reset() {
      this.clearFiles();
      this.tableData = [];
      (this.successMac = ""), (this.failedMac = "");
    },
    clearFiles() {
      this.$refs["my-upload"].clearFiles();
    },
    handleChange(file, fileList) {
      this.fileTemp = file.raw;
      if (this.fileTemp) {
        this.importfxx(this.fileTemp);
      } else {
        this.$message({
          type: "warning",
          message: "请上传附件！"
        });
      }
    },
    //超出最大上传文件数量时的处理方法
    handleExceed(file, fileList) {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！请删除已上传文件后重新上传"
      });
      return;
    },
    //移除文件的操作方法
    handleRemove(file, fileList) {
      this.fileTemp = null;
      this.tableData = [];
    },
    importfxx(obj) {
      let _this = this;
      let inputDOM = this.$refs.inputer;
      // 通过DOM取文件数据
      this.file = event.currentTarget.files[0];
      var rABS = false; //是否将文件读取为二进制字符串
      var f = this.file;
      var reader = new FileReader();

      //if (!FileReader.prototype.readAsBinaryString) {
      FileReader.prototype.readAsBinaryString = function(f) {
        var binary = "";
        var rABS = false; //是否将文件读取为二进制字符串
        var pt = this;
        var wb; //读取完成的数据
        var outdata;
        var reader = new FileReader();
        reader.onload = function(e) {
          var bytes = new Uint8Array(reader.result);
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }

          //此处引入，用于解析excelnpm
          var XLSX = require("xlsx");

          if (rABS) {
            wb = XLSX.read(btoa(fixdata(binary)), {
              //手动转化
              type: "base64"
            });
          } else {
            wb = XLSX.read(binary, {
              type: "binary"
            });
          }
          outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
          //outdata就是读取的数据（不包含标题行即表头，表头会作为对象的下标）
          //此处可对数据进行处理
          let arr = [];
          let arr2 = [];
          outdata.map(v => {
            let mac = v["MAC"];
            if (mac != "" && !arr.find(imei => imei === mac)) {
              arr.push(mac);
            }
          });
          _this.tableData = arr;
          _this.$message({
            type: "success",
            message: "导入成功，共导入" + arr.length + "个有效设备IMEI"
          });
          return arr;
        };
        reader.readAsArrayBuffer(f);
      };
      if (rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    }
  }
};
</script>

<style lang="less" scoped>
// .title {
//   font-size: 0.8rem;
//   margin: 2rem;
//   font-weight: bold;
// }
textarea {
  outline: none;
  border: 0px;
  background-color: #fafafa;
  font-size: 0.8rem;
}
.row-height {
  height: 4rem;
}
.row-margin {
  margin-bottom: 0.5rem;
}
.left-title {
  font-size: 0.8rem;
  line-height: 3rem;
  text-align: right;
  margin-right: 1rem;
}
.input-small-height {
  padding-left: 5px;
  height: 1.7rem;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 0.5rem;
}
.input-height {
  height: 12rem;
  margin-top: 0.6rem;
  width: 99%;
  border: 1px solid #d3d3d3;
  border-radius: 1rem;
}
.desc {
  line-height: 3rem;
  font-size: 0.6rem;
  color: gray;
}
.query-button {
  float: right;
  width: 6rem;
  height: 1.7rem;
  font-size: 0.7rem;
  min-height: 20px;
  min-width: 60px;
}
.input-button {
  width: 6rem;
  height: 1.7rem;
  font-size: 0.7rem;
  min-height: 20px;
  min-width: 60px;
}
.device-content {
  height: 6rem;

  .device-info-item {
    float: left;
    margin-right: 3rem;

    .label-info {
      margin-bottom: 0.5rem;

      label {
        font-size: 0.75rem;

        span {
          color: red;
          margin-right: 0.5rem;
        }
      }
    }

    .el-input {
      width: 10rem;
    }

    .el-select {
      width: 12rem;
    }
  }
}
.select {
  height: 46px;
  overflow: hidden;
  border-bottom: 1px solid #d9d9d9;
  padding: 0rem 3rem;
  .title {
    display: inline-block;
    line-height: 46px;
    font-weight: bold;
    font-size: 0.8rem;
  }
}
.content {
  padding: 0rem 3rem;
  margin-top: 1rem;
  .content-span {
    display: inline-block;
    margin-right: 10%;
  }
  .content-vv {
    width: 100%;
    display: flex;
    margin-top: 1rem;
  }
}

</style>