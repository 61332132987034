<template>
  <div>
    <div class="outting">
      <div class="select">
        <span class="title">NB卡管理</span>
      </div>
      <div class="detail">
        <el-card class="box-card" shadow="never">
          <h5>卡资料信息</h5>
          <span class="new-span">接入号码：</span>
          <el-input size="mini" clearable style="width:15rem;margin-right:1rem;margin-bottom:1rem"
            v-model="accessNumber" placeholder="请输入接入号码："></el-input>
          <el-button size="mini" type="primary" @click="inquireAccessNumber()">查询</el-button>
          <br>
          <span class="new-span">卡归属地:</span> <span class="new-vv" style="color:#66b1ff">{{simAttr ||'暂无'}}</span>
          <span class="new-span">到期时间 :</span> <span class="new-vv" style="color:#66b1ff">{{expDate ||'暂无'}}</span>
        </el-card>
        <el-card class="box-card" shadow="never">
            <h5>根据imei获取卡号信息</h5>
          <span class="new-span">IMEI：</span>
          <el-input size="mini" style="width:15rem;margin-right:1rem;margin-bottom:1rem" clearable v-model="imei"
            placeholder="请输入IMEI"></el-input>
          <el-button size="mini" type="primary" @click="inquireImei()">查询</el-button>
          <br>
          <span class="new-span">接入号码:</span> <span class="new-vv" style="color:#66b1ff">{{accessNumberimei ||'暂无'}}</span>
          <span class="new-span">iccid:</span> <span class="new-vv" style="color:#66b1ff">{{iccidimei ||'暂无'}}</span>
          <span class="new-span">imsi:</span> <span class="new-vv" style="color:#66b1ff">{{imsiimei||'暂无'}}</span>
        </el-card>
        <el-card class="box-card" shadow="never">
           <h5>设备imei查询</h5>
          <span class="new-span">请求编码：</span>
          <el-select v-model="code" size="mini" style="margin-right:1rem" placeholder="请选择">
            <el-option v-for="item in codeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <span class="new-span">请求值：</span>
          <el-input size="mini" style="width:15rem;margin-right:1rem;margin-bottom:1rem" v-model="valueimei"
            placeholder="请输入请求值"></el-input>
          <el-button size="mini" type="primary" @click="inquire()">查询</el-button>
          <br>
          <span class="new-span">imei:</span> <span class="new-vv" style="color:#66b1ff">{{valueImei ||'暂无'}}</span>

        </el-card>
        <el-card class="box-card" shadow="never">
             <h5>三码互查</h5>
          <span class="new-span">请求编码：</span>
          <el-select v-model="codeThree" size="mini" style="margin-right:1rem" placeholder="请选择">
            <el-option v-for="item in codeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <span class="new-span">请求值：</span>
          <el-input size="mini" style="width:15rem;margin-right:1rem;margin-bottom:1rem" v-model="valueimeiThree"
            placeholder="请输入请求值"></el-input>
          <el-button size="mini" type="primary" @click="inquireThree()">查询</el-button>
          <br>
          <span class="new-span">接入号码:</span> <span class="new-vv" style="color:#66b1ff">{{accessNumberThree ||'暂无'}}</span>
          <span class="new-span">iccid:</span> <span class="new-vv" style="color:#66b1ff">{{iccidThree ||'暂无'}}</span>
          <span class="new-span">imsi:</span> <span class="new-vv" style="color:#66b1ff">{{imsiThree ||'暂无'}}</span>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "cardManagement",
    data() {
      return {
        accessNumber: "",
        simAttr: "",
        expDate: "",
        codeThree: "",
        valueimeiThree: "",
        imei: "",
        accessNumberimei: "",
        iccidimei: "",
        imsiimei: "",
        valueimei: "",
        code: "",
        valueImei:"",
        accessNumberThree:"",
        iccidThree:"",
        imsiThree:"",
        codeList: [{
          label: "接入号码",
          value: "0"
        }, {
          label: "iccid",
          value: "1"
        }, {
          label: "imsi",
          value: "2"
        }]
      };
    },
    created() {

    },
    mounted() {


    },
    methods: {
      // 卡资料信息
      inquireAccessNumber() {
          this.simAttr = ''
              this.expDate =''
        this.$api
          .cardDataInfo(this.accessNumber)
          .then(res => {
            console.log(res.data.data)
            if (res.data.data) {
              this.simAttr = res.data.data.simAttr
              this.expDate = res.data.data.expDate
            }

          });
      },
      // 根据imei获取卡号信息
      inquireImei() {
         this.accessNumberimei = ''
              this.iccidimei =''
              this.imsiimei = ''
        this.$api
          .cardInfo(this.imei)
          .then(res => {
            console.log(res.data.data)
            if (res.data.data) {
              this.accessNumberimei = res.data.data.accessNumber
              this.iccidimei = res.data.data.iccid
              this.imsiimei = res.data.data.imsi
            }

          });
      },
      inquire(){
          this.valueImei = ""
          this.$api
          .getImei({
            code :this.code ,
            value  :this.valueimei ,
          })
          .then(res => {
            console.log(res.data.data)
            if (res.data.data) {
            this.valueImei = res.data.data
            }

          });
      },
      // 三码互查
      inquireThree(){
         this.accessNumberThree = ""
              this.iccidThree = ""
              this.imsiThree = ""
       this.$api
          .telephonePlus({
            code :this.codeThree ,
            value  :this.valueimeiThree ,
          })
          .then(res => {
            console.log(res.data.data)
            if (res.data.data) {
             this.accessNumberThree = res.data.data.accessNumber
              this.iccidThree = res.data.data.iccid
              this.imsiThree = res.data.data.imsi
            }

          });
      },
      resetForm(ruleForm) {
        this.$refs[ruleForm].resetFields();
      },
    }
  };
</script>
<style lang="less" scoped>
  @border-bottom-color: #e7eaed;
  @toolBarFontColor: #111d;

  .outting {
    height: 100%;
    overflow: hidden;
    background-color: #fafafa;

    .box-card {
      width: 96%;
      margin: 1rem auto;
    }

    .select {
      height: 46px;
      overflow: hidden;
      border-bottom: 1px solid #d9d9d9;
      padding: 0rem 3rem;

      .title {
        display: inline-block;
        line-height: 46px;
        font-weight: bold;
        font-size: 0.8rem;
      }

      button {
        float: right;
        color: @toolBarFontColor;
        margin-top: 7px;
      }
    }

    .grid-content {
      border-radius: 4px;
      min-height: 36px;
      padding: 0rem 3rem;
    }

    .new-span {
      font-size: 12 /16rem;
      color: #898989;
    }

    .newSpan {
      font-size: 12 /16rem;
      color: red;
    }

    .new-vv {
      font-size: 13 / 16rem;
      margin-top: 5 /16rem;
      //   cursor: pointer;
      margin-right: 4rem;

      /*鼠标悬停变小手*/
    }

    .newVv {
      font-size: 14 /16rem;
      margin-top: 5 /16rem;
      color: whitesmoke;
    }


  }
</style>