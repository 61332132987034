<template>
  <div class="outting">
    <div class="select">
      <span class="title">获取微信登录展示状态</span>
    </div>
   <el-card class="box-card">
  <div  class="text item" >
  是否获取微信登录展示状态  
   <el-radio v-model="radio" label="1" style="margin-left:20px">是</el-radio>
  <el-radio v-model="radio" label="0">否</el-radio>   <el-button type="primary" size="mini" @click="setWechatLogin()">确定</el-button>
  </div>
</el-card>
  </div>
</template>
<script>
import qs from "qs"; 
export default {
  name: "wechatLoginShow",
  data() {
    return {
      radio: ''
    };
  },
  methods: {
      setWechatLogin(){
           this.$api
            .setWechatLoginShow(this.radio)
            .then(res => {
              console.log(res.data.data)
               this.$message.success("提交成功！");
            })
            .catch(error => {
              console.log(error);
            });
      }
  },
  mounted(){
          this.$api
            .getWechatLoginShow()
            .then(res => {
              console.log(res.data.data)
              this.radio = res.data.data.toString()

            })
            .catch(error => {
              console.log(error);
            });
  }
};
</script>
<style lang="less" scoped>
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;
.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;
    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

  }
  .box-card{
        margin: 1rem 3rem;
  }
  
}
</style>