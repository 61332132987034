<template>
  <div class="iphoneProduct" @touchmove="handleTouchMove">
    <img src="../../assets/banner3.jpg" class="bannerImg" />
    <!-- <div class="iphone_bg">
      <div class="item-title">
        <p>产品概括</p>
        <span> </span>
      </div>
      <div class="article">
        　　目前“吻胜”软硬结合，既有报警、应急等硬件产品，自主研发了接入NB-loT技术的烟感、可燃气体探测等设备，运用独立的报警监控云平台双重保障，在品类、技术类别等各个维度均形成了较为完整的家用消防安全生态链条。
      </div>
    </div> -->
    <!--
    <div class="item-title">
      <p>产品列表</p>
      <span> </span>
    </div> -->
    <div class="dropdown_box"    @click="openDropdown()">
      <el-dropdown trigger="click" @command="handleCommand" ref='messageDrop'>
        <span class="el-dropdown-link">
          {{ all_item}}<i class="el-icon-caret-bottom el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown" class="dropdownPop">
          <!-- <el-dropdown-item>全部</el-dropdown-item> -->
          <el-dropdown-item v-for="item in option" :key='item.id' @click.native="getDepartment(item)"
            :command="{value:item.id,label:item.productCategoryName,flag:1}">{{item.productCategoryName}}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>




    <div class="product_6200">
      <div class="product_right" v-for="(item,index) in this.tableList" :key="index"  @click="ws6101(item)">
        <img :src="item.newsImgUrl" class="img">
        <b>{{item.newsTitle}}</b>
        <p>{{ item.newsSubtitle }}</p>
      </div>
      <!-- <div class="product_right"  @click="ws6200()">
        <img src="../../assets/iphonelogo/6200_ 2.png" class="img">
        <b>独立式燃气报警器</b>
        <p>JT-WSH6200 甲烷</p>
      </div> -->

    </div>
    <!-- <div class="product_nb" @click="ws6200n()">
      <div class="product_right"  >
        <b>NB智能燃气报警器</b>
        <p>JT-WSH6200N 甲烷</p>
        <div class="grouplogo">
          <img src="../../assets/iphonelogo/Group6.png">
          <img src="../../assets/iphonelogo/Group5.png">

        </div>
      </div>
      <div class="product_left">
        <img src="../../assets/iphonelogo/6200n1.png">
      </div>

    </div>
    <div class="product_6200">
      <div class="product_right" @click="ws6201n()">
        <img src="../../assets/iphonelogo/6201_1.png" class="img">
        <b>NB智能燃气报警器</b>
        <p>JM-WSH6201N 一氧化碳</p>
      </div>
      <div class="product_right" @click="ws6201()">
        <img src="../../assets/iphonelogo/6201-p2.png" class="img">
        <b>独立式燃气报警器</b>
        <p>JM-WSH6201 一氧化碳</p>
      </div>
    </div>
    <div class="product_6200">
      <div class="product_right" @click="ws6103n()">
        <img src="../../assets/iphonelogo/6103n.png" class="img">
        <b>控烟探测器</b>
        <p>WSH6103N</p>
      </div>
      <div class="product_right"  @click="ws6103w()">
        <img src="../../assets/iphonelogo/6103w.png" class="img">
        <b>控烟探测器</b>
        <p>WSH6103W</p>
      </div>
    </div>
    <div class="product_6200">
      <div class="product_right"  @click="ws6103()">
        <img src="../../assets/iphonelogo/6103.png" class="img">
        <b>控烟探测器</b>
        <p>WSH6103</p>
      </div>
      <div class="product_left"  @click="wsYjsd()">
        <img src="../../assets/iphonelogo/shoudain.png" class="flashlight">
        <b>应急手电</b>
        <p>WSH-YJSD-01</p>
      </div>
    </div>
    <div class="product_6200">
      <div class="product_right"  @click="wsMht()">
        <img src="../../assets/iphonelogo/mht.png" class="mht">
        <b>灭火毯</b>
        <p>硅胶+玻纤</p>
      </div>
      <div class="product_right"  @click="wsJylh()">
        <img src="../../assets/iphonelogo/NBlh.png" class="NBlh">
        <b>家用礼盒</b>
        <p></p>
      </div>
    </div>
    <div class="product_6200">
      <div class="product_right" @click="wsj500()">
        <img src="../../assets/iphonelogo/wj500.png" class="wj500">
        <b>简易式水基灭火器</b>
        <p>MSWJ500</p>
      </div>
      <div class="product_right"  @click="wsj950()">
        <img src="../../assets/iphonelogo/wj950.png" class="wj950">
        <b>简易式水基灭火器</b>
        <p>MSWJ950</p>
      </div> -->
    <!-- </div> -->
    <p class="interval"></p>
  </div>
</template>

<script>
  export default {
    name: "iphoneProduct",

    data() {
      return {
        option: [],
        visible: false,
        productCategoryId: '',
        all_item: '分类',
        tableList: [],
        activeName:'',
      };
    },
    watch: {
    "$route.path":{
      handler(to, from) {
        console.log('to:::', to);
        console.log('from:::', from);
        if(to == '/productCenter' && from == '/productCenter'){
           if (this.$route.query.data) {
        this.activeName = this.$route.query.data.toString()
        this.productCategoryId = this.$route.query.data.toString()
        this.newsList()
      }
        }
      },
      deep: true
    }
  },
    mounted() {
      this.productListForCategory()
      // this.newsList()
      let that = this;
      window.addEventListener("scroll", that.handleTouchMove, true);
      // console.log(this.activeName)

    },
    methods: {
      openDropdown(){
        this.$refs.messageDrop.show();
      },
      // 页面滚动方法
      handleTouchMove() {

        this.$refs.messageDrop.hide()
        // console.log('滚动了', this.visible)
      },

      //产品列表
      newsList() {



          if (this.activeName == '0') {
          this.productCategoryId = null

        } else {

          this.productCategoryId = this.activeName
            // console.log()
        }
        this.$api
          .productListForApp({
              page: {
            current: 1,
            size: 1000
            },
            queryDto: {
              productCategoryId: this.productCategoryId,
            }
          })
          .then(res => {
            this.tableList = res.data.data.records;
            console.log(this.tableList)
            // if (this.tableList.length < 5) {
            //   this.yushu = 4 - this.tableList.length
            // } else {
            //   this.yushu = 4 - this.tableList.length % 4
            // }
            // for (let index = 0; index < this.tableList.length; index++) {
            //   this.tableList[index].isyu = false
            // }
            // this.total = res.data.data.total;

            // this.currentPage = parseInt(this.current)

            // console.log(this.yushu)

            // for (var i = 0; i < this.yushu; i++) {
            //   let vote_arr = {
            //     isyu: true
            //   }
            //   this.tableList.push(vote_arr)
            // }
            // console.log(this.tableList)
          })
          .catch(error => {
            console.log(error);
          });
      },
      handleCommand(command) {
        // console.log(command)
        this.all_item = command.label
         this.activeName = command.value
        if (command.value == '0') {
          this.productCategoryId = null

        } else {
          this.productCategoryId = command.value
        }
        this.newsList()
      },
      getDepartment(item) {
        console.log(item);

        // if (tab.name == '0') {
        //   this.productCategoryId = null

        // } else {
        //   this.productCategoryId = this.activeName
        // }
        // this.newsList()
      },
      productListForCategory() {
        this.$api
          .productListForCategory()
          .then(res => {
            // console.log(res.data.data)
            this.option = res.data.data
            this.option.unshift({
              id: 0,
              productCategoryName: '全部'
            })
          })
          .catch(error => {
            console.log(error);
          });
      },

      ws6101(data) {
        console.log(this.activeName,data)
        this.$router.push({
          path: `/dynamicPage`,
          query: {
            id: data.id,
            newsContent: data.newsContent,
             activeName:this.activeName,

          }
        });
      },

    },
          beforeRouteEnter(to, from, next) {
      console.log(from.path, '组件独享守卫beforeRouteEnter第二个参数');
      next(vm => {
        if (from.path == '/dynamicPage') {
          // vm.current = sessionStorage.getItem("produvtCurrent")
          vm.activeName = sessionStorage.getItem("appproduvtActiveName")
          vm.all_item = sessionStorage.getItem("appproduvtall_item")
          console.log( vm.activeName,'存储')
          vm.newsList();
        } else {
          //因为当钩子执行前，组件实例还没被创建
          // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
          // console.log(vm);//当前组件的实例
          vm.current = 1
          vm.newsList();
        }

      });
    },

    beforeRouteLeave(to, from, next) {
      // 导航离开该组件的对应路由时调用
      // 可以访问组件实例 `this`
      // console.log(this, 'beforeRouteLeave'); //当前组件实例
      console.log(to.path, '组件独享守卫beforeRouteLeave第一个参数');
      if (to.path == '/dynamicPage') {
        // sessionStorage.setItem("produvtCurrent",  this.current);
        sessionStorage.setItem("appproduvtActiveName",  this.activeName);
        sessionStorage.setItem("appproduvtall_item",  this.all_item);
      } else {
        // sessionStorage.removeItem("produvtCurrent");
        sessionStorage.removeItem("appproduvtActiveName");
        sessionStorage.removeItem("appproduvtall_item");
      }
      next();
    },
    //页面销毁时 移除监听
    destroyed() {
      //  改一下 this 指向
      let that = this;
      window.removeEventListener("scroll", that.handleTouchMove, true);
    },

  }
</script>
<style lang="less" scoped>
  .iphoneProduct {
    margin-top: 14vw;
    width: 100%;
    overflow: auto;
    background-color: #fff;

    .bannerImg {
      width: 100%;
    }



    .item-title {
      padding-top: 11.6vw;
      padding-bottom: 9vw;
      text-align: center;
      color: #333333;
      width: 100%;
      letter-spacing: 1px;
      margin: 0 auto;
      font-size: 5.6vw;

      span {
        width: 16.4vw;
        margin: 0 auto;
        height: 2px;
        padding-top: 1px;
        display: block;
        background: #F1A948;
      }
    }

    .article {
      width: 89.4vw;
      text-indent: 2em;
      margin: 0 auto;
      font-size: 4.2vw;
      line-height: 1.7em;
      color: #333;
      text-align-last: left; //最后一行左对齐
      text-align: justify;
      text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
      padding-bottom: 7.73vw;

      p {
        height: 11.6vw;
      }
    }

    .product_6101,
    .product_nb {
      width: 94.6vw;
      height: 50.6vw;
      margin: auto;
      margin-bottom: 2vw;
      border-radius: 5px;
      box-shadow: 0 0 2px 0 rgb(197, 196, 196);
      display: flex;
      background: #ffffff;

      .product_left {
        width: 50%;
        height: 100%;
        // border: #1da3f1 1px solid;
        display: flex;
        align-items: center;
        vertical-align: middle;

        img {
          width: 36vw;
          margin: 0 auto;
          display: inline-block;
          align-items: center;
        }
      }

      .product_right {
        // border: #1da3f1 1px solid;
        width: 50%;
        text-align: center;
        height: 100%;
        vertical-align: middle;

        b {
          color: #333;
          font-size: 4.2vw;
          display: inline-block;
          margin-top: 8vw;
        }

        p {
          color: #666;
          font-size: 3.7vw;
        }

        .grouplogo {
          width: 40vw;
          margin: 0 auto;
          padding-top: 8vw;
          display: flex;
          align-items: center;
          justify-content: space-around;
          vertical-align: middle;

          img {
            height: 15.7vw;
          }
        }
      }
    }

    .product_6200 {
      width: 94.6vw;
      min-height: 80vw;
      border-radius: 5px;
      //  height: 56.8vw;
      margin: auto;
      margin-bottom: 2vw;
      // box-shadow: 0 0 2px 0 rgb(197, 196, 196);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .product_right {
        width: 49%;
        height: 56.8vw;
        border-radius: 5px;
        text-align: center;
        margin-bottom: 2vw;
        vertical-align: middle;
        background: #f7f7f7;
        // border: #12b7f5 1px solid;
        // height: 100%;



        .img {
          padding-top: 4vw;
          display: inline-block;
          align-items: center;
          width: 32.6vw;
          height: 32.6vw;
        }


        b {
          color: #333;
          font-size: 4.2vw;
          display: inline-block;
          margin-top: 4vw;
        }

        p {
          color: #666;
          font-size: 3.7vw;
        }
      }

      .product_left {
        width: 49%;
        border-radius: 5px;
        text-align: center;
        vertical-align: middle;
        background: #f7f7f7;
        // border: #12b7f5 1px solid;
        height: 100%;

        .img {
          padding-top: 4vw;
          display: inline-block;
          align-items: center;
          width: 30.1vw;
          height: 32.6vw;
        }

        b {
          color: #333;
          font-size: 4.2vw;
          display: inline-block;
          margin-top: 4vw;
        }

        p {
          color: #666;
          font-size: 3.7vw;
        }
      }
    }

    .interval {
      height: 20.8vw;
    }
  }

  //element ui下拉弹窗 dropdownPop自定义
  .dropdownPop {
    width: 96vw;
    background-color: #f7f7f7;

    // border: 1px solid #6b6d6e;
    /deep/.el-dropdown {
      width: 96vw;
    }

    .el-dropdown-menu__item {
      color: #000608;
    }

    .el-dropdown-menu__item:hover {
      background-color: #5f6263;
    }

    .popper__arrow {
      border: 1px solid #6b6d6e !important;
    }

    .popper__arrow::after {
      border-top-color: #6b6d6e !important;
      border-bottom-color: #6b6d6e !important;
    }
  }

  .el-dropdown-link {
    color: #000608;
    margin: auto;
  }

  .dropdown_box {
    margin-left: 3.2vw;
    margin-top: 4vw;
    margin-bottom: 4vw;
    padding: 0.5vw 2vw;
    background-color: #f7f7f7;
    width: 35vw;
    text-align: center;
    border-radius: 10px;
  }
</style>
