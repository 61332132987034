<template>
  <div class="mainContainer">
    <div class="select">
      <span class="title">工单列表</span>
      <el-button
        type="text"
        size="small"
        icon="el-icon-refresh"
        class="select-button"
        @click="refresh"
      >刷新</el-button>
    
      <el-button
        type="text"
        size="small"
        icon="el-icon-bottom"
        class="select-button"
        @click="playShow()"
      >高级查询</el-button>
   
    </div>
    <div class="gaojiSearch" v-show="ifSearchMany">
      <div style="width:100%;overflow: hidden;">
        <div style="float:left">
          <div class="toolbar-right">
           
             <span style="font-size:14px">工单编号 ：</span>
            <el-input
              placeholder="请输入内容"
     
              v-model="workOrderNo"
              size="mini"
              style="width: 11.25rem;;margin-right:2rem"
              clearable
            ></el-input>
         
            <span style="font-size:14px">问题状态 ：</span>
            <!-- <el-input
              placeholder="请输入内容"
               @keyup.native="changeAmount"
              v-model="problemStatus"
              size="mini"
              style="width: 11.25rem;;margin-right:2rem;margin-bottom:1rem;"
              clearable
            ></el-input> -->
               <el-select
              v-model="problemStatus"
              clearable
              size="mini"
              style="width: 11.25rem;margin-right:2rem;margin-bottom:1rem;"
            >
              <el-option
                v-for="item in problemStatusList"
                :key="item.id"
                :label="item.status"
                :value="item.status"
              ></el-option>
            </el-select>
    <br>
            <span style="font-size:14px" >问题标题 ：</span>
            <el-input
              placeholder="请输入内容"
              v-model="problemTitle"
              size="mini"
              style="width: 11.25rem;;margin-right:2rem"
              clearable
            ></el-input>
            <span style="font-size:14px" >问题类型 ：</span>
            <el-select
              v-model="problemTypeId"
              clearable
              size="mini"
              style="width: 11.25rem;margin-right:2rem;"
            >
              <el-option
                v-for="item in problemTypeIdlist"
                :key="item.value"
                :label="item.typeName"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div style="width:100%;overflow: hidden;">
        <div style="float:right;">
          <div class="grid-content bg-purple-dark">
            <div class="searchBut">
              <el-button size="small" type="primary" @click="imeiSearch">搜索</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-vv">
      <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
        <div class="result-info">
          共有
          <span>{{ total }}</span>个问题
        </div>
      </div>
      <el-table
        :data="tableData"
        stripe
        size="small"
        class="product-table"
        :header-cell-style="{'background-color': '#eeeeee'}"
        @select="getIds"
        @select-all="getAllIds"
      >
        <el-table-column type="selection" align="center" width="55"></el-table-column>
        <!-- <el-table-column label="序号" type="index" align="center"></el-table-column> -->
        <el-table-column
          v-for="(item,index) in labels"
          :key="index"
          :prop="props[index]"
          :label="item"
          v-if="relLabelsStatus[index]"
          align="center"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" align="center" width="200">
          <template slot-scope="scope">
            <el-button
              @click="questionDetails(scope.row.id)"
              type="text"
              size="small"
              icon="el-icon-document"
            >详情</el-button>
            <el-button
              @click="handleFn(scope.row)"
              type="text"
              size="small"
              icon="el-icon-printer"
              v-if="scope.row.isHandler"
            >处理</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :page-sizes="[10, 20]"
        :page-size="pageSize"
        :total="total"
        layout="sizes,prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>


  </div>
</template>


<script>
import Bus from "../../components/bus";
import qs from "qs";
import PubSub from "pubsub-js";
import Storage from "@/utils/Storage";
export default {
  name: "problemStatus",
  data() {
    return {
      isActive: 1,
      productDel: false,
      productAdd: false,
      ifSearchMany: false,
      workOrderNo:"",//工单编号
      form: {
        handlerUserId: []
      },
      formHandle: {
        assignUserId: "",
        ccUserIds: [],
        handleOpinion: "",
        handleResurt: ""
      },
      visible: false,
      total: 0,
      tableData: [],
      problemStatusList: [],
      ids: [],
      chooseOnlineStatus: "", //搜索选择
      sysUserlist: [], //账号所属类型
      handRealNameList:[],
      newSsUserlist: {
        value: "id",
        label: "typeName",
        children: "childs",
        expandTrigger: "hover"
        // emitPath: false
      },
      checked: false,
      //转派对话框标记
      dialogVisible: false,
      //修改账号弹出对话框标记
      handleDialogVisible: false,
      //协议类型列表
      protocols: [
        {
          id: 1,
          protocolName: "test"
        }
      ],

      //产品品类列表
      categories: [],
      handleResurt: [
        {
          value: false,
          label: "不通过"
        },
        {
          value: true,
          label: "通过"
        }
      ],
      value: "",
      input: "",
      current: 1,
      pageSize: 10,
      delDialogVisible: false,
      isHandlerUser:true,
      // productId: "",
      indexId: "", //反显id
      nameinput: "", //搜索输入
      indexIdlist: "", //反显列表
      formLabelWidth: "120px",
      realNameList: [], //当前处理人员列表
      handlerUserId: "", //当前处理人员
      mac: "", //设备号
      problemStatus: "", //问题状态
      problemTitle: "", //问题标题
      problemTypeId: "", //问题类型
      problemTypeIdlist:[], //问题类型列表
      product:[], //获取产品下拉列表接口
      productId:"", //获取产品下拉列表接口
      problemId: "", //指派id
      userDisabled:false,
      props: [
        "workOrderNo",
        "problemTitle",
        "problemType",
        "problemStatus",
        "createTime",
        "updateTime"
      ],
      labels: [
        "工单编号",
        "问题标题",
        "问题类型",
        "问题状态",
        "创建时间",
        "更新时间"
      ],
      relLabelsStatus: [
        true,
        true,
        true,
        true,
        true,
      ],
      accountLevel:"",//是否是超级账号,
      defaulShow:false,//处理人隐藏,
      realName:"",//指派人禁止选择是id
    };
  },
  methods: {
    refresh() {
      this.problemList();
    },
    // 处理结果change事件
    defaultHandler(item){
         console.log(item)
         this.defaulShow = true
            this.$api.problemDefaultHandler({
              problemId:this.problemId,
              handleResurt:item
            }).then(res => {
            if (res.data.code == 200) {
              console.log(res.data.data)
               this.userDisabled = false
                this.$refs["formHandle"].clearValidate();
                this.formHandle.assignUserId = ""
              if(res.data.data.length == 1){
                this.formHandle.assignUserId = res.data.data[0].realName
                this.realName =  res.data.data[0].sysUserId
                this.userDisabled = true
              }else{
                this.handRealNameList = res.data.data
              }
            }
          });
    },
    // 切换样式
    changeClass(i) {
      this.isActive = i;
      
      if(i == 2){
        this.isHandlerUser = false
      }else{
        this.isHandlerUser = true
      }
      this.problemList();
    },
    //高级搜索点击函数
    playShow() {
      if (this.ifSearchMany == false) {
        this.ifSearchMany = true;
      } else {
        this.ifSearchMany = false;
      }

      // 问题类型下拉框
      this.$api.selectorForWorkOrder().then(res => {
    
        this.problemTypeIdlist = res.data.data;
      });
         // 问题状态下拉框
      this.$api.selectorToWorkorder().then(res => {
    
        this.problemStatusList = res.data.data;
      });
    },
    // 高级搜索模糊查询
    imeiSearch() {
      this.problemList();
    },
  
    // 点击处理
    handleFn(item) {
       this.$router.push({
        path: `/woekOrderHandle`,
        query: { id: item.id }
      });
    
    },
    // 处理确定接口
    submitFormFandle() {
      this.$refs["formHandle"].validate(valid => {
        if (valid) {
          if(this.userDisabled == true){
            this.formHandle.assignUserId = this.realName

          }
          this.handleDialogVisible = false;
          this.formHandle.problemId = this.problemId;
          this.formHandle.ccUserIds = this.formHandle.ccUserIds;
          this.$api.problemHandle(this.formHandle).then(res => {
            if (res.data.code == 200) {
              this.$message.success("处理成功!");
              this.problemList();
            }
          });
        } else {
          return false;
        }
      });
    },
    //批量选中
    getIds(selection, row) {
      var arr = [];
      for (var i = 0; i < selection.length; i++) {
        arr.push(selection[i].mac);
      }
      this.dispatchList = arr;
      var idArr = [];
      for (var i = 0; i < selection.length; i++) {
        idArr.push(selection[i].id);
      }
      this.ids = idArr;
   
    },
    //全部选中
    getAllIds(selection, row) {
      var arr = [];
      for (var i = 0; i < selection.length; i++) {
        arr.push(selection[i].mac);
      }
      this.dispatchList = arr;
      var idArr = [];
      for (var i = 0; i < selection.length; i++) {
        idArr.push(selection[i].id);
      }
      this.ids = idArr;
 
    },
    // 跳转详情
    questionDetails(id) {
      this.$router.push({
        path: `/workOrderDetails`,
        query: { id: id }
      });
    },
    // 新增跳转
    questionAdd() {
      this.$router.push({
        path: `/questionAdd`
      });
    },
    ifMohuHandler(e) {
      // console.log(e);
      this.ifMohu = false;
      this.searchData = [];
    },
    // changex清空
    upOptionType() {
      this.nameinput = "";
    },
    handlePageChange(val) {
     
      this.current = val;
      this.problemList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.problemList();
    },

    cancelDelete() {
      this.delDialogVisible = false;
      this.productId = "";
    },
    handleDelClose() {
      this.delDialogVisible = false;
      this.deleteProduct(this.productId);
    },

    problemList() {
      //查询列表
      this.$api
        .workOrderList({
          page: {
            current: this.current,
            size: this.pageSize
          },
          query: {
        
            workOrderNo: this.workOrderNo ,
            problemStatus: this.problemStatus,
            problemTitle: this.problemTitle,
            problemTypeId: this.problemTypeId,
            
          }
        })
        .then(res => {
          this.tableData = res.data.data.records;
        
          this.total = res.data.data.total;
          // let cloudList = JSON.parse(sessionStorage.getItem("cloudList"));
         
          // for (let i = 0; i < cloudList.length; i++) {
          //   if (
          //     this.$store.state.localList.localList.productDel == cloudList[i]
          //   ) {
          //     this.productDel = true;
          //   }
          //   if (
          //     this.$store.state.localList.localList.productAdd == cloudList[i]
          //   ) {
          //     this.productAdd = true;
          //   }
          // }
        })
        .catch(error => {
          console.log(error);
        });
    },
    // 导出
    problemExport() {
      let properties = [];
      let titles = [];
      for (let i = 0; i < this.relLabelsStatus.length; i++) {
        if (this.relLabelsStatus[i]) {
          properties.push(this.props[i]);
          titles.push(this.labels[i]);
        }
      }
      let query = {};
      query.handlerUserId = this.handlerUserId;
      query.ids = this.ids;
      query.isHandled = this.isActive;
      query.mac = this.mac;
      query.problemStatus = this.problemStatus;
      query.problemTitle = this.problemTitle;
      query.problemTypeId = this.problemTypeId;
      this.$api
        .problemExport({
          properties: properties,
          titles: titles,
          query: query
        })
        .then(res => {
      this.download(res.data);
        })
        .catch(error => {});
    },
    download(data) {
      if (!data) {
        return;
      }
      let url = window.URL.createObjectURL(
        new Blob([data], {
          type: "application/octet-binary"
          // type: "application/vnd.ms-excel"
        })
      );
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "excel.xls");

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
    //重置函数
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    showAddProductDialog() {
      this.dialogVisible = true;
      this.form.problemStatus = "";
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    // 指派接口
    submitForm(formName) {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.dialogVisible = false;
          this.$api
            .problemReassignment({
              problemId: this.problemId,
              reassignmentUserId: this.form.handlerUserId,
              handleOpinion: this.form.handleOpinion
            })
            .then(res => {
              if (res.data.code == 200) {
                this.$message.success("转派成功!");
                this.form.handlerUserId = "";
                this.problemList();
              }
            });
        } else {
          return false;
        }
      });
    }
  },
  created() {
    //查询产品列表
    this.problemList();
     this.accountLevel =  sessionStorage.getItem('accountLevel');
  
  }
};
</script>
<style lang="less" scoped>
@toolBarFontColor: rgba(17, 17, 17, 0.87);

.el-row {
  margin: 1rem 3rem;
  line-height: 0;
  min-width: 900px;

  .platform-toolbar {
    position: relative;
    margin: 0;
    border: 1px solid #d9d9d9;

    .title-info {
      height: 46px;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .el-col {
      padding: 0 3rem;

      .toolbar-right {
        float: right;
        margin-top: 7px;

        &.export {
          margin-left: 1rem;

          .toolbar-btn {
            color: @toolBarFontColor;
            font-size: 0.9rem;

            &.left {
              margin-left: 1.5rem;
            }

            &.right {
              margin-right: 0;
            }
          }
        }
      }
    }

    .title-info {
      float: left;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .result-info-wrapper {
    height: 1rem;
    margin: 1rem 0 0.5rem 0;
    position: relative;

    .setting-panel {
      position: absolute;
      float: right;
      top: 2rem;
      width: 200px;
      height: 200px;
      background: red;
      z-index: 1000;
      border-radius: 3px;
    }

    .result-info {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      float: left;
      color: #898989;

      span {
        color: #292929;
        padding: 0 0.1rem;
      }
    }

    .data-setting {
      font-size: 0.875rem;
      color: #2673bb;
      float: right;

      i {
        padding-right: 0.2rem;
      }

      cursor: pointer;
    }
  }

  .el-table td,
  .el-table th {
    padding: 0 !important;
  }

  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}

.product-content {
  height: 6rem;

  .product-info-item {
    float: left;
    margin-right: 4rem;

    .label-info {
      margin-bottom: 0.5rem;

      label {
        font-size: 0.75rem;

        span {
          color: red;
          margin-right: 0.5rem;
        }
      }
    }

    .el-input {
      width: 10rem;
    }

    .el-select {
      width: 13rem;
    }
  }
}
.container-el-row {
  margin-top: 0px;
}
.select {
  height: 46px;
  overflow: hidden;
  border-bottom: 1px solid #d9d9d9;
  padding: 0rem 3rem;
  .title {
    display: inline-block;
    line-height: 46px;
    font-weight: bold;
    font-size: 0.8rem;
  }

  .select-button {
    float: right;
    color: @toolBarFontColor;
    margin-top: 7px;
    margin-left: 15px;
  }
  .active {
    color: #2673bb;
  }
  .select-input-right-input {
    float: right;
    margin-top: 7px;
  }
}
.gaojiSearch {
  margin-top: 10px;
  padding: 1rem 3rem;
  margin-bottom: 10px;
  background-color: #f4f6fa;
  .searchBut {
    float: right;
    margin-left: 20px;
  }
}
.toolbarTit {
  min-width: 101px;
  display: inline-block;
}
.table-vv {
  padding: 0rem 3rem;
  margin-top: 1rem;
  .result-info {
    height: 1rem;
    line-height: 1rem;
    font-size: 0.875rem;
    float: left;
    color: #898989;

    span {
      color: #292929;
      padding: 0 0.1rem;
    }
  }
  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}
</style>
