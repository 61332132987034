let lodash = require('lodash')
/**
 * 数组格式转树状结构
 * @param   {array}     array
 * @param   {String}    id
 * @param   {String}    pid
 * @param   {String}    children
 * @return  {Array}
 */
const arrayToTree = (array, id = 'id', pid = 'pid', children = 'children') => {
    const data = lodash.cloneDeep(array);
    const result = [];
    const hash = {};
    data.forEach((item, index) => {
      hash[data[index][id]] = data[index];
    });
  
    data.forEach((item) => {
      const hashVP = hash[item[pid]];
      if (hashVP) {
        !hashVP[children] && (hashVP[children] = []);
        hashVP[children].push(item);
      } else {
        result.push(item);
      }
    });
    return result;
  };
  export default{
    arrayToTree
  }