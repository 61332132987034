import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userinfo:{
      // username:"",
      token:""
    },
    menuList:[],
    wenshengtwo:[],
    wenshengthree:[],
    sidebar:[],//侧边栏数据
    activeIndex2:'',//侧边栏目前选中高亮状态
    activeIndex:'',//头顶栏目前选中高亮状态
    localList:{},//本地权限对象
    cloudList:[],//拿到的后端权限数组
    DeviceList:[],//缓存设备列表数据
  },
  mutations: {
    setUserInfo(state,userinfo){
    state.userinfo = userinfo;
    },
    setMenuList(state,menuList){
      state.userinfo = menuList;
    },
    setWenShengTwo(state,WenShengTwo){
      state.wenshengtwo=WenShengTwo;
    },
    setWenShengThree(state,WenShengThree){
      state.wenshengthree=WenShengThree;
    },
    setSidebar(state,sidebar){
      state.sidebar=sidebar
    },
    setActiveIndex2(state,activeIndex2){
      state.activeIndex2=activeIndex2
    },
    setActiveIndex(state,activeIndex){
      state.activeIndex=activeIndex
    },
    setLocalList(state,localList){
      state.localList=localList
    },
    setcloudList(state,cloudList){
      state.cloudList=cloudList
    },
    getDeviceListState(state,data){
      state.DeviceList=data
    }

  },
  actions: {
    seUserInfoActions(context,userinfo){
      context.commit('setUserInfo',userinfo)
    },
    setMenuListActions(context,menuList){
      context.commit('setMenuList',menuList)
    },
    setWenShengTwoActions(context,WenShengTwo){
      context.commit('setWenShengTwo',WenShengTwo)
    },
    setWenShengThreeActions(context,WenShengThree){
      context.commit('setWenShengThree',WenShengThree)
    },
    setSidebarActions(context,sidebar){
      context.commit('setSidebar',sidebar)
    },
    setActiveIndex2Actions(context,activeIndex2){
      context.commit('setActiveIndex2',activeIndex2)
    },
    setActiveIndexActions(context,activeIndex){
      context.commit('setActiveIndex',activeIndex)
    },
    setLocalListActions(context,localList){
      context.commit('setLocalList',localList)
    },
    setCloudListActions(context,cloudList){
      context.commit('setcloudList',cloudList)
    }
  },
  modules: {
  }
})
