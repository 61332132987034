<template>
  <div class="outting">
    <div class="select">
      <span class="title">新增板块</span>
      <el-button
        type="text"
        size="small"
        icon="el-icon-back"
        @click="gobackPlateList"
        class="addRoleButton"
      >返回广告列表</el-button>
    </div>
    <div class="luobitu--vv">
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix">
          <span>{{floorName}}</span>
        </div>
        <el-upload
          action
          :on-change="handleChange"
          list-type="picture-card"
          v-show="!value"
          :show-file-list="false"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog>
        <div v-show="value" class="el-upload-list el-upload-list--picture-card">
          <div class="el-upload-list__item is-success">
            <img class="avatar" v-show="value" :src="value" />
            <label class="el-upload-list__item-status-label">
              <i class="el-icon-upload-success el-icon-check"></i>
            </label>
            <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-preview">
                <i class="el-icon-zoom-in" @click.stop="handlePictureCardPreview"></i>
              </span>
              <span class="el-upload-list__item-delete">
                <i class="el-icon-delete" @click.stop="handleRemove"></i>
              </span>
            </span>
          </div>
        </div>
        <div style="margin: 20px;"></div>
        <el-form
          label-position="left"
          label-width="80px"
          :model="formLabelAlign"
          style="width:70%;"
        >
          <el-form-item label="版块名称">
            <el-input v-model="formLabelAlign.plateName"></el-input>
          </el-form-item>
          <el-form-item label="版块标题">
            <el-input v-model="formLabelAlign.plateTitle"></el-input>
          </el-form-item>
          <el-form-item label="版块副标题">
            <el-input v-model="formLabelAlign.plateSubtitle"></el-input>
          </el-form-item>
          <el-form-item label="版块详情">
            <el-input v-model="formLabelAlign.plateDetail "></el-input>
          </el-form-item>
          <el-form-item label="跳转链接">
            <el-input v-model="formLabelAlign.plateLinkUrl"></el-input>
          </el-form-item>
          <el-form-item label="版块顺序">
            <el-input v-model="formLabelAlign.plateSort"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm()">提交</el-button>
            <el-button @click="resetForm()">重置</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>
<script>
export default {
  name: "AddPlate",
  data() {
    return {
      value: "",
      dialogVisible: false,
      dialogImageUrl: "",
      file: {},
      ifUpload: false,
      formLabelAlign: {
        plateName: "",
        plateTitle: "",
        plateSubtitle: "",
        plateDetail: "",
        plateLinkUrl: "",
        plateSort: ""
      },
      sysFloorId: "",
      floorName: ""
    };
  },
  created() {
    this.sysFloorId = Number(this.$route.query.id);
    this.floorName = this.$route.query.name;
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleChange(file, fileList) {
      this.ifUpload = true;
      this.value = file.url;
      this.file = file.raw;
    },
    handleRemove(file, fileList) {
      this.ifUpload = false;
      this.value = "";
      this.file = {};
    },
    submitForm() {
      if (this.ifUpload === false) {
        this.$message.error("请上传图片!");
      } else if (this.formLabelAlign.plateSort) {
        let formData = new FormData();
        formData.append("multipartFile", this.file);
        formData.append("plateSort", Number(this.formLabelAlign.plateSort));
        formData.append("plateName", this.formLabelAlign.plateName);
        formData.append("plateTitle", this.formLabelAlign.plateTitle);
        formData.append("plateSubtitle", this.formLabelAlign.plateSubtitle);
        formData.append("plateDetail", this.formLabelAlign.plateDetail);
        formData.append("plateLinkUrl", this.formLabelAlign.plateLinkUrl);
        formData.append("sysFloorId", Number(this.sysFloorId));
        this.$api.addPlateHandler(formData).then(res => {
          if (res.data.code == 200) {
            this.$message.success("添加版块成功!");
            this.$router.push({
              path: `/plateList`,
              query: { id: this.sysFloorId, name: this.floorName }
            });
          }
        });
      } else {
        let formData = new FormData();
        formData.append("multipartFile", this.file);
        formData.append("plateName", this.formLabelAlign.plateName);
        formData.append("plateTitle", this.formLabelAlign.plateTitle);
        formData.append("plateSubtitle", this.formLabelAlign.plateSubtitle);
        formData.append("plateDetail", this.formLabelAlign.plateDetail);
        formData.append("plateLinkUrl", this.formLabelAlign.plateLinkUrl);
        formData.append("sysFloorId", Number(this.sysFloorId));
        this.$api.addPlateHandler(formData).then(res => {
          if (res.data.code == 200) {
            this.$message.success("添加版块成功!");
            this.$router.push({
              path: `/plateList`,
              query: { id: this.sysFloorId, name: this.floorName }
            });
          }
        });
      }
    },
    resetForm() {},
    gobackPlateList() {
      this.$router.push({
        path: `/plateList`,
        query: { id: this.sysFloorId, name: this.floorName }
      });
    }
  }
};
</script>
<style lang="less" scoped>
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;
.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .select-status {
      width: 9rem;
      margin-left: 1.7rem;
      margin-top: 7px;
      // margin-top:7px;
    }

    .select-input-timer {
      float: right;
    }

    .select-status {
      float: right;
    }

    .select-input-right-input {
      float: right;
      // width: 8rem;
      line-height: 46px;
    }

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .addRoleButton {
      margin-left: 15px;
    }

    .serachRoleButton {
      margin-left: 15px;
    }

    button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
    }
  }
  .luobitu--vv {
    padding: 1rem 3rem;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>