<template>
  <div>
    <div class="outting">
      <div class="select">
        <span class="title">房间列表</span>
        <el-button
          type="text"
          size="small"
          icon="el-icon-bottom"
          @click="playShow"
          class="serachRoleButton"
        >高级查询</el-button>
        <div style="float:right;line-height:46px;">
          <el-input
            v-model="inputPlaceName"
            prefix-icon="el-icon-search"
            placeholder="请输入场地名"
            size="small"
            @keyup.enter.native="search"
          ></el-input>
        </div>
      </div>
      <div class="gaojiSearch" v-show="ifSearchMany">
        <div style="width:100%;overflow: hidden;">
          <div style="float:left">
            <div class="grid-content bg-purple-dark">
              <span>房间查询</span>
              <div style="margin-top:5px;">
                <el-input
                  v-model="roomName"
                  prefix-icon="el-icon-search"
                  placeholder="请输入房间名"
                  size="small"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
        <div style="width:100%;overflow: hidden;margin-top:.5rem;">
          <div style="float:right;">
            <div class="grid-content bg-purple-dark">
              <div class="searchBut">
                <el-button size="small" type="primary" @click="checkHandler">搜索</el-button>
              </div>
              <div class="emptyBut">
                <el-button size="small" @click="emptyHandler">清空</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="placeTable">
        <el-table
          :data="tableData"
          style="width: 100%"
          stripe
          size="small"
          :header-cell-style="{'background-color': '#eeeeee'}"
        >
          <el-table-column label="序号" type="index" min-width="1" fixed align="center"></el-table-column>
          <el-table-column prop="roomId" label="房间名ID" min-width="1" fixed align="center">
            <template slot-scope="{row}">{{ row.roomId || '暂无数据' }}</template>
          </el-table-column>
          <el-table-column prop="roomName" label="房间名称" min-width="1" fixed align="center">
            <template slot-scope="{row}">{{ row.roomName || '暂无数据' }}</template>
          </el-table-column>
          <el-table-column prop="parentPlace" label="场地名称" min-width="1" fixed align="center">
            <template slot-scope="{row}">{{ row.parentPlace || '暂无数据' }}</template>
          </el-table-column>
          <el-table-column prop="deviceCount" label="设备数量" min-width="1" fixed align="center">
            <template slot-scope="{row}">{{ row.deviceCount || '暂无数据' }}</template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          class="pagin"
          @current-change="changeCurrent"
          :page-size="10"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RoomList",
  data() {
    return {
      inputPlaceName: "",
      ifSearchMany: false,
      roomName: "",
      tableData: [],
      current: "",
      total: 0
    };
  },
  created() {
    this.$api
      .roomList({
        page: {
          current: 1,
          size: 10
        }
      })
      .then(res => {
        console.log(res.data.data.records);
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
  },
  methods: {
    //高级查询函数
    playShow() {
      if (this.ifSearchMany == false) {
        this.ifSearchMany = true;
      } else {
        this.ifSearchMany = false;
      }
    },
    //回车搜索函数
    search() {
      this.$api
        .roomList({
          page: {
            current: 1,
            size: 10
          },
          query: {
            placeName: this.inputPlaceName,
            roomName: this.roomName
          }
        })
        .then(res => {
          console.log(res.data.data.records);
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        });
    },
    //搜索点击事件
    checkHandler() {
      this.$api
        .roomList({
          page: {
            current: 1,
            size: 10
          },
          query: {
            placeName: this.inputPlaceName,
            roomName: this.roomName
          }
        })
        .then(res => {
          console.log(res.data.data.records);
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        });
    },
    //清空事件
    emptyHandler() {
      this.inputPlaceName = "";
      this.roomName = "";
    },
    //分页函数
    changeCurrent(id) {
      this.current = id;
      this.$api
        .roomList({
          page: {
            current: this.current,
            size: 10
          },
          query: {
            placeName: this.inputPlaceName,
            roomName: this.roomName
          }
        })
        .then(res => {
          console.log(res.data.data.records);
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        });
    }
  }
};
</script>
<style lang="less" scoped>
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;
.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;
    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }
    .serachRoleButton {
      margin-left: 15px;
    }

    button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
    }
  }
  .gaojiSearch {
    margin-top: 10px;
    padding: 1rem 3rem;
    margin-bottom: 10px;
    background-color: #f4f6fa;
    .searchBut {
      float: right;
      margin-left: 20px;
    }

    .emptyBut {
      float: right;
    }
  }
  .placeTable {
    padding: 0rem 3rem;
    margin-top: 1rem;
    .el-pagination {
      margin: 1rem 0;
      float: right;
    }
  }
}
.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

// .bg-purple-dark {
//   background: #99a9bf;
// }
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>