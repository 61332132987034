<template>
  <div class="outting">
    <div class="select">
      <span class="title">权限列表</span>
      <el-button type="text" size="small" icon="el-icon-plus" @click="addModuleStar">新增模块</el-button>
    </div>
    <!-- dialogFormVisible = true -->
    <div class="container">
      <div class="container-power">
        <el-tree
            :data="dataTree"
            node-key="id"
            :props="defaultProps"
            @check="getModulesAndPowerId"
            :default-expanded-keys="defaultDisplayId"
        >
          <span class="custom-tree-node" slot-scope="{ node,data }">
            <span>{{ node.label }}</span>
            <span class="iconSp" @click.stop="editPower(data)">
              <i class="el-icon-edit" v-show="data.id!==0"></i>
            </span>
            <span class="iconSp" @click.stop="plusPower(data)">
              <i class="el-icon-plus"></i>
            </span>
            <span class="iconSp" @click.stop="deletePower(data.id)">
              <i class="el-icon-delete" v-show="data.id!==0"></i>
            </span>
          </span>
        </el-tree>
      </div>
    </div>
    <!-- 增加模块对话框开始 -->

    <el-dialog title="新增模块" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="模块名称" :label-width="formLabelWidth" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="模块路由" :label-width="formLabelWidth" prop="router">
          <el-input v-model="form.router" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addModule">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 增加模块对话框结束 -->
    <!-- 删除模块对话框开始 -->
    <el-dialog title="提示" :visible.sync="deleteVisible" width="30%" :close-on-click-modal="false">
      <span>确定要删除这个模块吗</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deketeTabHandler">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 删除模块对话框结束 -->
    <!-- 编辑模块对话框开始 -->
    <el-dialog title="编辑模块" :visible.sync="editFormVisible" :close-on-click-modal="false">
      <el-form :model="editform" :rules="editFormRules" ref="editform">
        <el-form-item label="模块名称" :label-width="formLabelWidth" prop="name">
          <el-input v-model="editform.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="editModule">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 编辑模块对话框结束 -->
    <!-- 增加权限对话框开始 -->
    <el-dialog title="新增权限" :visible.sync="addPowerFormVisible" :close-on-click-modal="false">
      <el-form :model="addPowerForm" :rules="addPowerRules" ref="addPowerForm">
        <el-form-item label="权限名称" :label-width="formLabelWidth" prop="name">
          <el-input v-model="addPowerForm.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="权限类型" :label-width="formLabelWidth" prop="type">
          <el-select v-model="addPowerForm.type" placeholder="请选类型" @change="addChangeType">
            <el-option label="导航菜单" value="1"></el-option>
            <el-option label="功能模块" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="后端接口" :label-width="formLabelWidth" prop="uri">
          <el-input v-model="addPowerForm.uri" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="页面路由" :label-width="formLabelWidth" prop="url">
          <el-input v-model="addPowerForm.url" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="图标" :label-width="formLabelWidth" prop="icon">
          <el-input v-model="addPowerForm.icon" autocomplete="off" @focus="openIcon()"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addPowerFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="plusPowerHandler">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 增加权限对话框结束 -->
    <!-- 编辑权限对话框开始 -->
    <el-dialog title="编辑权限" :visible.sync="editPowerFormVisible" :close-on-click-modal="false">
      <el-form :model="editPowerForm" :rules="editPowerRules" ref="editPowerForm">
        <el-form-item label="权限名称" :label-width="formLabelWidth" prop="name">
          <el-input v-model="editPowerForm.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
            label="权限类型"
            :label-width="formLabelWidth"
            prop="type"
            style="overflow：hidden"
        >
          <el-select v-model="editPowerForm.type" placeholder="请选类型" @change="editChangeType">
            <el-option label="导航菜单" value="1"></el-option>
            <el-option label="功能模块" value="2"></el-option>
            <el-option label="系统模块" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="后端接口" :label-width="formLabelWidth" prop="uri">
          <el-input v-model="editPowerForm.uri" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="页面路由" :label-width="formLabelWidth" prop="url">
          <el-input v-model="editPowerForm.url" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="图标" :label-width="formLabelWidth" prop="icon">
          <el-input v-model="editPowerForm.icon" autocomplete="off" @focus="openIcon()"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelPower">取 消</el-button>
        <el-button type="primary" @click="editPowerHandler">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 编辑权限对话框结束 -->
    <!-- 删除权限对话框开始 -->
    <el-dialog title="提示" :visible.sync="deletePowerFormVisible" width="30%" :close-on-click-modal="false">
      <span>确定要删除这个权限吗</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deletePowerFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="deletePowerHandler">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 删除权限对话框结束 -->
    <!-- icon图标开始 -->
    <el-dialog title="ICON图标选择" :visible.sync="iconFormVisible" style="width:100%;" :close-on-click-modal="false">
      <div style="height:400px;">
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-setting" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-user" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-phone-outline" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-more-outline" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-document" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv-right">
          <span class="icon-span">
            <i class="el-icon-bell" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-warning-outline" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-question" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-info" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-remove-outline" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-circle-plus-outline" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv-right">
          <span class="icon-span">
            <i class="el-icon-help" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-notebook-2" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-bell" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-s-order" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-s-cooperation" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-s-home" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv-right">
          <span class="icon-span">
            <i class="el-icon-menu" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-date" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-tickets" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-takeaway-box" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-search" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-monitor" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv-right">
          <span class="icon-span">
            <i class="el-icon-position" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-location-outline" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-link" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-school" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-discount" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-office-building" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv-right">
          <span class="icon-span">
            <i class="el-icon-news" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-service" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-takeaway-box" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-paperclip" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-house" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-copy-document" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv-right">
          <span class="icon-span">
            <i class="el-icon-aim" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-delete" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-edit" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-edit-outline" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-document-copy" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv">
          <span class="icon-span">
            <i class="el-icon-mobile-phone" @click="getClass($event)"></i>
          </span>
        </div>
        <div class="icon-vv-right">
          <span class="icon-span">
            <i class="el-icon-data-line" @click="getClass($event)"></i>
          </span>
        </div>
      </div>
    </el-dialog>
    <!-- icon图标结束 -->
  </div>
</template>
<script>
import Storage from "../../../utils/Storage";

export default {
  name: "SysPermission",
  data() {
    return {
      iconFormVisible: false,
      editPowerObj: {},
      editModuleObj: {},
      dialogFormVisible: false,
      plusPowerIdTwo: "",
      defaultDisplayId: [],
      activeName: "first",
      editableTabsValue: "2",
      // 模块数据
      editableTabs: [],
      tabIndex: 2,
      //模块ID
      moduleId: "",
      oldEditableTabs: [],
      deleteId: "",
      arr: [],
      //添加模块对话框数据
      form: {
        name: "",
        router: ""
      },
      formLabelWidth: "120px",
      rules: {
        name: [
          {
            required: true,
            message: "请输入模块名称",
            trigger: "blur"
          },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: "blur"
          }
        ],
        router: [
          {
            required: true,
            message: "请输入模块路由",
            trigger: "blur"
          }
        ]
      },
      //编辑模块校验规则
      editFormRules: {
        name: [
          {
            required: true,
            message: "请输入模块名称",
            trigger: "blur"
          }
        ]
      },
      //新增权限校验规则
      addPowerRules: {
        name: [
          {
            required: true,
            message: "请输入权限名称",
            trigger: "blur"
          }
        ],
        type: [
          {
            required: true,
            message: "请选权限类型",
            trigger: "change"
          }
        ],
        uri: [
          {
            required: true,
            message: "请输入后端接口",
            trigger: "blur"
          }
        ],
        url: [
          {
            required: true,
            message: "请输入页面路由",
            trigger: "blur"
          }
        ],
        icon: [
          {
            required: true,
            message: "请选择图标",
            trigger: "change"
          }
        ]
      },
      //编辑权限校验
      editPowerRules: {
        name: [
          {
            required: true,
            message: "请输入权限名称",
            trigger: "blur"
          }
        ],
        type: [
          {
            required: true,
            message: "请选权限类型",
            trigger: "change"
          }
        ],
        uri: [
          {
            required: true,
            message: "请输入后端接口",
            trigger: "blur"
          }
        ],
        url: [
          {
            required: true,
            message: "请输入页面路由",
            trigger: "blur"
          }
        ],
        icon: [
          {
            required: true,
            message: "请输入图标",
            trigger: "change"
          }
        ]
      },

      //删除模块对话框数据
      deleteVisible: false,
      //编辑模块对话框数据
      editFormVisible: false,
      editModuleId: "",
      editform: {
        name: ""
      },
      //权限树数据
      dataTree: [],
      defaultProps: {
        children: "children",
        // label: "name",
        label: "permissionName",
        id: "id"
      },
      //增加权限数据
      plusPowerId: "",
      addPowerFormVisible: false,
      addPowerForm: {
        name: "",
        type: "",
        uri: "",
        url: "",
        icon: ""
      },
      addType: "",
      //编辑权限数据
      editPowerId: "",
      editPowerFormVisible: false,
      editPowerForm: {
        name: "",
        type: "",
        uri: "",
        url: "",
        icon: ""
      },
      editType: "",
      //删除权限函数
      deletePowerId: "",
      deletePowerFormVisible: false
    };
  },
  created() {
    var token = this.$store.state.userinfo.token;
    this.$api.permissionList(token).then(res => {
      console.log(res.data.data);
      var oldEditableTabsOne = res.data.data;
      //修改传入的数据的键名
      var keyMap = {
        permissionList: "children",
        platformId: "id",
        platformName: "permissionName"
      };
      this.defaultDisplayId = [];
      oldEditableTabsOne.forEach(element => {
        // console.log(element.platformId);
        this.defaultDisplayId.push(element.platformId);
      });
      for (var i = 0; i < oldEditableTabsOne.length; i++) {
        var obj = oldEditableTabsOne[i];
        for (var key in obj) {
          var newKey = keyMap[key];
          if (newKey) {
            obj[newKey] = obj[key];
            delete obj[key];
          }
        }
      }
      //打印新数据
      // console.log(oldEditableTabsOne);
      var arr = [];
      for (var i = 0; i < oldEditableTabsOne.length; i++) {
        oldEditableTabsOne[i].children = Storage.toTreePower(
            oldEditableTabsOne[i].children
        );
        arr.push(oldEditableTabsOne[i].id);
      }
      console.log(arr);
      this.arr = arr;
      var oldEditableTabsOneOne = this.setLastChildren(oldEditableTabsOne);
      console.log(oldEditableTabsOneOne);
      this.dataTree = oldEditableTabsOneOne;
      this.oldEditableTabs = res.data.data;
      var newEditableTabs = res.data.data;
    });
  },
  methods: {
    //补充最后一个子级有children
    setLastChildren(arr) {
      arr.forEach(item => {
        if (!item.children) {
          this.$set(item, "children", []);
        } else {
          this.setLastChildren(item.children);
        }
      });
      return arr;
    },
    //编辑权限关闭清空数据
    cancelPower() {
      this.editPowerFormVisible = false;
    },
    //对话框取消校验函数
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //添加模块
    addModule() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          var token = this.$store.state.userinfo.token;
          var name = this.form.name;
          var router = this.form.router;
          this.dialogFormVisible = false;
          this.$api
              .addModuleHandler(
                  {
                    platformName: name,
                    platformUri: router
                  },
                  token
              )
              .then(res => {
                console.log(res.data.data);
                if (res.data.code == 200) {
                  var obj = {};
                  obj.id = res.data.data.id;
                  obj.permissionName = res.data.data.platformName;
                  obj.children = [];
                  this.dataTree.push(obj);
                  this.arr.push(obj.id);
                  this.$message.success("添加模块成功！");
                } else {
                  var message = res.data.message;
                  this.$message.error(message);
                }
              });
        } else {
          return false;
        }
      });
    },
    //编辑权限获取数据函数
    editPower(data) {
      this.editPowerObj = data;
      console.log(data);
      console.log(data.id);
      if (data.platformId) {
        this.editPowerFormVisible = true;
        // this.$nextTick(function() {
        if (this.$refs["editPowerForm"] !== undefined) {
          this.resetForm("editPowerForm");
        }

        // });
        //如果没有修改权限类型
        this.editType = String(data.permissionType);
        //编辑回显
        this.editPowerId = data.id;
        this.moduleId = data.platformId;
        this.editPowerForm.name = data.permissionName;
        this.editPowerForm.uri = data.uri;
        this.editPowerForm.url = data.permissionKey;
        this.editPowerForm.icon = data.icon;
        // console.log(data.permissionType);
        this.editPowerForm.type = String(data.permissionType);
      } else {
        this.editFormVisible = true;
        if (this.$refs["editform"] !== undefined) {
          this.resetForm("editform");
        }
        //编辑回显
        this.editModuleObj = data;
        this.editModuleId = data.id;
        this.editform.name = data.permissionName;
      }
    },
    //编辑权限请求函数
    editPowerHandler() {
      this.$refs["editPowerForm"].validate(valid => {
        console.log(this.editType);
        if (valid) {
          var token = this.$store.state.userinfo.token;
          this.$api
              .updatePower(
                  {
                    permissionName: this.editPowerForm.name,
                    permissionKey: this.editPowerForm.url,
                    permissionType: Number(this.editType),
                    platformId: Number(this.moduleId),
                    id: this.editPowerId,
                    uri: this.editPowerForm.uri,
                    icon: this.editPowerForm.icon,
                    sort: 0
                  },
                  token
              )
              .then(res => {
                console.log(res);
                if (res.data.code == 200) {
                  this.editPowerObj.permissionName = this.editPowerForm.name;
                  this.editPowerObj.permissionType = Number(this.editType);
                  this.editPowerObj.icon = this.editPowerForm.icon;
                  this.editPowerObj.uri = this.editPowerForm.uri;
                  this.editPowerObj.permissionKey = this.editPowerForm.url;
                  var result = Storage.staticEditPower(
                      this.dataTree,
                      this.editPowerId,
                      this.editPowerObj
                  );
                  this.dataTree = result;
                  this.$message.success("编辑成功!");
                  this.editPowerFormVisible = false;
                } else {
                  var message = res.data.message;
                  this.$message.error(message);
                  this.editPowerFormVisible = false;
                }
              })
              .catch(err => {
                console.log(err);
              });
        } else {
          return false;
        }
      });
    },
    //权限添加获取数据函数
    plusPower(data) {
      console.log(data);
      console.log(data.id);
      //判断是否为根权限
      if (data.platformId) {
        this.moduleId = data.platformId;
        this.plusPowerId = data.id;
        this.plusPowerIdTwo = data.id;
      } else {
        this.moduleId = data.id;
        this.plusPowerId = 0;
        this.plusPowerIdTwo = data.id;
      }
      this.addPowerFormVisible = true;
      this.$nextTick(function () {
        this.resetForm("addPowerForm");
      });
    },

    //权限添加确定函数
    plusPowerHandler() {
      this.$refs["addPowerForm"].validate(valid => {
        if (valid) {
          this.addPowerFormVisible = false;
          var token = this.$store.state.userinfo.token;
          this.$api
              .plusPower(
                  {
                    permissionName: this.addPowerForm.name,
                    permissionKey: this.addPowerForm.url,
                    permissionType: Number(this.addType),
                    platformId: Number(this.moduleId),
                    ppermissionId: this.plusPowerId,
                    uri: this.addPowerForm.uri,
                    icon: "none",
                    sort: 0,
                    icon: this.addPowerForm.icon
                  },
                  token
              )
              .then(res => {
                console.log(res.data.data);
                if (res.data.code == 200) {
                  var obj = res.data.data;
                  this.$message.success("添加权限成功！");
                  obj.children = [];
                  console.log(obj);
                  console.log(this.plusPowerIdTwo);

                  var result = Storage.staticPlusPpwer(
                      this.dataTree,
                      this.plusPowerIdTwo,
                      obj
                  );
                  this.dataTree = result;
                } else {
                  var message = res.data.message;
                  this.$message.error(message);
                }
              })
              .catch(err => {
                console.log(err);
              });
        } else {
          return false;
        }
      });
    },
    //添加权限选择器函数
    addChangeType(key) {
      console.log(key);
      this.addType = key;
    },
    //编辑权限选择器函数
    editChangeType(key) {
      // if(key==1){
      //   this.editPowerForm.type="导航菜单"
      // }else{
      //   this.editPowerForm.type="功能模块"
      // }
      console.log(this.editPowerForm.type);
      this.editType = key;
    },
    //删除权限获取id函数
    deletePower(key) {
      console.log(key);
      if (this.arr.indexOf(key) == -1) {
        this.deletePowerId = key;
        this.deletePowerFormVisible = true;
      } else {
        this.deleteId = key;
        this.deleteVisible = true;
      }
    },
    //删除权限确定函数
    deletePowerHandler() {
      var token = this.$store.state.userinfo.token;
      this.$api
          .deletePower([Number(this.deletePowerId)], token)
          .then(res => {
            this.deletePowerFormVisible = false;
            if (res.data.code == 200) {
              this.$message.success("删除成功！");
              var result = Storage.staticDeletePower(
                  this.dataTree,
                  Number(this.deletePowerId)
              );
            } else {
              var message = res.data.message;
              this.$message.error(message);
            }
          })
          .catch(err => {
            console.log(err);
          });
    },

    //前端也页面增加模块函数
    addTab(targetName) {
      let newTabName = ++this.tabIndex + "";
      this.editableTabs.push({
        title: "New Tab",
        name: newTabName,
        content: "New Tab content"
      });
      this.editableTabsValue = newTabName;
    },
    removeTab(targetName) {
      console.log(id);
      console.log(targetName.name);
    },
    //删除模块获取当前模块ID
    deleteTab(e) {
      console.log(e.currentTarget.id);
      this.deleteVisible = true;
      this.deleteId = e.currentTarget.id;
    },
    //确认删除模块
    deketeTabHandler() {
      var token = this.$store.state.userinfo.token;
      this.deleteVisible = false;
      this.$api.deleteModuleHandler(this.deleteId, token).then(res => {
        console.log(res);
        if (res.data.code == 200) {
          this.dataTree.forEach(item => {
            if (item.id == this.deleteId) {
              this.dataTree.splice(this.dataTree.indexOf(item), 1);
            }
          });
          this.$message.success("删除成功！");
        } else {
          var message = res.data.message;
          this.$message.error(message);
        }
      });
    },
    //获取模块和权限ID
    getModulesAndPowerId(obj1, obj2) {
      console.log(obj1, obj2);
    },
    //编辑模块获取当前模块ID
    ediTabHandler(e) {
      this.editModuleId = e.currentTarget.id;
      this.editFormVisible = true;
      this.editform.name = e.currentTarget.permissionName;
      console.log(this.editform.name);
    },
    //确认编辑模块请求函数
    editModule() {
      this.$refs["editform"].validate(valid => {
        if (valid) {
          var token = this.$store.state.userinfo.token;
          this.editFormVisible = false;
          this.$api
              .editModuleHandler(
                  {
                    id: this.editModuleId,
                    platformName: this.editform.name
                  },
                  token
              )
              .then(res => {
                console.log(res);
                if (res.data.code == 200) {
                  this.editModuleObj.permissionName = this.editform.name;
                  this.dataTree.forEach(item => {
                    if (item.id == this.editModuleId) {
                      item = this.editModuleObj;
                    }
                  });
                  this.$message.success("编辑成功！");
                } else {
                  var message = res.data.message;
                  this.$message.error(message);
                }
              });
        } else {
          return false;
        }
      });
    },
    //新增模块对话框函数
    addModuleStar() {
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        this.resetForm("form");
      });
      this.form.name = "";
      this.form.router = "";
    },
    editEve() {
      console.log(123456);
    },
    //icon选择打开函数
    openIcon() {
      this.iconFormVisible = true;
    },
    //图标点击事件函数
    getClass(e) {
      if (this.addPowerFormVisible) {
        this.addPowerForm.icon = e.path[0].className;
        this.iconFormVisible = false;
      } else if (this.editPowerFormVisible) {
        this.editPowerForm.icon = e.path[0].className;
        this.iconFormVisible = false;
      }
    }
  }
};
</script>
<style lang="less" scoped>
@toolBarFontColor: #111d;
.outting {
  width: 100%;
  background-color: #fafafa;

  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #cccccc;
    padding: 0rem 3rem;

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    button {
      color: @toolBarFontColor;
      font-size: 0.8rem;
      float: right;
      margin-top: 7px;
    }
  }

  .container {
    padding: 0rem 3rem;
    margin-top: 20px;

    .container-power {
      width: 100%;
      height: 100px;
      background-color: #ffffff;
    }
  }
}
span{
  font-size: 0.8rem;
}
.iconSp {
  display: inline-block;
  margin-left: 8px;
}

.icon-vv {
  float: left;
  margin-right: 2%;
  width: 15%;
  height: 40px;
  margin-top: 10px;
  text-align: center;

  .icon-span {
    font-size: 25px;

    &:hover {
      cursor: pointer;
    }
  }
}

.icon-vv-right {
  float: left;
  width: 15%;
  height: 40px;
  margin-top: 10px;
  text-align: center;

  .icon-span {
    font-size: 25px;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
<style lang="less">
.el-select-dropdown .el-scrollbar .el-scrollbar__wrap //控制下拉框显示不完全的问题
{
  overflow: scroll !important;
}
</style>
<style lang="less">
.el-select-dropdown .el-scrollbar .el-scrollbar__wrap {
  overflow: scroll !important;
}
</style>