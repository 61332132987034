<template>
  <div style="height: 100%;background-color:#fff">
    <div class="main">
      <EasyPlayer

          id="player"
          :video-url="src"
          live
          muted="false"
          :fluent="fluent"
          poster=""
          style="width: 100vw;height: 56.2vw"></EasyPlayer>
    </div>
  </div>
</template>

<script>
import EasyPlayer from "@easydarwin/easyplayer"
export default {
  name: "play_cam",
  components: {
    EasyPlayer
  },
  mounted() {

  },
  data() {
    return {
      src: "",
      autoplay:true,
      fluent:true,
    };
  },
  beforeMount() {
    this.src = decodeURIComponent(this.$route.query.src)
  },
  methods: {
    play(){

    }
  },
  beforeDestroy() {


  }
}
</script>

<style lang="less" scoped>

  body{
    background-color: #fff;
  }



  .easy-player-loading {
    display: none !important;
  }
</style>
