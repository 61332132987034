<template>
  <div class="mainContainer">
    <el-dialog title="提示" :visible.sync="delDialogVisible" width="30%">
      <span>确定删除该设备吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleDelClose">确 定</el-button>
      </span>
    </el-dialog>
    <!-- <el-row class="platform-toolbar">
      <el-col :span="24">
        <label class="title-info">设备注册列表</label>
        <div class="toolbar-right export">
          <el-button type="text" size="small" icon="el-icon-refresh-right" class="toolbar-btn" @click="refresh">刷新
          </el-button>
        </div>
        <div class="toolbar-right export">
          <el-button type="text" size="small" icon="el-icon-plus" class="toolbar-btn right"
                     @click="showAddDeviceDialog">注册
          </el-button>
        </div>
        <div class="toolbar-right">
          <el-input placeholder="请输入设备IMEI"  v-model="imei" size="small"
                    @keyup.13.native="search">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>-->
    <div class="select">
      <span class="title">设备注册列表</span>
      <el-button
        type="text"
        size="small"
        icon="el-icon-refresh-right"
        class="select-button"
        @click="refresh"
      >刷新</el-button>
      <el-button
        type="text"
        size="small"
        icon="el-icon-plus"
        class="select-button"
        @click="showAddDeviceDialog"
      >注册</el-button>
      <div class="select-input-right-input">
        <el-input placeholder="请输入设备IMEI" v-model="imei" size="small" @keyup.13.native="search">
          <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
        </el-input>
      </div>
    </div>
    <!-- <el-row style="margin-top:0;">
      <div class="result-info-wrapper">
        <div class="result-info">
          共有:
          <span>{{total}}</span>设备
        </div>
      </div>
      <el-table
        :data="tableData"
        stripe
        size="small"
        class="device-table"
        :header-cell-style="{'background-color': '#eeeeee'}"
      >
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column v-if="checkedImei" prop="mac" label="设备IMEI" align="center"></el-table-column>
        <el-table-column
          v-if="checkedRegistStatus"
          prop="registerStatusView"
          label="注册状态"
          align="center"
        ></el-table-column>
        <el-table-column v-if="checkedProduct" prop="productName" label="产品名称" align="center"></el-table-column>
        <el-table-column v-if="checkedOpera" prop="netOperatorView" label="运营商类型" align="center"></el-table-column>
        <el-table-column v-if="checkedTime" prop="createTime" label="创建时间" align="center"></el-table-column>
        <el-table-column v-if="checkedOnline" prop="onlineStatusView" label="在线状态" align="center"></el-table-column>
        <el-table-column v-if="checkedDelDet" fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              @click="deleteDevice(scope.row.mac,scope.row.netOperator)"
              type="text"
              v-show="allowDelete"
              size="small"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :current-page="current"
        :total="total"
        layout="sizes,prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
      ></el-pagination>
    </el-row>-->
    <div class="table-vv">
      <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
        <div class="result-info">
          共有
          <span>{{ total }}</span>台设备
        </div>
      </div>
      <el-table
        :data="tableData"
        stripe
        size="small"
        class="device-table"
        :header-cell-style="{'background-color': '#eeeeee'}"
      >
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column v-if="checkedImei" prop="mac" label="设备IMEI" align="center"></el-table-column>
        <el-table-column
          v-if="checkedRegistStatus"
          prop="registerStatusView"
          label="注册状态"
          align="center"
        ></el-table-column>
        <el-table-column v-if="checkedProduct" prop="productName" label="产品名称" align="center"></el-table-column>
        <el-table-column v-if="checkedOpera" prop="netOperatorView" label="运营商类型" align="center"></el-table-column>
        <el-table-column v-if="checkedTime" prop="createTime" label="创建时间" align="center"></el-table-column>
        <el-table-column v-if="checkedOnline" prop="onlineStatusView" label="在线状态" align="center"></el-table-column>
        <el-table-column v-if="checkedDelDet" fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              @click="deleteDevice(scope.row.mac,scope.row.netOperator)"
              type="text"
              v-show="allowDelete"
              size="small"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :current-page="current"
        :total="total"
        layout="sizes,prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>
    <el-dialog title="注册设备" :visible.sync="dialogVisible" width="48rem">
      <div class="device-content">
        <div class="device-info-item">
          <div>
            <div class="label-info">
              <label>
                <span>*</span>设备IMEI
              </label>
            </div>
            <el-input v-model="device.macStr" placeholder="设备IMEI" size="small"></el-input>
          </div>
        </div>
        <div class="device-info-item">
          <div>
            <div class="label-info">
              <label>
                <span>*</span>运营商类型
              </label>
            </div>
            <el-select v-model="device.netOperatorId" placeholder="请选择" size="small">
              <el-option
                v-for="item in optionsOperator"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="device-info-item">
          <div>
            <div class="label-info">
              <label>
                <span>*</span>产品类型
              </label>
            </div>
            <el-select v-model="device.productId" placeholder="请选择" size="small">
              <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="registDevice()" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
import bus from "@/components/bus";

export default {
  data() {
    return {
      device: {
        macStr: "",
        netOperatorId: "",
        productId: ""
      },
      allowDelete: true,
      imei: "",
      visible: false,
      checkedRegistStatus: true,
      macStr: "",
      netOperatorId: "",
      checkedProduct: true,
      checkedOpera: true,
      checkedTime: true,
      checkedOnline: true,
      checkedDelDet: true,
      checkedImei: true,
      delDialogVisible: false,
      tableData: [],
      //新增设备弹出对话框标记
      dialogVisible: false,
      options: [],
      optionsOperator: [
        { name: "电信", code: "1" },
        { name: "联通", code: "2" },
        { name: "移动", code: "0" }
      ],
      value: "",
      input: "",
      current: 1,
      pageSize: 10,
      total: 0
    };
  },
  methods: {
    deviceDetail(data) {
      this.$router.push("deviceDetail");
    },
    deleteDevice(macStr, netOpera) {
      this.delDialogVisible = true;
      this.macStr = macStr;
      this.netOperatorId = netOpera;
    },
    handleDelClose() {
      this.delDialogVisible = false;
      this.$api
        .deleteIotDevice({
          macStr: this.macStr,
          netOperatorId: this.netOperatorId
        })
        .then(res => {
          if (res.data.code == "200") {
            this.$message({
              message: "删除成功",
              type: "success"
            });
            this.macStr = "";
            this.netOperatorId = "";
            this.getRegisterList();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    search() {
      this.current = 1;
      this.getRegisterList();
    },
    reset() {
      (this.checkedRegistStatus = true),
        (this.checkedProduct = true),
        (this.checkedOpera = true),
        (this.checkedTime = true),
        (this.checkedOnline = true),
        (this.checkedDelDet = true),
        (this.checkedImei = true);
      this.current = 1;
      this.imei = "";
      this.pageSize = 10;
      this.getRegisterList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getRegisterList();
    },
    handlePageChange(val) {
      this.current = val;
      this.getRegisterList();
    },
    refresh() {
      this.getRegisterList();
    },

    registDevice() {
      if (this.device.macStr == "") {
        this.$message({
          message: "设备IMEI不能为空",
          type: "error"
        });
        return;
      } else if (this.device.netOperatorId == "") {
        this.$message({
          message: "运营商类型不能为空",
          type: "error"
        });
        return;
      } else if (this.device.productId == "") {
        this.$message({
          message: "产品类型不能为空",
          type: "error"
        });
        return;
      }
      this.dialogVisible = false;
      this.$api
        .registDeivce(this.device)
        .then(res => {
          this.device.netOperatorId = "";
          this.device.macStr = "";
          this.device.productId = "";
          if (res.data.code == "200") {
            this.$message({
              message: "正在注册，请稍后查看注册结果",
              type: "success"
            });
            //查询设备列表
            this.current = 1;
            this.getRegisterList();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    showAddDeviceDialog() {
      this.dialogVisible = true;
      //请求后台接口，获取产品列表
      this.$api
        .productListForPullUrl({
          page: {
            current: 1,
            size: 1000
          }
        })
        .then(res => {
          this.options = res.data.data;
        })
        .catch(error => {});
    },
    getRegisterList() {
      //查询设备列表
      this.$api
        .deviceRegisterList({
          page: {
            current: this.current,
            size: this.pageSize
          },
          queryDto: {
            mac: this.imei
          }
        })
        .then(res => {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        })
        .catch(error => {});
    }
  },
  created() {
    this.getRegisterList();
    // let cloudList=JSON.parse(sessionStorage.getItem("cloudList"))
    // for(let i=0;i<cloudList.length;i++) {
    //   if (this.$store.state.localList.localList.productDel == cloudList[i]) {
    //     this.allowDelete = true
    //   }
    // }
  }
};
</script>
<style lang="less" scoped>
@toolBarFontColor: rgba(17, 17, 17, 0.87);

.el-row {
  margin: 1rem 3rem;
  line-height: 0;
  min-width: 900px;

  .platform-toolbar {
    position: relative;
    margin: 0;
    border: 1px solid #d9d9d9;

    .title-info {
      height: 46px;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .el-col {
      padding: 0 3rem;

      .toolbar-right {
        float: right;
        margin-top: 7px;

        &.export {
          margin-left: 1rem;

          .toolbar-btn {
            color: @toolBarFontColor;
            font-size: 0.9rem;

            &.left {
              margin-left: 1.5rem;
            }

            &.right {
              margin-right: 0;
            }
          }
        }
      }
    }

    .title-info {
      float: left;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .result-info-wrapper {
    height: 1rem;
    margin: 1rem 0 0.5rem 0;
    position: relative;

    .setting-panel {
      position: absolute;
      float: right;
      top: 2rem;
      width: 200px;
      height: 200px;
      background: red;
      z-index: 1000;
      border-radius: 3px;
    }

    .result-info {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      float: left;
      color: #898989;

      span {
        color: #292929;
        padding: 0 0.1rem;
      }
    }

    .data-setting {
      font-size: 0.875rem;
      color: #2673bb;
      float: right;

      i {
        padding-right: 0.2rem;
      }

      cursor: pointer;
    }
  }

  .el-table td,
  .el-table th {
    padding: 0 !important;
  }

  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}

.device-content {
  height: 6rem;

  .device-info-item {
    float: left;
    margin-right: 3rem;

    .label-info {
      margin-bottom: 0.5rem;

      label {
        font-size: 0.75rem;

        span {
          color: red;
          margin-right: 0.5rem;
        }
      }
    }

    .el-input {
      width: 10rem;
    }

    .el-select {
      width: 12rem;
    }
  }
}
.select {
  height: 46px;
  overflow: hidden;
  border-bottom: 1px solid #d9d9d9;
  padding: 0rem 3rem;
  .title {
    display: inline-block;
    line-height: 46px;
    font-weight: bold;
    font-size: 0.8rem;
  }
  .select-button {
    float: right;
    color: @toolBarFontColor;
    margin-top: 7px;
    margin-left: 15 /16rem;
  }
  .select-input-right-input {
    float: right;
    margin-top: 7px;
  }
}
.table-vv {
  padding: 0rem 3rem;
  margin-top: 1rem;
  .result-info {
    height: 1rem;
    line-height: 1rem;
    font-size: 0.875rem;
    float: left;
    color: #898989;

    span {
      color: #292929;
      padding: 0 0.1rem;
    }
  }
  .data-setting {
    font-size: 0.875rem;
    color: #2673bb;
    float: right;
    line-height: 1rem;

    i {
      padding-right: 0.2rem;
    }

    cursor: pointer;
  }
  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}
</style>
