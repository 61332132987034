<template>
  <div class="outting">
    <div class="select">
      <span class="title">{{title}}版块列表</span>
      <el-button
        type="text"
        size="small"
        icon="el-icon-back"
        @click="gobackFloorList"
        class="addRoleButton"
      >返回楼层列表</el-button>
      <el-button
        type="text"
        size="small"
        icon="el-icon-plus"
        @click="addUserHandler"
        class="addRoleButton"
      >新增版块</el-button>
      <el-button
        type="text"
        size="small"
        icon="el-icon-bottom"
        @click="playShow"
        class="serachRoleButton"
      >高级查询</el-button>
      <div class="select-input-right-input">
        <el-input
          v-model="inputName"
          prefix-icon="el-icon-search"
          placeholder="请输入版块名称"
          size="small"
          @keyup.enter.native="search"
        ></el-input>
      </div>
    </div>
    <div class="table-vv">
      <el-table
        :data="tableData"
        style="width: 100%"
        stripe
        size="small"
        :header-cell-style="{'background-color': '#eeeeee'}"
      >
        <el-table-column prop="plateName" label="名称" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.plateName || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="plateTitle" label="标题" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.plateTitle || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="plateSubtitle" label="副标题" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.plateSubtitle || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="plateLinkUrl" label="跳转链接" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.plateLinkUrl || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="plateDetail" label="详情" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.plateDetail || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.createTime || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.updateTime || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column label="图片" min-width="1" fixed align="center">
          <template slot-scope="scope">
            <el-popover placement="top-start" title trigger="hover">
              <el-image
                :src="scope.row.plateImgUrl"
                :fit="fit"
                style="width:160px;height:80px"
                referrerpolicy="no-referrer"
              ></el-image>
              <el-image
                slot="reference"
                :src="scope.row.plateImgUrl"
                style="width: 30px;height: 30px"
                :fit="fit"
                referrerpolicy="no-referrer"
              ></el-image>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column min-width="1" label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              @click="handleChange(scope.$index, scope.row)"
            >编辑</el-button>
            <el-popconfirm title="确定删除这条广告吗？" @onConfirm="deleteAddHandler()">
              <el-button
                type="text"
                size="small"
                icon="el-icon-delete"
                @click="handleDelete(scope.$index, scope.row)"
                style="margin-left:10px;"
                slot="reference"
              >删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "PlateList",
  data() {
    return {
      current: 1,
      sysFloorId: "",
      size: 10,
      inputName: "",
      tableData: [],
      fit: "fill",
      title: "",
      deleteId: ""
    };
  },
  created() {
    this.sysFloorId = Number(this.$route.query.id);
    this.title = this.$route.query.name;
    this.plateList();
  },
  methods: {
    playShow() {},
    addUserHandler() {
      this.$router.push({
        path: `/addPlate`,
        query: { id: this.sysFloorId, name: this.title }
      });
    },
    handleChange(index, row) {
      this.$router.push({
        path: `editPlate`,
        query: { row: row, id: this.sysFloorId ,name:this.title}
      });
    },
    handleDelete(index, row) {
      this.deleteId = row.id;
    },
    search() {},

    deleteAddHandler() {
      this.$api.deletePlateHandler(this.deleteId).then(res => {
        if (res.data.code == 200) {
          this.$message.success("删除成功!");
          this.plateList();
        }
      });
    },
    plateList() {
      this.$api
        .plateListHandler({
          page: {
            current: this.current,
            size: this.size
          },
          queryDto: {
            sysFloorId: this.sysFloorId
          }
        })
        .then(res => {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        });
    },
    gobackFloorList() {
      this.$router.push("/sysFloorPlate");
    }
  }
};
</script>
<style lang="less" scoped>
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;
.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .select-status {
      width: 9rem;
      margin-left: 1.7rem;
      margin-top: 7px;
      // margin-top:7px;
    }

    .select-input-timer {
      float: right;
    }

    .select-status {
      float: right;
    }

    .select-input-right-input {
      float: right;
      // width: 8rem;
      line-height: 46px;
    }

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .addRoleButton {
      margin-left: 15px;
    }

    .serachRoleButton {
      margin-left: 15px;
    }

    button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
    }
  }
  .table-vv {
    padding: 1rem 3rem;
  }
}
</style>
