<template>
    <div class="outting">

        <div class="left-card">
            <div class="left_tit">
                <h3 style=";margin-left:2rem">信号强度实时统计
                </h3>

            </div>
            <div class="charts" id="rssi"></div>
        </div>
        <div class="left-card">
            <div class="left_tit">
                <h3 style=";margin-left:2rem">电量正常异常实时统计
                </h3>

            </div>
            <div class="charts" id="battery"></div>

        </div>
        <div class="left-card">
            <div class="left_tit">
                <h3 style=";margin-left:2rem">7日电量数据统计
                </h3>

            </div>
            <div class="charts" id="batteryWeek"></div>
        </div>
        <div class="left-card">
            <div class="left_tit">
                <h3 style=";margin-left:2rem">7日温度数据统计
                </h3>

            </div>
            <div class="charts" id="temperature"></div>

        </div>
    </div>
</template>
<script>
    import bus from "@/components/bus";
    export default {
        name: "statistics",
        data() {
            return {

            };
        },
        methods: {




            rssiFn() {
                this.$api.analysisRssi().then(res => {
                    // console.log(res.data.data);
                    this.echarts7 = this.$echarts.init(document.getElementById("rssi"));
                    const option = {
                        tooltip: {
                            trigger: 'item'
                        },
                        legend: {
                            top: '5%',
                            left: 'center'
                        },
                        series: [{
                            name: '强度',
                            type: 'pie',
                            //    width:400,
                            radius: ['40%', '60%'],
                            center: ['50%', '60%'],
                            
                            color: [
                                '#FFA940',
                                '#29EFC4',
                                '#F8AEA4',
                                '#006EFE',
                                '#F5855F',
                                '#C099FC',

                            ],
                            labelLine: {
                                normal: {
                                    length: 15, // 指示线长度
                                    lineStyle: {
                                        color: "#595959" // 指示线颜色
                                    }
                                },
                            },
                            label: {

                                minMargin: 25,
                                lineHeight: 50,
                            
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '17',
                                    fontWeight: 'bold'
                                }
                            },
                            // labelLine: {
                            //     show: false
                            // },
                            data: [{
                                    value: res.data.data.abnormalLevelCount,
                                    name: '异常项(未知)'
                                },
                                {
                                    value: res.data.data.highLevelCount,
                                    name: '高强度(20~31)'
                                },
                                {
                                    value: res.data.data.lowLevelCount,
                                    name: '低强度(0~10)'
                                },
                                {
                                    value: res.data.data.midLevelCount,
                                    name: '中强度(10-20)'
                                },

                            ]
                        }]
                    };
                 

                    this.echarts7.setOption(option);
                });
            },
            batteryFn() {
                this.$api.analysisBattery().then(res => {
                    // console.log(res.data.data);
                    this.echartsbattery = this.$echarts.init(document.getElementById("battery"));
                    const option = {
                        // title: {
                        //     text: '语音发送折线图',
                        //     left: 'center'
                        // },
                        tooltip: {
                            trigger: 'item'
                        },
                        legend: {
                            top: '5%',
                            left: 'center'
                        },
                        series: [{
                            name: '强度',
                            type: 'pie',
                         radius: ['40%', '60%'],
                             center: ['50%', '60%'],
                            color: [
                                '#FFA940',
                                '#29EFC4',
                                '#F8AEA4',
                                '#006EFE',
                                '#F5855F',
                                '#C099FC',

                            ],

                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '20',
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                normal: {
                                    length: 15, // 指示线长度
                                    lineStyle: {
                                        color: "#595959" // 指示线颜色
                                    }
                                },
                            },
                            label: {

                                minMargin: 5,
                                lineHeight: 15,
                                rich: {
                                    x: {
                                        width: 10,
                                        height: 10,
                                        backgroundColor: 'inherit',
                                        borderRadius: 5
                                    },
                                    a: {
                                        fontSize: 14,
                                        color: 'inherit',
                                        padding: [0, 20, 0, 8]
                                    },
                                    b: {
                                        fontSize: 12,
                                        align: 'left',
                                        color: '#666666',
                                        padding: [8, 0, 0, 18]
                                    },
                                    c: {
                                        fontSize: 12,
                                        align: 'left',
                                        color: '#666666',
                                        padding: [8, 0, 0, 8]
                                    },
                                },
                            },
                            data: [{
                                    value: res.data.data.abnormalLevelCount,
                                    name: '异常（电量30以下）'
                                },
                                {
                                    value: res.data.data.normalLevelCount,
                                    name: '正常(电量30以上)'
                                },


                            ]
                        }]
                    };

                    this.echartsbattery.setOption(option);
                });
            },
            batteryWeekFn() {
                this.$api.analysisBatteryWeek().then(res => {
                    console.log(res.data.data);
                    this.echartsbattery = this.$echarts.init(document.getElementById("batteryWeek"));
                    const option = {
                        // title: {
                        //     text: '语音发送折线图',
                        //     left: 'center'
                        // },
                        tooltip: {
                            trigger: 'item'
                        },
                        legend: {
                            top: '5%',
                            left: 'center'
                        },
                        series: [{
                            name: '强度',
                            type: 'pie',
                           radius: ['40%', '60%'],
                             center: ['50%', '60%'],
                            color: [
                                '#FFA940',
                                '#29EFC4',
                                '#F8AEA4',
                                '#006EFE',
                                '#F5855F',
                                '#C099FC',

                            ],
                            labelLine: {
                                normal: {
                                    length: 15, // 指示线长度
                                    lineStyle: {
                                        color: "#595959" // 指示线颜色
                                    }
                                },
                            },
                            label: {

                                minMargin: 5,
                                lineHeight: 15,
                                rich: {
                                    x: {
                                        width: 10,
                                        height: 10,
                                        backgroundColor: 'inherit',
                                        borderRadius: 5
                                    },
                                    a: {
                                        fontSize: 14,
                                        color: 'inherit',
                                        padding: [0, 20, 0, 8]
                                    },
                                    b: {
                                        fontSize: 12,
                                        align: 'left',
                                        color: '#666666',
                                        padding: [8, 0, 0, 18]
                                    },
                                    c: {
                                        fontSize: 12,
                                        align: 'left',
                                        color: '#666666',
                                        padding: [8, 0, 0, 8]
                                    },
                                },
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '20',
                                    fontWeight: 'bold'
                                }
                            },

                            data: [{
                                    value: res.data.data.abnormalLevelCount,
                                    name: '异常项（未知）'
                                },
                                {
                                    value: res.data.data.highLevelCount,
                                    name: '高强度（60-100）'
                                },
                                {
                                    value: res.data.data.lowLevelCount,
                                    name: '低强度（0~30）'
                                },
                                {
                                    value: res.data.data.midLevelCount,
                                    name: '中强度（30-60）'
                                },

                            ]
                        }]
                    };

                    this.echartsbattery.setOption(option);
                });
            },
            temperatureFn() {
                this.$api.analysisTemperature().then(res => {
                    console.log(res.data.data);
                    this.echartsbattery = this.$echarts.init(document.getElementById("temperature"));
                    const option = {

                        tooltip: {
                            trigger: 'item'
                        },
                        legend: {
                            top: '5%',
                            left: 'center'
                        },
                        series: [{
                            name: '强度',
                            type: 'pie',
                       radius: ['40%', '60%'],
                            center: ['50%', '60%'],
                            color: [
                                '#8676FF',
                                '#4A33D7',
                                '#66C8FF',
                                '#FF6666',
                            ],

                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '20',
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                normal: {
                                    length: 15, // 指示线长度
                                    lineStyle: {
                                        color: "#595959" // 指示线颜色
                                    }
                                },
                            },
                            label: {

                                minMargin: 5,
                                lineHeight: 15,
                                rich: {
                                    x: {
                                        width: 10,
                                        height: 10,
                                        backgroundColor: 'inherit',
                                        borderRadius: 5
                                    },
                                    a: {
                                        fontSize: 14,
                                        color: 'inherit',
                                        padding: [0, 20, 0, 8]
                                    },
                                    b: {
                                        fontSize: 12,
                                        align: 'left',
                                        color: '#666666',
                                        padding: [8, 0, 0, 18]
                                    },
                                    c: {
                                        fontSize: 12,
                                        align: 'left',
                                        color: '#666666',
                                        padding: [8, 0, 0, 8]
                                    },
                                },
                            },
                            //
                            data: [{
                                    value: res.data.data.abnormalLevelCount,
                                    name: '异常项（未知）'
                                },
                                {
                                    value: res.data.data.highLevelCount,
                                    name: '高强度（60℃以上）'
                                },
                                {
                                    value: res.data.data.lowLevelCount,
                                    name: '低强度(15℃以下)'
                                },
                                {
                                    value: res.data.data.midLevelCount,
                                    name: '中强度（15℃-60℃）'
                                },

                            ]
                        }]
                    };

                    this.echartsbattery.setOption(option);
                });
            },

        },
        created() {




        },
        mounted() {
            this.rssiFn()
            this.batteryFn()
            this.batteryWeekFn()
            this.temperatureFn()


        }
    };
</script>
<style lang="less" scoped>
    @border-bottom-color: #e7eaed;
    @toolBarFontColor: #111d;

    .outting {
        height: 100%;
        overflow: hidden;
        // display: flex;
        text-align: center;
        // justify-content: space-around;
        background-color: #fafafa;

        .left-card,
        .right-card {
            width: 47%;
            display: inline-block;

            // margin-top: 1rem;
            // margin: 1rem auto;
            .left_tit {
                text-align: left;
                margin: 20px 20px;
            }

            .charts {
                width: 90%;
                height: 58vh;
                margin-top: 30px;
                margin-bottom: 3rem;
                min-width: 280px;
                min-height: 210px;
            }
        }




    }
</style>