//写接口的时候注释写清楚是什么接口
const base = {
    baseUrl:"/api",
    loginUrl:"/oauth/token",//辉哥接口

    // login:'/login'
    menuListUrl:'/iotServer/sysUser/menuList',//菜单接口
    permissionListUrl:'/iotServer/sysPermission/getPermissionList',//模块列表接口
    addModuleUrl:'/iotServer/SysPlatform/insertSysPlatform',//新增模块接口
    deleteModuleUrl:'/iotServer/SysPlatform/deleteSysPlatform',//删除模块接口
    editModuleUrl:'/iotServer/SysPlatform/updateSysPlatform',//编辑模块接口
    plusPowerUrl:"/iotServer/sysPermission/addPermission",//新增权限接口
    updatePowerUrl:'/iotServer/sysPermission/updatePermission',//更新权限接口
    deletePowerurl:'/iotServer/sysPermission/deletePermission',//删除权限接口
    getRoleListUrl:'/iotServer/sysRole/pageList',//角色列表接口
    editRoleUrl:'/iotServer/sysRole/updateRole',//编辑角色接口
    deleteRoleUrl:'/iotServer/sysRole/deleteRole',//删除角色接口
    addRoleUrl:'/iotServer/sysRole/addRole',//添加角色接口
    roleDetailUrl:'/iotServer/sysRole/roleDetail',//角色详情接口
    userTableUrl:'/iotServer/sysUser/pageList',//账户列表接口
    userDetailUrl:'/iotServer/sysUser/userInfo',//用户详情接口-当前登录
    getPaylUrl:'/iotServer/wechatpay/unifiedorderNativeV3',//用户详情接口-当前登录
    deviceListUrl:'/iotServer/device/list',//设备列表接口
    productListUrl:'/iotServer/product/list',//设备列表接口
    productListForPullUrl:'/iotServer/product/listForPull',//产品列表下拉列表接口
    addDeviceUrl: "/iotServer/device/add",//新增设备接口
    addProductCategoryUrl: "/iotServer/productCategory/addProductCategory",//新增产品品类接口
    delProductCategoryUrl: "/iotServer/productCategory/deleteProductCategory",//删除产品品类接口
    categoryListForPullUrl: "/iotServer/productCategory/listForPull",//产品品类下拉列表接口
    productCategoryListUrl: "/iotServer/productCategory/list",//产品品类列表接口
    protocolListUrl: "/iotServer/protocol/selectorList",//协议列表下拉框接口
    delDeviceUrl: "/iotServer/device/delete",//删除设备接口npm
    detailUserUrl:'/iotServer/sysUser/detailUser',//用户详情接口-用id获取
    roleListSelectUrl:'/iotServer/sysRole/roleList',//角色下拉框接口
    editUserUrl:'/iotServer/sysUser/updateUser',//编辑用户接口
    userChildUrl:'/iotServer/sysUser/childUserList',//用户子账号列表接口
    addUserUrl:'/iotServer/sysUser/addUser',//用户增加接口
    deleteUserUrl:'/iotServer/sysUser/deleteUser',//删除用户接口
    areaUrl:'/iotServer/chinaArea/area',//三级联动接口
    registerListUrl:'/iotServer/deviceRegister/registerList',//注册列表接口
    registerDeviceUrl:'/iotServer/deviceRegister/registDevice',//设备注册接口
    iotDelDeviceUrl:'/iotServer/deviceDeleted/deleteDevices',//删除设备接口
    getRegisterResult:'/iotServer/deviceRegister/registerDeviceResult',//获取注册结果接口
    getDeleteResult:'/iotServer/deviceDeleted/getDeletedResult',//获取删除结果接口
    getMapDevices:'/iotServer/device/map/deviceMap',//设备地图接口
    childIdsDispatchUrl:'/iotServer/sysUser/assignUser',//子账号分配函数
    dispatchUrl:'/iotServer/sysUser/assignUserForPull',//子账号分配的下拉选择接口
    productAdd:'/iotServer/product/addProduct',//产品添加
    productDel:'/iotServer/product/deleteProduct',//产品删除
    getProductDetailUrl:'/iotServer/product/productDetail',//根据产品ID获取产品详情接口
    getDataPointLisrUrl:'/iotServer/dataPoint/list',//产品数据点列表接口
    updateProductUrl:'/iotServer/product/productUpdate',//编辑产品接口
    addDatePointUrl:'/iotServer/dataPoint/add',//新增数据点接口
    deleteDataPointUrl:'/iotServer/dataPoint/delete',//删除数据点接口
    editDataPointUrl:'/iotServer/dataPoint/update',//编辑数据点接口
    getDeviceInfoForMac:'/iotServer/device/map/deviceInfo/', //获取设备信息
    getDeviceStatusNum:'/iotServer/device/map/deviceStatisticsInfo',//获取各个状态设备数量
    getDeviceFaultStatusNum:'/iotServer/device/map/deviceFaultTypeStatisticsInfo', //获取各个状态设备数量
    getLastWeekStatisticsInfo:'/iotServer/device/map/deviceLastWeekStatisticsInfo', //获取最近一周数据点总数
    getLastWeekDeviceFaultStatisticsInfo:'/iotServer/device/map/deviceLastWeekDeviceFaultStatisticsInfo' ,//获取最近一周数据点情况

    recentlyReportedDataUrl:'/iotServer/device/recentlyReportedData',//设备最新上报数据接口
    deviceDetailUrl:'/iotServer/device/deviceDetail',//设备详情接口
    deviceStatusUrl:'/iotServer/device/deviceLiveStatus',//设备实时状态接口
    placeListUrl:'/iotServer/app/place/pageList',//场地列表接口
    deletePlaceUrl:'/iotServer/app/place/delete',//删除场地接口
    placeDetailUrl:'/iotServer/app/place/detail',//场地详情接口
    roomListUrl:'/iotServer/app/room/pageList',//房间列表接口
    userListUrl:'/iotServer/app/user/pageList',//用户列表接口
    childProjectlistUrl:'/iotServer/sysUser/childProjectList',//分配账号列表接口
    deviceDispatchUrl:'/iotServer/device/deviceAssignBatch',//批量分配设备接口
    deviceUnbindingUrl:'/iotServer/device/deviceUnbindBatch',//批量解绑接口
    getFireList: '/iotServer/device/alarm/firePageList',//火警列表接口
    getFaultList:'/iotServer/device/alarm/faultPageList',//故障列表接口
    getFireInfo:'/iotServer/device/alarm/fireInfo',//火警详情
    getFaultInfo:'/iotServer/device/alarm/faultInfo/',//故障详情
    dataPointSelectUrl:'/iotServer/device/dataPointForPull',//数据点下拉接口
    dataPointEchartsUrl:'/iotServer/device/dataPointList',//历史数据图表接口
    dataPointTableUrl:'/iotServer/device/dataPointPageList',//历史数据表格接口
    exportFile:'/iotServer/device/export', //文件导出
    deviceFaultTypeUrl:'/iotServer/deviceRepair/faultTypeList',//设备故障类型列表接口
    addDeviceFaultUrl:'/iotServer/deviceRepair/addFaultType',//增加故障类型接口
    deleteDeviceFaultUrl:'/iotServer/deviceRepair/deleteFaultType',//删除故障类型接口
    deviceRepairUrl:'/iotServer/deviceRepair/recordList',//设备维修记录
    addDeviceRepariUrl:'/iotServer/deviceRepair/add',//新增设备维修记录
    deviceRepairListUrl:'/iotServer/deviceRepair/selectorFaultTypeList',//设备故障下拉列表接口
    deleteDeviceRepairUrl:'/iotServer/deviceRepair/delete',//删除设备维修记录
    agreementListUrl:'/iotServer/protocol/selectList',//协议列表接口
    addAgreementUrl:'/iotServer/protocol/addProtocol',//新增协议接口
    deleteAgreementUrl:'/iotServer/protocol/deleteProtocol',//删除协议接口
    agreementDetailUrl:'/iotServer/protocol/queryDetailInfo',//协议详情接口
    editAgreementUrl:"/iotServer/protocol/modifyProtocol",//编辑协议接口
    getCode:'/iotServer/sysUser/getCode',//获取短信验证码
    forgetPwd:'/iotServer/sysUser/forgetPassword', //忘记密码
    logout:'/sysUser/logout',//退出登录
    allDeviceListStatus:'/iotServer/device/dataPointPageListForAll',//历史数据接口，全部数据
    logListUrl:'/iotServer/sysLog/getLogList',//平台LOG列表接口
    appLogListUrl:"/iotServer/app/getLogList",//appLog列表接口
    lastLogInfoUrl:'/iotServer/sysLog/lastLoginInfo',//上次登录IP信息接口
    addAdvertisementUrl:'/iotServer/sysAd/add',//添加广告接口
    advertisementListUrl:'/iotServer/sysAd/list',//广告列表展示接口
    advertisementDeleteUlr:'/iotServer/sysAd/delete',//广告列表删除接口
    advertisementEditUrl:'/iotServer/sysAd/modify',//广告列表编辑接口
    addFloorUrl:'/iotServer/sysFloorPlate/addFloor',//添加楼层接口
    floorPlateListUrl:'/iotServer/sysFloorPlate/floorList',//楼层板块接口
    addPlateUrl:'/iotServer/sysFloorPlate/addPlate',//新增版块接口
    plateListUrl:'/iotServer/sysFloorPlate/plateList',//版块列表接口
    deleteFloorUrl:'/iotServer/sysFloorPlate/deleteFloor',//删除楼层接口
    editFloorUrl:'/iotServer/sysFloorPlate/modifyFloor',//修改楼层接口
    deletePlateUrl:'/iotServer/sysFloorPlate/deletePlate',//删除版块接口
    editPlateUrl:'/iotServer/sysFloorPlate/modifyPlate',//编辑版块接口
    quickQueryUrl:'/iotServer/device/quickQuery',//快捷查询接口
    testResultUrl:'/iotQc/qc/pageList',//测试结果列表接口
    testFateUrl:'/iotQc/qc/fate',//测试通过率接口
    firstQrcodeUrl:'/iotQc/qc/qrcode',//跳转二维码接口
    testResultDetailUrl:'/iotQc/qc/testInfo',//测试结果详情接口
    ifPassUrl:'/iotQc/qc/operation',//是否合格接口
    refrashTestResultUrl:'/iotQc/qc/devtest',//刷新测试结果接口
    checkMacUrl:'/iotQc/qc/checkMac',//测试是否注册接口
    checkJyMacUrl:'/iotQc/qc/jy/checkMac',//久远测试是否注册接口
    testResultExportUrl:'/iotQc/qc/devTestResult/export',//测试结果导出接口
    qeuryTestResult:'/iotQc/qc/queryTestResult',//查询设备测试结果接口
    appListUrl:'/iotServer/version/list',//app列表接口
    uploadAddUrl:'/iotServer/version/add',//app版本增加接口
    deleteAppUrl:'/iotServer/version/delete',//删除app版本接口
    editAppUrl:'/iotServer/version/update',//编辑app版本接口
    refrashUrl:'/iotServer/product/refreshCacheData',//刷新数据点缓存接口
    wxLogin:'/oauth/wx/token',//微信登录请求token
    wxBind:'/iotServer/wechart/bindWechart',//微信绑定账号
    wxUnBind:'/iotServer/wechart/unbindWechart',//微信解绑账号
    accountListUrl:'/iotServer/processUser/list',//账号列表展示接口
    accountListAddUrl:'/iotServer/processUser/add',//账号列表新增展示接口
    accountListUpdateUrl:'/iotServer/processUser/update',//账号列表修改展示接口
    accountTypeDeleteUrl:'/iotServer/processUser/delete',//账号列表删除接口
    accountTypeUrl:'/iotServer/processUserType/list',//账号新增类型展示接口
    deleteAccountType:'/iotServer/processUserType/delete',//删除账号类型接口
    accountTypeUrlAdd:'/iotServer/processUserType/add',//新增账号类型接口
    accountTypeUrlUpdate:'/iotServer/processUserType/update',//修改账户类型接口
    accountListSelector:'/iotServer/sysUser/selector',//账号列表新增平台账号下拉框接口
    nodeListUrl:'/iotServer/processNode/list',//节点列表展示接口
    questionStatusUrl:'/iotServer/problemStatus/list',//问题状态列表展示接口
    questionStatusAddUrl:'/iotServer/problemStatus/add',//新增问题状态列表展示接口
    questionStatusDeleteUrl:'/iotServer/problemStatus/delete',//删除问题状态列表展示接口
    nodeListAddUrl:'/iotServer/processNode/add',//新增节点接口
    nodeListUpdateUrl:'/iotServer/processNode/update',//修改节点接口
    nodeListDeleteUrl:'/iotServer/processNode/delete',//修改节点接口
    moduleListUrl:'/iotServer/processTemplate/list',//模板列表接口
    moduleListAddUrl:'/iotServer/processTemplate/add',//新增模板列表接口
    moduleListUpdateUrl:'/iotServer/processTemplate/update',//修改模板列表接口
    moduleListDeleteUrl:'/iotServer/processTemplate/delete',//删除模板列表接口
    moduleDetailsUrl:'/iotServer/processTemplate/configDetail',//模板详情接口
    configEdit:'/iotServer/processTemplate/configEdit',//模板详情修改
    moduleselectorListUrl:'/iotServer/processUser/selectorList',//默认处理者下拉框接口
    nodeselectorListUrl:'/iotServer/processNode/selectorList',//节点名称下拉框接口
    nodepreTemplateConfigUrl:'/iotServer/processTemplate/preTemplateConfig',//回滚节点下拉框接口
    nodepreconfigAddUrl:'/iotServer/processTemplate/configAdd',//添加模板配置接口
    nodeConfigDeleteUrl:'/iotServer/processTemplate/configDelete',//添加模板详情节点删除接口
    problemListUrl:'/iotServer/problem/list',//问题列表接口

    problemReassignmentUrl:'/iotServer/problem/reassignment',//问题列表指派接口
    problemHandleUrl:'/iotServer/problem/handle',//问题列表处理接口
    problemDefaultHandlerUrl:'/iotServer/problem/defaultHandler',//获取默认处理人
    problemDetailUrl:'/iotServer/problem/detail',//问题详情接口
    severity:'/iotServer/problem/update',//修改问题等级
    problemHandleProcessUrl:'/iotServer/problem/handleProcess',//问题流程展示接口

    processTemplateselectorUrl:'/iotServer/processTemplate/selector',//问题流程展示接口
    problemAddUrl:'/iotServer/problem/add',//问题流程展示接口
    problemExportUrl:'/iotServer/problem/export',//问题列表导出接口
    problemTypeListUrl:'/iotServer/problemType/list',//问题类型展示接口
    problemTypeDeleteUrl:'/iotServer/problemType/delete',//问题类型删除接口
    problemTypeAddUrl:'/iotServer/problemType/add',//问题类型新增接口
    problemListForPull:'/iotServer/product/listForPull',//问题新增-获取产品下拉列表接口
    showProjectName:'/showProjectName',//问题类型新增接口
    deviceDoneFireListUrl:'/iotServer/device/message/doneFireList',//已处理火警消息列表接口
    deviceFireListUrl:'/iotServer/device/message/fireList',//待处理火警消息列表接口
    deviceFireDealUrl:'/iotServer/device/message/fireDeal',//设备火警处理
    devicefaultListUrl:'/iotServer/device/message/faultList',//当前账号故障消息列表
    doneFauttListlUrl:'/iotServer/device/message/doneFauttList',//当前账号故障已处理消息列表
    faultDeallUrl:'/iotServer/device/message/faultDeal',//故障设备处理
    fireTimeMonthStatisticsUrl:'/iotServer/analysis/alarm/fireTimeMonthStatistics',//火警高发时间分布月度统计
    fireTimeWeekStatisticsUrl:'/iotServer/analysis/alarm/fireTimeWeekStatistics',//火警高发时间分布周统计
    fireStatisticsUrl:'/iotServer/analysis/alarm/fireStatistics',//火警统计列表
    exportDeviceFireUrl:'/iotServer/analysis/export/deviceFire',//火警设备列表导出
    exportDeviceCycleUrl:'/iotServer/analysis/export/deviceCycle',//火警设备周期列表导出
    fireCycleStatisticsUrl:'/iotServer/analysis/alarm/fireCycleStatistics',//火警设备周期列表
    firePlaceTopStatisticsUrl:'/iotServer/analysis/alarm/firePlaceTopStatistics',//火警高发场地top统计
    fireProjectTopStatisticsUrl:'/iotServer/analysis/alarm/fireProjectTopStatistics',//火警高发项目top统计
    fireAndDeviceCount:'/iotServer/device/message/fireAndDeviceCount',//当前账号故障消息列表
    newsList:'/iotServer/sysNews/newsList',//新闻资讯列表展示
    modifyNews:'/iotServer/sysNews/modifyNews',//修改资讯
    deleteNews:'/iotServer/sysNews/deleteNews',//删除资讯
    addNews:'/iotServer/sysNews/addNews',//新增资讯资讯
    addPic:'/iotServer/sysNews/addPic',//添加新增资讯富文本图片
    queryRegisterResult:'/iotServer/deviceRegister/queryRegisterResult',//查询设备注册结果
    queryTestRecord:'/iotQc/qc/queryTestRecord',//设备测试数据查询
    queryTestRecordByMac:'/iotQc/qc/queryTestRecordByMac',//单个设备测试数据查询
    queryTestRecordExport:'/iotQc/qc/queryTestRecord/export',//测试设备数据导出
    queryTestRecordByMacExport:'/iotQc/qc/queryTestRecordByMac/export',//单个测试设备数据导出
    versionSelectorList:'/iotServer/version/selectorList',//设备升级-获取版本下拉列表
    versionUpgrade:'/iotServer/deviceUpgrade/upgrade',//设备升级
    upgradeResult:'/iotServer/deviceUpgrade/upgradeResult',//设备升级列表
    getUserMobile:'/iotServer/sysUser/getUserMobile',//提交工单-获取用户手机号
    submitWorkOrder:'/iotServer/workOrder/submitWorkOrder',//提交工单
    workOrderList:'/iotServer/workOrder/workOrderList',//工单列表
    workOrderDetail:'/iotServer/workOrder/workOrderDetail',//工单列表
    workOrderProcess:'/iotServer/workOrder/workOrderProcess',//工单处理流程
    workOrderHandle:'/iotServer/workOrder/handle',//客户处理工单问题
    updateWorkOrderPhone:'/iotServer/workOrder/updateWorkOrderPhone',//修改工单联系人手机号
    selectorForWorkOrder:'/iotServer/problemType/selectorForWorkOrder',//工单问题类型下拉框
    selectorToWorkorder:'/iotServer/problemStatus/selectorToWorkorder',//工单问题状态下拉框
    problemTypeSelector:'/iotServer/problemType/selector',//问题管理-问题类型下拉框
    getHandlerUserByType:'/iotServer/problemType/getHandlerUserByType',//问题管理-根据问题类型获取默认处理人
    problemRepatriate:'/iotServer/problem/repatriate',//问题列表-问题回派
    productListThird:'/iotServer/productCategory/list/third',//产品品类-第三方列表接口
    upgradeStatusUpdate:'/iotServer/deviceUpgrade/upgradeStatusUpdate',//设备更新
    deviceCategory:'/iotServer/device/list/category',//查询用户对应的设备品类
    sunshyList:'/sunshy/api/device/list',//电气火灾接口
    sunshDeviceData:'/sunshy/api/device/data',//设备详情
    sunshDeviceHistory:'/sunshy/api/device/data/history',//设备电气火灾-历史数据
    deviceFailureRecord:'/iotServer/device/failureRecord',//历史数据接口，失败记录
    addThirdPlatformAppData:'/third/platform/data/push/addThirdPlatformAppData',//新增第三方应用信息
    delThirdPlatformAppData:'/third/platform/data/push/delThirdPlatformAppData',//删除第三方应用信息
    editThirdPlatformAppData:'/third/platform/data/push/editThirdPlatformAppData',//修改第三方应用信息
    getThirdPlatformAppData:'/third/platform/data/push/getThirdPlatformAppData',//查询第三方应用信息
    regenerateAes128Key:'/third/platform/data/push/regenerateAes128Key',//重新生成Aes128Key
    regenerateThirdPlatformAppSecret:'/third/platform/data/push/regenerateThirdPlatformAppSecret',//重新生成appSecret信息
    cardDataInfo:'/iotServer/chinanetCard/cardDataInfo',//卡资料信息
    cardInfo:'/iotServer/chinanetCard/cardInfo',//根据imei获取卡号信息
    getImei:'/iotServer/chinanetCard/getImei',//设备imei查询
    telephonePlus:'/iotServer/chinanetCard/telephonePlus',//三码互查
    BaiduVoiceToken:'/iotServer/device/map/baiduVoiceToken',//百度语音token获取接口
    SolutionList:'/iotServer/sysNews/solutionList',//解决方案列表展示无分页
    addGoods:'/iotServer/goods/add',//小程序新增商品
    listGoods:'/iotServer/goods/list',//小程序列表展示
    GoodsUpdate:'/iotServer/goods/update',//小程序列表展示
    GoodsDetail:'/iotServer/goods/detail',//小程序列表详情
    GoodsExport:'/iotServer/goods/export',//小程序列表d导出
    GoodsDelete:'/iotServer/goods/delete',//小程序列表删除
    CodeList:'/iotServer/mall/code/codeList',//小程序兑换码列表
    CodeExport:'/iotServer/mall/code/export',//小程序兑换码导出
    GenerateCode:'/iotServer/mall/code/generateCode',//小程序生成兑换码
    GoodsListPull:'/iotServer/goods/list/pull',//兑换商品列表下拉框
    goodsOrderDetail:'/iotServer/order/detail',//获取订单详情
    goodsOrderExport:'/iotServer/order/export',//订单导出
    goodsOrderList:'/iotServer/order/list',//获取订单列表
    getDeliveryCodes:'/iotServer/mall/track/getDeliveryCodes',//查询快递编码--支持快递名称查询
    getTracks:'/iotServer/mall/track/getTracks',//查询物流信息
    addTracks:'/iotServer/mall/track/addTracks',//新增物流信息
    templateAndNode:'/iotServer/processTemplate/templateAndNode',//获取模板节点配置信息
    exchangeDetail:'/iotServer/mall/code/exchangeDetail',//兑换码详情
    saleCode:'/iotServer/mall/code/saleCode',//卖出兑换码支持批量

    codeStatistics:'/iotServer/mall/code/codeStatistics',//兑换统计
    orderStatistics:'/iotServer/order/statistics',//订单统计
    goodsAddPic:'/iotServer/goods/addPic',//商品上传富文本图片
    modifyTracks:'/iotServer/mall/track/modifyTracks',//修改订单的物流信息
    mallAddAdvertisementUrl:'/iotServer/mall/ad/add',//添加广告接口
    mallAdvertisementListUrl:'/iotServer/mall/ad/list',//广告列表展示接口
    mallAdvertisementDeleteUlr:'/iotServer/mall/ad/delete',//广告列表删除接口
    mallAdvertisementEditUrl:'/iotServer/mall/ad/modify',//广告列表编辑接口
    deviceGroup:'/iotServer/deviceGroup/create',//创建设备组
    analysisSms:'/iotServer/analysis/message/sms',//短信发送折线图
    analysisVoice:'/iotServer/analysis/message/voice',//语音发送折线图
    getWechatLoginShow:'/iotServer/wechat/getWechatLoginShow',//获取微信登录展示状态
    setWechatLoginShow:'/iotServer/wechat/setWechatLoginShow',//设备微信登录展示
    analysisRssi:'/iotServer/analysis/device/rssi',//信号强度饼图
    analysisBattery:'/iotServer/analysis/device/battery',//电量正常异常饼图统计
    analysisBatteryWeek:'/iotServer/analysis/device/battery/week',//7日电量数据统计
    analysisTemperature:'/iotServer/analysis/device/temperature/week',//7日温度数据统计
    wifiRegisterList:'/iotServer/deviceRegister/registerList/wifi',//wifi已注册设备列表
    wifiRegistDevice:'/iotServer/deviceRegister/registDevice/wifi',//wifi注册设备到iot
    wifiDeleteDevices:'/iotServer/deviceDeleted/deleteDevices/wifi',//从iot删除设备--WIFI
    exportDeviceRegister:'/iotServer/deviceRegister/export',//wifi设备注册列表导出
    addPicMall:'/iotServer/mall/ad/add/pic',//添加广告列表
    piclistMall:'/iotServer/mall/ad/list/pic',//添加广告列表编辑
    newsListApp:'/iotServer/sysNews/newsList/app',//APP新闻资讯
    solutionListApp:'/iotServer/sysNews/solutionList/app',//APP解决方案
    appCasesFirstList:'/iotServer/sysNews/companyCases/showList',//案例列表展示
    getScheme:'/iotServer/file/scheme',
     attractInvestmentAdd:'/iotServer/attractInvestment/add',//招商信息收集
     productListForCategory:'/iotServer/sysNews/productListForCategory',//产品列表展示--类型列表
     productListForApp:'/iotServer/sysNews/productListForApp',//移动官网产品配置
    productListForAppHome:'/iotServer/sysNews/productListForApp/home',//移动官网产品配置
    fileManagementList:'/iotServer/fileCategory/list',//文件列表
    fileManagementListWeb:'/iotServer/file/listForWeb',//文件列表
}

export default base
