<template>
    <div>
        <div class="outting">
            <div class="select">
                <span class="title">问题处理</span>
                <el-button type="text" size="small" icon="el-icon-back" @click="gobackPlaceDetail"
                    class="addRoleButton">返回工单列表
                </el-button>
            </div>
            <!-- 流程节点展示 -->
            <div class="detail">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <span>处理流程</span>
                    </div>
                    <div class="stepShow">
                        <el-steps :active="milepostActive" direction="vertical">
                            <el-step v-for="(value, key) in milepost" :class="milepostActive== key+1 ? stepActive: '' "
                                :title="value.nodeName" :key="key" :description="value.value">
                                <template slot="description">
                                    <div v-if="milepostActive == key">
                                        <el-form :model="formHandle" :rules="rulesHandle" ref="formHandle">

                                          
                                            <el-form-item label="处理意见" :label-width="formLabelWidth" prop="problemDesc">
                                                <el-input v-model="formHandle.problemDesc" type="textarea" size="mini"
                                                    rows="10" style="width:60%;;margin-right:2rem;margin-bottom:10px">
                                                </el-input>
                                            </el-form-item>
                                              <el-form-item label="图片" prop="file" :label-width="formLabelWidth">
                                                <el-upload class="upload-demo" action :on-change="handleChange"
                                                    :on-remove="handleRemove" :on-exceed="handleExceed"
                                                    list-type="picture-card" :file-list="fileList" :limit="limitUpload"
                                                    :auto-upload="false" ref="my-upload">
                                                    <el-button type="primary" round size="mini" class="input-button">
                                                        上传图片</el-button>
                                                    <br />
                                                </el-upload>
                                            </el-form-item>


                                        </el-form>
                                        <div slot="footer" class="dialog-footer" style="margin-bottom:2rem">
                                            <el-button @click="handleDialogVisible = false">取 消</el-button>
                                            <el-button type="primary" @click="submitFormFandle('form')">确 定</el-button>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <p><span
                                                style="color:#666;display:inline-block;margin: 0 0 5px 0px">问题状态：</span>{{value.problemStatus}}
                                            <span
                                                style="color:#666;display:inline-block;margin: 0 0 5px 10px">时间:</span>
                                            {{value.createTime}}

                                        </p>
                                        <p style="margin-bottom:20px"><span style="color:#666;">问题描述：</span>
                                            {{value.handleOpinion}} </p>
                                        <br />
                                    </div>


                                </template>
                            </el-step>
                        </el-steps>

                    </div>
                </el-card>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "PlaceDetail",
        data() {
            return {
                formLabelWidth: "120px",
                // 默认步骤数
                milepostActive: 0,
                milepostId: false,
                milepost: [],
                fileList: [],
                limitUpload: 5,
                // 动态添加类名
                stepActive: "stepActive",
                formHandle: {

                    problemDesc: "",
                    file: [],
                },

                defaulShow: false, //处理人隐藏,
                rollbackShow: false, //处理人隐藏,
                handRealNameList: [],
                //修改账号弹出对话框标记
                handleDialogVisible: false,
                processTemplate: [], //回滚节点
                userDisabled: false,
                realNameList: [], //当前处理人员列表
                rulesHandle: {

                    problemDesc: [{
                        required: true,
                        message: "请输入处理意见",
                        trigger: "blur"
                    }],
                    file: [{
                        required: false,
                        message: "请上传图片",
                        trigger: "blur"
                    }]
                },
            };
        },
        created() {
            sessionStorage.setItem("detailsIdProblem", this.$route.query.id);
        },
        mounted() {

            let detailsId = sessionStorage.getItem("detailsIdProblem");
            this.$api.workOrderProcess(detailsId).then(res => {
                res.data.data.push({
                    id: null,
                    nodeName: "回复"
                })
                this.milepost = res.data.data

                let numList = []
                this.milepost.forEach((item, index) => {
                    if (item.id === null) {
                        numList.push(index)
                        this.milepostActive = numList[0]
                    } else {
                        this.milepostActive = 20
                    }
                })

                console.log(this.milepostActive)
            });

        },
        methods: {
            //回到模块页面
            gobackPlaceDetail() {
                this.$router.push("/myWorkorder");
            },

            // 处理确定接口
            submitFormFandle() {
                let detailsId = sessionStorage.getItem("detailsIdProblem");
                this.$refs["formHandle"][0].validate(valid => {
                    if (valid) {
                     
                        let formData = new FormData();
                        let fileList2 = [];
                        this.fileList.forEach(file => {
                            formData.append("files", file.raw); 
                        });
                        formData.append("id", parseInt(detailsId));
                        formData.append("problemDesc", this.formHandle.problemDesc);

                        this.$api.workOrderHandle(formData).then(res => {
                            if (res.data.code == 200) {
                                this.$message.success("处理成功!");
                                this.$router.push("/myWorkorder");
                            }
                        });
                    } else {
                        return false;
                    }
                });
            },
            handleChange(file, fileList) {
                console.log(file.raw, fileList);
                this.fileList = fileList;
                if (this.fileList == []) {
                    this.$message({
                        type: "warning",
                        message: "请上传图片！"
                    });
                }
            },
            //超出最大上传文件数量时的处理方法
            handleExceed(file, fileList) {
                this.$message({
                    type: "warning",
                    message: "超出最大上传文件数量的限制！请删除已上传文件后重新上传"
                });
                return;
            },
            //移除文件的操作方法
            handleRemove(file, fileList) {
                this.fileList = null;
                this.tableData = [];
            },

        }
    };
</script>
<style lang="less" scoped>
    @border-bottom-color: #e7eaed;
    @toolBarFontColor: #111d;

    .outting {
        height: 100%;
        overflow: hidden;
        background-color: #fafafa;

        .item {
            height: 80px;
            width: 80px;
            border: 1px solid blue;
            float: left;
        }

        .select {
            height: 46px;
            overflow: hidden;
            border-bottom: 1px solid #d9d9d9;
            padding: 0rem 3rem;

            .title {
                display: inline-block;
                line-height: 46px;
                font-weight: bold;
                font-size: 0.8rem;
            }

            button {
                float: right;
                color: @toolBarFontColor;
                margin-top: 7px;
            }
        }

        .dialog-footer {
            width: 100%;
            margin: 0 30%;
        }

        .detail {
            padding: 0rem 3rem;
            margin-top: 1rem;

            .detail-span {
                font-size: 12 /16rem;
                color: #898989;
            }

            .detail-vv {
                margin-top: 5 /16rem;
                font-size: 14 /16rem;
            }
        }
    }

    .el-row {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-col {
        border-radius: 4px;
    }

    .bg-purple-dark {
        background: #99a9bf;
    }

    //   .bg-purple {
    //     background: #d3dce6;
    //   }
    .bg-purple-light {
        background: #e5e9f2;
    }

    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }

    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }

    .stepShow {
        width: 80%;
        margin: 3rem auto;

        .downloadList {
            //  transform:translate(5.5rem, -4rem)
        }

        /deep/.el-step.is-horizontal.stepActive {
            .el-step__head.is-finish {
                .el-step__line {

                    // 当前步骤数横线样式设置
                    .el-step__line-inner {
                        width: 50% !important;
                        border-width: 1px !important;
                    }
                }

                // 当前步骤数圆圈样式设置
                .el-step__icon.is-text {
                    background: #409eff;
                    color: #fff;
                }
            }
        }
    }

    #wrapper {
        background: radial-gradient(ellipse at top left,
                rgba(255, 255, 255, 1) 40%,
                rgba(229, 229, 229, 0.9) 100%);
        height: 100vh;
        padding: 60px 80px;
        width: 100vw;
        display: flex;
    }

    .state-item {
        width: 80px;
        height: 40px;
        color: #606266;
        background: #f6f6f6;
        border: 2px solid rgba(0, 0, 0, 0.05);
        text-align: center;
        line-height: 40px;
        font-family: sans-serif;
        border-radius: 4px;
        //   margin-right: 60px;
    }

    .line-wrap {
        //   display: flex;
        width: 100px;
        margin-bottom: 40px;
    }
</style>