<template>
    <div class="iphoneNews">
        <div class="collapse">
            <span @click="iphoneProduct">产品中心</span>
            <!-- <el-divider></el-divider> -->
            <el-collapse v-model="activeName" accordion>
                <el-collapse-item title="解决方案" name="1">
                    <div class="solurone" @click="iphoneSolve(item.id)"  v-for="(item,key) in solutList" :key="key" >
                        <span >{{item.newsTitle}}</span>
                        <el-divider class="divider"></el-divider>

                    </div>

                </el-collapse-item>
            </el-collapse>
            <span @click="iphoneAppCase()">应用案例</span>
            <span class="titlaber"  @click="iphoneNews()">新闻资讯</span>
            <!-- <el-divider></el-divider> -->
            <span @click="iphoneExpandMenu()">关于我们</span>

            <!-- <el-divider></el-divider> -->
<!--          <el-collapse v-model="activeName" accordion>-->
<!--            <el-collapse-item title="下载中心" name="1">-->
<!--              <div class="solurone" @click="iphoneSolve(item.id)"  v-for="(item,key) in solutList" :key="key" >-->
<!--                <span >{{item.newsTitle}}</span>-->
<!--                <el-divider class="divider"></el-divider>-->

<!--              </div>-->

<!--            </el-collapse-item>-->
<!--          </el-collapse>-->
            <span @click="iphoneJoin()" >招商加盟</span>
            <!-- <el-divider></el-divider> -->
        </div>



    </div>
</template>

<script>
    export default {
        name: "iphoneExpandMenu",
        data() {
            return {
                activeName: '2',
                solutList:[]
            };
        },
    mounted(){
       this.solutionList()
    },
        methods: {
                //解决方案列表
      solutionList() {
        this.$api
          .solutionListApp()
          .then(res => {
            console.log(res.data.data)
            this.solutList = res.data.data
          })
          .catch(error => {
            console.log(error);
          });
      },
            iphoneExpandMenu() {
                this.$router.push({
                    path: "/iphoneOurselves"
                });
            },
          iphoneAppCase(){
            this.$router.push({
              path: "/iphoneAppCase"
            });
          },
            iphoneJoin(){
                 this.$router.push({
                    path: "/iphoneJoin"
                });
            },
               iphoneProduct(){
                 this.$router.push({
                    path: "/iphoneProduct"
                });
            },
            iphoneSolve(id){
                 this.$router.push({
                    path: "/iphoneSolve",
                       query: {
            id: id
          }
                });
            },
            iphoneNews(){
                 this.$router.push({
                    path: "/iphoneNews"
                });
            }
        }

    }
</script>
<style lang="less" scoped>


        .iphoneNews {
            margin-top: 14vw;
            height: 93.5vh;
            font-size: 4vw;
            color: #333;
            background: #ffffff;
            padding: 0 7.6vw;
        }

        .collapse {
            padding-top: 8vw;
        }

        /deep/.el-collapse {
            margin: 4vw 0;
            font-size: 4vw;
        }

        /deep/.el-collapse-item__header {
            font-size: 4vw;
            padding: 1vw 0;
            color: #333333 !important;
            font-weight: 400;
        }

        .el-collapse-item {
            font-size: 4vw;
        }

        /* el-divider 修改高度&虚线效果 */
        /deep/.el-divider {
            margin: 4vw 0;
            background: 0 0;
            border-top: 1px solid #e8eaec;
        }

        .divider {
            border-top: 1px dashed #999;
        }

        .solurone {
            color: #666;
            margin-top: 2vw;
            padding: 0 2vw;
        }
      .titlaber{
           padding: 4vw 0;
           display: inline-block;
      }

</style>
