<template>
  <div style="background-color: #eee">

    <div style="margin-top: 50px;padding: 3vw">
      <div style="font-size: 18px;color: black;margin-top: 10px;margin-left: 10px" @click="back">应用案例 > 案例详情</div>

        <div style="background-color: white;border-radius: 10px;margin-top: 15px;width: 94vw;" >

          <div>
            <img :src="item.newsImgUrl" style="width: 94vw;height: 50vw;border-top-right-radius: 10px;border-top-left-radius: 10px">
          </div>

          <div style="color: black;padding: 15px;font-size: 20px">
            <div style="font-weight: bold;font-size: 25px;display: flex;align-items: center;">
              <div style="width: 5px;height: 25px;background-color: #fba417;display: inline-block;vertical-align: middle"></div>
              <div style="display: inline-block;vertical-align: middle;margin-left: 10px">{{item.newsTitle}}</div>
            </div>
            <div style="margin-top: 20px;margin-left: 10px">项目位置：{{item.position}}</div>
            <div style="margin-top: 10px;margin-left: 10px">应用系统：{{item.applicationSystem}}</div>
            <div style="margin-top: 10px;margin-left: 10px;text-align:justify;">项目亮点：{{item.newsContent.replace('<\p\>','').replace('<\/\p\>','').replace('&lt;br/&gt','')}}</div>
          </div>
        </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "iphoneAppCase",
  data() {
    return {
      item:null,
    }
  },

  created() {
    this.item = this.$route.query;
  },
  methods: {
    back(){
      this.$router.back();
    }
  }
}
</script>

<style scoped lang="less">

</style>
