<template>
    <div class="iphoneSolve">
     <div class="item-title_new">
              <p>{{title}}</p>
              <span> </span>
            </div>
       <div v-html="outlink" class="texztaaa"></div>
    </div>
</template>

<script>

    export default {
        name: "iphoneSolve",
   
        data() {
            return {
                  outlink: "" ,// 外部链接,
                  title:"",
            };
        },
          watch: {
             $route(to, from) {
                      console.log(to.path);
                      console.log(this.$route.query.id) 
                         this.$api
          .solutionListApp()
          .then(res => {
            console.log(res.data.data)
           for (let index = 0; index < res.data.data.length; index++) {
             if(this.$route.query.id == res.data.data[index].id){
                    this.outlink  =  res.data.data[index].newsContent
                     this.title  =   res.data.data[index]. newsTitle
             }
               
           }

          })
          .catch(error => {
            console.log(error);
          });
             }
          }
          ,
        created(){
               console.log(this.$route.query.id) 
        },
        mounted(){
               this.$api
          .solutionListApp()
          .then(res => {
            console.log(res.data.data)
           for (let index = 0; index < res.data.data.length; index++) {
             if(this.$route.query.id == res.data.data[index].id){
                    this.outlink  =  res.data.data[index].newsContent
                     this.title  =   res.data.data[index]. newsTitle
             }
               
           }

          })
          .catch(error => {
            console.log(error);
          });
        },
        methods:{
        }

    }
</script>
<style lang="less" scoped>
  

  .iphoneSolve{
         margin-top: 14vw;
    
         width: 100%;

      background: #ffffff;
      .texztaaa{
          width: 94vw;
           margin: auto;
           padding-bottom: 4vw;
      }
        .item-title_new {
      padding-top: 3.6vw;
      text-align: center;
      padding-bottom: 4.8vw;
      color: #333333;
      width: 100%;
      letter-spacing: 1px;
      margin: 0 auto;
      font-size: 5.6vw;

      span {
        width: 17.4vw;
        margin: 0 auto;
        height: 2px;
        padding-top: 1px;
        display: block;
        background: #F1A948;
      }
    }
  }


    
</style>