<template>
  <div class="productCenter">

    <!-- <div class="block" > -->
      <div class="lunbotu"   >
        <!-- <el-carousel-item > -->
        <img src="../../assets/banner3.jpg"  />
        <!-- </el-carousel-item> -->
      <!-- </div> -->
    </div>

    <div class="titleDisplay">
      <div class="major_service">
        <div class="item-title">
          <p>产品概括</p>
          <span></span>

        </div>
        <p class="text_list">
          目前“吻胜”软硬结合，既有报警、应急等硬件产品，自主研发了接入NB-loT技术的烟感、可燃气体探测等设备，运用独立的报警监控云平台双重保障，在品类、技术类别等各个维度均形成了较为完整的家用消防安全生态链条。</p>
        <div>

        </div>
      </div>
      <div class="item-title">
        <p>产品列表</p>

        <span></span>
        <p style="margin-top:2rem"></p>
      </div>
      <!-- 中间广告展示第一层 -->
      <div class="middle_show">
        <div class="middle_showchild"  @click="examineWsh()">
          <div>
            <img src="../../assets/productCut/6101.png" />
          </div>
          <b>感烟火灾探测报警器</b>
          <p>JTY-GF-WSH6101</p>
          <el-button size="medium" @click="examineWsh()">查看详情</el-button>
        </div>
        <div class="middle_showchild"  @click="examineWshNn()">
          <div>
            <img src="../../assets/productCut/6101N.png" />
          </div>
          <b>感烟火灾探测报警器</b>
          <p>JTY-GF-WSH6101N</p>
          <el-button size="medium" @click="examineWshNn()">查看详情</el-button>
        </div>
        <div class="middle_showchild"  @click="methane()">
          <div>
            <img src="../../assets/productCut/6200.png" />
          </div>
          <b>家用可燃气体探测器</b>
          <p>JT-WSH6200 甲烷</p>
          <el-button size="medium" @click="methane()">查看详情</el-button>
        </div>
        <div class="middle_showchild" @click="methaneNn()">
          <div>
            <img src="../../assets/productCut/6200N.png" />
          </div>
          <b>家用可燃气体探测器</b>
          <p>JT-WSH6200N 甲烷</p>
          <el-button size="medium" @click="methaneNn()">查看详情</el-button>
        </div>
      </div>
      <!-- 第二层 -->
      <div class="middle_show">
        <div class="middle_showchild" @click="carbonMonoxide()">
          <div>
            <img src="../../assets/productCut/6201.png" />
          </div>
          <b>家用可燃气体探测器</b>
          <p>JM-WSH6201 一氧化碳</p>
          <el-button size="medium" @click="carbonMonoxide()">查看详情</el-button>
        </div>
        <div class="middle_showchild"  @click="carbonMonoxideNn()">
          <div>
            <img src="../../assets/productCut/6201n.png" />
          </div>
          <b>家用可燃气体探测器</b>

          <p>JM-WSH6201N 一氧化碳</p>
          <el-button size="medium" @click="carbonMonoxideNn()">查看详情</el-button>
        </div>
        <div class="middle_showchild"  @click="smoke()">
          <div>
            <img src="../../assets/productCut/6103.png" />
          </div>
          <b>控烟
            探测器</b>
          <p>WSH6103</p>
          <el-button size="medium" @click="smoke()">查看详情</el-button>
        </div>
        <div class="middle_showchild"  @click="smokeLl()">
          <div>
            <img src="../../assets/productCut/6103L.png" />
          </div>
          <b>控烟
            探测器</b>
          <p>WSH6103L</p>
          <el-button size="medium" @click="smokeLl()">查看详情</el-button>
        </div>
      </div>
      <!-- 第3层 -->
      <div class="middle_show">
        <div class="middle_showchild" @click="SmokeWw()">
          <div>
            <img src="../../assets/productCut/6103W.png" />
          </div>
          <b>
            控烟
            探测器
          </b>
          <p>WSH6103W</p>
          <el-button size="medium" @click="SmokeWw()">查看详情</el-button>
        </div>
        <div class="middle_showchild" @click="flashlight()">
          <div>
            <img src="../../assets/productCut/yjsd.jpg" />
          </div>
          <b>应急手电</b>
          <p>WSH-YJSD-01</p>
          <el-button size="medium" @click="flashlight()">查看详情</el-button>
        </div>
        <div class="middle_showchild" @click="fireBlanket()">
          <div>
            <img src="../../assets/productCut/mht.jpg" />
          </div>
          <b>灭火毯</b>
          <p>硅胶+玻纤</p>
          <el-button size="medium" @click="fireBlanket()">查看详情</el-button>
        </div>
        <div class="middle_showchild" @click="jylh()">
          <div>
            <img src="../../assets/productCut/JYLH.jpg" />
          </div>
          <b>家用礼盒</b>
          <p style="color:#fff"> zhnwei &nbsp;</p>
          <el-button size="medium" @click="jylh()">查看详情</el-button>
        </div>
      </div>
      <!-- 第4层 -->
      <div class="middle_show" style=";margin-bottom:6rem;">
        <div class="middle_showchild" @click="fireExtinguisher()">
          <div>
            <img src="../../assets/productCut/mhq500ml.jpg" />
          </div>
          <b>简易式水基灭火器</b>
          <p>MSWJ500</p>
          <el-button size="medium" @click="fireExtinguisher()">查看详情</el-button>
        </div>
        <div class="middle_showchild" @click="fireExtinguisher()">
          <div>
            <img src="../../assets/productCut/mhq900ml.jpg" />
          </div>
          <b>简易式水基灭火器</b>
          <p>MSWJ950</p>
          <el-button size="medium" @click="fireExtinguisher()">查看详情</el-button>
        </div>
        <div class="middle_showchild" style="height:0px;">

        </div>
        <div class="middle_showchild" style="height:0px">

        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
  </div>
</template>
<script>
  import Storage from "../../utils/Storage";

  export default {
    name: "productCenter",
    components: {



    },
    data() {
      return {
        isTop: true,
         // 图片父容器高度
        bannerHeight: 0,
        bannerWidth:""
      };
    },
    mounted() {
       this.bannerWidth = window.innerWidth;
      this.bannerHeight = window.innerWidth * 0.44
      console.log(this.bannerHeight, this.bannerWidth)
      document.body.scrollTop = 0
  // firefox浏览器中是这句
     document.documentElement.scrollTop = 0
      // window.addEventListener('scroll', this.scrollToTop)

      // const that = this
      // let ispeed = Math.floor(-that.scrollTop / 5)
      // document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
      //  console.log(ispeed)
    },
    created() {},

    methods: {

      //    为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
      scrollToTop() {
        const that = this
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        that.scrollTop = scrollTop
        that.btnFlag = false
        // if (that.scrollTop > 60) {
        //   that.btnFlag = true
        // } else {
        //   that.btnFlag = false
        // }
      },
      // 查看独立式感烟探测报警器6101
      examineWsh() {
        this.$router.push("/fireAlarm");
      },
      //查看独立式感烟探测报警器6101N
      examineWshNn() {
        this.$router.push("/fireAlarmNn");
      },
      //家用可燃气体探测器6200
      methane() {
        this.$router.push("/methane");
      },
      // 6200n
      methaneNn() {
        this.$router.push("/methaneNn");
      },
      // 一氧化碳
      carbonMonoxide() {
        this.$router.push("/carbonMonoxide");
      },
      // 一氧化碳6201N
      carbonMonoxideNn() {
        this.$router.push("/carbonMonoxideNn");
      },
      // 控烟
      smoke() {
        this.$router.push("/smoke");
      },
      // 控烟6103L
      smokeLl() {
        this.$router.push("/smokeLl");
      },
      // 控烟6103W
      SmokeWw() {
        this.$router.push("/smokeWw");
      },
      // 手电筒
      flashlight() {
        this.$router.push("/flashlight");
      },
      // 灭火毯
      fireBlanket() {
        this.$router.push("/fireBlanket");
      },
      // 灭火器
      fireExtinguisher() {
        this.$router.push("/fireExtinguisher");
      },
      jylh(){
        this.$router.push("/jylh");
      }

    },
    destroyed() {
      window.removeEventListener('scroll', this.scrollToTop)
    },
  };
</script>
<style lang="less" scoped>
  @media screen and (min-width: 0px) and (max-width: 1200px) {
    .productCenter {
      background-color: #fafafa;
      // overflow-x: auto;
      min-width: 1000px;
    }
  }

  @media screen and (min-width: 1201px) {
    .productCenter {
      background-color: #fafafa;
      // overflow-x: auto;
      min-width: 1300px;
    }
  }


  .lunbotu {
    margin: 0 auto;
    width: 100%;
      text-align: center;
      img {
        width: 100%;
        margin: 0 auto;
      }

  }

  // @media screen and (min-width: 0px) and (max-width: 1370px) {
  //   .block {
  //     width: 100%;
  //     // margin-top: 5rem;

  //     .lunbotu {
  //    height: 36rem;
  //       .image {
  //         width: 100%;
  //         height: 100%;
  //       }
  //     }

  //     /deep/.el-carousel__button {
  //       border: 3px solid #f28c02;
  //       border-radius: 5px 5px;
  //       padding: 5px 10px;
  //     }
  //   }
  // }

  // @media screen and (min-width: 1371px) {
  //   .block {
  //     width: 100%;
  //     // margin-top: 5rem;

  //     .lunbotu {
  //      height: 53.125rem;
  //       .image {
  //         width: 100%;
  //          height: 100%;
  //       }
  //     }

  //     /deep/.el-carousel__button {
  //       border: 3px solid #f28c02;
  //       border-radius: 5px 5px;
  //       padding: 5px 10px;
  //     }

  //     // .el-carousel__container {
  //     //   position: relative;
  //     //   height: 450 / 16rem;
  //     // }
  //   }

  // }



  .product {
    width: 90%;
    margin: 0 auto;

    .text {
      text-align: center;
    }

    h3 {
      color: #a5a3a3;
    }

    .swiper-item {
      background-color: #fafafa;
    }
  }

  .selected_marker_container_left {
    width: 50%;
    float: left;
    height: 20px;
    background: #12b7f500;
    border: none;
    overflow: hidden;
  }

  .selected_marker_container_right {
    width: 50%;
    float: right;
    height: 20px;
    background: #12b7f500;
    border: none;
    overflow: hidden;
  }

  .index_nav {
    position: fixed;
    width: 100%;
    height: 41px;
    margin-right: 2rem;

    overflow: hidden;
    //z-index: 10000;
    z-index: 1000011;
    background: #fafafa;
    border-bottom: 1px solid #d9d9d9;

    //transition: all 0.5s;

    ul {
      width: 100%;
      padding-right: 0.2rem;
      height: 30px;
      float: right;

      text-align: center;
      border-bottom: #dddddd;


      li {
        font-size: 0.875rem;
        display: inline-block;
        height: 28px;
        vertical-align: middle;
        text-align: center;
        float: right;


        span {
          display: inline-block;
          padding: 8px 2px 0 2px;
        }

        list-style: none;
        cursor: pointer;

        //transition: font-size 0.5s, font-weight 0.5s;
        //navSelect
        .selected_marker {
          //background: #ff994f;
          width: 0;
          background: #12b7f5;
          //text-align: center;
          margin-top: 17px;
          margin-left: 0;
          margin-right: 0;
          height: 3px;
          border: none;
          transition: width 0.2s;
          overflow: hidden;
        }

        .selected_marker_left {
          float: left;
        }

        .selected_marker_right {
          float: right;
        }

        //.navSelect{
        //  font-weight: bold;
        //}
        //margin-bottom: 1px;
        &:hover {
          //background: #128bf533;
          //font-size: 1rem;
          //font-weight: bolder;
          //border-bottom: 2px solid #12b7f5;

          .selected_marker {
            //vertical-align: bottom;
            //text-align: center;
            width: 100%;
          }
        }
      }
    }
  }

  .index_nav_top {
    background: #333 !important;
    border-bottom: 1px solid #d9d9d900;

    ul {
      background: #333 !important;

      li {
        background: #333 !important;
        color: #b2b1af;
      }
    }
  }

  .major_service {
    width: 100%;
    background: #ffffff;
    padding-bottom: 20px;

    // margin-top: 20px;
    .middle_major_show {
      width: 60%;
      height: 14rem;
      // border: 1px solid red;
      margin: 0 auto;
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;

      .middle_major_showchild {
        width: 48%;
        border: 1px solid red;
        background-color: #fff;
        height: 100%;
      }
    }

    .text_list {
      width: 60%;
      margin: 0 auto;
      font-size: 1.5rem;
      line-height: 2.5rem;
      color: #333333;
      padding: 3rem 0 4.2rem 0;
    }
  }

  // 菜单导航样式
  .menuNavigation {
    // margin-top: 41px;
    width: 100%;
    margin-left: 10px;
    height: 80px;
    // padding-left: 20%;
    //  border: 1px rebeccapurple solid;
    background-color: #fff;

    .el-button {
      padding: 1px 1px 1px 10px;
    }

    .imgLogo {
      // border: #12b7f5 1px solid;
    }

    img {
      padding-left: 78%;
      width: 50px;
      height: 50px;
    }

    /deep/.el-input-group__append,
    .el-input-group__prepend {
      background-color: #fff !important;
    }

    .menu_list {
      height: 80px;
      color: #A1A1A1;
      // border: 1px rebeccapurple solid;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .active {
        color: red;
        transition: all .8s;
        background: #000;
        color: #fff;
      }

      // .menu_listone:hover {
      //   color: #403C3B;
      //   cursor: pointer;
      // }
    }

    ul {
      width: 100%;
      padding-left: 3rem;
      text-align: center;
      border-bottom: #dddddd;

      div {
        padding: 0;
        margin: 0;
      }

      li {
        color: #A1A1A1;
        display: inline-block;
        height: 80px;
        line-height: 80px;
        margin: 0 auto;

        vertical-align: middle;
        text-align: center;

        span {
          display: inline-block;
          // padding: 8px 2px 0 2px;
        }

        list-style: none;
        cursor: pointer;

        //transition: font-size 0.5s, font-weight 0.5s;
        //navSelect
        .selected_marker {
          width: 0;
          background: #A1A1A1;
          //text-align: center;
          margin-top: 17px;
          margin-left: 0;
          margin-right: 0;
          height: 3px;
          border: none;
          transition: width 0.2s;
          overflow: hidden;
        }

        .selected_marker_left {
          float: left;
        }

        .selected_marker_right {
          float: right;
        }

        //.navSelect{
        //  font-weight: bold;
        //}
        //margin-bottom: 1px;
        &:hover {
          //background: #128bf533;
          //font-size: 1rem;
          //font-weight: bolder;
          //border-bottom: 2px solid #12b7f5;

          .selected_marker {
            //vertical-align: bottom;
            //text-align: center;
            width: 100%;
          }
        }
      }
    }
  }

  .middle_show {
    width: 80%;
    // border: 1px solid red;
    margin: 0 auto;
    height: auto;
    // margin-top: 2rem;
    display: flex;
    justify-content: center;

    .middle_showchild:hover {
      box-shadow: 5px 5px 2px rgba(241, 169, 72, 0.18);

      img {
        transform: scale(1.2);
      }
    }

    .middle_showchild {
      width: 17.5rem;
      text-align: center;
      height: 23.75rem;
      margin: 12px;
         cursor: pointer;
      border-radius: 10px;
      background-color: #fff;

      img {
        width: 60%;
        // border: 1px solid red;
        margin: 0 auto;
        margin-top: 15%;
        margin-bottom: 2rem;
      }

      img:hover {
        transform: scale(1.2);
      }

      b {
        font-size: 1.25rem;

        color: #000;
      }

      b:hover {
        color: #333231;
        cursor: pointer;
      }

      p {
        //  font-size: 1.5rem;

        padding: 10px 0 20px 0;
      }

      p:hover {
        color: #f28c02;
        cursor: pointer;
      }

      .el-button {
        margin-bottom: 20px;
      }
    }
  }

  .middle_major_show {
    width: 88%;
    height: 14rem;
    // border: 1px solid red;
    margin: 0 auto;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;

    .middle_major_showchild {
      width: 48%;
      border: 1px solid #fff;
      background-color: #fff;
      height: 100%;
    }
  }

  .nav_container {
    float: right;
    margin-right: 2rem;
    background: red;

    li {
      margin-left: 2rem;
      display: inline-block;
      list-style: none;
    }
  }

  .nav_container_top {
    background: #12b7f500 !important;

    ul {
      background: #12b7f500 !important;

      li {
        background: #12b7f500 !important;
      }
    }
  }

  .product_content {
    width: 100%;
    height: 50rem;

    //position: relative;
    .imageBox {
      width: 100%;
      height: 35rem;
      background-image: url("../../assets/rotationChart/chanpin.jpg");
      background-size: cover;
      position: relative;
      transition: width 0.3s, height 1s;
      background-position: center center;
      //overflow: auto;
    }

    .introduce {
      height: 15rem;
      text-align: center;
      vertical-align: middle;
      color: #645d58;
      transition: width 0.3s, height 1s;
      position: relative;

      .product_title_top_layer {
        height: 3rem;
        padding-top: 6rem;
        transition: padding-top 0.3s;
        //padding-bottom: 6rem;
      }
    }

    .background-layer {
      //background: #12b7f5;
      width: 0;
      height: 15rem;
      position: absolute;
      z-index: 10000;
      transition: width 0.3s, height 1s;
      background-image: url("../../assets/rotationChart/index_bg2.jpg");
      background-size: cover;

      &:hover {
        width: 100%;
        background: #12b7f5;
      }
    }

    &:hover {
      .introduce {
        height: 20rem;
        color: #fafafa;

        //margin-top: 2rem;
        .product_title_top_layer {
          //height: 3rem;
          padding-top: 8rem;
          //padding-bottom: 6rem;
        }

        .background-layer {
          //background: #12b7f5;
          background-image: url("../../assets/rotationChart/index_bg2.jpg");
          background-size: cover;
          width: 100%;
          height: 20rem;
          position: absolute;
          z-index: 10000;
        }
      }

      .imageBox {
        height: 30rem;
      }
    }
  }

  .titleDisplay {
    background-color: #efefef;
    width: 100%;
    padding-bottom: 3rem;

    .item-title {
      padding-top: 6rem;
      text-align: center;
      color: #333333;
      width: 100%;
      margin: 0 auto;
      font-size: 2.1rem;
      font-weight: 500;

      p {
        text-shadow: 1px 1px 1px #666;
        //  text-decoration: underline;
        // border-bottom: 8px solid #f28c02;
        display: inline-block;
        padding: 0 50px;
        letter-spacing: 5px;
        font-weight: 550;
      }

      span {
        width: 14%;
        margin: 0 auto;
        height: 8px;
        margin-top: -10px;
        display: block;
        // background: #f28c02;
        background: rgba(242, 140, 2, 0.7);
      }
    }
  }

  .introduceOne,
  .introduceTwo {
    position: relative;
    z-index: 100000;
  }

  .introduceTwo {
    margin-top: 0.5rem;
  }

  //
  //.nav_selected {
  //  //border-bottom: 2px solid #ff994f;
  //  //overflow: hidden;
  //  color: #128bf5;
  //}

  .link-content {
    margin: 0 5% 0 5%;
    width: 90%;
    height: 10rem;

    //background: #ff994f;
    .link_item {
      margin-top: 2rem;
      text-align: center;
      width: 100%;
      transition: margin-top 0.5s;

      .link-item-marker {
        margin-top: 0.5rem;
        width: 100%;
        height: 3px;
        background: #0c9cf000;
        transition: background-color 0.5s;
      }

      &:hover {
        margin-top: 1rem;

        .link-item-marker {
          background: #0c9cf0ee;
        }
      }
    }
  }
</style>
