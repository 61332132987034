<template>
    <div>
        <div class="outting">
            <div class="select">
                <span class="title">问题处理</span>
                <el-button type="text" size="small" icon="el-icon-back" @click="gobackPlaceDetail"
                    class="addRoleButton">返回问题列表
                </el-button>
            </div>
            <!-- 流程节点展示 -->
            <div class="detail">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <span>处理流程</span>
                    </div>
                    <div class="stepShow">
                        <el-steps :active="milepostActive" direction="vertical">
                            <el-step v-for="(value, key) in milepost" :class="milepostActive== key+1 ? stepActive: '' "
                                :title="value.nodeName" :key="key" :description="value.value">
                                <template slot="description">
                                    <p><span style="color:#666">处理人：</span> {{value.handlerUser}} <span><span
                                                style="color:#666;display:inline-block;margin: 0 0 5px 10px">问题状态：</span>{{value.problemStatus}}</span>
                                        <span style="color:#666;display:inline-block;margin: 0 0 5px 10px">时间:</span>
                                        {{value.createTime}}
                                        <span style="color:#666;margin-left:15px">抄送人员信息：</span> <span
                                            v-for=" (item,index) in value.ccUserNames" :key="index"> {{item}} </span>
                                    </p>
                                    <p style="margin-bottom:20px"><span style="color:#666;">处理意见：</span>
                                        {{value.handleOpinion}} </p>
                                    <br />
                                    <div v-if="milepostActive == key">
                                        <el-form :model="formHandle" :rules="rulesHandle" ref="formHandle">
                                            <el-form-item label="处理结果" :label-width="formLabelWidth"
                                                prop="handleResurt">
                                                <el-select v-model="formHandle.handleResurt" clearable  placeholder="请选择处理结果"
                                                    @change="defaultHandler(formHandle.handleResurt)" size="mini"
                                                    style="width:60%;margin-bottom:10px">
                                                    <el-option v-for="item in handleResurt" :key="item.value"
                                                        :label="item.label" :value="item.value"></el-option>
                                                </el-select>
                                                <el-tooltip class="item" effect="dark" content="处理结果不通过，则问题节点返回到回滚节点"
                                                    placement="right-start">
                                                    <!-- <i class="el-icon-info" style="display:inline-black;margin-left:10px"></i> -->
                                                </el-tooltip>
                                            </el-form-item>
                                            <el-form-item label="回滚节点" v-if="rollbackShow" :label-width="formLabelWidth"
                                                prop="rollbackTemplateNodeId">
                                                <el-select v-model="formHandle.rollbackTemplateNodeId" filterable
                                                    @change="rollbackFn(formHandle)"
                                                    style="width:60%;margin-right:2rem;margin-bottom:10px" clearable
                                                    placeholder="请选择回滚节点" size="mini">
                                                    <el-option v-for="item in processTemplate" :key="item.id"
                                                        :label="item.nodeName" :value="item.id"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="指派人员" v-if="defaulShow" :label-width="formLabelWidth"
                                                prop="assignUserId"    >
                                                <el-select v-model="formHandle.assignUserId" clearable
                                                    :disabled="userDisabled"  placeholder="请选择指派人员"size="mini"
                                                    style="width:60%;margin-right:2rem;margin-bottom:10px">
                                                    <el-option v-for="item in handRealNameList" :key="item.value"
                                                        :label="item.realName" :value="item.sysUserId"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="抄送人员"    v-if="defaulShow" :label-width="formLabelWidth">
                                                <el-select v-model="formHandle.ccUserIds" placeholder="请选择抄送人员" clearable multiple size="mini"
                                                    style="width:60%;margin-right:2rem;margin-bottom:10px">
                                                    <el-option v-for="item in realNameList" :key="item.value"
                                                        :label="item.realName" :value="item.sysUserId"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="处理意见" :label-width="formLabelWidth"
                                                prop="handleOpinion">
                                                <el-input v-model="formHandle.handleOpinion" type="textarea" size="mini"
                                                    rows="10" style="width:60%;;margin-right:2rem;margin-bottom:10px">
                                                </el-input>
                                            </el-form-item>

                                        </el-form>
                                        <div slot="footer" class="dialog-footer" style="margin-bottom:2rem">
                                            <el-button @click="handleDialogVisible = false">取 消</el-button>
                                            <el-button type="primary" @click="submitFormFandle('form')">确 定</el-button>
                                        </div>
                                    </div>
                                </template>
                            </el-step>
                        </el-steps>

                    </div>
                </el-card>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "PlaceDetail",
        data() {
            return {
                formLabelWidth: "120px",
                // 默认步骤数
                milepostActive: 0,
                milepostId: false,
                milepost: [],
                // 动态添加类名
                stepActive: "stepActive",
                formHandle: {
                    assignUserId: "",
                    ccUserIds: [],
                    handleOpinion: "",
                    handleResurt: "",
                    rollbackTemplateNodeId: '',
                },
                handleResurt: [{
                        value: false,
                        label: "不通过"
                    },
                    {
                        value: true,
                        label: "通过"
                    }
                ],
                defaulShow: false, //处理人隐藏,
                rollbackShow: false, //处理人隐藏,
                handRealNameList: [],
                //修改账号弹出对话框标记
                handleDialogVisible: false,
                processTemplate: [], //回滚节点
                userDisabled: false,
                realNameList: [], //当前处理人员列表
                rulesHandle: {
                    assignUserId: [{
                        required: true,
                        message: "请选择指派人员",
                        trigger: "change"
                    }],
                    ccUserIds: [{
                        required: true,
                        message: "请选择抄送人员",
                        trigger: "change"
                    }],
                    handleOpinion: [{
                        required: true,
                        message: "请输入处理意见",
                        trigger: "blur"
                    }],
                    handleResurt: [{
                        required: true,
                        message: "请选择处理结果",
                        trigger: "change"
                    }],
                    rollbackTemplateNodeId: [{
                        required: false,
                        message: "请选择回滚节点",
                        trigger: "change"
                    }],
                },
                // additionalPicturesUrl: "", //附加图片地址
                // ccUsers: "", //抄送人员信息
                // createTime: "", //创建时间 
                // creatorUser: "", // 问题创建者 
                // handlerUser: "", //当前处理人员
                // ccUserNames: "", //抄送人员
                // id: "", //问题id 
                // mac: "", //关联mac号
                // problemDesc: "", //问题描述 
                // problemSource: "", // 问题来源 = ['后台', 'app'], 
                // problemStatus: "", // 问题状态  
                // productName: "", // 产品名称  
                // problemTitle: "", //问题标题 
                // problemType: "", // 问题类型 

            };
        },
        created() {
            sessionStorage.setItem("detailsIdProblem", this.$route.query.id);
        },
        mounted() {

            let detailsId = sessionStorage.getItem("detailsIdProblem");
            this.$api.problemHandleProcess(detailsId).then(res => {
                this.milepost = res.data.data
                let numList = []
                this.milepost.forEach((item, index) => {
                    if (item.id === null) {
                        numList.push(index)
                        this.milepostActive = numList[0]
                    } else {
                        this.milepostActive = 20
                    }
                })

                console.log(this.milepostActive)
            });
            //抄送下拉框接口
            this.$api.moduleselectorList().then(res => {

                this.realNameList = res.data.data;
            });
        },
           beforeRouteLeave(to, from, next) {
         if(to.path == '/questionList'){
         sessionStorage.setItem("isKeepAlive", "1");
         }
        next();
        console.log(to.path)
    },
        methods: {
            //回到模块页面
            gobackPlaceDetail() {
                 this.$router.back();
        let keepAliveList = {
               id: this.$route.query.id,
            isActive: this.$route.query.isActive,
            current: this.$route.query.current,
            ifSearchMany: this.$route.query.ifSearchMany,
            mac: this.$route.query.mac,
            handlerUserId: this.$route.query.handlerUserId,
            isHandlerUser: this.$route.query.isHandlerUser,
            problemStatus: this.$route.query.problemStatus,
            problemSource: this.$route.query.problemSource,
            creatorUserId: this.$route.query.creatorUserId,
            problemTitle: this.$route.query.problemTitle,
            productId: this.$route.query.productId,
            workOrderNo: this.$route.query.workOrderNo,
            severityCode: this.$route.query.severityCode,
        }
          sessionStorage.setItem("keepAliveList", JSON.stringify(keepAliveList));
            // console.log(keepAliveList)
                // this.$router.push("/questionList");
        //               this.$router.push({
        //   path: '/questionList',
        //   query: {
       
        //     isActive:this.$route.query.isActive,
        //     ifSearchMany:this.$route.query.ifSearchMany,
        //     mac:this.$route.query.mac,
        //     handlerUserId:this.$route.query.handlerUserId,
        //     current:this.$route.query.current,
        //     isHandlerUser:this.$route.query.isHandlerUser,
        //     problemStatus:this.$route.query.problemStatus,
        //     problemSource:this.$route.query.problemSource,
        //     creatorUserId:this.$route.query.creatorUserId,
        //     problemTitle:this.$route.query.problemTitle,
        //     productId:this.$route.query.productId,
        //     workOrderNo:this.$route.query.workOrderNo,
        //     severityCode:this.$route.query.severityCode,

        //   }
        // });
            },
            //回滚change事件
            rollbackFn(item) {
                let detailsId = sessionStorage.getItem("detailsIdProblem");
                this.$api.problemDefaultHandler({
                    problemId: detailsId,
                    handleResurt: item.handleResurt,
                    rollbackTemplateNodeId: item.rollbackTemplateNodeId
                }).then(res => {
                    if (res.data.code == 200) {
                        console.log(res.data.data)
                        this.userDisabled = false
                        // this.$refs["formHandle"].clearValidate();
                        this.formHandle.assignUserId = ""
                        if (res.data.data.length == 1) {
                            this.formHandle.assignUserId = res.data.data[0].realName
                            this.realName = res.data.data[0].sysUserId
                            this.userDisabled = true
                        } else {
                            this.handRealNameList = res.data.data
                        }
                    }
                });

            },
            // 处理结果change事件
            defaultHandler(item) {
                console.log(item)
                let detailsId = sessionStorage.getItem("detailsIdProblem");
                this.defaulShow = true
                if (item == false) {
                    this.rollbackShow = true
                    // 回滚节点
                    this.$api
                        .nodepreTemplateConfig(parseInt(detailsId))
                        .then(res => {
                            this.processTemplate = res.data.data;
                        });
                } else {
                    this.rollbackShow = false
                }
                this.$api.problemDefaultHandler({
                    problemId: parseInt(detailsId),
                    handleResurt: item,
                    rollbackTemplateNodeId: ""
                }).then(res => {
                    if (res.data.code == 200) {
                        console.log(res.data.data)
                        this.userDisabled = false
                        // this.$refs["formHandle"].clearValidate();
                        this.formHandle.assignUserId = ""
                        if (res.data.data.length == 1) {
                            this.formHandle.assignUserId = res.data.data[0].realName
                            this.realName = res.data.data[0].sysUserId
                            this.userDisabled = true
                        } else {
                            this.handRealNameList = res.data.data
                        }
                    }
                });

            },
            // 处理确定接口
            submitFormFandle() {
                let detailsId = sessionStorage.getItem("detailsIdProblem");
                this.$refs["formHandle"][0].validate(valid => {
                    if (valid) {
                        if (this.userDisabled == true) {
                            this.formHandle.assignUserId = this.realName
                        }
                        this.handleDialogVisible = false;
                        this.formHandle.problemId = parseInt(detailsId);
                        this.formHandle.ccUserIds = this.formHandle.ccUserIds;
                        console.log(this.formHandle)
                        this.$api.problemHandle(this.formHandle).then(res => {
                            if (res.data.code == 200) {
                                this.$message.success("处理成功!");
                                this.gobackPlaceDetail()
                                //  this.$router.push("/questionList");
                                // this.$api.problemHandleProcess(detailsId).then(res => {01
                                //     this.milepost = res.data.data
                                //        let numList = []
                                //     this.milepost.forEach((item, index) => {
                                //         if (item.id === null) {
                                //             numList.push(index)
                                //             this.milepostActive = numList[0]
                                //         } else {
                                //             this.milepostActive = 20
                                //         }
                                //     })
                                // });
                                // this.$refs["formHandle"][0].clearValidate();
                                // this.$refs["formHandle"][0].resetFields();
                                // this.formHandle.assignUserId = ""
                                // this.formHandle.ccUserIds = []
                                // this.formHandle.handleOpinion = ""
                                // this.formHandle.handleResurt = ""
                                // this.formHandle.rollbackTemplateNodeId = ""
                            }
                        });
                    } else {
                        return false;
                    }
                });
            },
        }
    };
</script>
<style lang="less" scoped>
    @border-bottom-color: #e7eaed;
    @toolBarFontColor: #111d;

    .outting {
        height: 100%;
        overflow: hidden;
        background-color: #fafafa;

        .item {
            height: 80px;
            width: 80px;
            border: 1px solid blue;
            float: left;
        }

        .select {
            height: 46px;
            overflow: hidden;
            border-bottom: 1px solid #d9d9d9;
            padding: 0rem 3rem;

            .title {
                display: inline-block;
                line-height: 46px;
                font-weight: bold;
                font-size: 0.8rem;
            }

            button {
                float: right;
                color: @toolBarFontColor;
                margin-top: 7px;
            }
        }

        .dialog-footer {
            width: 100%;
            margin: 0 30%;
        }

        .detail {
            padding: 0rem 3rem;
            margin-top: 1rem;

            .detail-span {
                font-size: 12 /16rem;
                color: #898989;
            }

            .detail-vv {
                margin-top: 5 /16rem;
                font-size: 14 /16rem;
            }
        }
    }

    .el-row {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-col {
        border-radius: 4px;
    }

    .bg-purple-dark {
        background: #99a9bf;
    }

    //   .bg-purple {
    //     background: #d3dce6;
    //   }
    .bg-purple-light {
        background: #e5e9f2;
    }

    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }

    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }

    .stepShow {
        width: 80%;
        margin: 3rem auto;

        .downloadList {
            //  transform:translate(5.5rem, -4rem)
        }

        /deep/.el-step.is-horizontal.stepActive {
            .el-step__head.is-finish {
                .el-step__line {

                    // 当前步骤数横线样式设置
                    .el-step__line-inner {
                        width: 50% !important;
                        border-width: 1px !important;
                    }
                }

                // 当前步骤数圆圈样式设置
                .el-step__icon.is-text {
                    background: #409eff;
                    color: #fff;
                }
            }
        }
    }

    #wrapper {
        background: radial-gradient(ellipse at top left,
                rgba(255, 255, 255, 1) 40%,
                rgba(229, 229, 229, 0.9) 100%);
        height: 100vh;
        padding: 60px 80px;
        width: 100vw;
        display: flex;
    }

    .state-item {
        width: 80px;
        height: 40px;
        color: #606266;
        background: #f6f6f6;
        border: 2px solid rgba(0, 0, 0, 0.05);
        text-align: center;
        line-height: 40px;
        font-family: sans-serif;
        border-radius: 4px;
        //   margin-right: 60px;
    }

    .line-wrap {
        //   display: flex;
        width: 100px;
        margin-bottom: 40px;
    }
</style>