import { render, staticRenderFns } from "./VedioTutorialDetail.vue?vue&type=template&id=5c0e7eb4&scoped=true&"
import script from "./VedioTutorialDetail.vue?vue&type=script&lang=js&"
export * from "./VedioTutorialDetail.vue?vue&type=script&lang=js&"
import style0 from "./VedioTutorialDetail.vue?vue&type=style&index=0&id=5c0e7eb4&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c0e7eb4",
  null
  
)

export default component.exports